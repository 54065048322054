import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';
import i18n from './../../i18n';
import { getReservations, requestCubicle } from './../../models/reservation';
import ReservationDetails from './partials/reservation-details';
import RoomDetails from './partials/room-details';
import { getPrinters } from './../../models/setting';
import ProgressBar from './../../components/snippets/progress-bar';
import SignalR from './../../components/signalr';
import Loader from './../../components/snippets/loader';
import Button from './../../components/button';
import ReactSVG from 'react-svg';
import iconExcludedAttendant from "../../assets/images/icon-excluded-attendant.svg";
import iconWalkin from "../../assets/images/icon-walkin.svg";
import iconBathPrivate from '../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from '../../assets/images/icon-shower-private.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRooms, getCubicles } from './../../models/room';
import { getActiveReception, updateActiveReception, getStaff } from './../../models/staff';

import ReceptionistTabs from './partials/tabs';
import { SettingsContext } from '../../components/settings';
import { Alert, UncontrolledTooltip, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

class ReceptionistView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      modals: {
        checkin: false,
        walkin: false,
      },
      isLoadedRooms: false,
      isLoadedCubicles: false,
      isLoadedStaff: false,
      printers: null,
      isLoadedReservations: false,
      reservations: null,
      errorReservations: null,
      selectedReservation: null,
      selectedRoom: null,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.viewReservation = this.viewReservation.bind(this);
    this.retrieveActiveReception = this.retrieveActiveReception.bind(this);
    this.handleChangeReceptionist = this.handleChangeReceptionist.bind(this);
    this.retrieveStaff = this.retrieveStaff.bind(this);
  }

  componentDidMount() {
    this.retrieveReservations();
    this.retrieveCubicles();
    this.retrieveRooms();
    this.retrieveActiveReception();
  }

  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result;

          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            errorReservations: error
          });
        }
      );
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          let groupedRooms = rooms.reduce(function (r, a) {
            r[a.effectiveStatus] = r[a.effectiveStatus] || [];
            r[a.effectiveStatus].push(a);
            return r;
          },Object.create(null));
          this.setState({
            isLoadedRooms: true,
            rooms: rooms,
            groupedRooms,
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: true,
            error
          });
        }
      );
  }

  retrieveCubicles() {
    getCubicles()
      .then(
        result => {
          this.setState({
            isLoadedCubicles: true,
            cubicles: result,
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedCubicles: true,
            error
          });
        }
      );
  }

  retrieveActiveReception() {
    getActiveReception()
      .then(
        result => {
          this.setState({
            isLoadedActiveReception: true,
            activeReception: result
          }, () => {         
            if(!result.length){
              this.retrieveStaff();
            }
          });
        },
        error => {
          this.setState({
            isLoadedCubicles: true,
            error
          });
        }
      );
  }

  handleChangeReceptionist(staff) {
    updateActiveReception(staff)
    .then(result => {
      this.setState({
        activeReception: result,
      })
    }, error => {
      this.setState({error});
    });
  }

  retrieveStaff() {
    getStaff(true)
      .then(result => {
        let staffs = result;
        let recs = [];
        if(!this.state.activeReception.length){
          recs = staffs && staffs.filter((staff,i) => {
            return staff.active && staff.role=="Receptionist"
          })
          if(recs.length==1){
            this.handleChangeReceptionist(recs[0].id)
          }
        }
        this.setState({
          staffs,
          isLoadedStaff: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  handleRequestCubicle = (e, reservationId) => {
    e.stopPropagation();
    this.setState({isSubmittingRequestCubicle: true});
    requestCubicle(reservationId)
      .then(result=>this.setState({isSubmittingRequestCubicle: false}));
  }

  viewReservation(reservationId) {
    this.setState({selectedReservation: reservationId});
  }

  viewRoom(roomId) {
    this.setState({selectedRoom: roomId});
  }

  handleReservationUpdated(res) {
    let index = this.state.reservations.all.findIndex(r => r.reservationId===this.state.selectedReservation);
    this.setState(prevState => ({
      reservations:{
        ...prevState.reservations,
        all: Object.assign([], prevState.reservations.all, {[index]:  res})        
      }
    }))
  }

  handleRoomUpdated(room) {
    const { rooms } = this.state;
    const index = rooms.findIndex(r => r.id === room.id);
    rooms[index] = room;
    this.setState({rooms});
  }

  // retrievePrinters() {
  //   getPrinters()
  //     .then(
  //       result => {
  //         this.setState({
  //           printers: result
  //         });
  //       },
  //       error => {
  //         this.setState({
  //           error: error
  //         });
  //       }
  //     );
  // }


  modalToggle(modal) {
    this.setState({selectedReservation: null, selectedRoom: null});
  }


  render() {
    const { t } = this.props;
    const { isLoadedReservations, isLoadedCubicles, isLoadedRooms, isLoadedActiveReception, isLoadedStaff, staffs, activeReception, rooms, selectedRoom, reservations, selectedReservation, cubicles, isSubmittingRequestCubicle, errorReservations } = this.state;
    let availableCubicleCount = cubicles?cubicles.filter(s=>s.effectiveStatus==='Available').length:0;
    let kallahReservationsCount = isLoadedReservations && reservations.all.filter(res=>res.kallah).length;
    const privateRooms = {"private-bath":iconBathPrivate, "private-shower": iconShowerPrivate}

    return (
      <div className={'container-page view-dashboard lang-' + i18n.language}>
        <SignalR setReception={true} onReservationsUpdated={() => this.retrieveReservations()} onRoomsUpdated={(s) => this.retrieveRooms(s)} />
        <div className="container-main">
          {isLoadedActiveReception?
            <div className="container-body reception-background">
              <ReceptionistTabs page="Dashboard"/>
              <div className="reception-view">
              {activeReception.length?
              <SettingsContext.Consumer>
                {({selPrinter, recPrinters, changePrinter, cardReaders, selCardReader, changeCardReader}) => (
                  (( selCardReader) || (cardReaders && !cardReaders.length)?
                    <>
                    <React.Fragment>
                      <div className="col-6">
                        { errorReservations!==null ?
                          <Alert color="danger">{errorReservations} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try Again")}</span></Alert>
                        :
                        isLoadedReservations && isLoadedCubicles ?
                        <div className="reservation-list">
                          <h3>{t("Waiting")}</h3>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>{t("Reservation")}</th>
                                <th>{t("Type")}</th>
                                <th>{t("Slot")}</th>
                                <th>{t("Arrived")}</th>
                                <th>{t("Room")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reservations.waiting.map((res,i) => {
                                return <tr key={i} className="clickable" onClick={() => res.reservationStatus === "Assigned" && res.roomId ? this.viewRoom(res.roomId) : this.viewReservation(res.reservationId)}>
                                  <td>{res.reservationCode}
                                    {res.firstTime?
                                      <>
                                        <sup><FontAwesomeIcon className="mis-2 text-orange small" icon="asterisk" id={`icon-new-user-${i}`} /></sup>
                                        <UncontrolledTooltip target={`icon-new-user-${i}`}>{t("New User")}</UncontrolledTooltip>
                                      </>
                                    :null}
                                    {res.user.testUser?<small className="text-muted"> {t("Test")}</small>:null}</td>
                                  <td>
                                    {res.kallah?
                                      <FontAwesomeIcon icon={['far', 'gem']} />
                                    :
                                      res.privateRoom?<ReactSVG className="icon-private" src={privateRooms[res.roomType]} />:<FontAwesomeIcon icon={res.roomType} />
                                    }
                                    {res.parking?<FontAwesomeIcon className="icon mis-2" icon="car" />:null}
                                    {res.doubleSlot?<FontAwesomeIcon className="icon mis-2" icon={["far", "clock"]} />:null}
                                    {res.salonManicure||res.salonBoth?<FontAwesomeIcon className="icon mis-2" icon="paint-brush" />:null}
                                  </td>
                                  <td>{res.advanceReservation?<Moment format={t("h:mm A")}>{res.reservationTime}</Moment>:<ReactSVG className="icon-walkin" src={iconWalkin} />}{res.user.phoneNumber.slice(-10)=='0000000000'?<FontAwesomeIcon className="mis-1" icon={'user-alt-slash'} />:null}</td>
                                  <td><Moment className={!res.arrivedOnTime&&res.advanceReservation?'text-danger':''} format={t("h:mma")}>{res.timeArrived}</Moment></td>
                                  <td>
                                    {res.roomIdPreassign&&!res.room?<span className="mie-2">{t("Pre")+"-"+res.roomIdPreassign}</span>:null}
                                    {res.room!==null?
                                      res.room.id
                                    :
                                    null
                                      // (res.cubicle?
                                      //   'C-'+res.cubicle.id
                                      // :
                                      //   (availableCubicleCount?
                                      //     <>
                                      //       <Button className="btn btn-secondary btn-sm" onClick={(e)=>this.handleRequestCubicle(e, res.reservationId)} icon={<FontAwesomeIcon icon="door-open" id={'waiting-reservation-cubicle-'+i} />} loading={isSubmittingRequestCubicle}></Button>
                                      //     </>
                                      //   :null)
                                      // )
                                    }
                                  </td>
                                </tr>
                              })}
                              {reservations.waiting.length===0?
                                <tr><td colSpan="6" className="text-center">{t("No waiting reservations.")}</td></tr>
                              :null}
                            </tbody>
                          </table>
                              
                          <h3>{t("Upcoming")}</h3>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>{t("Reservation")}</th>
                                <th>{t("Type")}</th>
                                <th>{t("Slot")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {reservations.upcoming.map((res,i) => {
                                return <tr key={i} className="clickable" onClick={() => this.viewReservation(res.reservationId)}>
                                  <td>
                                    {res.reservationCode}
                                    {res.firstTime?
                                      <>
                                        <sup><FontAwesomeIcon className="mis-2 text-orange small" icon="asterisk" id={`icon-new-user-${i}`} /></sup>
                                        <UncontrolledTooltip target={`icon-new-user-${i}`}>{t("New User")}</UncontrolledTooltip>
                                      </>
                                    :null}
                                    {res.user.testUser?<small className="text-muted"> {t("Test")}</small>:null}</td>
                                  <td>
                                    {res.kallah?
                                      <FontAwesomeIcon icon={['far', 'gem']} />
                                    :
                                    res.privateRoom?<ReactSVG className="icon-private" src={privateRooms[res.roomType]} />:<FontAwesomeIcon icon={res.roomType} />
                                  }
                                    {res.user.phoneNumber.slice(-10)=='0000000000'?<FontAwesomeIcon className="mis-2" icon={'user-alt-slash'} />:null}
                                    {res.parking?<FontAwesomeIcon className="icon mis-2" icon="car" />:null}
                                    {res.doubleSlot?<FontAwesomeIcon className="icon mis-2" icon={["far", "clock"]} />:null}
                                    {res.salonManicure||res.salonBoth?<FontAwesomeIcon className="icon mis-2" icon="paint-brush" />:null}
                                    {res.notes||res.user.notes?<FontAwesomeIcon icon={['far', 'sticky-note']} className="mis-2" />:''}
                                    {res.user.excludedAttendants && res.user.excludedAttendants.length>0?<ReactSVG className="icon mis-2 icon-excluded" src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>:''}
                                  </td>
                                  <td><Moment format={t("h:mm A")}>{res.reservationTime}</Moment></td>
                                  <td>{res.roomIdPreassign?t("Pre")+"-"+res.roomIdPreassign:null}</td>
                                </tr>
                              })}
                              {reservations.upcoming.length===0?
                                <tr><td colSpan="3" className="text-center">{t("No upcoming reservations.")}</td></tr>
                              :null}
                            </tbody>
                          </table>
                          </div>
                        : <Loader /> }
                      </div>
                    </React.Fragment>
                    <div className="col-6">
                      <div className="receptionist-cards checkin-card mb-4">
                        <div className="card">
                          <p className="title mb-5">{t("Check In")}</p>
                          <div className="row w-100">
                            <div className="col text-end"><Link className="btn btn-lg" to="/receptionist/checkin/">{t("Reservation")}</Link></div>
                            <div className="col text-start"><Link className="btn btn-lg" to={{pathname:"/receptionist/checkin/", state:{walkin: true}}}>{t("Walk-in")}</Link></div>
                          </div>
                        </div>
                      </div>
                      <div className="receptionist-cards mb-5">
                        <div className="card">
                          <p className="title mb-5">{t("Create New Reservation")}</p>
                          <Link className="btn btn-lg mb-3" to="/receptionist/reservation">{t("Start")}</Link>
                          <Link className="" to={{pathname:"/receptionist/reservation", state:{kallah:true}}}><FontAwesomeIcon className="mie-1" icon={['far', 'gem']} /> {t("Kallah Reservations")}</Link>
                        </div>
                      </div>
                      {errorReservations?
                        <Alert color="danger">{errorReservations} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                      :
                        ( isLoadedReservations ?
                          <div className="reservation-count">
                            <div className="title d-flex justify-content-between">
                            {t("Reservation Overview")} 
                              <span>
                                {kallahReservationsCount?<span className="mie-3"><FontAwesomeIcon className="mie-1" icon={['far', 'gem']} />{kallahReservationsCount}</span>:null}
                                <span className="num d-inline-block">{reservations.all.length}</span>
                              </span>
                            </div>
                            <ProgressBar reservations={reservations} />
                            <div className="row text-center">
                              <div className="col">{reservations.completed.length} {t("CompletedRooms")}</div>
                              <div className="col">{reservations.here.length} {t("Here")}</div>
                              <div className="col">{reservations.upcoming.length} {t("Upcoming")}</div>
                            </div>
                          </div>
                        : <Loader /> )
                      }
                    </div>
                  </>
              :
              !recPrinters||!cardReaders?
                <Loader />
              :
              <>
               {!selPrinter && recPrinters.length?
                <div className="container-body">
                  <h3 className="text-uppercase">{t("Please select a printer")}:</h3>
                  <div className="d-flex flex-wrap justify-content-center choose-printer">
                    {recPrinters && recPrinters.length ?
                      recPrinters.length > 1 ?
                        recPrinters.map((printer,i) => {
                          return <div className="mx-2" key={i}><Button type="circle" text={printer.printerName} onClick={() => changePrinter(printer.id)} /></div>;
                        })
                      : changePrinter(recPrinters[0].id)
                    :null}
                  </div>
                </div>
                :null}
                {!selCardReader?
                <div className="container-body">
                <h3 className="text-uppercase">{t("Please select a card reader")}:</h3>
                <div className="d-flex flex-wrap justify-content-center choose-printer">
                  {cardReaders && cardReaders.length ?
                    cardReaders.length > 1 ?
                      cardReaders.map((cardReader,i) => {
                        return <div className="mx-2" key={i}><Button type="circle" text={cardReader.readerName} onClick={() => changeCardReader(cardReader.stripeTerminalId)} /></div>;
                      })
                    : changeCardReader(cardReaders[0].stripeTerminalId)
                  :null}
                </div>
              </div>
              :null}
              </>
              )
            )}
          </SettingsContext.Consumer>
          : 
          isLoadedStaff?
            <div className="container-body">
              <h3 className="text-uppercase text-center mb-4">{t("Please select a receptionist")}:</h3>
              <div className="d-flex flex-wrap justify-content-center">
                <UncontrolledDropdown className="">
                    <DropdownToggle caret className="btn-block">
                      {t("Select Receptionist")}
                    </DropdownToggle>
                    <DropdownMenu>
                      {staffs && staffs.map((staff,i) => {
                        return staff.active && staff.role=="Receptionist" && !activeReception.filter(rec=>rec.id===staff.id).length ?
                         <DropdownItem key={i} onClick={() => this.handleChangeReceptionist(staff.id)}>{staff.name}</DropdownItem>
                         : null
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
              </div>
            </div>
          :<Loader />}
          </div>
          </div>
        :<Loader />}
        </div>
        {selectedReservation!==null?
          <ReservationDetails modalToggle={this.modalToggle} manager={this.props.manager} groupedRooms={this.state.groupedRooms}  handleReservationUpdated={(s) => this.handleReservationUpdated(s)} reservation={reservations.all.find(res=>res.reservationId===selectedReservation)} />
        :null}
        {isLoadedRooms && rooms ?
          <RoomDetails modalToggle={this.modalToggle} room={rooms.find(r => r.id === selectedRoom)} groupedRooms={this.state.groupedRooms} handleRoomUpdated={(r) => this.handleRoomUpdated(r)} />
        :null}
      </div>
    );
  }
}

export default withTranslation()(ReceptionistView);
