import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { supplyRequest, updateRoomStatus } from './../../../models/room';
import Loader from './../../../components/snippets/loader';
import Button from './../../../components/button';
import { SettingsContext } from '../../../components/settings';
import ReactSVG from 'react-svg';
import iconBath from '../../../assets/images/icon-bath.svg';
import iconShower from '../../../assets/images/icon-shower.svg';
import iconWheelchair from '../../../assets/images/icon-wheelchair.svg';
import iconKallah from '../../../assets/images/icon-kallah.svg';
import iconExcludedAttendant from "../../../assets/images/icon-excluded-attendant.svg";
import ScreenSaver from '../../../components/screensaver';
import SpecialModeImage from '../../../assets/images/special-mode.png';

class AttendantListView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      time: moment().format(),
      showNotes: false,
      showAllRooms: false,
      showListTemp: false
    };
    this.handleLeaveMikvah = this.handleLeaveMikvah.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.handleSupplyRequestCompleted = this.handleSupplyRequestCompleted.bind(this);
    this.toggleAttendantRooms = this.toggleAttendantRooms.bind(this);
    this.showListTemp = this.showListTemp.bind(this);
  }

  componentDidMount() {
    if ( this.props.id || this.props.attendantTablet ) {
      this.setUpTick();
    }
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  componentWillUnmount() {
    if ( this.props.id ) {
      clearInterval(this.intervalID);
    }
  }
  
  toggleNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes,
    })
    setTimeout(() => this.setState({showNotes: false}), 30000)
  }

  toggleAttendantRooms = (section) => {
    const { attendant } = this.props;
    const currentSection = section.attendant&&attendant&&section.attendant.id===attendant.id;
    if(section && !currentSection){
      return false;
    } else if(currentSection){
      this.setState({ showAllRooms: false })
    } else {
      this.setState({
        showAllRooms: !this.state.showAllRooms,
      })
      setTimeout(() => this.setState({showAllRooms: false}), 30000)
    }
  }

  handleSupplyRequestCompleted(res) {  
    let openAttendantRequest = res.supplies.filter(supply=>supply.isAttendantRequest&&supply.status==='open')[0]
    const roomId = res.room.id;
    let supplies = [openAttendantRequest.id];
    const action = 'complete';

    supplyRequest(roomId, supplies, action)
      .then(
        result => {
        },
        error => {
        }
      );
  }

  handleLeaveMikvah(roomId) {
    let status = "FromMikvah";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
    .then(
      result => {
      },
      error => {
        this.setState({
          error
        });
      }
    ).finally(() => 
      this.setState({
        isUpdatingStatus: false,
      })
    );
  }

  showListTemp(){
    this.setState({showListTemp: true})
    setTimeout(() => this.setState({showListTemp: false}), 5000)
  }

  render() {
    const { t, isLoadedRooms, rooms, isLoadedSections, sections, isLoadedAttendants, attendants, attendant, supplyRequests, allTaskView, attendantTablet, id, mikvahView, isUpdatingPrepStatus } = this.props;
    const { time, error, showNotes, showAllRooms, showListTemp } = this.state;
    const {settings} = this.context;
    const attendantRooms = (showAllRooms && rooms) || (attendantTablet && !attendant)  ? rooms : (rooms && attendant && !allTaskView ? rooms.filter(room => room.section.attendant && room.section.attendant.id===attendant.id) : rooms && rooms.filter(room => room.section.id === this.props.id));
    const roomsInPrep = attendantRooms && attendantRooms.filter(room => room.status=="Prep").length;
    const roomsInMikvah = attendantRooms && attendantRooms.filter(room => room.status=="InMikvah");
    const currentSection = isLoadedSections && sections.filter(sec=>sec.id==this.props.id)[0];
    const currentSectionAttendant = isLoadedAttendants && currentSection && attendants.filter(att=>currentSection.attendant&&att.id==currentSection.attendant.id)[0];

    return (
      <>
            {isLoadedSections && !allTaskView && !attendantTablet?
              <>
                <div className="d-flex flex-column attendant-list sections">
                    {sections.map((section,i) => {
                      return (
                        <div key={i} onClick={() => this.toggleAttendantRooms(section)} className={"col"+((section.attendant&&attendant&&section.attendant.id===attendant.id)?" currentSection"+(!showAllRooms?" active":""):"")}>{section.name}</div>
                        )
                      })}
                    <div onClick={() => this.toggleAttendantRooms(false)} className={"col col-all"+(showAllRooms?" active":"")}>{t("All")}</div>
                </div>
              </>
            :null}
            <div className="attendant-list container-body">
              <React.Fragment>
                <div className={!attendantTablet?"sidebar-top":""}>
                  {error !== null ?
                    <div>
                      <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                    </div>
                  :
                  settings!== null && ['Friday', 'ErevYomTov'].includes(settings.scheduleType.value)?
                    <div className="special-mode text-center">
                      {!allTaskView && settings!== null && moment().isBefore(moment(settings.zmanTevillah.value))?
                        <div>
                          <Alert color="warning">{t('Please Note')}: {t('Tevilah begins at')} <Moment format={t("h:mma")}>{settings.zmanTevillah.value}</Moment>.</Alert>
                        </div>
                      :null}
                      <div>
                        <img src={SpecialModeImage} className="image" alt="Special Mode" />
                      </div>
                      <div>
                        <div className="display-1">{t('Good '+(settings!== null && settings.scheduleType.value==='ErevYomTov'?'Yom Tov':'Shabbos'))}</div>
                      </div>
                    </div>
                  :
                    isLoadedRooms ?
                      <div className="attendant-list">
                        {!allTaskView && settings!== null && moment().isBefore(moment(settings.zmanTevillah.value))?
                          <div>
                            <Alert color="warning">{t('Please Note')}: {t('Tevilah begins at')} <Moment format={t("h:mma")}>{settings.zmanTevillah.value}</Moment>.</Alert>
                          </div>
                        :null}
                        {attendantRooms
                          .filter(room => room.effectiveStatus==="InMikvah" || room.effectiveStatus==="ReadyForMikvahNext" || room.effectiveStatus==="ReadyForMikvah" || room.effectiveStatus==="ReadyForCheckingNext" || room.effectiveStatus==="ReadyForChecking" || room.effectiveStatus==="Checking" || room.effectiveStatus==="Prep")
                          .length || (supplyRequests!=null && supplyRequests.length) || allTaskView?
                            null
                          : <div><ScreenSaver /></div>
                        }
                        {mikvahView && roomsInMikvah.length && !showListTemp &&(settings&&settings.reservationsUseFromToMikvah&&settings.reservationsUseFromToMikvah==="True")?
                          <div className="leave-mikvah">
                            {roomsInMikvah.map((room, i) => {
                              return <Button key={i} text="leaveMikvahText" roomId={room.id} onClick={() => this.handleLeaveMikvah(room.id)} loading={this.state.isUpdatingStatus} type="circle-big" />
                            })}
                            <div className="col-12 text-center"><Button text="Show List" type="secondary" size="lg" onClick={this.showListTemp} /></div>
                          </div>
                        :
                        <>
                        {attendantTablet && attendant && attendantRooms
                            .filter(room => room.effectiveStatus==="InMikvah")
                            .map((room, i) => {
                              return (
                                <div className="in-mikvah text-center mb-3" key={i}>
                                  <h3 className="title mb-3"><Trans i18nKey="mikvahCurrentlyWith">You are currently with Room {{roomId: room.id}}</Trans></h3>
                                  {room.reservation.backToPrep?
                                    <>
                                      <div>{t("Returning to Prep")}</div>
                                      <div><Button className="small text-muted" onClick={() => this.props.handleChangeStatus(room.id, "InMikvah")} loading={isUpdatingPrepStatus}>{t("Undo")}</Button></div>
                                    </>
                                  :
                                    <Button className="mb-3 btn-secondary" type="link" text="Return to Prep" onClick={() => this.props.handleChangeStatus(room.id, "BackToPrep")} loading={isUpdatingPrepStatus} />
                                  }
                                  <hr />
                                </div>
                            )})
                          }                                        
                        <table className="table">
                          <thead>
                            <tr>
                              <th className={allTaskView?"font-weight-normal":""}>{t("Status")}</th>
                              <th className={allTaskView?"font-weight-normal":""}>{t("Room")}</th>
                              <th>{allTaskView && currentSection?currentSection.name:t("Section")}</th>
                              <th>{allTaskView && currentSectionAttendant?currentSectionAttendant.firstName + " " + currentSectionAttendant.lastName:t("Balanit/Notes")}</th>
                              <th className={allTaskView?"font-weight-normal":""}>{t("Time")}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {supplyRequests
                            .filter(res => (!allTaskView && attendant && res.room && res.room.section && res.room.section.attendant && res.room.section.attendant.id===attendant.id) || (attendantTablet && !attendant) || (allTaskView && res.room && res.room.section && res.room.section.id == id))     
                            .map((res, i) => {
                              let roomTypeIcon;
                              if (res.kallah) {
                                roomTypeIcon = iconKallah;
                              } else if (res.handicapped) {
                                roomTypeIcon = iconWheelchair;
                              } else if (res.bath) {
                                roomTypeIcon = iconBath;
                              } else {
                                roomTypeIcon = iconShower;
                              }
                              let firstOpenSupplyRequest = res.supplies.filter(supply=>supply.status==="open")[0];                        
                              return <tr key={i}>
                                <td className="">{t("Supplies")}</td>
                                <td className="room-number">
                                  <div className="d-flex">
                                    {res.room.id}
                                    <ReactSVG src={roomTypeIcon} />
                                  </div>
                                </td>
                                {allTaskView?<td></td>:<td className="section-number">{res.room.section.name}</td>}
                                <td>
                                  {!allTaskView?<div className="small">{res.room.section.attendant.firstName} {res.room.section.attendant.lastName}</div>:null}
                                  {res.supplies.filter(supply=>supply.status==="open").map((supply, i) => <div key={i}>{t(supply.item)}</div>)}
                                </td>
                                <td className="times">
                                  <div className={firstOpenSupplyRequest.isOverTime?"over-time":""}>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(firstOpenSupplyRequest.dateRequested)).format("h:*mm:ss")}</div>
                                </td>
                                {res.supplies.filter(supply=>supply.isAttendantRequest&&supply.status==="open").length?
                                <td>
                                    <Button text="Mark Attendant Request Completed" type="secondary" onClick={() => this.handleSupplyRequestCompleted(res)} />
                                </td>
                                :null}
                              </tr>
                            })}
                          {!allTaskView &&  (!attendantTablet || !attendant) && attendantRooms
                            .filter(room => room.effectiveStatus==="InMikvah" || room.effectiveStatus==="Checking" || room.effectiveStatus==="ToMikvah")
                            .sort((a,b) => a.effectiveStatus<b.effectiveStatus?1:-1)
                            .map((room, i) => {
                              let roomTypeIcon;
                              if (room.reservation.kallah) {
                                roomTypeIcon = iconKallah;
                              } else if (room.reservation.handicapped) {
                                roomTypeIcon = iconWheelchair;
                              } else if (room.reservation.bath) {
                                roomTypeIcon = iconBath;
                              } else {
                                roomTypeIcon = iconShower;
                              }                        
                              return <React.Fragment key={i}>
                                <tr>
                                  <td className="">
                                    <div>{t(room.statusName)}</div>
                                  </td>
                                  <td className="room-number">
                                    <div className="d-flex">
                                      {room.id}
                                      <ReactSVG src={roomTypeIcon} />
                                    </div>
                                  </td>
                                  {allTaskView?<td></td>:<td className="section-number">{room.section.name}</td>}
                                  <td>
                                    {room.section.attendant?
                                    <div  className="small">{room.section.attendant.firstName} {room.section.attendant.lastName}</div>
                                    :null}
                                    {room.reservation.notes || room.reservation.user.notes || room.reservation.user.internalNote?
                                      <FontAwesomeIcon onClick={this.toggleNotes} icon={['far', 'sticky-note']} />
                                    : null}
                                    {room.reservation.user.excludedAttendants.length>0?
                                      <ReactSVG className="icon-excluded mis-2" onClick={this.toggleNotes} src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>
                                    : null}
                                  </td>
                                  <td className="times">
                                    <div className={room.isOverTime?"over-time":""}>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).format("h:*mm:ss")}</div>
                                  </td>
                                </tr>
                                {room.reservation.notes && showNotes?
                                  <tr><td colSpan="4">{t("Notes")}: {room.reservation.notes}</td></tr>
                                :null}
                                {room.reservation.user.notes && showNotes?
                                  <tr><td colSpan="4">{t("Notes")}: {room.reservation.user.notes}</td></tr>
                                :null}
                                {room.reservation.user.internalNote && showNotes?
                                  <tr><td colSpan="4">{t("Internal Note")}: {room.reservation.user.internalNote}</td></tr>
                                :null}
                                {room.reservation.user.excludedAttendants.length>0 && isLoadedAttendants && showNotes?
                                  <tr><td colSpan="4">{t("Excluded Attendants")}: 
                                  {room.reservation.user.excludedAttendants.map((ea,i) => {
                                    let att = attendants.find(att=>att.id===ea.attendantId);
                                    return (i>0?', ':' ') + (att&&att.name)              
                                  })}
                                  </td></tr>
                                :null}
                              </React.Fragment>
                            })
                          }
                          {attendantRooms
                            .filter(room => room.effectiveStatus==="ReadyForMikvahNext" || room.effectiveStatus==="ReadyForMikvah" || room.effectiveStatus==="ReadyForChecking" || room.effectiveStatus==="ReadyForCheckingNext")
                            .sort((a,b) => a.effectiveStatus==="ReadyForMikvahNext"?-1:b.effectiveStatus==="ReadyForMikvahNext"?1:a.effectiveStatus==="ReadyForCheckingNext"?-1:b.effectiveStatus==="ReadyForCheckingNext"?1:(moment(a.effectiveStatusLastChange).valueOf()-moment(b.effectiveStatusLastChange).valueOf()))
                            .map((room, i) => {
                              let roomIsNext = room.effectiveStatus==="ReadyForMikvahNext" && rooms.filter(room => attendant && room.section.attendant && room.section.attendant.id===attendant.id)
                              .filter(room => room.effectiveStatus==="InMikvah").length === 0;
                              let roomIsNextChecking = room.effectiveStatus==="ReadyForCheckingNext" && rooms.filter(room => attendant && room.section.attendant && room.section.attendant.id===attendant.id);
                              let roomTypeIcon;
                              if (room.reservation.kallah) {
                                roomTypeIcon = iconKallah;
                              } else if (room.reservation.handicapped) {
                                roomTypeIcon = iconWheelchair;
                              } else if (room.reservation.bath) {
                                roomTypeIcon = iconBath;
                              } else {
                                roomTypeIcon = iconShower;
                              }                        
                              return <React.Fragment key={i}>
                                <tr className={roomIsNext||roomIsNextChecking?"mikvahNext":""}>
                                  <td className="">
                                    <div>{t(room.statusName)}</div>
                                  </td>
                                  <td className="room-number">
                                    <div className="d-flex">
                                      {room.id}
                                      <ReactSVG src={roomTypeIcon} />
                                    </div>
                                  </td>
                                  {allTaskView?<td></td>:<td className="section-number">{room.section.name}</td>}
                                  <td>
                                  {!allTaskView && room.section.attendant?
                                    <div  className="small">{room.section.attendant.firstName} {room.section.attendant.lastName}</div>
                                    :null}
                                    {room.reservation.notes || room.reservation.user.notes || room.reservation.user.internalNote?
                                      <FontAwesomeIcon onClick={this.toggleNotes} icon={['far', 'sticky-note']} />
                                    : null}
                                    {room.reservation.user.excludedAttendants.length>0?
                                      <ReactSVG className="icon-excluded mis-2" onClick={this.toggleNotes} src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>
                                    : null}
                                  </td>
                                  <td className="times">
                                    <div className={room.isOverTime?"over-time":""}>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).format("h:*mm:ss")}</div>
                                  </td>
                                </tr>
                                {room.reservation.notes && showNotes?
                                  <tr className={roomIsNext?"mikvahNext":""}><td colSpan="4">{t("Notes")}: {room.reservation.notes}</td></tr>
                                :null}
                                {room.reservation.user.notes && showNotes?
                                  <tr className={roomIsNext?"mikvahNext":""}><td colSpan="4">{t("Notes")}: {room.reservation.user.notes}</td></tr>
                                :null}
                                {room.reservation.user.internalNote && showNotes?
                                  <tr><td colSpan="4">{t("Internal Note")}: {room.reservation.user.internalNote}</td></tr>
                                :null}
                                {room.reservation.user.excludedAttendants.length>0 && isLoadedAttendants && showNotes?
                                  <tr className={roomIsNext?"mikvahNext":""}><td colSpan="4">{t("Excluded Attendants")}: 
                                  {room.reservation.user.excludedAttendants.map((ea,i) => {
                                    let att = attendants.find(att=>att.id===ea.attendantId);
                                    return (i>0?', ':' ') + att.name              
                                  })}
                                  </td></tr>
                               
                                :null}
                              </React.Fragment>
                            })
                          }
                          {roomsInPrep?
                          <tr><td className="roomsPrep" colSpan="5">{t("Rooms in Prep")}: {roomsInPrep}</td></tr>
                          :null}
                          </tbody>
                        </table>
                        </>
                        }
                      </div>
                    : <Loader />
                  }
                </div>
              </React.Fragment>
            </div>
            </>
    );
  }
}

export default withTranslation()(AttendantListView);