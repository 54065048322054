import { api_url } from './global-props';
import { doRequest } from './models/account';


export function login(login) {
  let url = api_url + '../token';
  let body = Object.keys(login).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(login[key])).join('&');
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'}
  const method = 'POST';
  return fetch(url, { method: method, body: body, headers: headers })
    .then(response=>response.json());
}

export function register({username=null, phone=null, email=null, password=null, language=null}) {
  let url = api_url + 'account/register';
  let body = JSON.stringify({userName: username, phone: phone, email: email, password: password, language: language});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function registerVerify({phone=null, authCode=null}) {
  let url = api_url + 'account/registrationVerify';
  let body = JSON.stringify({phoneNumber: phone, authCode: authCode});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getRooms() {
  let url = api_url + 'rooms/list/';
  return doRequest(url);
}

export function getCubicles() {
  let url = api_url + 'settings/cubicles/';
  return doRequest(url);
}

export function getCleaningRooms() {
  let url = api_url + 'rooms/cleaninglist/';
  return doRequest(url);
}

export function updateCubicleStatus(id, status) {
  let url = api_url + 'settings/cubicles/' + id + '/status';
  let body = JSON.stringify({status});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function unlockCubicle(id) {
  let url = api_url + 'settings/cubicles/' + id + '/unlock';
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function getRoom(id) {
  let url = api_url + 'rooms/' + id;
  return doRequest(url);
}

export function reloadRoom(id) {
  let url = api_url + 'rooms/' + id + '/pagereload';
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function staffEntry(id, pinCode, clearRoom=false) {
  let url = api_url + 'rooms/' + id + '/staffEntry?pinCode=' + pinCode + '&clearRoom=' + clearRoom;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function doorIOEvent(id, action) {
  let url = api_url + 'rooms/' + id + '/doorioevent';
  let body = JSON.stringify({deviceName: `room ${id} dry`, action: action});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function unlockRoom(id, doorType='dry') {
  let url = api_url + 'hartmann/unlockroom?roomid=' + id + '&wetdry=' + doorType;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function getReservations(date=null, dateTo=null, kallah=false) {
  let url = api_url + 'reservations/list/';
  if ( process.env.REACT_APP_SOURCE==='mobileApp' ) { url = api_url + 'reservations/listadmin'; }
  if (date!==null) {
    url = url + '?dt=' + date;
    if (dateTo!==null) {
      url = url + '&dtTo=' +  dateTo;
    }
    if(kallah){
      url = url + '&bKallahOnly=' +  kallah;
    }
  }
  return doRequest(url);
}

export function getReservation(id) {
  let url = api_url + 'reservations/' + id;
  return doRequest(url);
}

export function getReservationsByUser(id, dateFrom=null, dateTo=null) {
  let url = api_url + 'reservations/findbyuser?user=' + id;
  if (dateFrom!==null) { url = url + '&dateFrom=' + dateFrom; }
  if (dateTo!==null) { url = url + '&dateTo=' + dateTo; }
  return doRequest(url);
}

export function openGarageDoor(reservationId, lat, lng) {
  let url = api_url + 'reservations/' + reservationId + '/opengaragedoor';
  let body = JSON.stringify({lat,lng});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function requestCubicle(reservationId) {
  let url = api_url + 'reservations/' + reservationId + '/requestcubicle';
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function createReservation(newres, ignoreCutoff=false, ignoreAvailability=false) {
  let url = api_url + 'reservations/newreservation?v=1';
  url = url + (ignoreCutoff ? '&ignoreCutoff=True' : '');
  url = url + (ignoreAvailability ? '&ignoreAvailability=True' : '');
  let body = JSON.stringify(newres);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function updateReservationStatus(id, status) {
  let url = api_url + 'reservations/' + id + '/status/';
  let body = JSON.stringify({ status: status });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function addReservationMerchandise(id, merch) {
  let url = api_url + 'reservations/' + id + '/merchandise/';
  let body = JSON.stringify(merch);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function checkinReservation(id, {printerId=null}, userLanguage) {
  let url = api_url + 'reservations/checkin?resId=' + id;
  if (printerId!==null) {
    url = url + '&printer=' + printerId;
  }
  if (userLanguage!==null) {
    url = url + '&userLanguage=' + userLanguage;
  }
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function printReceipt(id, printerId) {
  let url = api_url + 'reservations/printreceipt?resId=' + id + '&printer=' + printerId;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getAvailableReservations(date, resId) {
  let url = api_url + 'reservations/availablereservations?dt=' + date + '&replacingreservationid=' + resId;
  return doRequest(url);
}

export function updateNote(id, notes=null, userNotes=null) {
  let url = api_url + 'reservations/' + id + '/notes';
  let body = JSON.stringify({ notes, userNotes });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function sendSms(msg, users, numbers) {
  let url = api_url + 'reservations/sendsms';
  let body = JSON.stringify({ msg: msg, users: users, numbers: numbers });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function assignRoom(reservationId, roomId, roomStatus) {
  let url = api_url + 'reservations/assignroom';
  let body = JSON.stringify({ reservationId: reservationId, roomId: roomId, roomStatus: roomStatus });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function preassignRoom(reservationId, roomId) {
  let url = api_url + 'reservations/' + reservationId + '/preassignroom/?roomId=' + roomId;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setRoomLanguage(id, language) {
  let url = api_url + 'rooms/' + id + '/setlanguage/';
  let body = JSON.stringify({ language: language});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function updateRoomStatus(id, status, reservationStatus) {
  let url = api_url + 'rooms/' + id + '/status/';
  let body = JSON.stringify({ status: status, reservationStatus: reservationStatus });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function swapRooms(roomA, roomB) {
  let url = api_url + 'rooms/swaprooms';
  let body = JSON.stringify({ roomA: roomA, roomB: roomB });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function requestSupplies(id, supply, action, isAttendantRequest=false) {
  let url = api_url + 'rooms/' + id + '/supplyrequest/';
  let body = "";
  if ( action==="add" ) {
    body = JSON.stringify({ action: action, items: supply, isAttendantRequest:isAttendantRequest });
  } else {
    body = JSON.stringify({ action: action, ids: supply, isAttendantRequest:isAttendantRequest });
  }
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getSections() {
  let url = api_url + 'sections/list/';
  return doRequest(url);
}

export function getSection(id) {
  let url = api_url + 'sections/' + id;
  return doRequest(url);
}

export function setSectionAttendant(id, attendantId) {
  let url = api_url + 'sections/' + id + '/setattendant/?attendantId=' + attendantId;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setSectionHelper(id, attendantId) {
  let url = api_url + 'sections/' + id + '/sethelper/?attendantId=' + attendantId;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setBorStatus(id, newStatus) {
  let url = api_url + 'sections/' + id + '/setborstatus/?newStatus=' + newStatus;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getTimes(date=null) {
  let url = api_url + 'settings/zmanim';
  if (date!==null) {
    url = url + '?dt=' + date;
  }
  return doRequest(url);
}

export function getWeather() {
  let url = api_url + 'weather/';
  return doRequest(url);
}

export function getUserByPhone(phone, isKiosk) {
  let url = api_url + 'users/findbyphone?phone=' + phone;
  url = isKiosk ? url + '&forkioskcheckin=true' : url;
  return doRequest(url);
}

export function getSettings() {
  let url = api_url + 'settings';
  return doRequest(url);
}

export function getPublicSettings() {
  let url = api_url + 'settings/publicsettings';
  return doRequest(url);
}

export function updateSetting(setting, value) {
  let url = api_url + 'settings/';
  let body = JSON.stringify({ key: setting, value: value });
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getAttendants(getAll) {
  let url = api_url + 'attendants/list' + (getAll?'?activeonly=false':'');
  return doRequest(url);
}

export function updateAttendant(attendant) {
  let url;
  if ( attendant.id!=null ) {
    url = api_url + 'attendants/' + attendant.id + '/update/';
  } else {
    url = api_url + 'attendants/add/';
  }
  let body = JSON.stringify(attendant);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getStaff(getAll) {
  let url = api_url + 'staff/list' + (getAll?'?activeonly=false':'');
  return doRequest(url);
}

export function updateStaff(staff) {
  let url;
  if ( staff.id!=null ) {
    url = api_url + 'staff/' + staff.id + '/update/';
  } else {
    url = api_url + 'staff/add/';
  }
  let body = JSON.stringify(staff);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getActiveReception() {
  let url = api_url + 'staff/activereception/';
  return doRequest(url);
}

export function updateActiveReception(staff) {
  let url = api_url + 'staff/activereception/' + staff;
  const method = 'PUT';
  return doRequest(url, { method: method});
}

export function deleteActiveReception(staff) {
  let url = api_url + 'staff/activereception/' + staff;
  const method = 'DELETE';
  return doRequest(url, { method: method});
}

export function getSavedCards(userId) {
  let url = api_url + 'users/' + userId + '/savedcards/';
  return doRequest(url);
}

export function addSavedCard(userId, paymentInfo) {
  let url = api_url + 'users/' + userId + '/savedcards/';
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  let body = JSON.stringify(paymentInfo);
  const method = 'PUT';
  return doRequest(url, { method: method, body: body, headers: headers});
}

export function deleteSavedCard(userId, cardId) {
  let url = api_url + 'users/' + userId + '/savedcards/' + cardId;
  const method = 'DELETE';
  return doRequest(url, { method: method});
}

export function getConnectionToken() {
  let url = api_url + 'payments/getterminalconnectiontoken';
  return doRequest(url);
}

export function createPaymentIntent(paymentIntent) {
  let url = api_url + 'payments/createpaymentintent';
  let body = JSON.stringify(paymentIntent);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getPaymentReport(dt, dtTo) {
  let url = api_url + 'payments/list?dt=' + dt + '&dtTo=' + dtTo;
  return doRequest(url);
}

export function getPaymentsByUser(user, dt, dtTo) {
  let url = api_url + 'payments/byuser?user=' + user + '&dt=' + dt + '&dtTo=' + dtTo;
  return doRequest(url);
}

export function createPayment(payment) {
  let url = api_url + 'payments/makepayment';
  let body = JSON.stringify(payment);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getAdminUsers() {
  let url = api_url + 'users/getadminusers';
  return doRequest(url);
}

export function getUsersByUsername(username) {
  let url = api_url + 'users/findbyusername?username=' + username;
  return doRequest(url);
}

export function getUser(userId) {
  let url = api_url + 'users/' + userId;
  return doRequest(url);
}

export function changeUserRole(userId, role, mode) {
  let url = api_url + 'users/' + userId + '/roles/' + role;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = mode==='delete'?'DELETE':'PUT';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function changeExcludedAttendants(userId, attendantId, mode) {
  let url = api_url + 'users/' + userId + '/excludedattendants/' + attendantId;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = mode==='delete'?'DELETE':'PUT';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setUserPassword(userId, password, confirmPassword) {
  let url = api_url + 'account/setpassword';
  let body = JSON.stringify({userId: userId, NewPassword: password, ConfirmPassword: confirmPassword});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function patchUser(userId, patch) {
  let url = api_url + 'users/' + userId;
  let body = JSON.stringify(patch);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PATCH';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function newUserInfo(userId, info) {
  let url = api_url + 'users/' + userId + '/newuserinfo';
  let body = JSON.stringify(info);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setLicenseRegistration(userId, content) {
  const token = window.tokens.accessToken;
  let url = api_url + 'users/' +  userId + '/LicenseAndRegistration';
  let body = content;
  const headers = {Authorization: `Bearer ${token}`}
  const method = 'POST';
  return fetch(url, { method: method,  headers: headers , body: body});
}

export function getAudioStreams() {
  let url = api_url + 'settings/audiostreams';
  return doRequest(url);
}

export function getAudioZones() {
  let url = api_url + 'settings/audiozones';
  return doRequest(url);
}

export function setAudio(zoneId, streamId, volume) {
  let url = api_url + 'settings/audiozones/' + zoneId;
  let body = JSON.stringify({stream: streamId, volume: volume});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setRoomAudio(roomId, streamId, volume) {
  let url = api_url + 'rooms/' + roomId + '/audio';
  let body = JSON.stringify({stream: streamId, volume: volume});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getPrinterStatus() {
  let url = api_url + 'settings/printerstatus';
  return doRequest(url);
}

export function getSchedule() {
  let url = api_url + 'settings/schedule';
  return doRequest(url);
}

export function updateSchedule(schedule) {
  let url = api_url + 'settings/editschedule';
  let body = JSON.stringify(schedule);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function getLanguage() {
  let url = api_url + 'settings/languagetexts';
  return fetch(url)
    .then(response=>response.json());
}

export function setLanguageText(params) {
  let url = api_url + `Settings/setLanguageText/?${params}`;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function setLanguageDescription(key, desc) {
  let url = api_url + `Settings/setLanguagedescription/?key=${key}&description=${desc}`;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function reloadAllScreens() {
  let url = api_url + 'settings/pagereload';
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
} 

export function submitContactForm(data) {
  let url = api_url + 'settings/ContactForm';
  let body = JSON.stringify(data);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

  export function openGarageGate() {
  let url = api_url + 'settings/OpenGarageGate';
  const method = 'POST';
  return doRequest(url, { method: method });
} 

export function getPrinters() {
  let url = api_url + 'settings/printers';
  return doRequest(url);
}

export function getCardReaders() {
  let url = api_url + 'settings/cardreaders';
  return doRequest(url);
}

export function getUsageReport(dt, dtTo) {
  let url = api_url + 'reservations/UsageReport?dt=' + dt + '&dtTo=' + dtTo;
  return doRequest(url);
}

export function getListReport(dt, dtTo) {
  let url = api_url + 'reservations/ListReport?dt=' + dt + '&dtTo=' + dtTo;
  return doRequest(url);
}

export function addBannerImage(image) {
  const token = window.tokens.accessToken;
  let url = api_url + 'settings/bannerimage';
  const headers = {Authorization: `Bearer ${token}`}
  let body = image;
  const method = 'POST';
  return fetch(url, { method: method,  headers: headers , body: body});
}

export function getMerchandise() {
  let url = api_url + 'settings/merchandise';
  return doRequest(url);
}

export function addMerchandise(items) {
  let url = api_url + 'settings/merchandise';
  let body = JSON.stringify(items);
  const method = 'POST';
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  return doRequest(url, { method: method, body: body, headers: headers });
}