import React from "react";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BodyHeader from "../partials/BodyHeader";
import Button from "../../../components/button";
import { getAvailableReservations, createReservation } from "./../../../models/reservation";
import Moment from "react-moment";
import moment from "moment";
import Loader from "./../../../components/snippets/loader";
import ZmanTevilah from "./../../../components/zman-tevilah";
import Shkia from "./../../../components/shkia";
import HebrewDate from './../../../components/hebrewDate';
import { Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import iconKallah from "../../../assets/images/icon-kallah.svg";
import iconParking from "../../../assets/images/icon-parking.svg";
import iconClockExtra from "../../../assets/images/icon-clock-extra.svg";
import iconWheelchair from "../../../assets/images/icon-wheelchair.svg";
import ReactSVG from "react-svg";
import { UncontrolledTooltip } from 'reactstrap';
import { appAuth } from './../../../router';
import CheckinCode from "./checkinCode";

class ReservationSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      optionParking: false,
      optionDoubleSlot: false,
      optionKallah: false,
      optionPrivateRoom: false,
      optionHandicapped: false,
      selectedSlotBath: null,
      selectedSlot: null,
      canContinue: false,
      customTimeOpen: false,
      customTime: {hour: null, minutes: null},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.filterReservations = this.filterReservations.bind(this);
  }

  componentDidMount() {
    this.retrieveAvailableReservations();
  }

  retrieveAvailableReservations() {
    getAvailableReservations(moment(this.props.reservation.reservationDate).format('YYYY-MM-DD'), this.props.reservation.replacingReservationId ? this.props.reservation.replacingReservationId : null )
      .then(
        result => {
          this.processAvailableReservations(result);

          //Load state if modifying existing reservation
          if ( this.props.reservation.reservationId || this.props.reservation.replacingReservationId ) {
            let resTimeSlot = result.filter(slot=>slot.timeSlot===this.props.reservation.reservationTime)[0];
            let resHour = moment(this.props.reservation.reservationTime).format('HH');
            let resMinutes = moment(this.props.reservation.reservationTime).format('mm');
            if(!resTimeSlot && moment(this.props.reservation.reservationTime).isSame(this.props.reservation.reservationDate,'day')){
              this.setState({
                customTimeOpen: true,
                customTime: {hour: resHour, minutes: resMinutes},          
              });  
            }
            this.setState({
              optionParking: this.props.reservation.parking,
              optionDoubleSlot: this.props.reservation.doubleSlot,
              optionKallah: this.props.reservation.kallah,
              optionPrivateRoom: this.props.reservation.privateRoom,
              optionHandicapped: this.props.reservation.handicapped,
              selectedSlotBath: this.props.reservation.bath,
              selectedSlot:  resTimeSlot?resTimeSlot:null,
            });
            this.processSelection();
          }
        },
        error => { 
          this.setState({
            error
          })
        }   
      )
  }

  processAvailableReservations(slots) {
    this.setState({
      slots: slots
    })
  }

  handleSubmit(e, ignoreAvailability=false) {
    const {customTime} = this.state;
    let date = moment(this.props.reservation.reservationDate).format('YYYY-MM-DD');
    let customTimeSlot = date+'T'+customTime.hour+':'+customTime.minutes;

    let res = {
      ...this.props.reservation,
      roomType: this.state.optionHandicapped ? "wheelchair" : (this.state.selectedSlotBath?"bath":"shower"),
      bath: this.state.selectedSlotBath,
      handicapped: this.state.optionHandicapped,
      kallah: this.state.optionKallah,
      privateRoom: this.state.optionPrivateRoom,
      parking: this.state.optionParking,
      doubleSlot: this.state.optionDoubleSlot,
      reservationTime: this.state.selectedSlot?this.state.selectedSlot.timeSlot:customTimeSlot,
      advanceReservation: true,
    }

    let ignoreCutoff = this.props.reservation.ignoreCutoff ? this.props.reservation.ignoreCutoff : false;
    ignoreAvailability = this.props.reservation.ignoreAvailability ? this.props.reservation.ignoreAvailability : ignoreAvailability;

    this.setState({
      isSubmitting: true,
    })

    //Create/modify reservation
    createReservation(res, ignoreCutoff, ignoreAvailability)
      .then(result => {
        this.props.handleUpdateReservation(result, 'checkin-payment', res.replacingReservationId);
      }, error => {
        this.setState({
          isSubmitting: false,
        })
        this.setState({error});
      });
  }

  selectSlot(slot, roomType) {
    if(slot === 'custom'){
      this.setState({
        selectedSlot: null, 
        customTimeOpen:true,
        canContinue: false,
        selectedSlotBath: roomType.name==="Bath",
      })
    } else {
      this.setState({
        selectedSlotBath: roomType.name==="Bath",
        selectedSlot: slot,
        customTimeOpen:false
      }, this.processSelection);
    }
  }

  selectOption(option) {
    let options = { [option]: !this.state[option] };
    if (option==='optionKallah') { options.optionHandicapped = false }
    // if (option==='optionHandicapped') { options.optionKallah = false; options.optionParking = (this.state.optionHandicapped ? options.optionParking : true); }
    if (option==='optionHandicapped') { options.optionKallah = false;}
    this.setState(options, this.processSelection);
  }

  processSelection() {
    const { selectedSlot, selectedSlotBath } = this.state;
    let canContinue = false;
    if ( selectedSlot ) {
      let availableReservations = selectedSlot.availableReservations.filter(s=>this.filterReservations(s, selectedSlotBath))[0];
      if (availableReservations && availableReservations.available>0) {
        canContinue = true;
      }
    }
    this.setState({
      canContinue: canContinue
    })
  }

  filterReservations(s, isBath) {
    const { optionDoubleSlot, optionHandicapped, optionKallah, optionPrivateRoom, optionParking } = this.state;
    let response = (s.bath === isBath
      && s.doubleSlot === (optionKallah ? false : optionDoubleSlot) //Assume DoubleSlot=false for Kallah reservations
      && s.handicapped === optionHandicapped
      && s.kallah === optionKallah)
      && s.privateRoom === optionPrivateRoom
      // && s.parking === (optionHandicapped ? true : optionParking)) //Assume Parking=true for Handicapped reservations
    return response;
  }

  render() {
    const { slots, selectedSlot, selectedSlotBath, canContinue, customTimeOpen, customTime, error, isSubmitting } = this.state;
    const { t, reservation } = this.props;
    const roomTypes = [{name: "Bath", iconName: 'bath'}, {name: "Shower", iconName: "shower"}];
    let customTimeContinue = customTimeOpen&&customTime.hour&&customTime.minutes
    let customSelected = customTimeOpen?'selected':''
    return (
      <>
        <BodyHeader goBack={this.props.goBack} isAdvancedReservation={true} isEditingReservation={this.props.isEditingReservation}/>
        {error?<div className="checkin-message"><Alert color="warning">{t(error)}</Alert></div>:null}
        <div className="modal-body">
          {slots?
            <>
              {!reservation.guest&&!reservation.userId&&!reservation.phoneNumber?<CheckinCode isEditingReservation={this.props.isEditingReservation} userId={this.props.userId} reservation={this.props.reservation} handleUpdateReservation={this.props.handleUpdateReservation} handleScreenChange={this.props.handleScreenChange} isAdvancedReservation={true} />:null}
              {!reservation.userId&&!reservation.phoneNumber?
                <div className="mb-3 cursor-pointer hover-underline" onClick={(e)=>this.props.handleUpdateReservation({...this.props.reservation,userId: null, guest: !reservation.guest})}>
                  <FontAwesomeIcon className="mie-1" icon={!reservation.guest?'user-alt-slash':'user'} />{t(!reservation.guest?"Guest Reservation":"User Reservation")}</div>
                  :!reservation.replacingReservationId?<div className="mb-3 cursor-pointer hover-underline" onClick={(e)=>this.props.handleUpdateReservation({...this.props.reservation,userId: null, phoneNumber:null, guest: false})}>{t("Switch User")}
                </div>
              :null}
              <div className="d-flex">
                <div className="closing-time">
                <Moment className="mie-1" format={t("dddd")}>{this.props.reservation.reservationDate}</Moment>
                <HebrewDate className="mie-1" date={this.props.reservation.reservationDate} day={true} month={true}/>
                <Moment format={t("MM/DD")}>{this.props.reservation.reservationDate}</Moment>
                </div>
                <Shkia className="mb-2 mx-3" date={moment(this.props.reservation.reservationDate).format('YYYY-MM-DD')} />
                <ZmanTevilah className="mb-4" date={moment(this.props.reservation.reservationDate).format('YYYY-MM-DD')} />
              </div>
              <div className="newReservation-body">
                <div className="reservation-sidebar">
                  <div className="title">{t("Options")}</div>
                  {[
                    // { name: "optionParking", title: "Parking", icon: iconParking },
                    { name: "optionDoubleSlot", title: "Extra Time", icon: iconClockExtra },
                    { name: "optionKallah", title: "Kallah", icon: iconKallah },
                    { name: "optionHandicapped", title: "Accessible", icon: iconWheelchair },
                    { name: "optionPrivateRoom", title: "Private Room", icon: "award" },
                  ].map((type, i) => {
                    return (
                      <div className="btn-container" key={i} id={"btnTooltip-"+type.name}>
                        <Button
                          type="circle d-block"
                          active={this.state[type.name]}
                          onClick={() => this.selectOption(type.name)}
                        >
                          {type.name == "optionPrivateRoom"?
                            <FontAwesomeIcon className="icon" icon={type.icon} />
                          :
                            <ReactSVG src={type.icon} className="icon" />}
                        </Button>
                        <UncontrolledTooltip target={"btnTooltip-"+type.name}>
                          {t(type.title)}
                        </UncontrolledTooltip>
                      </div>
                    );
                  })}
                </div>
                {roomTypes.map((roomType,i)=>{
                  return <div className="times-container" key={i}>
                    <div className="title"><FontAwesomeIcon icon={roomType.iconName} /> {t(roomType.name + " Room")}</div>
                    <div className="times">
                      {slots.map((slot,i) => {
                        let filteredSlot = slot.availableReservations.filter(s=>this.filterReservations(s, roomType.name==="Bath"))[0];
                        let className = "slot";
                        if ( !filteredSlot || filteredSlot.available<=0 ) { className = className + " disabled" }
                        if ( selectedSlot===slot && (selectedSlotBath===(roomType.name==="Bath")) ) {
                          className = className + " selected";
                        }
                        return <div onClick={() => this.selectSlot(slot, roomType)} className={className} key={i}><Moment format={t("h:mma")}>{slot.timeSlot}</Moment></div>;
                      })}
                    {customTimeOpen && selectedSlotBath==(roomType.name==="Bath")?
                      <div className={'slot custom '+ customSelected}>
                        <UncontrolledDropdown>
                          <DropdownToggle className="customTime" caret>{customTime.hour?customTime.hour:t("Hour")}</DropdownToggle>
                            <DropdownMenu>
                              {Array.from('x'.repeat(24)).map((val, i) =>{
                                let h = i<10?'0'+i:`${i}`;
                                return <DropdownItem className={h==customTime.hour?'active':''} key={i} onClick={()=>this.setState({customTime: {...this.state.customTime,hour:h}})}>{h}</DropdownItem>
                              }
                              )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <span className="px-1">:</span>
                        <UncontrolledDropdown>
                          <DropdownToggle className="customTime" caret>{customTime.minutes?customTime.minutes:t("Minutes")}</DropdownToggle>
                            <DropdownMenu>
                              {Array.from('x'.repeat(12)).map((val, i) =>{
                                let m = i*5;
                                m = m<10?'0'+m:`${m}`;
                                return <DropdownItem className={m==customTime.minutes?'active':''} key={i} onClick={()=>this.setState({customTime: {...this.state.customTime,minutes:m}})}>{m}</DropdownItem>
                              }
                              )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      :<div onClick={() => this.selectSlot('custom', roomType)} className={customTimeOpen&&selectedSlotBath==(roomType.name==="Bath")?'slot custom selected':'slot custom'}>{t("Custom Time")}</div>}
                    </div>
                  </div>
                })}
              </div>
              <div className="reservation-footer">
                <Button type="secondary" size="lg" disabled={!canContinue||(!reservation.userId&&!reservation.phoneNumber&&!reservation.guest)} loading={isSubmitting} onClick={this.handleSubmit}>{reservation.reservationId||reservation.replacingReservationId?t("Update Reservation"):t("Create Reservation")}</Button>
                {!canContinue&&(selectedSlot||customTimeContinue)&&appAuth.authorization.roles.includes('"Admin"')&&(reservation.userId||reservation.phoneNumber||reservation.guest)?
                  <div><Button type="link" onClick={(e)=>this.handleSubmit(e, true)}>{t("Manager Override")}</Button></div>
                :null}
              </div>
            </>
          : <Loader />}
        </div>
      </>
    );
  }
}

export default withTranslation()(ReservationSetup);
