import React from 'react';
import { withTranslation } from 'react-i18next';

class RoomRabbilist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rabbiList: [
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
        {
          title: 'Rabbi Weissmandel',
          number: '011-335-6584',
        },
      ]
    }
  }

  render() {
    const { rabbiList } = this.state;
    const { t } = this.props;

    return <div>
      <div className="rabbonim-content row">
        {rabbiList.map((section,is)=><div key={is} className="rabbi-num col-6">
          <h3>{t(section.title)}</h3>
          <h4>{section.number}</h4>
          </div>
          )}
        </div>
    </div>
  }
}

export default withTranslation()(RoomRabbilist);


