import React from 'react';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';

function RoomArrived(props) {
  const { t } = props;
  return <div className="room-arrived">
    <div className="title">{t('Arrived')}</div>
    <Moment format={t('h:mm A')}>{props.arrived}</Moment>
  </div>
}

export default withTranslation()(RoomArrived);