import React, { Component, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Moment from 'react-moment';
import { Alert, UncontrolledDropdown, Modal, ModalBody } from 'reactstrap';
import ReceptionistTabs from './partials/tabs';
import Loader from './../../components/snippets/loader';
import HebrewDate from './../../components/hebrewDate';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserByPhone, changeExcludedAttendants, getUser, patchUser } from "./../../models/user";
import { getReservationsByUser } from './../../models/reservation';
import { createPayment, getSavedCards, deleteSavedCard, getPaymentsByUser } from "./../../models/payment";
import { getAttendants } from './../../models/attendant';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { cardBrands, statuses, languages } from './../../global-props';
import StripeProvider from './../../components/stripeProvider';
import StripeTerminal from './../../components/stripeTerminal';
import StripeCardForm from './../../components/stripeCardForm';
import { SettingsContext } from "./../../components/settings";
import CurrencyFormat from './../../components/snippets/currency-format';
import Button from './../../components/button';
import NumberFormat from "react-number-format";
import ReservationDetails from './partials/reservation-details';
import SignalR from './../../components/signalr';
import { Link } from 'react-router-dom';
import i18n from './../../i18n';
import { useTable, useSortBy } from 'react-table';
import iconWalkin from "../../assets/images/icon-walkin.svg";
import iconExcludedAttendant from "../../assets/images/icon-excluded-attendant.svg";
import iconBathPrivate from '../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from '../../assets/images/icon-shower-private.svg';

class ReceptionistUserView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userResults: null,
      isUserResultsOpen: false,
      enteredPhone: '',
      plateNumber: "",
      selectedPaymentItem: null,
      otherPaymentItem: '',
      paymentAmount: '',
      creditMode: null,
      user: null,
      savedCards: null,
      isLoadingSavedCards: false,
      paymentReference: '',
      selectedReservation: null,
      activeUserResult: 0,
      modalDeleteCard: false,
      modalLicensePLate: false,
      overrideMaxExcluded: false
    };

    this.toggleUserResults = this.toggleUserResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleCashCheckReceived = this.handleCashCheckReceived.bind(this);
    this.handleGetSavedCards = this.handleGetSavedCards.bind(this);
    this.handleChargeNewCard = this.handleChargeNewCard.bind(this);
    this.handleChargeSavedCard = this.handleChargeSavedCard.bind(this);
    this.handleCapturePaymentIntent = this.handleCapturePaymentIntent.bind(this);
    this.setupLineItems = this.setupLineItems.bind(this);
  }

  componentDidMount() {
    if (this.props.userId) {
      this.retrieveUserById(this.props.userId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props && !this.props.userId ) {
      this.setState({user:null, enteredPhone: '', userResults: null, plateNumber:''})
    }
  }

  retrieveReservations(hideLoader=false) {
    if (!hideLoader) {this.setState({isLoadingReservations: true});}
    let userId = this.state.user?this.state.user.id:null;
    let dateFrom = moment('2019-01-01').format('YYYY-MM-DD');
    if (!userId) return;
    getReservationsByUser(userId, dateFrom)
      .then(
        result => {
          let reservations = result;
          this.setState({
            reservations: reservations,
            isLoadingReservations: false,
          });
        },
        error => {
          console.log(error);
          this.setState({
            isLoadingReservations: false,
            error
          });
        }
      );
  }

  retrievePayments(hideLoader=false) {
    if (!hideLoader) {this.setState({isLoadingPayments: true});}
    let userId = this.state.user?this.state.user.id:null;
    let dateFrom = moment('2019-01-01').format('YYYY-MM-DD');
    if (!userId) return;
    getPaymentsByUser(userId, dateFrom)
      .then(
        result => {
          let payments = result;
          this.setState({
            payments: payments,
            isLoadingPayments: false,
          });
        },
        error => {
          console.log(error);
          this.setState({
            isLoadingPayments: false,
            error
          });
        }
      );
  }

  retrieveAttendants() {
    this.setState({isLoadingAttendants: true})
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadingAttendants: false,
        })
      }, error => {
        this.setState({error, isLoadingAttendants: false});
      });
  }

  setupLineItems() {
    let lineItems = {
      type: 'cart',
      cart: {
        line_items: [
          {
            description: this.state.selectedPaymentItem,
            quantity: 0,
            amount: parseFloat(this.state.paymentAmount)*100,
          },
        ],
        total: Math.round(this.state.paymentAmount * 100),
        tax: null,
        currency: 'usd',
      }        
    }
    this.setState({lineItems});
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({[name]: value});
    if (name.substring(0,12)==='notification') { this.handleSaveNotification(name, value) }
  }

  handleChangePhone(e) {
    let numberLength = 15;
    let value = e.target.value;
    value = value.slice(0,numberLength);

    this.setState({ enteredPhone: value, userResults: null, error: null })
    if ( value.length >= 4) {
      getUserByPhone(value)
      .then(
        result => {
          this.setState({
            userResults: result,
            isUserResultsOpen: true,
          })
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
      
    }
  }

  retrieveUserById(userId) {
    getUser(userId)
      .then(result=>this.handleSelectUser(result));
  }

  handleSelectUser(user) {
    if (user) {
      this.setState({ enteredPhone: user.phoneNumber, user: user }, () => {
        this.retrieveReservations();
        this.handleGetSavedCards();
        this.retrieveAttendants();
        this.retrievePayments();
      });      
    }
  }

  handleRefreshUser(userId) {
    getUser(userId)
      .then(result => this.setState({user: result}), error => this.setState({error}))
  }

  toggleUserResults() {
    this.setState({isUserResultsOpen: !this.state.isUserResultsOpen});
  }

  handleGetSavedCards() {
    if (this.state.user) {
      this.setState({isLoadingSavedCards:true});
      getSavedCards(this.state.user.id)
        .then(result => {
          this.setState({
            savedCards: result,
            isLoadingSavedCards: false,
          })
        }, error => this.setState({error}))
    }
  }

  handleCreatePayment(payment) {
    payment.userId = this.state.user.id;
    this.setState({
      isLoading: true,
      error: null,
    })
    createPayment(payment)
      .then(() => {
        this.setState({
          paymentView: 'paid',
          isLoading: false,
        })
      }, error => {
        this.setState({error: error});
      })
  }

  handleCashCheckReceived(e) {
    e.preventDefault();
    let reference = this.state.paymentReference;
    let payment = {
      method: reference!=="" ? 'check' : 'cash',
      amt: this.state.paymentAmount,
      reference: reference!=="" ? reference : null,
    }
    this.handleCreatePayment(payment);
  }

  handleChargeNewCard(payload) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      sourceToken: payload.token.id,
      saveCard: true,
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'new'});
  }

  handleChargeSavedCard(card) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      sourceCard: card.id,
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'saved'});
  }

  handleCapturePaymentIntent(paymentIntent) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentIntent: paymentIntent.id,
      saveCard: true,
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'terminal'});
  }

  startOver = () => {
    this.setState({
      user:null,
      paymentAmount: null,
      paymentView: null,
      enteredPhone: null
    });
  }

  viewReservation = (resId) => {
    this.setState({selectedReservation: this.state.reservations.findIndex(res=>res.reservationId===resId), modalReservation: true});
  }

  handleReservationUpdated(res) {
    let reservations = this.state.reservations;
    reservations[this.state.selectedReservation] = res;
    this.setState({reservations})
  }

  handleKeyDown = e => {
    const { activeUserResult, userResults } = this.state;

    if (userResults) {
      if (e.keyCode===13) {
        this.handleSelectUser(userResults[activeUserResult]);
        this.setState({
          activeUserResult: 0,
          isUserResultsOpen: false,      
        })
      } else if (e.keyCode===38) {
        if (activeUserResult===0) return;
        this.setState({activeUserResult: activeUserResult - 1});
      } else if (e.keyCode===40) {
        if (activeUserResult-1===userResults.length) return;
        this.setState({activeUserResult: activeUserResult + 1});
      }      
    }
  }

  modalToggleDeleteCard = () => {
    this.setState({modalDeleteCard: !this.state.modalDeleteCard});
  }

  handleDeleteSavedCard = () => {
    this.setState({isSavingCards: true})
    deleteSavedCard(this.state.user.id, this.state.selectedDeleteCard.id)
      .then(result => {
        this.modalToggleDeleteCard();
        this.setState({isSavingCards: false, savedCards: result,})
      }, error => {
        this.setState({error, isSavingCards: false})
      });
  }

  modalToggleRemoveAttendant = () => {
    this.setState({modalRemoveAttendant: !this.state.modalRemoveAttendant});
  }

  handleRemoveAttendant = () => {
    const { user, selectedRemoveAttendant } = this.state;
    this.setState({isSavingAttendant: true})
    changeExcludedAttendants(user.id, selectedRemoveAttendant.id, 'delete')
      .then(result => {
        this.handleRefreshUser(user.id);
        this.modalToggleRemoveAttendant();
        this.setState({isSavingAttendant: false, overrideMaxExcluded:false})
      }, error => {
        this.setState({error, isSavingAttendant: false})
      })
  }

  modalToggleAddAttendant = () => {
    this.setState({modalAddAttendant: !this.state.modalAddAttendant, selectedAddAttendant: null});
  }

  handleAddAttendant = () => {
    const { selectedAddAttendant, user } = this.state;
    this.setState({isSavingAttendant: true})
    changeExcludedAttendants(user.id, selectedAddAttendant.id)
      .then(result => {
        this.handleRefreshUser(user.id);
        this.modalToggleAddAttendant();
        this.setState({isSavingAttendant: false,overrideMaxExcluded:false})
      }, error => {
        this.setState({error, isSavingAttendant: false})
      })
  }

  modalToggleUserNote = () => {
    let { userNote, user } = this.state;
    if (!userNote) { userNote = user.notes }
    this.setState({modalUserNote: !this.state.modalUserNote, userNote});
  }

  modalToggleInternalNote = () => {
    let { internalNote, user } = this.state;
    if (!internalNote) { internalNote = user.internalNote }
    this.setState({modalInternalNote: !this.state.modalInternalNote, internalNote});
  }

  modalToggleLicensePLate = () => {
    let { plateNumber, user } = this.state;
    if (!plateNumber) { plateNumber = user.plateNumber }
    this.setState({modalLicensePLate: !this.state.modalLicensePLate, plateNumber});
  }

  handleSaveNote = () => {
    const { user, userNote } = this.state;
    this.setState({isSavingNote: true});
    patchUser(user.id, [{"op": "add", "path": "/Notes", "value": userNote }])
      .then(result => {
        let user = result.result;
        this.modalToggleUserNote();
        this.setState({
          isSavingNote: false,
          user
        })
      }, error => {
        this.setState({error, isSavingNote: false})
      })
  }

  handleSaveInternalNote = () => {
    const { user, internalNote } = this.state;
    this.setState({isSavingInternalNote: true});
    patchUser(user.id, [{"op": "add", "path": "/InternalNote", "value": internalNote }])
      .then(result => {
        let user = result.result;
        this.modalToggleInternalNote();
        this.setState({
          isSavingInternalNote: false,
          user
        })
      }, error => {
        this.setState({error, isSavingNote: false})
      })
  }

  handleSavePlate = () => {
    const { user, plateNumber } = this.state;
    this.setState({isSavingPlate: true});
    let modNum = plateNumber.split("\n").join("|")
    patchUser(user.id, [{"op": "add", "path": "/plateNumber", "value": modNum }])
      .then(result => {
        let user = result.result;
        this.modalToggleLicensePLate();
        this.setState({
          isSavingPlate: false,
          user,
          plateNumber:user.plateNumber
        })
      }, error => {
        this.setState({error, isSavingPlate: false})
      })
  }

  handleSaveNotification = (name, value) => {
    const { user } = this.state;
    patchUser(user.id, [{"op": "add", "path": "/"+name, "value": value }])
      .then(result => {
        let user = result.result;
        this.setState({
          isSavingNotification: false,
          user
        })
      }, error => {
        this.setState({error, isSavingNotification: false})
      })
  }

  handleSaveLangauge = (lang) => {
    const { user } = this.state;
    this.setState({isSavingLanguage: true});
    patchUser(user.id, [{"op": "add", "path": "/Language", "value": lang.name }])
      .then(result => {
        let user = result.result;
        this.setState({
          isSavingLanguage: false,
          user
        })
      }, error => {
        this.setState({error, isSavingLanguage: false})
      })
  }
  
  paymentView() {
    const { t } = this.props;
    const { paymentView, savedCards, paymentAmount, paymentReference, isLoading, creditMode, lineItems, isLoadingSavedCards } = this.state;
    const { settings, receptionist } = this.context;
    let terminalId = settings&&receptionist?settings[`stripeReception${receptionist.title}TerminalId`].value:null;
    switch(paymentView) {
      case 'paid':
        return <div>
          <h3 className="mb-5">{t("Paid")} <b><CurrencyFormat value={paymentAmount} /></b></h3>
          <Button onClick={this.startOver} className="btn btn-secondary btn-lg">{t("Start Over")}</Button>
        </div>

      case 'cash':
        return <div className="payment-view payment-view-cash">
          <form autoComplete="off" onSubmit={this.handleCashCheckReceived}>
            {paymentAmount!==''?
              <>
                <div className="form-group">
                  <label htmlFor="paymentReference">{t("Check Number")} <span className="small text-muted">({t("Optional")})</span></label>
                  <input type="text" className="form-control form-control-lg" name="paymentReference" value={paymentReference} onChange={this.handleChange} />
                </div>
                <div>
                  <Button type="secondary" size="lg" loading={isLoading}>{paymentReference?t("Accept Check"):t("Accept Cash")}</Button>
                </div>
              </>
            :
              <Alert color="warning">{t("Please enter an amount.")}</Alert>
            }
            <div className="text-end">
              <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
            </div>
          </form>
        </div>

      case 'credit':
        return <div className="payment-view payment-view-credit">

          {paymentAmount!==''?
            <>
              {(creditMode===null||creditMode==='terminal')&&terminalId!==null?
                <StripeProvider>
                  <StripeTerminal 
                    paymentAmount={paymentAmount}
                    isLoading={isLoading}
                    lineItems={lineItems}
                    readerId={terminalId}
                    onReadyForCapture={(paymentIntent) => this.handleCapturePaymentIntent(paymentIntent)}
                    handleUpdateCreditMode={(mode) => this.setState({creditMode: mode, error: null})}
                    handleErrorMessage={(error) => this.setState({error, creditMode: null})}
                  />
                </StripeProvider>
              :null}
              
              {creditMode===null||creditMode==='saved'?
                <>
                  <div className="saved-cards">
                    <div className="header">
                      <div className="title mb-3">
                      {t("Charge Saved Payment Method")}:
                      </div>
                      
                      {!isLoading?
                        <button className="btn btn-link" onClick={() => this.setState({creditMode: 'new'})}>+ {t("Add New Card")}</button>
                      :null}
                    </div>
                    
                    {isLoading?
                      <Loader />
                    :
                      (isLoadingSavedCards?
                        <Loader />
                      :
                        (savedCards?
                          <div className="saved-cards-buttons row row-sm">
                            {savedCards.map((card,i) => <div className="col" key={i}><Button type="secondary btn-saved-card btn-block" size="lg" disabled={!paymentAmount} onClick={() => this.handleChargeSavedCard(card)} loading={isLoading}>
                              <div className="d-flex align-items-center">
                                {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon className="mie-2" icon={cardBrands[card.brand.toUpperCase()].icon} />:<div className="mie-2">{card.brand.toUpperCase()}</div>}
                                <div>{t("Charge card")} •••• <span className="last4">{card.last4}</span></div>
                                <div className="amount mis-auto small text-muted"><CurrencyFormat value={paymentAmount} /></div>
                              </div>
                            </Button></div>)}
                          </div>
                        :
                          <div className="text-center text-muted">{t("No saved cards")}</div>
                        )
                      )
                    }
                  </div>
                </>
              :null}
              
              {creditMode==='new'?
                <div className="new-card">
                  <div className="title mb-3">
                  {t("Enter a new card")}:
                  </div>
                  <StripeProvider>
                    <StripeCardForm
                      onGetToken={(payload) => this.handleChargeNewCard(payload)}
                      isLoading={isLoading}
                      handleIsLoading={(isLoading) => this.setState({isLoading: isLoading})}
                      paymentAmount={paymentAmount}
                      handleUpdateCreditMode={(mode) => this.setState({creditMode: mode})}
                    />
                  </StripeProvider>
                  <div className="text-end">
                    <Button type="link btn-cancel" onClick={() => this.setState({creditMode: null})}>{t("Cancel")}</Button>
                  </div>
                </div>
              :null}
            </>          
          :
            <Alert color="warning">{t("Please enter an amount.")}</Alert>
          }          

          {creditMode===null?
            <div className="text-end">
              <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
            </div>
          :null}
        </div>

      case 'balance':
      default:
        return <div>
          <div className="">
            <div className="title mb-3">
            {t("Choose a payment method")}:
            </div>
            <Button type="secondary mx-1" size="lg" onClick={() => this.setState({paymentView: 'cash'})}>{t("Cash/Check")}</Button>
            <Button type="secondary mx-1" size="lg" onClick={() => this.setState({paymentView: 'credit'})}>{t("Credit/Debit")}</Button>
          </div>
        </div>
    }
  }


  render() {
    const { t } = this.props;
    const { error, enteredPhone, userResults, isUserResultsOpen, user, isLoadingSavedCards, savedCards, isLoadingAttendants, attendants, isLoadingReservations, reservations, isLoadingPayments, payments, selectedReservation, activeUserResult, isSavingAttendant, isSavingNote, isSavingInternalNote, isSavingPlate, plateNumber, overrideMaxExcluded, isSavingCards, userNote, internalNote, isSavingNotification, isSavingLanguage } = this.state;
    const currentPageName = "Users";
    const { settings } = this.context;
    return (
      <div className={'container-page view-user lang-' + i18n.language}>
        <SignalR setReception={true} onReservationsUpdated={() => this.retrieveReservations(true)} onCubiclesUpdated={() => this.retrieveReservations(true)} />
        <div className="container-main">
          <div className="container-body reception-background">
            <ReceptionistTabs page={currentPageName}/>
            {error!==null?
              <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
            :null}

            {!user?
              <div className="container-checkin">
                <div className="modal-header">
                  <div className="header-title"><h4>{t("Users")}</h4></div>
                </div>
                <div className="modal-body">
                  <div className="mx-auto w-100 text-center">
                    <div className="checkin-numbers w-75 mx-auto mb-4">
                      <div className="title mb-3">{t("Enter the last 4 digits of guest's phone number")}</div>
                      <form autoComplete="off" onSubmit={(e)=>e.preventDefault()}>
                        <Dropdown inNavbar isOpen={isUserResultsOpen} toggle={this.toggleUserResults}>
                          <NumberFormat
                            className="form-control number-input"
                            type="tel"
                            name="phoneNumber"
                            decimalScale={0}
                            allowNegative={false}
                            placeholder="0000"
                            value={enteredPhone}
                            onChange={this.handleChangePhone}
                            onClick={()=>this.setState({isUserResultsOpen:true})}
                            onKeyDown={this.handleKeyDown}
                            autoFocus={true}
                          />
                          <DropdownToggle tag="span"></DropdownToggle>
                          {userResults&&userResults.length?
                            <DropdownMenu>
                              {userResults.map((s,i)=>
                                <DropdownItem key={i} active={i===activeUserResult} className="d-flex justify-content-between" onClick={()=>this.handleSelectUser(s)}>
                                  <div>{t("User Id")}: {s.id}</div>
                                  <div>{s.phoneNumber}</div>
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          :null}              
                        </Dropdown>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            :
              <>
                <div className="row reception-view">
                  <div className="col-8">
                    {isLoadingReservations?
                      <Loader />
                    :
                      reservations?
                      <>
                      <h3>{t("Reservations")}</h3>
                      <ReservationsTable attendants={attendants} data={reservations} t={t} viewReservation={this.viewReservation}/>
                      </>
                      :null
                    }
                    {isLoadingPayments && isLoadingReservations?
                      <Loader />
                    :
                    <>
                    <h3>{t("Payments")}</h3>
                      {payments && payments.length?
                        <table className="table text-start">
                          <thead>
                            <tr>
                              <th>{t("Time")}</th>
                              <th>{t("Method")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("Details")}</th>
                              <th className="text-end">{t("Amount")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.map((payment,i) => {
                              return <tr className={payment.reservationID?"clickable":""} onClick={() => payment.reservationID?this.viewReservation(payment.reservationID):null} key={i}>
                                <td>
                                  <Moment format={t("MM/DD")}>{payment.dateCreated}</Moment>
                                  <HebrewDate className="mis-1" date={payment.dateCreated} day={true} month={true}/>
                                </td>
                                <td>{t(payment.method)} {payment.method==='Fee' && payment.note?' - ' + payment.note:null}{payment.method==='Tip'?(payment.tipNote?' - ' + t(payment.tipNote):' - ' + t("General")):null}{payment.method==='CreditCard'&&payment.transactionID&&(settings && settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?<a href={"https://dashboard.stripe.com/payments/"+payment.transactionID} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="file-invoice-dollar" className="mis-2" /></a>:null}</td>
                                <td>{t(payment.status)} {payment.status==='Approved'?<FontAwesomeIcon icon="check" className="mis-2" />:null}</td>
                                <td>{t(payment.reference)}</td>
                                <td className="text-end"><CurrencyFormat value={payment.amount} /></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      :<Alert className="mt-3 m-2 text-center" color="warning">{t("There are no payments for this user.")}</Alert>
                      }
                    </>
                    }
                  </div>
                  <div className="col-4 font-weight-normal">
                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom d-flex justify-content-between align-items-center">
                        <h5 className="card-title">{t("User Info")}</h5>
                        <UncontrolledDropdown>
                          <DropdownToggle className="btn btn-sm" tag="div"><FontAwesomeIcon icon="ellipsis-v" /></DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem tag={Link} to={{pathname: "../checkin/", state: { userId: user.id }}}>{t("Create Walk In")}</DropdownItem>
                            <DropdownItem tag={Link} to={{pathname: "../reservation/", state: { userId: user.id }}}>{t("Create Reservation")}</DropdownItem>
                            <DropdownItem tag={Link} to={{pathname: "../payment/", state: { userId: user.id }}}>{t("Create Payment")}</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <ul className="pis-0 list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <div>{t("Name")}</div>
                          <div>{user.firstName} {user.lastName}</div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <div>{t("User Id")}</div>
                          <div>{user.id}</div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <div>{t("User Code")}</div>
                          <div>{user.phoneNumber.slice(user.phoneNumber.length - 4)}</div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <div>{t("Phone")}</div>
                          <div>{user.phoneNumber}</div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <div>{t("Language")}</div>
                          {isSavingLanguage?
                            <Loader />
                          :
                            <UncontrolledDropdown>
                              <DropdownToggle caret className="btn btn-sm" tag="div">{user.language?languages.find(lang=>lang.name===user.language).title:t('Select')}</DropdownToggle>
                              <DropdownMenu right>
                                {languages.map((language,i)=><DropdownItem key={i} active={user.language===language.name} onClick={()=>this.handleSaveLangauge(language)}>{language.title}</DropdownItem>)}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          }
                        </li>
                      </ul>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom d-flex justify-content-between align-items-center">
                        <h5 className="card-title">{t("User Note")}</h5>
                        <Button className="btn-sm" onClick={this.modalToggleUserNote}><FontAwesomeIcon icon={user.notes?['far', 'edit']:'plus'} /></Button>
                      </div>
                      <div className="card-body">
                        {user.notes?
                          <div>{user.notes}</div>
                        :
                          <div className="text-center text-muted small">{t("No user notes")}</div>
                        }                        
                      </div>

                      <Modal isOpen={this.state.modalUserNote} toggle={this.modalToggleUserNote} centered={true} className={"lang-" + i18n.language} modalClassName="modal-delete-card">
                        <div className="modal-header">
                        {t("User Note")}
                          <button type="button" onClick={this.modalToggleUserNote}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <ModalBody>
                          <div className="form-group">
                            <textarea className="form-control" rows="3" name="userNote" value={userNote?userNote:''} onChange={this.handleChange}/>
                          </div>
                          <div className="text-start">
                            <div className="d-flex">
                              <Button className="btn btn-secondary mis-auto" onClick={this.handleSaveNote} loading={isSavingNote}>{t("Confirm")}</Button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom d-flex justify-content-between align-items-center">
                        <h5 className="card-title">{t("Internal Note")}</h5>
                        <Button className="btn-sm" onClick={this.modalToggleInternalNote}><FontAwesomeIcon icon={user.notes?['far', 'edit']:'plus'} /></Button>
                      </div>
                      <div className="card-body">
                        {user.internalNote?
                          <div>{user.internalNote}</div>
                        :
                          <div className="text-center text-muted small">{t("No internal note")}</div>
                        }                        
                      </div>

                      <Modal isOpen={this.state.modalInternalNote} toggle={this.modalToggleInternalNote} centered={true} modalClassName="modal-delete-card">
                        <div className="modal-header">
                        {t("Internal Note")}
                          <button type="button" onClick={this.modalToggleInternalNote}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <ModalBody>
                          <div className="form-group">
                            <textarea className="form-control" rows="3" name="internalNote" value={internalNote?internalNote:''} onChange={this.handleChange}/>
                          </div>
                          <div className="text-left">
                            <div className="d-flex">
                              <Button className="btn btn-secondary ml-auto" onClick={this.handleSaveInternalNote} loading={isSavingInternalNote}>{t("Confirm")}</Button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom">
                        <h5 className="card-title">{t("Notification Settings")}</h5>
                      </div>
                      <div className="list-group list-group-flush">
                        {[
                          {name: 'notificationReservations', title: 'reservationCreated'},
                          {name: 'notificationDayBefore', title: 'Reservation Day Before'},
                          {name: 'notificationDayOf', title: 'Reservation Day Of'},
                        ].map((notification,i)=>{
                          return <div key={i} className="list-group-item d-flex justify-content-between align-items-center">
                            <div>{t(notification.title)}</div>
                            {isSavingNotification===i?
                              <Loader className="mis-3" />
                            :
                              <div className="form-check form-switch mis-3">
                                <input type="checkbox" name={notification.name} onChange={e=>{this.handleChange(e);this.setState({isSavingNotification:i})}} checked={user[notification.name]} className="form-check-input" />
                              </div>
                            }
                          </div>
                        })}
                      </div>

                    </div>

                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom d-flex justify-content-between align-items-center">
                        <h5 className="card-title">{t("Excluded Attendants")}</h5>
                        <Button className="btn-sm" onClick={this.modalToggleAddAttendant}><FontAwesomeIcon icon="plus" /></Button>
                      </div>
                      {isLoadingAttendants?
                        <Loader />
                      :
                        <>
                          {attendants&&attendants.length?
                            <>
                              {(user.excludedAttendants?
                                <>
                                  <ul className="list-group list-group-flush">
                                    {user.excludedAttendants.map((ea,i)=>{
                                      let att = attendants.find(att=>att.id===ea.attendantId);
                                      return <div key={i} className="list-group-item d-flex justify-content-between align-items-center">
                                        <div>{att.name}</div>
                                        <Button className="btn-sm" onClick={()=>this.setState({selectedRemoveAttendant:att,modalRemoveAttendant:true})}><FontAwesomeIcon icon={['far', 'trash-alt']} /></Button>
                                      </div>
                                    })}
                                  </ul>

                                  <Modal isOpen={this.state.modalRemoveAttendant} toggle={this.modalToggleRemoveAttendant} centered={true} className={"lang-" + i18n.language} modalClassName="modal-delete-card">
                                    <div className="modal-header">
                                    {t("Remove Excluded Attendant")}
                                      <button type="button" onClick={this.modalToggleRemoveAttendant}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <ModalBody>
                                      <div className="text-start">
                                        <p>{t("Are you sure you want to remove this excluded attendant?")}</p>
                                        <div className="d-flex">
                                          <Button className="btn btn-secondary mis-auto" onClick={this.handleRemoveAttendant} loading={isSavingAttendant}>{t("Confirm")}</Button>
                                        </div>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </>
                              :
                                <div className="card-body text-center text-muted small">{t("No excluded attendants")}</div>
                              )}

                              <Modal isOpen={this.state.modalAddAttendant} toggle={this.modalToggleAddAttendant} centered={true} className={"lang-" + i18n.language} modalClassName="modal-delete-card">
                                <div className="modal-header">
                                {t("Add Excluded Attendant")}
                                  <button type="button" onClick={this.modalToggleAddAttendant}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <ModalBody>
                                  <div className="text-start">
                                    {user.excludedAttendants && user.excludedAttendants.length > 1 && !overrideMaxExcluded?
                                    <>
                                    <p>{t("maximumExcludedAtteandantsExceeded")}</p>
                                    <div className="d-flex">
                                      <Button className="btn btn-secondary mis-auto" onClick={() => this.setState({overrideMaxExcluded: true})}>{t("Manager Override")}</Button>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <p>{t("Choose an attendant to exclude for this user.")}</p>
                                    <UncontrolledDropdown>
                                      <DropdownToggle caret>{this.state.selectedAddAttendant?this.state.selectedAddAttendant.name:'Select Attendant'}</DropdownToggle>
                                      {attendants&&attendants.length?
                                        <DropdownMenu>
                                          {attendants.map((s,i)=>{
                                            if(!user.excludedAttendants.find(e => e.attendantId===s.id)){
                                              return <DropdownItem key={i} onClick={()=>this.setState({selectedAddAttendant:s})}>{s.name}</DropdownItem>
                                            }
                                          })}
                                        </DropdownMenu>
                                      :null}              
                                    </UncontrolledDropdown>
                                    <div className="d-flex">
                                      <Button className="btn btn-secondary mis-auto" onClick={this.handleAddAttendant} loading={isSavingAttendant}>{t("Confirm")}</Button>
                                    </div>
                                    </>
                                    }
                                    </div>
                                </ModalBody>
                              </Modal>
                            </>
                          :
                            <div className="card-body text-center text-muted small">{t("No attendants")}</div>
                          }
                        </>
                      }
                    </div>

                    <div className="card mb-3">
                      <div className="card-body pb-0 border-bottom">
                        <h5 className="card-title">{t("Saved Cards")}</h5>
                      </div>
                      {isLoadingSavedCards?
                        <Loader />
                      :
                        (savedCards&&savedCards.length ?
                          <>
                            <ul className="list-group list-group-flush">
                              {savedCards.map((card,i) => <div key={i} className="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                  {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon className="mie-2" icon={cardBrands[card.brand.toUpperCase()].icon} />:null}
                                  {card.brand} ••••{card.last4}</div>
                                <Button className="btn-sm" onClick={()=>this.setState({selectedDeleteCard:card,modalDeleteCard:true})}><FontAwesomeIcon icon={['far', 'trash-alt']} /></Button>
                              </div>)}
                            </ul>

                            <Modal isOpen={this.state.modalDeleteCard} toggle={this.modalToggleDeleteCard} centered={true} className={"lang-" + i18n.language} modalClassName="modal-delete-card">
                              <div className="modal-header">
                              {t("Delete Card")}
                                <button type="button" onClick={this.modalToggleDeleteCard}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                              </div>
                              <ModalBody>
                                <div className="text-start">
                                  <p>{t("Are you sure you want to delete this card?")}</p>
                                  <div className="d-flex">
                                    <Button className="btn btn-secondary mis-auto" onClick={this.handleDeleteSavedCard} loading={isSavingCards}>{t("Confirm")}</Button>
                                  </div>
                                </div>
                              </ModalBody>
                            </Modal>
                          </>
                        :
                          <div className="card-body text-center text-muted small">{t("No saved cards")}</div>
                        )
                      }
                    </div>
                  </div>
                </div>
                {selectedReservation!==null?
                  <ReservationDetails modalToggle={()=>this.setState({selectedReservation:null})} handleReservationUpdated={(s) => this.handleReservationUpdated(s)} reservation={reservations[selectedReservation]} />
                :null}
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ReceptionistUserView);

function ReservationsTable(props) {
  const { t, attendants } = props;
  const privateRooms = {"private-bath":iconBathPrivate, "private-shower": iconShowerPrivate}
  const columns = React.useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "reservationDate",
        sortDescFirst: true,
        sortType: (a, b) => {
          var a1 = moment(a.original.reservationTime).valueOf();
          var b1 = moment(b.original.reservationTime).valueOf();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
        Cell: ({row}) => {
          const res = row.original;
          return (
          <>
            <Moment format={t("MM/DD")}>{res.reservationTime}</Moment>
            <HebrewDate className="mis-1" date={res.reservationTime} day={true} month={true}/>
          </>
          )
        }
      },
      {
        Header: t("Status"),
        accessor: "reservationStatus",
        Cell: ({row}) => {
          const res = row.original;
          return t(statuses[res.reservationStatus].name)
        },
      },
      {
        Header: t("Type"),
        accessor: "type",
        Cell: ({row}) => {
          const res = row.original;
          return <>
            {res.privateRoom?<ReactSVG className="icon-private" src={privateRooms[res.roomType]} />:<FontAwesomeIcon icon={res.kallah?['far','gem']:res.roomType} />}
            {!res.advanceReservation?<ReactSVG className="icon-walkin mis-2" src={iconWalkin} />:null} 
            {res.parking?<FontAwesomeIcon className="icon mis-2" icon="car" />:null}
            {res.doubleSlot?<FontAwesomeIcon className="icon mis-2" icon={["far", "clock"]} />:null}
            {res.salonManicure||res.salonBoth?<FontAwesomeIcon className="icon mis-2" icon="paint-brush" />:null}
          </>
        },
        sortType: (a, b) => {
          var a1 = a.original.kallah?'kallah':a.original.roomType;
          var b1 = b.original.kallah?'kallah':b.original.roomType;
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Balance"),
        accessor: "balance",
        Cell: ({row}) => {
          const res = row.original;
          return res.paid?t('Paid'):<CurrencyFormat value={res.balance} />
        }
      },
      {
        Header: t("AttendantTevilah"),
        accessor: "attendantId",
        Cell: ({row}) => {
          const res = row.original;
          let atttevilah = attendants && attendants.find(att=>att.id===res.attendantId);
          return atttevilah?atttevilah.name:null
        }
      },
      {
        Header: t("Room"),
        accessor: "roomId",
        Cell: ({row}) => {
          const res = row.original;
          return res.room!=null?res.room.id:(['Done', 'Canceled'].includes(res.reservationStatus)?res.roomId:res.roomIdPreassign?t("Pre")+"-"+res.roomIdPreassign:null)
        },
        sortType: (a, b) => {
          var a1 = a.original.room!=null?a.original.room.id:(['Done', 'Canceled'].includes(a.original.reservationStatus)?a.original.roomId:a.original.roomIdPreassign?a.original.roomIdPreassign:null);
          var b1 = b.original.room!=null?b.original.room.id:(['Done', 'Canceled'].includes(b.original.reservationStatus)?b.original.roomId:b.original.roomIdPreassign?b.original.roomIdPreassign:null);
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Notes"),
        accessor: "notes",
        Cell: ({row}) => {
          const res = row.original;
          return <>{res.notes!=null||res.user.notes?<FontAwesomeIcon icon={['far', 'sticky-note']} />:null}
          {res.user.excludedAttendants.length>0?<ReactSVG className="icon-excluded mis-2" src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>:null}
          </>
        },
        sortType: (a, b) => {
          var a1 = !!(a.original.notes || a.original.user.notes);
          var b1 = !!(b.original.notes || b.original.user.notes);
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      ],
    []
  )
  const data = useMemo(()=>{
    return props.data ? props.data : [{}]
}, [props.data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },useSortBy);

  return (
    <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (<th className={(column.isSorted?"sort":"")+(column.canSort?" canSort":"")} {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
              </th>
            )})}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {data.length?
        rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="clickable"  onClick={(e) => props.viewReservation(row.original.reservationId)}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()} className={cell.column.Header=="SMS"?"smsCheckbox":""}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })
        :<tr>
            <td colSpan="10" className="text-center">{t("No reservations found.")}</td>
          </tr>
        }
      </tbody>
    </table>
  );
}
