import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from 'moment';
import 'moment/locale/he';
import { getLanguage } from './models/setting';


moment.updateLocale('en', {
  weekdays: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Motzei Shabbos" ]
});
moment.locale('en', {
  calendar : {
    lastDay : '[Yesterday]',
    sameDay : '[Today]',
    nextDay : '[Tomorrow]',
    lastWeek : '[Past] dddd',
    nextWeek : 'dddd',
    sameElse : 'LL'
  }
});
moment.locale('he', {
  calendar : {
    lastDay : '[אתמול]',
    sameDay : '[היום]',
    nextDay : '[מחר]',
    lastWeek : '[Past] dddd',
    nextWeek : 'dddd',
    sameElse : 'LL'
  }
});
let lang = "en";
let selectedLanguage = localStorage.getItem('selectedLanguage');
if (selectedLanguage) { lang = selectedLanguage}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: lang,

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: '|',

    //saveMissing: true,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
}).then(() => {
  getLanguage()
    .then(results => {
      let languages = {};

      for (const key in results) {
        let values = results[key]['values'];
        for (const lang in values) {
          let value = values[lang];
          if (!languages[lang]) { languages[lang] = { } }
          languages[lang][key] = value;
        }
      }

      for (const language in languages) {
        i18n.addResourceBundle(language, 'translation', languages[language], true, true);
        i18n.changeLanguage(i18n.language);
        moment.locale(i18n.language);  
      }
    })
});

export default i18n;