import React from 'react';
import moment from 'moment';
import { getSettings, getPublicSettings , getPrinters, getCardReaders } from '../models/setting';
import { getTimes } from '../models/times';
import { appAuth } from "../router";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.intervalID = setInterval(
      () => this.retrieveSettings(),
      360000
    );
  }

  componentDidMount() {
    this.retrieveSettings();
  }

  retrieveSettings() {
    let today = moment().startOf('day');
    let localStorageSettings = JSON.parse(localStorage.getItem('settings'));
    let storedDate = localStorageSettings && localStorageSettings.data && localStorageSettings.data.date ? moment(localStorageSettings.data.date.value) : null;
    let savedDate = localStorageSettings && localStorageSettings.savedTime ? moment(localStorageSettings.savedTime) : null;

    if ( localStorageSettings && moment(storedDate).isSame(today) && !moment().isAfter(savedDate,'hour') && !this.props.updateNow) {
      this.updateSettings(localStorageSettings.data);
    } else {
      Promise.all([getSettings(), getTimes(), getPublicSettings()])
        .then((result) => {
          let responseSettings = result[0];
          let responsePublicSettings = result[2];
          let responseTimes = [];
          let offset = moment().diff(moment(responseSettings&&responseSettings.currentTime&&responseSettings.currentTime.value),'seconds');
          Object.keys(result[1]).map(s=>(responseTimes[s] = {name: s, value: result[1][s]}));
          let settings = {...responseSettings, ...responsePublicSettings , ...responseTimes, 'offset':{value: offset}};
          localStorage.setItem('settings', JSON.stringify({savedTime: moment().format(), data: settings }));
          this.updateSettings(settings);
        }, error => error);
    }
  }

  updateSettings(settings) {
    if ( this.props.onSettingsUpdated ) {
      this.props.onSettingsUpdated(settings);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    return null;
  }
}

export default Settings;

export class SettingsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      selPrinter: null,
      recPrinters: null,
      kioskPrinters: null,
      cardReaders: null,
      selCardReader: null
    };
  }

  componentDidMount() {
    if(!this.props.mobile){
      let selPrinter = localStorage.getItem('selectedPrinter');
      let selCardReader = localStorage.getItem('selectedCardReader');
      if(appAuth.authorization&&(appAuth.authorization.roles.includes('Admin')||["Reception","Kiosk"].includes(appAuth.authorization.username))){
        if(selPrinter){
          this.setState({selPrinter});
        } else {
          this.retrievePrinters();
        }
        if(selCardReader){
          this.setState({selCardReader});
        } else {
          this.retrieveCardReaders();
        }
      }
    }
  }

  retrievePrinters = () => {
    getPrinters()
      .then(
        result => {
          let recPrinters = result.filter(printer => printer.printerType == 'reception');
          let kioskPrinters = result.filter(printer => printer.printerType == 'kiosk');
          this.setState({
            allPrinters: result,
            recPrinters: recPrinters,
            kioskPrinters: kioskPrinters
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }

  retrieveCardReaders = () => {
    getCardReaders()
      .then(
        result => {
          this.setState({
            cardReaders: result,
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }

  changePrinter = (selPrinter) => {
    if(selPrinter){
      localStorage.setItem('selectedPrinter', selPrinter);
    } else{
      localStorage.removeItem('selectedPrinter');
      this.retrievePrinters();
    }
    this.setState({selPrinter});  
  }

  changeCardReader = (selCardReader) => {
    if(selCardReader){
      localStorage.setItem('selectedCardReader', selCardReader);
    } else{
      localStorage.removeItem('selectedCardReader');
    }
    this.setState({selCardReader});
  }

  handleSettingsUpdated(settings) {
    this.setState({settings});
  }
  
  render() {
    const { settings, selPrinter, allPrinters, kioskPrinters, recPrinters,cardReaders, selCardReader } = this.state;
    const changePrinter = this.changePrinter;
    const changeCardReader = this.changeCardReader;
    return (
      <>
        <Settings onSettingsUpdated={(s) => this.handleSettingsUpdated(s)} />
        <SettingsContext.Provider value={{settings, changePrinter, allPrinters, selPrinter, kioskPrinters, recPrinters, cardReaders, selCardReader, changeCardReader}}>
          {this.props.children}
        </SettingsContext.Provider>
      </>
    );
  }
}

export const SettingsContext = React.createContext();