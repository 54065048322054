import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import BtnClickSoundFile from './../assets/sounds/btn-click.mp3';
import ReactSVG from "react-svg";
import LoaderSpinner from './../assets/images/icon-loader-spinner.svg';
import Audio from './Audio';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  setupSound = async () => {
    const filePath = BtnClickSoundFile;
    const sample = await this.setupAudioBuffer(Audio.context, filePath);
    return sample;
  }

  setupAudioBuffer = async (audioContext, filepath) => {
    if (!window.siteAudio) { window.siteAudio = []; }
    if (window.siteAudio[filepath]) { return window.siteAudio[filepath]; }
    const response = await fetch(filepath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    window.siteAudio[filepath] = audioBuffer;
    return audioBuffer;
  }

  playSound = (audioContext, audioBuffer) => {
    const sampleSource = audioContext.createBufferSource();
    sampleSource.buffer = audioBuffer;
    sampleSource.connect(audioContext.destination)
    sampleSource.start();
    return sampleSource;
  }

  onTouchEnd(e) {
    // if(this.disableDoubleClick){
    //   return;
    // }
    // this.disableDoubleClick = true;
    // setTimeout(()=>this.disableDoubleClick = false,5000);
    this.setupSound().then(buffer=>{
      this.playSound(Audio.context, buffer);
    })
    if (this.props.onClick) {
      e.preventDefault();
      this.props.onClick(e);
    }
  }

  onClick(e) {
    // if(this.disableDoubleClick){
    //   return;
    // }    
    // this.disableDoubleClick = true;
    // setTimeout(()=>this.disableDoubleClick = false,5000);
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { t ,className, background, text } = this.props;

    var icon;
    if (this.props.icon != null) {
      icon = <Icon icon={this.props.icon} />;
    }
    return <button
      className={
      (className?className:'') + " btn" + 
      (this.props.type!=null ? " btn-" + this.props.type.toLowerCase() : "") + 
      (this.props.size!=null ? " btn-" + this.props.size : "") + 
      (this.props.active!=null&&this.props.active?" active":"")}
      onClick={this.onClick} onTouchEnd={this.onTouchEnd}
      style={{
        backgroundImage: background?`url(${this.props.background})`:null,
      }}
      disabled={this.props.loading||this.props.disabled}
      title={this.props.title!=null ? this.props.title : ""}
    >
      <div className="btn-inner">
        {this.props.loading
        ? <ReactSVG className="loader-spinner" src={LoaderSpinner} />
        :
          <>
            {icon}
            {text==="leaveMikvahText"?
              <Trans i18nKey="leaveMikvahText"><span className="room-in-mikvah"></span>{{roomId:this.props.roomId}}</Trans>
            :t(text)}
            {this.props.children}
          </>
        }
      </div>
    </button>
  }
}
class Icon extends React.Component {
  render() {
    return (
      <div className="icon">
        {typeof this.props.icon === "object" ? (
          this.props.icon
        ) : (
          <img className="icon" src={this.props.icon} alt="" />
        )}
      </div>
    );
  }
}

export default withTranslation()(Button);
