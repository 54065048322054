import React, { Component } from 'react';
import i18n from 'i18next';
import { Trans, withTranslation } from 'react-i18next';

import Clock from './../../components/clock';
import moment from 'moment';
import Logo from './../../assets/images/MikvahLogo.png';
import ZmanTevilah from '../../components/zman-tevilah';

import { SettingsContext } from './../../components/settings';
import { logoImageAltText } from '../../global-props';

class LobbyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      time: moment(),
    };
    
  }

  componentDidMount() {
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      600000
    );
  }

  tick() {
    this.setState({
      time: moment(),
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { time } = this.state; 
    const he = i18n.getFixedT('he');
    const en = i18n.getFixedT('en');
    let hefsekDayEN = moment(time).locale('en').subtract(7,'d').format('dddd');
    let todayEN = moment(time).locale('en').format('dddd, MMMM DD');
    let hefsekDayHE = moment(time).locale('he').subtract(7,'d').format('dddd');
    let todayHE = moment(time).locale('he').format('dddd');
    return (
      <div>
        <div className={'container-page view-lobby lang-' + i18n.language}>
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-left row align-items-center">
                  <h1 className="dir-rtl">{he("Welcome!")}</h1>
                </div>
                <div className="header-center">
                  <Clock />
                  <ZmanTevilah />
                </div>
                <div className="header-right row align-items-center justify-content-end">
                  <h1 className="dir-ltr">{en("Welcome!")}</h1>
                </div>
              </div>
            </header>
            <div className="container-body text-center d-flex flex-wrap flex-row">
              <div className="col-5 d-flex flex-column justify-content-center">
                <img src={Logo} className="logo" alt={logoImageAltText}/>
              </div>
              <div className="col-7 d-flex flex-column justify-content-center">
              <SettingsContext.Consumer>
                {({settings})=> {
                  if (settings) {
                    return <h1 className="mb-5">
                      <Trans i18nKey="lobbyText" t={he}><span className="date"></span><span className="prevdate"></span>{{today:`${todayHE}, ${settings.hebrewDayHe.value} ${settings.hebrewMonthHe.value}`}}{{hefsekDay:hefsekDayHE}}</Trans>
                    </h1>
                  }
                }}
                </SettingsContext.Consumer>        
                <h1>
                  <Trans i18nKey="lobbyText" t={en}><span className="date"></span><span className="prevdate"></span>{{today:todayEN}}{{hefsekDay:hefsekDayEN}}</Trans>
                </h1>
              </div>
              <div className="col-12 mt-5">
                <h1 className="dir-ltr">{he("itemsLeftOver")}</h1>
                <h1 className="dir-ltr">{en("itemsLeftOver")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LobbyView);
