import React from 'react';
import { withTranslation } from 'react-i18next';
import iconLock from '../assets/images/icon-lock.svg';
import ReactSVG from 'react-svg';
import AttentionSoundFile from './../assets/sounds/attention.mp3';
import { SettingsContext } from './../components/settings';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';


class ScreenAssigned extends React.Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
    }
    this.savedContext = null;
    this.attentionAudio = new Audio(AttentionSoundFile);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidMount() {
    this.savedContext = this.context;
    this.setupSound();
  }

  componentDidUpdate() {
    if (this.context!==null && this.savedContext.settings !== this.context.settings) {
      if (this.context.settings!==null) {
        this.setupSound();
      }
    }
    this.savedContext = this.context;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "Prep";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }

  setupSound() {
    if (!this.isSoundSetup && this.context.settings) {
      this.isSoundSetup = true;
      const settingsAssignedMinutes = parseFloat(this.context.settings.assignedMinutes.value);
      this.timer = setTimeout(()=>this.playSound(), settingsAssignedMinutes*60*1000);
    }
  }

  playSound() {
    this.attentionAudio.play();
    this.timer = setTimeout(()=>this.playSound(), 30000);
  }

  render() {
    const { room, t } = this.props;
    const { error } = this.state;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}
        {room.dryDoorLocked || room.privateRoom?
          <Button type="circle-big" onClick={() => this.handleChangeStatus()} loading={this.state.isUpdatingStatus} text={t("Door is Locked")} />
        : 
          <>
          <div className="title">{t("Welcome!")}</div>
          <div className="lock-instructions">{t("Please make sure door is locked")}</div>
          <ReactSVG className="icon" src={iconLock} alt="Lock Icon" />
          </>
        }
      </div>
    );
  }
}

export default withTranslation()(ScreenAssigned);