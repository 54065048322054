import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Clock from './../../components/clock';
import moment from 'moment';
import SignalR from './../../components/signalr';
import Moment from 'react-moment';
import Logo from './../../assets/images/MikvahLogo.png';
import { logoImageAltText } from '../../global-props';
import ReactSVG from 'react-svg';

class ExitView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      time: moment().format(),
      inUse: null,
    };
    
    this.handleInUse = this.handleInUse.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  
  handleInUse() {
    this.setState({
      inUse: moment().add(20, 'seconds').format(),
    });
  }

  render() {
    const { t } = this.props;
    const { time, inUse } = this.state;

    return (
      <div>
        <div className={'container-page view-exit'}>
          <SignalR setExitScreen onEntryDoorOpen={() => this.handleInUse()} />
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-center">
                  <Clock />
                </div>
              </div>
              <div>
                <div className="header-center">
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <div className="container-body text-center">
              {moment(inUse).isAfter(moment()) ? (
                <div>
                  <h1>{t("The corridor is in use. Please wait before exiting.")}</h1>
                  <div className="timer">
                    <Moment diff={time} unit="seconds" className="number">{inUse}</Moment>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="mb-5"><img src={Logo} className="logo" alt={logoImageAltText}/></div>
                  <h1>
                  {t("Thank you for using The Mikvah")}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ExitView);
