import React from "react";
import { withTranslation } from 'react-i18next';

import { Alert } from 'reactstrap';
import Loader from './snippets/loader';
import Button from './button';
import { getConnectionToken, createPaymentIntent } from '../models/payment';
import LoaderSpinner from './../assets/images/icon-loader-spinner.svg';
import ReactSVG from 'react-svg';
import CurrencyFormat from './snippets/currency-format';
import iconTerminal from "./../assets/images/icon-terminal.svg";


class TerminalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.initializeTerminal = this.initializeTerminal.bind(this);
  }

  componentDidMount() {
    this.initializeTerminal();
  }

  initializeTerminal() {
    let terminal = window.StripeTerminal.create({
      onFetchConnectionToken: async () => {
        let connectionTokenResult = await this.handleFetchConnectionToken();
        return connectionTokenResult;
      },
      onUnexpectedReaderDisconnect: () => {}
    });
    this.setState({
      terminal
    })
  };

  handleFetchConnectionToken = async () => {
    return await getConnectionToken()
      .then(result => result.secret)
  }

  render() {
    if ( this.state.terminal ) {
      return (
        <TerminalForm stripeTerminal={this.state.terminal} {...this.props} />
      );

    } else {
      return null;
    }
  }
}

class TerminalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.terminal = this.props.stripeTerminal;
  }

  componentDidMount() {
    this.connectToSimulator();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lineItems !== this.props.lineItems) {
      this.setReaderDisplay();
    }
  }

  componentWillUnmount() {
    this.terminal.clearReaderDisplay();
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
  };

  connectToSimulator = async () => {
    const discoverResult = await this.terminal.discoverReaders();
    if (discoverResult.error || !discoverResult.discoveredReaders || !discoverResult.discoveredReaders.length) {
      console.log("Failed to discover: ", discoverResult.error||null);
      this.props.discoverError(discoverResult.error||'No card readers found')
      return discoverResult.error;
    } else {
      this.setState({
        discoveredReaders: discoverResult.discoveredReaders
      });
      await this.connectToReader(discoverResult.discoveredReaders.find((reader,i) => reader.id===this.props.readerId));
      this.setReaderDisplay();
      return discoverResult.discoveredReaders;
    }
  };

  connectToReader = async selectedReader => {
    // 2b. Connect to a discovered reader.
    const connectResult = await this.terminal.connectReader(selectedReader);
    if (connectResult.error) {
      console.log("Failed to connect:", connectResult.error);
      this.setState({
        isConnected: true,
      });
    } else {
      this.setState({
        status: "workflows",
        discoveredReaders: [],
        isConnected: true,
        reader: connectResult.reader
      });
      return connectResult;
    }
  };

  setReaderDisplay = () => {
    this.terminal.setReaderDisplay(this.props.lineItems);
  }

  // 3b. Collect a card present payment
  collectCardPayment = async () => {
    this.props.handleUpdateCreditMode('terminal');
    // We want to reuse the same PaymentIntent object in the case of declined charges, so we
    // store the pending PaymentIntent's secret until the payment is complete.
    if (!this.pendingPaymentIntentSecret||this.prevAmount!==this.props.paymentAmount) {
      try {
        this.setState({ isLoadingPaymentIntent: true });
        let createIntentResponse = await createPaymentIntent({
          userId: this.props.userId,
          reservationId: this.props.reservation ? this.props.reservation.reservationId : null,
          amt: this.props.paymentAmount,
        });
        this.prevAmount = this.props.paymentAmount;
        this.setState({ isLoadingPaymentIntent: false });
        this.pendingPaymentIntentSecret = createIntentResponse.client_secret;
      } catch (e) {
        // Suppress backend errors since they will be shown in logs
        return;
      }
    }
    // Read a card from the customer
    const paymentMethodPromise = this.terminal.collectPaymentMethod(
      this.pendingPaymentIntentSecret
    );
    this.setState({ cancelablePayment: true });
    const result = await paymentMethodPromise;
    if (result.error) {
      console.log("Collect payment method failed:", result.error.message);
    } else {
      const processResult = await this.terminal.processPayment(
        result.paymentIntent
      );
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      this.setState({ cancelablePayment: false });
      if (processResult.error) {
        this.props.handleErrorMessage(processResult.error.message);
        //alert(`Confirm failed: ${processResult.error.message}`);
      } else if (processResult.paymentIntent) {
        try {
          // Capture the PaymentIntent from your backend client and mark the payment as complete
          let captureResult = await this.props.onReadyForCapture(processResult.paymentIntent);
          this.prevAmount = null;
          this.pendingPaymentIntentSecret = null;
          console.log("Payment Successful!");
          return captureResult;
        } catch (e) {
          // Suppress backend errors since they will be shown in logs
          return;
        }
      }
    }
  };

  // 3c. Cancel a pending payment.
  // Note this can only be done before calling `confirmPaymentIntent`.
  cancelPendingPayment = async () => {
    await this.terminal.cancelCollectPaymentMethod();
    this.pendingPaymentIntentSecret = null;
    this.setState({ cancelablePayment: false });
    this.props.handleUpdateCreditMode(null);
  };

  render() {
    const { isConnected, reader, cancelablePayment, isLoadingPaymentIntent} = this.state;
    const { t, isLoading } = this.props;
    return (isConnected?
      (reader?
        cancelablePayment?(
          <div>
            <ReactSVG className="loader-spinner dark mb-3" src={LoaderSpinner} />
            <p>{t("Waiting for card...")}</p>
            <Button type="secondary" size="lg" onClick={this.cancelPendingPayment}>{t("Cancel")}</Button>
          </div>
        ):(
          <div>
            <Button type="secondary btn-terminal-start" size="lg" disabled={this.props.disabled} onClick={this.collectCardPayment} loading={isLoadingPaymentIntent||isLoading}>
              <div className="icon"><ReactSVG src={iconTerminal} /></div>
              {t("Swipe Card")} - <CurrencyFormat value={this.props.paymentAmount} /></Button>
          </div>
        )
      :
        <Alert color="warning">{t("Unable to connect to the terminal")}</Alert>
      )
    :
      <Loader />
    );
  }
}

export default withTranslation()(TerminalProvider);

