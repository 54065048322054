import React from 'react';
import Flipper from '../flipper';
import { withTranslation } from 'react-i18next';

function ReservationNumber(props) {
  const { t } = props;
  const digits = (''+props.number).split('');
  return <div className="room-reservation dir-ltr">
    <div className="title">{t('Reservation')}</div>
    <div className="flippers">
      {digits.map((digit, i) => {
        return <Flipper type="single" key={i} text={digit} />
      })}
    </div>
  </div>
}

export default withTranslation()(ReservationNumber);