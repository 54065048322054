import React from "react";
import ReactSVG from 'react-svg';
import i18n from './../i18n';
import { Modal, ModalBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import Timer from "./timer";
import Button from "./button";
import logo from '../assets/images/MikvahLogo.png';
import iconMinimize from "../assets/images/icon-minimize.svg";
import iconMusic from "../assets/images/icon-music.svg";
import iconSupplies from "../assets/images/icon-supplies.svg";
import iconTimer from "../assets/images/icon-timer.svg";
import iconClose from "../assets/images/icon-close.svg";
import iconCheck from "../assets/images/icon-check.svg";
import iconChecklist from "../assets/images/icon-checklist.svg";
import iconBalanitHelp from "../assets/images/icon-balanit-help.svg";
import iconRabbi from "../assets/images/icon-rabbi.svg";
import timerUpArrow from "../assets/images/icon-arrow-up.svg";
import { supplyRequest, updateRoomStatus } from "./../models/room";
import { supplies, audioTracks, logoImageAltText } from "./../global-props";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRoomAudio } from './../models/audio';
import Slider from '@material-ui/core/Slider';
import RoomChecklist from "../views/room/partials/checklist";
import RoomRabbiList from "../views/room/partials/rabbiList";
import ScreenSaverClock from '../components/screensaver-clock';

class RoomButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modals: {
        music: false,
        supplies: false,
        timer: false,
        help: false,
        checklist: false,
        rabbonim: false
      },
      isTimerActive: false,
      supplies: [],
      showButton: "hidden",
      submittedRequest: false,
      selectedItems: 0,
      isSubmittingSupplyRequest: false,
      audioTracks: audioTracks,
      isUpdatingStatus: false,
      currentTrack: null,
      currentVolume: null
    };
    this.togglePlay = this.togglePlay.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.selectSupply = this.selectSupply.bind(this);
    this.handleAttendantRequest = this.handleAttendantRequest.bind(this);
    this.handleSubmitSupplyRequest = this.handleSubmitSupplyRequest.bind(this);
    this.handleCancelSupplyRequest = this.handleCancelSupplyRequest.bind(this);
    this.handleCompleteSupplyRequest = this.handleCompleteSupplyRequest.bind(this);
    this.toggleTimerActive = this.toggleTimerActive.bind(this);
    this.updateIsTimerPaused = this.updateIsTimerPaused.bind(this);
    this.handleChangeVolume = this.handleChangeVolume.bind(this);
  }

  componentDidMount() {
    let groupedSupplies = supplies.reduce((acc,item,i) => {
      if(i % 9 == 0) {
        acc.push([item])
      } else {
        acc[Math.floor(i/9)].push(item);
      }
      return acc;
    },[])
    this.setState({supplies: groupedSupplies})
    if (localStorage.getItem('timerEndTime') || localStorage.getItem('timerTimeLeft')) {
      this.setState({
        isTimerActive: true
      })
    }
    if (localStorage.getItem('timerModalOpen') === 'true') {
      this.setState({
        modals: {
          timer: true,
          checklist: false, 
          music: false,
          supplies: false,
          help: false,}
      })
    }
    if (localStorage.getItem('checklistModalOpen') === 'true') {
      this.setState({
        modals: {
          checklist: true, 
          music: false,
          supplies: false,
          timer: false,
          help: false,}
      })
    }
    if ( this.props.room.reservation ) {
      let requested = this.props.room.reservation.supplies.filter(supply => {
        return supply.status === "open" && !supply.isAttendantRequest
      })
      let requestedAttendant = this.props.room.reservation.supplies.filter(supply => {
        return supply.status === "open" && supply.isAttendantRequest
      })
      if (requested.length > 0) {
        this.setState({
          submittedRequest: true,
        })
      }
      if (requestedAttendant.length > 0) {
        this.setState({
          submittedAttendantRequest: true,
        })
      }
      if(this.props.room.reservation.audioStream){
        this.setState({currentTrack: this.props.room.reservation.audioStream.id, currentVolume: this.props.room.reservation.audioVolume})
        this.props.handleMusic(this.props.room.reservation.audioStream.streamUrl,this.props.room.reservation.audioVolume)
      }
    }
  }

  componentWillUnmount() {
    // localStorage.removeItem('timerEndTime');
    // localStorage.removeItem('timerTimeLeft');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.supplies !== this.state.supplies) {
      this.setState({
        showButton: "hidden"
      });
      this.state.supplies.map(supply => {
        return supply.map(s=>
          s.selected
            ? this.setState({
                showButton: ""
              })
            : null
        ) 
      });
    }
    if (prevProps.room !== this.props.room && this.props.room.reservation) {
      let requested = this.props.room.reservation.supplies.filter(room => room.status === "open"&&!room.isAttendantRequest);
      let requestedAttendant = this.props.room.reservation.supplies.filter(room => room.status === "open"&&room.isAttendantRequest);
      if(requested.length === 0){
        this.setState({
          selectedItems: requested.length,
          submittedRequest: false,
        })
      }else{
        this.setState({
          selectedItems: requested.length,
        });
      }
      if(requestedAttendant.length===0){
        this.setState({
          submittedAttendantRequest: false,
        })
      }
    }
    if (this.props.room.reservation && (this.state.currentTrack !== this.props.room.reservation.audioStreamId) && (this.state.currentTrack || this.props.room.reservation.audioStreamId)) {
      this.setState({currentTrack: this.props.room.reservation.audioStream?this.props.room.reservation.audioStream.id:0})
      if(this.props.room.reservation.audioStream){
        this.props.handleMusic(this.props.room.reservation.audioStream.streamUrl, this.props.room.reservation.audioVolume)
      } else {
        this.props.handleMusic(null)
      }
    }
    if (this.props.room.reservation && (this.state.currentVolume !== this.props.room.reservation.audioVolume)) {
      this.setState({currentVolume: this.props.room.reservation.audioVolume})
      this.props.handleMusic(null,this.props.room.reservation.audioVolume,true)
    }
  }

  modalToggle(modal) {
    this.setState(state => {
      state["modals"][modal] = !this.state["modals"][modal];
      return state;
    })
    if(modal === "timer"){
      localStorage.setItem("timerModalOpen",!this.state.modals.timer);
    }
    if(modal === "checklist"){
      localStorage.setItem("checklistModalOpen",!this.state.modals.checklist);
    }
    this.setState(prevState => ({
      supplies: prevState.supplies.map(supply =>
        supply.selected
          ? {
              title: supply.title,
              icon: supply.icon,
              selected: !supply.selected
            }
          : supply
      )
    }));
    if (modal === "supplies") {
      let requested = this.props.room.reservation.supplies.filter(supply => {
        return supply.status === "open" && !supply.isAttendantRequest
      })
      if (requested.length > 0) {
        this.setState({
          submittedRequest: true,
          selectedItems: requested.length
        })
      }
    }
    if (modal === "help") {
      let requestedAttendant = this.props.room.reservation.supplies.filter(supply => {
        return supply.status === "open" && supply.isAttendantRequest
      })
      if (requestedAttendant.length > 0) {
        this.setState({
          submittedAttendantRequest: true,
        })
      }
    }
  };

  togglePlay(track) {
    let activeStreamId = this.props.room.reservation ? this.props.room.reservation.audioStreamId : 0;
    let streamId = track.streamId===activeStreamId?0:track.streamId
    return this.handleChangeStream(streamId);
  }

  selectSupply(supplyName) {
    this.setState(prevState => ({
      supplies: prevState.supplies.map(supply =>
        supply.map(s => 
          s.title === supplyName
          ? {
            title: s.title,
            icon: s.icon,
            selected: !s.selected
          }
          : s
          ))
    }));
  }

  updateIsTimerPaused(value) {
    this.setState({
      isTimerPaused: value
    })
  }

  toggleTimerActive() {
    this.setState({
      isTimerActive: !this.state.isTimerActive
    })
  }

  handleAttendantRequest(){
    if(this.state.submittedAttendantRequest){
      this.handleCancelSupplyRequest(true);
    } else{
      this.handleSubmitSupplyRequest(true);
    }
  }

  handleSubmitSupplyRequest(isAttendantRequest=false) {
    // let selectedItems = this.state.supplies.filter((supply) => supply.selected);
    let selectedItems = [];
    this.state.supplies.forEach(supply => supply.forEach((s) => {if(s.selected){selectedItems.push(s)}}));

    const roomId = this.props.room.id;
    let supplyList = selectedItems.map((supply)=>supply.title);
    const action = 'add';

    this.setState({
      isSubmittingSupplyRequest: true
    })

    supplyRequest(roomId, supplyList, action, isAttendantRequest)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
          this.setState({
            submittedRequest: isAttendantRequest?this.state.submittedRequest:true,
            submittedAttendantRequest: isAttendantRequest?true:this.state.submittedAttendantRequest,
            selectedItems: selectedItems.length,
          });
          setTimeout(() => (this.state.modals.supplies ? this.modalToggle(isAttendantRequest?null:"supplies") : null), 5000);
          setTimeout(() => (this.state.modals.help ? this.modalToggle(isAttendantRequest?"help":null) : null), 5000);
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      ).finally(()=>{
        this.setState({
          isSubmittingSupplyRequest: false,            
        });
      });


    this.setState(prevState => ({
      supplies: prevState.supplies.map(supply =>
        supply.map(s => 
          s.selected
            ? {
                title: s.title,
                icon: s.icon,
                selected: !s.selected
              }
            : s
      ))
    }));
  }

  handleCancelSupplyRequest(isAttendantRequest=false) {
    const roomId = this.props.room.id;
    let supplyList = null;
    const action = 'cancel';

    this.setState({
      isSubmittingSupplyRequest: true
    })

    supplyRequest(roomId, supplyList, action, isAttendantRequest)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
          this.setState({
            submittedRequest: isAttendantRequest?this.state.submittedRequest:false,
            submittedAttendantRequest: isAttendantRequest?false:this.state.submittedAttendantRequest,
          });
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      ).finally(()=>{
        this.setState({
          isSubmittingSupplyRequest: false,            
        });
      });

  }

  handleCompleteSupplyRequest(isAttendantRequest) {  
    const roomId = this.props.room.id;
    const action = 'complete';
    this.setState({
      isSubmittingSupplyRequest: true
    })
    supplyRequest(roomId, null, action, isAttendantRequest)
      .then(
        result => {
          this.setState(state => {
            state["modals"]["help"] = false;
            state['isSubmittingSupplyRequest'] = false;
            return state;
          })
          console.log(result);
        },
        error => {
        }
      );
  }

  handleChangeStream(streamId) {
    this.handleUpdateZone(streamId, null);    
  }

  handleChangeVolume(event, volume) {
    let room = this.props.room;
    room.reservation.audioVolume = volume;
    this.props.handleUpdateRoom(room);
    clearTimeout(this.volumeDebounceTimer);
    this.volumeDebounceTimer = setTimeout((e) => {
      this.handleUpdateZone(null, Math.round(volume));    
    }, 300);
  }

  handleUpdateZone(streamId, volume) {
    const roomId = this.props.room.id;
    setRoomAudio(roomId, streamId, volume )
      .then(result => {
        let room = this.props.room;
        if (streamId!==null) { 
          room.reservation.audioStreamId = streamId;
          room.reservation.audioStream = result.result;  
        }        
        this.props.handleUpdateRoom(room);
      })
  }

  handleChangeStatus(status) {
    let roomId = this.props.room.id;
    this.setState({
      isUpdatingStatus: true,
    })
    updateRoomStatus(roomId, status)
      .then(
        result => { this.props.handleUpdateRoom(result); },
        error => { this.setState({ error }); }
      ).finally(() => this.setState({isUpdatingStatus: false}));
  }

  render() {
    const { t, room } = this.props;
    const supplyCount = room.reservation ? room.reservation.supplies.filter(s=>s.status==="open"&&!s.isAttendantRequest).length : null;
    var bodyLeft = "";
    var roomButtonsOutput = (
        <>
          {/* {room.status==="Prep"?<ScreenSaverClock room={room} isTimerActive={this.state.isTimerActive} updateIsTimerPaused={this.updateIsTimerPaused} isTimerPaused={this.state.isTimerPaused} status={room.status} toggleTimerActive={this.toggleTimerActive}/>:null} */}
          <div className={"room-buttons" + (room.status=="Dressing"?" status-dressing":"")}>
            {room.status !== "Dressing"?
            <>
            {this.state.activeMusic ? (
              <Button
                type="circle"
                onClick={() => this.modalToggle("music")}
                background={this.state.activeMusic}
              />
            ) : (
              <Button
                type="circle"
                text={t("Music")}
                icon={iconMusic}
                onClick={() => this.modalToggle("music")}
              />
            )}
            <Button
              type="circle"
              text={t(this.state.submittedRequest ? "Supplies Requested" : "Supplies")}
              icon={iconSupplies}
              onClick={() => this.modalToggle("supplies")}
            >
              {supplyCount ? <div className="badge">{supplyCount}</div> : null}
            </Button>
            {localStorage.getItem("timerEndTime") || localStorage.getItem("timerTimeLeft") ? (
              <Button
                type="circle"
                icon={iconTimer}
                onClick={() => this.modalToggle("timer")}
              >
                {this.state.isTimerActive?
              <Timer timerButton={true} updateIsTimerPaused={this.updateIsTimerPaused} isTimerPaused={this.state.isTimerPaused} status={room.status} toggleTimerActive={this.toggleTimerActive} room={room} />
                :null}
                {t("Timer")}
              </Button>
            ) : (
              <Button
                type="circle"
                text={t("Timer")}
                icon={iconTimer}
                onClick={() => this.modalToggle("timer")}
              />
            )}
            </>
            :null}
            <Button
              type="circle"
              text={t(this.state.submittedAttendantRequest ? "Help Requested" : "Help")}
              icon={iconBalanitHelp}
              onClick={this.handleAttendantRequest}
            >
              {this.state.submittedAttendantRequest ? <div className="badge"></div> : null}
            </Button>
            {/* <Button
              type="circle"
              text={t("Checklist")}
              icon={iconChecklist}
              onClick={() => this.modalToggle("checklist")}
            /> */}
            {/* <Button
              type="circle"
              text={t("Rabbonim")}
              icon={iconRabbi}
              onClick={() => this.modalToggle("rabbonim")}
            /> */}

            <Modal
              className={"modal-music lang-" + i18n.language}
              isOpen={this.state.modals.music}
              toggle={() => this.modalToggle("music")}
              size="full"
            >
              <div className="modal-header">
                <div className="icon">
                  <ReactSVG
                    src={iconMusic}
                    alt="Music Icon"
                  />
                </div>
                <div>
                  <h4 className="modal-title">{t("Music Player")}</h4>
                  <h5 className="modal-title">{t("modalMusicInstructions")}
                  </h5>
                </div>
                <Button
                  type="button close"
                  onClick={() => this.modalToggle("music")}
                  aria-label="Close"
                >
                  <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                </Button>
              </div>
              <ModalBody>
                <div className="tracks">
                  {this.state.audioTracks.map((track,i) => {
                    let audioStreamId = room.reservation ? room.reservation.audioStreamId : 0;
                    let trackIsPlaying = track.streamId===audioStreamId;
                    return <div className="music-option" key={i}>
                      <Button
                        type="circle"
                        text=""
                        icon={<FontAwesomeIcon icon={trackIsPlaying?"pause-circle":"play-circle"} />}
                        background={track.image}
                        className={trackIsPlaying?"is-playing":""}
                        onClick={() => this.togglePlay(track)}
                      />
                      <p>{t(track.title)}</p>
                    </div>
                  })}
                </div>
                <div className="slider-volume">
                  <FontAwesomeIcon className="icon-volume" icon="volume-up" />
                  <Slider
                    value={room.reservation ? room.reservation.audioVolume : 0}
                    onChange={this.handleChangeVolume}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                  />
                  <div>
                    <p>{t("Volume Control")}</p>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              className={"modal-supplies lang-" + i18n.language}
              isOpen={this.state.modals.supplies}
              toggle={() => this.modalToggle("Supplies")}
              size="full"
            >
              {['ReadyForMikvah', 'ReadyForMikvahNext', 'ReadyForChecking', 'ReadyForCheckingNext'].includes(room.status)?
                <>
                  <div className="modal-header">
                    <Button
                      type="button close"
                      onClick={() => this.modalToggle("supplies")}
                      aria-label="Close"
                    >
                      <span aria-hidden="true"><img src={iconClose} alt="Close" /></span>
                    </Button>
                  </div>
                  <ModalBody className="requestedBody">
                    <div className="requestedText">
                      <p className="requestedTitle">{t("Order Supplies")}</p>
                      <p className="requestedInfo">
                        {t("supplyRequestNotAllowedInReady")}
                      </p>
                    </div>
                    <div className="requestButtons">
                      <Button
                        type="outline-primary"
                        size="lg"
                        onClick={() => this.handleChangeStatus('Prep')}
                        disabled={this.state.isUpdatingStatus}
                        loading={this.state.isUpdatingStatus}
                      >
                        {t("Switch to Prep")}
                      </Button>
                      <Button type="primary" size="lg"
                        onClick={() => this.modalToggle("supplies")}
                      >
                        {t("I’m Ready")}
                      </Button>
                    </div>
                  </ModalBody>
                </> 
              :
                (this.state.submittedRequest ?
                  <React.Fragment>
                    <div className="modal-header">
                      <Button
                        type="button close"
                        onClick={() => this.modalToggle("supplies")}
                        aria-label="Close"
                      >
                        <span aria-hidden="true"><img src={iconClose} alt="Close" /></span>
                      </Button>
                    </div>
                    <ModalBody className="requestedBody">
                      <div className="requestedText">
                        <p className="requestedTitle">{t("Request Submitted")}</p>
                        <p className="requestedInfo">
                          {t("supplyRequestSubmittedText", { count: this.state.selectedItems })}
                        </p>
                      </div>
                      <div className="requestButtons">
                        <Button
                          type="outline-primary"
                          size="lg"
                          onClick={() => this.handleCancelSupplyRequest()}
                          disabled={this.state.isSubmittingSupplyRequest}
                          loading={this.state.isSubmittingSupplyRequest}
                        >
                          {t("Cancel Request")}
                        </Button>
                        <Button type="primary" size="lg"
                          onClick={() => this.modalToggle("supplies")}
                        >
                          {t("Close Window")}
                        </Button>
                      </div>
                    </ModalBody>
                  </React.Fragment> 
                : 
                  <React.Fragment>
                    <div className="modal-header">
                      <div className="icon">
                        <ReactSVG
                          src={iconSupplies}
                          alt="Supplies Icon"
                        />
                      </div>
                      <div>
                        <h4 className="modal-title">{t("Order Supplies")}</h4>
                      </div>
                      <Button
                        type="button close"
                        onClick={() => this.modalToggle("supplies")}
                        aria-label="Close"
                      >
                        <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                      </Button>
                    </div>
                    <ModalBody>
                      <React.Fragment>
                        <div className="items">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            slidesPerView={1}
                            navigation={{
                              nextEl: '.nex',
                              prevEl: '.prev',
                            }}
                            pagination={{ clickable: true }}
                          >
                          {this.state.supplies.map((supply,i) => {
                            return (<>
                            <SwiperSlide className="d-flex flex-wrap align-items-start" key={i}>
                            {supply.map(s => {
                            return <div className="item" key={supply.title}>
                              <div className="item-icon">
                                <Button
                                  type="circle"
                                  icon={s.selected ? iconCheck : s.icon }
                                  onClick={() => this.selectSupply(s.title)}
                                  active={s.selected}
                                />
                              </div>
                              <p>{t(s.title)}</p>
                              </div>
                            })}
                            </SwiperSlide></>)
                          })}
                          </Swiper>
                        </div>
                        <div className="requestButton">
                          <Button
                            type="primary"
                            size="lg"
                            text={t("Send Request")}
                            onClick={() => this.handleSubmitSupplyRequest()}
                            disabled={this.state.isSubmittingSupplyRequest || this.state.showButton === 'hidden'}
                            loading={this.state.isSubmittingSupplyRequest}
                          />
                        </div>           
                      </React.Fragment>
                    </ModalBody>
                    <div className="nex"><img src={timerUpArrow} /></div>
                    <div className="prev"><img src={timerUpArrow} /></div>
                  </React.Fragment>
                )
              }
            </Modal>

            <Modal
              className={"modal-timer lang-" + i18n.language}
              isOpen={this.state.modals.timer}
              toggle={() => this.modalToggle("timer")}
              size="full"
            >
              <div className="modal-header">
                <div className="icon">
                  <ReactSVG src={iconTimer} alt="Timer Icon" />
                </div>
                <div>
                  <h4 className="modal-title">{t("Set Timer")}</h4>
                </div>
                <Button
                  type="button close"
                  onClick={() => this.modalToggle("timer")}
                  aria-label="Close"
                >
                  {this.state.isTimerActive?
                    <span aria-hidden="true"><img src={iconMinimize} alt="Minimize"/></span>
                  :
                    <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                  }
                </Button>
              </div>
              <ModalBody>
                <Timer
                  minimize={this.modalToggle}
                  toggleTimerActive={this.toggleTimerActive}
                  status={room.effectiveStatus}
                  updateIsTimerPaused={this.updateIsTimerPaused}
                  isTimerPaused={this.state.isTimerPaused}
                  room={room} 
                />
              </ModalBody>
            </Modal>

            <Modal
              className={"modal-checklist lang-" + i18n.language}
              isOpen={this.state.modals.checklist}
              toggle={() => this.modalToggle("checklist")}
              size="full"
            >
              <div className="modal-header">
                <div className="icon">
                  <ReactSVG src={iconChecklist} alt="Check Icon" />
                </div>
                <div>
                  <h4 className="modal-title">{t("Checklist")}</h4>
                </div>
                <Button
                  type="button close"
                  onClick={() => this.modalToggle("checklist")}
                  aria-label="Close"
                >
                  <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                </Button>
              </div>
              <ModalBody>
                {room.reservation!==null?
                  <RoomChecklist />
                :null}
              </ModalBody>
            </Modal>

            <Modal
              className={"modal-help lang-" + i18n.language}
              isOpen={this.state.modals.help}
              toggle={() => this.modalToggle("help")}
              size="full"
            >
              {['ReadyForMikvah', 'ReadyForMikvahNext', 'ReadyForChecking', 'ReadyForCheckingNext'].includes(room.status)?
                <>
                  <div className="modal-header">
                    <Button
                      type="button close"
                      onClick={() => this.modalToggle("help")}
                      aria-label="Close"
                    >
                      <span aria-hidden="true"><img src={iconClose} alt="Close" /></span>
                    </Button>
                  </div>
                  <ModalBody className="requestedBody">
                    <div className="requestedText">
                      <p className="requestedTitle">{t("Balanit Help")}</p>
                      <p className="requestedInfo">
                        {t("attendantRequestNotAllowedInReady")}
                      </p>
                    </div>
                    <div className="requestButtons">
                      <Button
                        type="outline-primary"
                        size="lg"
                        onClick={() => this.handleChangeStatus('Prep')}
                        disabled={this.state.isUpdatingStatus}
                        loading={this.state.isUpdatingStatus}
                      >
                        {t("Switch to Prep")}
                      </Button>
                      <Button type="primary" size="lg"
                        onClick={() => this.modalToggle("help")}
                      >
                        {t("I’m Ready")}
                      </Button>
                    </div>
                  </ModalBody>
                </> 
              :
                (this.state.submittedAttendantRequest ?
                  <React.Fragment>
                    <div className="modal-header">
                      <Button
                        type="button close"
                        onClick={() => this.modalToggle("help")}
                        aria-label="Close"
                      >
                        <span aria-hidden="true"><img src={iconClose} alt="Close" /></span>
                      </Button>
                    </div>
                    <ModalBody className="requestedBody">
                      <div className="requestedText">
                        <p className="requestedTitle">{t("Help Request Submitted")}</p>
                      </div>
                      <div className="requestButtons">
                        <Button
                          type="outline-primary"
                          size="lg"
                          onClick={() => this.handleCancelSupplyRequest(true)}
                          disabled={this.state.isSubmittingSupplyRequest}
                          loading={this.state.isSubmittingSupplyRequest}
                        >
                          {t("Cancel Request")}
                        </Button>
                        <Button
                          type="primary"
                          size="lg"
                          onClick={() => this.handleCompleteSupplyRequest(true)}
                          disabled={this.state.isSubmittingSupplyRequest}
                          loading={this.state.isSubmittingSupplyRequest}
                        >
                          {t("Mark Request Completed")}
                        </Button>
                        {/* <Button type="primary" size="lg"
                          onClick={() => this.modalToggle("help")}
                        >
                          {t("Close Window")}
                        </Button> */}
                      </div>
                    </ModalBody>
                  </React.Fragment> 
                : 
                  <React.Fragment>
                    <div className="modal-header">
                      <div className="icon">
                        <ReactSVG
                          src={iconBalanitHelp}
                          alt="Help Icon"
                        />
                      </div>
                      <div className="mb-5">
                        <h4 className="modal-title">{t("Request Help")}</h4>
                        <h5 className="modal-title">
                        {t("If you need help from an attendant please click button to request attendant to come to your room.")}
                        </h5>
                      </div>
                      <Button
                        type="button close"
                        onClick={() => this.modalToggle("help")}
                        aria-label="Close"
                      >
                        <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                      </Button>
                    </div>
                    <ModalBody>
                      <React.Fragment>
                        <div className="requestButton">
                          <Button 
                            type="circle-big" 
                            onClick={() => this.handleSubmitSupplyRequest(true)} 
                            disabled={this.state.isSubmittingSupplyRequest}
                            loading={this.state.isSubmittingSupplyRequest}
                            text={t("Request Help")} />
                        </div>           
                      </React.Fragment>
                    </ModalBody>
                  </React.Fragment>
                )
              }
            </Modal>
            <Modal
              className={"modal-rabbonim lang-" + i18n.language}
              isOpen={this.state.modals.rabbonim}
              toggle={() => this.modalToggle("rabbonim")}
              size="full"
            >
              <div className="modal-header">
                <div className="icon">
                  <ReactSVG src={iconRabbi} alt="Check Icon" />
                </div>
                <div>
                  <h4 className="modal-title">{t("Rabbonim")}</h4>
                </div>
                <Button
                  type="button close"
                  onClick={() => this.modalToggle("rabbonim")}
                  aria-label="Close"
                >
                  <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                </Button>
              </div>
              <ModalBody>
                {room.reservation!==null?
                  <RoomRabbiList />
                :null}
              </ModalBody>
            </Modal>
          </div></>
        );
    if(room.reservation&&(room.reservation.timeConfirmedInfo||(this.props.settings && this.props.settings.reservationsNeedToConfirmInfo.value=="False"))){
      if(!room.reservation.attendantInRoom){
        switch (room.status) {
          case "Prep":
          case "Checking":
          case "ReadyForChecking":
          case "ReadyForCheckingNext":
          case "ReadyForMikvah":
          case "ReadyForMikvahNext":
          case "Dressing":
            bodyLeft = roomButtonsOutput;
            break;
        
          default:
            bodyLeft = <div className="flex-grow-1"><img src={logo} className="logo" alt={logoImageAltText}/></div>;
            break;
        }
      }
    } else{
      bodyLeft = <div className="flex-grow-1"><img src={logo} className="logo" alt={logoImageAltText}/></div>;
    }

    return <div className="body-left">{bodyLeft}</div>;
  }
}

export default withTranslation()(RoomButtons);
