import React from 'react';
import ReactSVG from 'react-svg';
import iconExcludedAttendant from "../assets/images/icon-excluded-attendant.svg";
import iconEmergency from "../assets/images/icon-emergency.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

export default function RoomTile(props) {
  let room = props.room;
  let t = props.t;
  let i18n = props.i18n;
  let section;
  switch ( room.id ) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      section = '1';
      break;
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
      section = '2';
      break;
    case 17:
      section = '3';
      break;
    default:
      

  }

  let allowedMinutes;
  let lastStatusChange;
  let isOverTime;
  let isOverTimeUser;
  let overTimeMinutes;
  let lastSupplyRequest;
  let lastSupplyRequestAgo;
  let lastSupplyRequestIsOverTime;
  let sectionMap;
  let activeRoom;
  let roomTypeDisplay;
  if ( props.settings ) {
    let allowedMinutesKey = Object.keys(props.settings).filter(s=>s.toLowerCase()===room.effectiveStatus.toLowerCase()+'minutes').pop();
    allowedMinutes = props.settings[allowedMinutesKey] ? props.settings[allowedMinutesKey].value : null;
    switch (room.effectiveStatus) {
      case 'Prep':
        if (room.reservation.bath) {
          allowedMinutes = parseFloat(props.settings.prepBathMinutes.value);
        } else {
          allowedMinutes = parseFloat(props.settings.prepShowerMinutes.value);
        }
        if (room.reservation.handicapped || room.reservation.kallah) {
          allowedMinutes = parseFloat(props.settings.prepBathMinutes.value);
        }
        if (room.reservation.doubleSlot) {
          allowedMinutes = allowedMinutes + parseFloat(props.settings.prepDoubleSlotExtraMinutes.value);
        }
        break;
      case 'ReadyForMikvahNext':
        allowedMinutes = props.settings.readyForMikvahMinutes.value;
        break;
      case 'ReadyForCheckingNext':
        allowedMinutes = props.settings.readyForCheckingMinutes.value;
        break;
      case 'Done':
        allowedMinutes = 2;
        break;
      default:
        break;
    }

    lastStatusChange = moment.duration(moment(props.time).subtract(props.settings&&props.settings.offset&&props.settings.offset.value,'seconds').diff(room.effectiveStatusLastChange));
    if(room.effectiveStatus==="Prep"){lastStatusChange = moment.duration(moment(props.time).subtract(props.settings&&props.settings.offset&&props.settings.offset.value,'seconds').diff(room.reservation.timePrep));}
    if(!["ReadyForMikvah", "ReadyForMikvahNext", "ReadyForChecking", "ReadyForCheckingNext"].includes(room.effectiveStatus)){
      isOverTimeUser = lastStatusChange.asMinutes()>allowedMinutes;
    } else {
      isOverTime = lastStatusChange.asMinutes()>allowedMinutes;
    }
    overTimeMinutes = isOverTime?moment.duration(moment(props.time).subtract(props.settings&&props.settings.offset&&props.settings.offset.value,'seconds').subtract(allowedMinutes, 'm').diff(room.effectiveStatus==="Prep"?room.reservation.timePrep:room.effectiveStatusLastChange)).asMinutes():null;
    
    if (room.reservation) {
      lastSupplyRequest = room.reservation.supplies.filter(s=>s.status==='open').pop();
      if (lastSupplyRequest) {
        lastSupplyRequestAgo = moment.duration(moment(props.time).subtract(props.settings&&props.settings.offset&&props.settings.offset.value,'seconds').diff(lastSupplyRequest.dateRequested));
        lastSupplyRequestIsOverTime = lastSupplyRequestAgo.asMinutes()>props.settings.waitingForSuppliesMinutes.value;
      }
    }

    if (room.reservation) {
      if (room.reservation.kallah) {
        roomTypeDisplay = ["far","gem"];
      } else if (room.reservation.handicapped) {
        roomTypeDisplay = "wheelchair";
      } else if (room.reservation.bath) {
        roomTypeDisplay = "bath";
      } else {
        roomTypeDisplay = "shower";
      }
    }
  }

  return <div className={'room-tile room-tile-' + room.id + ' room-section-' + section + ' status-color-' + room.statusColor + ' status-' + room.statusName + (room.section.attendant?'':' no-attendant') + (props.updated?' recentlyUpdatedIn':' recentlyUpdatedOut')} onClick={props.onClick}>
    <div className="header">
      <div className="number">{room.id}</div>
      <div className="icon"><FontAwesomeIcon icon={roomTypeDisplay?roomTypeDisplay:room.roomType}/></div>
    </div>
      {room.reservation?
        <div className="reservation">
          <div>
            {room.reservation.reservationCode}
            {room.reservation.firstTime?
              <>
                <sup><FontAwesomeIcon className="mis-2 text-orange small" icon="asterisk" id={`icon-new-user-${room.reservation.user.reservationCode}`} /></sup>
                <UncontrolledTooltip target={`icon-new-user-${room.reservation.user.reservationCode}`}>{t("New User")}</UncontrolledTooltip>
              </>
            :null}
            {room.reservation.user.testUser&&<small className="text-muted"> {t("Test")}</small>}
            {(room.reservation.notes||room.reservation.user.notes)&&<FontAwesomeIcon className="icon" icon={["far", "sticky-note"]} />}
            {(room.reservation.user.excludedAttendants && room.reservation.user.excludedAttendants.length>0)&&<ReactSVG className="icon-excluded" src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>}
            {room.reservation.attendantInRoom&&<FontAwesomeIcon className="icon" icon={["fas", "female"]} />}
          </div>
          <div>
            {room.reservation.parking?<FontAwesomeIcon className="icon" icon="car" />:null}
            {room.reservation.doubleSlot?<FontAwesomeIcon className="icon" icon={["far", "clock"]} />:null}
            {room.reservation.salonManicure||room.reservation.salonBoth?<FontAwesomeIcon className="icon" icon="paint-brush" />:null}
            {!room.dryDoorLocked && room.effectiveStatus!=="Done" && !room.privateRoom?<FontAwesomeIcon className="icon" icon="lock-open" />:null}
          </div>
        </div>
      :
        room.dryDoorLocked?<div className="text-center"><FontAwesomeIcon className="icon" icon="lock" /></div>:null
      }
    <div className="body">
      {room.emergencyCall?
        <ReactSVG className="emergency" src={iconEmergency} />
      :
      <>
      {props.settings&&(room.needsSupplies||room.waitingForAttendant)?
        <div className={"timer timer-supplies" + (lastSupplyRequestIsOverTime?" over-time":"")}>
          <FontAwesomeIcon className="icon" icon="hand-paper" /> {parseInt(lastSupplyRequestAgo.asMinutes())}:00
        </div>
      :null}
      {props.settings&&!['Ready', 'Offline', 'NeedsCleaning'].includes(room.effectiveStatus)?
        <>
          <div className={"timer timer-status" + (isOverTimeUser?" over-time":"")}>
            {parseInt(lastStatusChange.asMinutes())}{moment(lastStatusChange.asMilliseconds()).format(':ss')}
            <div className={isOverTime?"over-time":""}>
              {isOverTime?<FontAwesomeIcon className="icon" icon={["fas", "hourglass-end"]} />:null} 
              {isOverTime?parseInt(overTimeMinutes)+':00':null}
            </div>
          </div>
        </>
      :null}
      </>}
      <div className={"status lang-" + i18n.language}>
        {room.status=='ReadyForChecking'?room.readyForCheckingPosition+1:room.status=='ReadyForMikvah'?room.readyForMikvahPosition+1:room.status=='ReadyForMikvahNext'?1:room.status=='ReadyForCheckingNext'?1:null} {room.status=='ReadyForChecking'||room.status=='ReadyForCheckingNext'?t("For Checking"):room.status=='ReadyForMikvah'||room.status=='ReadyForMikvahNext'?t("For Tevilah"):t(room.statusName)} 
      </div>
    </div>
    
  </div>;
}
