import { getTimes as GetTimes } from '../api';

export function getTimes(date) {
  return new Promise((resolve, reject) => {
    GetTimes(date)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}
