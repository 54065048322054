import React from 'react';
import { withTranslation } from 'react-i18next';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import Flipper from './../components/flipper';
import Button from './../components/button';
import { staffEntry } from './../models/room';
import { SettingsContext } from './../components/settings';
import { Alert } from 'reactstrap';

class StaffCode extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      hiddenPressTimeout: 1000,
      pinCode: '',
      loading: false
    };
  }

  componentDidMount() {
  }


  componentWillUnmount() {
    clearTimeout(this.touchTimeout);
  }

  handleTouchStart = () => {
    this.touchTimeout = setTimeout(()=>this.setState({isActivated: true}), this.state.hiddenPressTimeout);    
  }

  handleTouchEnd = () => {
    clearTimeout(this.touchTimeout);
  }

  handleSubmit = (input, clear) => {
    this.setState({loading: true})
    staffEntry(this.props.room.id, input, clear)
    .then(
      result => {
        this.setState({pinCode: '', loading: false, error: null})
        this.props.handleUpdateRoom(result);
    },
    error => {
      this.setState({
        error,
        loading: false,
        pinCode: ''
      });
    })
  }

  handlePress = (key) => {
    let input = this.state.pinCode;
    if ( key === 'del' && input.length!==0 ) { input = input.slice(0, -1); }
    if ( typeof key === 'number' && (input.length<4) ) { input = input + key; }
    this.setState({
      pinCode: input,
    })
    if ( key === 'enter' && input.length>=4 ) {
      this.handleSubmit(input);
    }
  }
    
  render() {
    const { pinCode, error } = this.state;
    const { t, room, emptyRoom } = this.props;
    return (
    <>
    {error!=null
    ? <Alert color="danger">{error}</Alert>
    : null}
    {room.staff?
      <Button type={emptyRoom?"circle-big":"secondary btn-lg mt-3"} loading={this.state.loading} onClick={() => this.handleSubmit('', true)}>{t("Clear Staff")}</Button>
    :
    <div className="" onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}>
      <div>        
        <div className="wrapper-number">
          <div className={"flippers justify-content-center mb-4"}>
            {[0,0,0,0].map((v,i) => {
              return <Flipper type="single" key={i} text={pinCode[i]?pinCode[i]:''} />
            })}
          </div>
        </div>
        <NumericKeyboard layout="tel" onPress={this.handlePress} />
      </div>
    </div>
    }
    </>
  )}
}

export default withTranslation()(StaffCode);