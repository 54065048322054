import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import Loader from './../components/snippets/loader';
import { Alert } from 'reactstrap';
import moment from "moment";
import Moment from "react-moment";
import { SettingsContext } from "./settings";
class ScreenReady extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
      view: null,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLoadedSection !== this.props.isLoadedSection || prevProps.section !== this.props.section || prevProps.room.reservation.attendantInRoom !== this.props.room.reservation.attendantInRoom){
      const { room, section } = this.props;
      if(room && section && room.reservation.attendantInRoom){
        if(!room.readyForMikvahPosition && !room.directToBor && section.wetHallwayBusy){
          this.setState({view: 'leaveShortlyMikvah'})
        } else if(!room.readyForMikvahPosition && room.directToBor && !section.borBusyDirectToBor) {
          this.setState({view: 'leaveToMikvah'})
        }  else if(!room.readyForMikvahPosition && !room.directToBor && !section.wetHallwayBusy) {
          this.setState({view: 'leaveToMikvah'})
        } else if(room.readyForMikvahPosition && room.directToBor && !section.borBusyDirectToBor) {
          this.setState({view: 'queueLeaveToMikvah'})
        }  else if(room.readyForMikvahPosition && !room.directToBor && !section.wetHallwayBusy && !section.borBusy) {
          this.setState({view: 'queueLeaveToMikvah'})
        } else if(room.readyForMikvahPosition && !room.directToBor){
          this.setState({view: 'leaveNoMikvah'})
        } else if(room.readyForMikvahPosition && (room.directToBor && section.borBusyDirectToBor)){
          this.setState({view: 'leaveShortlyNoMikvah'})
        }
      } else {
        this.setState({view: null})
      }
    }
  }

  screen(view) {
    const { t } = this.props;
    switch(view){
      case 'leaveShortlyMikvah':
        return <div>{t("Please wait a moment, Mikvah will be available soon.")}</div>
      case 'leaveShortlyNoMikvah':
        return <div>{t("Attendant will be able to leave room momentarily.")}</div>
      case 'leaveToMikvah':
        return (<><div className="mb-2">{t("You can leave to Mikvah.")}</div>
        <Button type="circle" className="mie-4" onClick={() => this.handleChangeStatus("AttendantLeavingRoom")} loading={this.state.isUpdatingStatus} text={t("Balanit Exit")} />
        <Button type="circle-medium" onClick={() => this.handleChangeStatus("ToMikvah")} loading={this.state.isUpdatingStatus} text={t("Leave to Mikvah")} />
        </>
        )
      case 'queueLeaveToMikvah':
        return (<>
            <Button type="circle" className="mie-4" onClick={() => this.handleChangeStatus("AttendantLeavingRoom")} loading={this.state.isUpdatingStatus} text={t("Balanit Exit")} />
            <Button type="circle-medium" onClick={() => this.handleChangeStatus("ToMikvah")} loading={this.state.isUpdatingStatus} text={t("Leave to Mikvah")} />
        </>)
      case 'leaveNoMikvah':
        return (<>
        <Button type="circle" className="mie-4" onClick={() => this.handleChangeStatus("AttendantLeavingRoom")} loading={this.state.isUpdatingStatus} text={t("Balanit Exit")} />
        <Button type="circle-medium" disabled={true} text={t("BorBusy")} />
      </>)
    }
  }

  handleChangeStatus(status) {
    let roomId = this.props.room.id;

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }


  render() {
    const { t, room, isLoadedSection } = this.props;
    const { view, error } = this.state;
    let readyForMikvahPosition = room.readyForMikvahPosition;
    return (
      <div className={"body-right" + (room.reservation.attendantInRoom?" attendantInRoom":"")}>
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}

        {isLoadedSection?
        <>
        <SettingsContext.Consumer>
          {({settings})=> {
            if (settings) {
              let zmanTevilah = settings.zmanTevillah.value;
              return moment().isBefore(moment(zmanTevilah))?
                <Alert color="warning">{t('Please Note')}: {t('Tevilah begins at')} <Moment format={t("h:mma")}>{zmanTevilah}</Moment>. </Alert>
              :null
            }
          }}
        </SettingsContext.Consumer>
        <div className="room-queue">
         {readyForMikvahPosition?
          <>
            <p className="title">{t("There are")}</p>
            <p className="number">{readyForMikvahPosition}</p>
            <p className="title mb-0">{t("Ahead of you for tevilah")}</p>
          </>
         :
          <>
            <p className="text-next">{t("You are next")}</p>
            {!room.reservation.attendantInRoom?
            <p className="instructions mb-0">{t("The attendant will be there shortly")}</p>
            :null}
          </>
         }
        </div>
        <div className="instructions mb-0">
           {view?
              this.screen(view)
            : 
           null}
        </div>
        </>
        :<Loader />}
      </div>
    );
  }
}

export default withTranslation()(ScreenReady);
