import { getAudioStreams as GetAudioStreams, getAudioZones as GetAudioZones, setAudio as SetAudio, setRoomAudio as SetRoomAudio } from './../api';

export function getAudioStreams() {
  return new Promise((resolve, reject) => {
    GetAudioStreams()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getAudioZones() {
  return new Promise((resolve, reject) => {
    GetAudioZones()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function setAudio(zoneId, streamId, volume) {
  return new Promise((resolve, reject) => {
    SetAudio(zoneId, streamId, volume)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let res = result;
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function setRoomAudio(zoneId, streamId, volume) {
  return new Promise((resolve, reject) => {
    SetRoomAudio(zoneId, streamId, volume)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let res = result;
          return resolve(res);
        },
        error => reject(error)
      );
  })
}
