import React from 'react';
import { withTranslation } from 'react-i18next';

import { getAttendants, updateAttendant } from './../../models/attendant';
import { getAdminUsers, getUsers, changeUserRole } from './../../models/user';
import { getStaff, updateStaff } from './../../models/staff';
import { setUserPassword, register } from './../../models/account';
import Loader from './../../components/snippets/loader';
import { Alert } from 'reactstrap';
import Button from './../../components/button';
import { staffRoles } from './../../global-props';
import { UncontrolledDropdown, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SettingsContext } from "./../../components/settings";
import i18n from './../../i18n';

class ManagerUserView extends React.Component {
  static contextType = SettingsContext;
  constructor(props) {
    super(props)
  
    this.state = {
      isLoadedUsers: false,
      isLoadedAttendants: false,
      attendant: null,
      users: null,
      error: null,
      staffError: null,
      attendantError: null,
      modalMode: null,
      modalUser: false,
      modalStaff: false,
      modalAttendant: false,
      userResults: null,
      isUserResultsOpen: false,
      attendantFilterActive: true,
      staffFilterActive: true
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleStaffChange = this.handleStaffChange.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangeAttendant = this.handleChangeAttendant.bind(this);
    this.handleUserSubmit = this.handleUserSubmit.bind(this);
    this.handleStaffSubmit = this.handleStaffSubmit.bind(this);
    this.handleAttendantSubmit = this.handleAttendantSubmit.bind(this);
    this.retrieveUsers = this.retrieveUsers.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.toggleUserResults = this.toggleUserResults.bind(this);
    this.retrieveAttendants = this.retrieveAttendants.bind(this);
  }

  componentDidMount() {
    this.retrieveAttendants();
    this.retrieveUsers();
    this.retrieveStaff();
  }

  retrieveUsers() {
    getAdminUsers(true)
      .then(result => {
        let users = result;
        this.setState({
          users,
          isLoadedUsers: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveStaff() {
    getStaff(true)
      .then(result => {
        let staffs = result;
        this.setState({
          staffs,
          isLoadedStaff: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var user = {...this.state.user};
    user[name] = value;
    this.setState({user});
  }

  handleChangeAttendant(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var attendant = {...this.state.attendant};
    attendant[name] = value;
    this.setState({attendant});
  }

  handleStaffChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var staff = {...this.state.staff};
    staff[name] = value;
    this.setState({staff});
  }

  handleChangeUsername(event) {
    let value = event.target.value;

    this.setState({ enteredUsername: value, userResults: null, error: null })
    if ( value.length >= 4) {
      getUsers(value)
      .then(
        result => {
          let userResults = result.map((u,i)=>{u.new=true; return u;});
          this.setState({
            userResults,
            isUserResultsOpen: true,
          })
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
      
    }
  }

  toggleUserResults() {
    this.setState({isUserResultsOpen: !this.state.isUserResultsOpen});
  }

  showUserForm(user, modalMode) {
    this.setState({
      user,
      modalMode,
      modalUser: true,
    })
  }

  showStaffForm(staff) {
    this.setState({
      staff,
      modalStaff: true,
    })
  }

  handleUserSubmit(e) {
    e.preventDefault();
    const { modalMode, user } = this.state;

    this.setState({
      isSubmitting: true,
    })

    switch (modalMode) {
      case 'password':
        setUserPassword(user.id, user.password, user.confirmPassword).then(
          result => {
            this.setState({modalUser: false, isSubmitting: false});
            this.retrieveUsers();
          },
          error => {
            this.setState({
              error: error,
              modalUser: false,
              isSubmitting: false
            });
          }
        );
        break;
      case 'search':
      case 'remove':
        changeUserRole(user.id, 'Admin', (modalMode==='remove'?'delete':'put')).then(
          result => {
            this.setState({modalUser: false, isSubmitting: false});
            this.retrieveUsers();
          },
          error => {
            this.setState({
              error: error,
              modalUser: false,
              isSubmitting: false
            });
          }
        );
        break;
      case 'add':
        register({username: user.email, email: user.email, password: user.password})
          .then(
            result => {
              let user = result.result;
              changeUserRole(user.id, 'Admin', 'put').then(
                result => {
                  this.setState({modalUser: false, isSubmitting: false});
                  this.retrieveUsers();
                },
                error => {
                  this.setState({
                    error: error,
                    modalUser: false,
                    isSubmitting: false
                  });
                }
              );
            },
            error => {
              this.setState({
                error: error,
                modalUser: false,
                isSubmitting: false
              });
            }
          )
        break;
      default:
        break;
    }

    /*let user = this.state.user;

    if ( user.firstName!=='' && user.lastName!=='' ) {
      updateUser(user)
        .then(result => {
          let users = this.state.users;
          let index = users.findIndex(x=> x.id === result.id);
          if ( index === -1 ) {
            users.push(result);
          } else {
            users[index] = result;
          }
          this.setState({users, user: null, modalUser: false, isSubmitting: false});
        })
    }*/
  }

  handleStaffSubmit(setReception) {  
    let staff = this.state.staff;
    
    if ( staff.firstName && staff.lastName ) {
      if(setReception && !staff.role){
        staff.role = "Receptionist";
      }
      this.setState({
        isSubmitting: true,
      })
      updateStaff(staff)
        .then(result => {
          let staffs = this.state.staffs;
          let index = staffs.findIndex(x=> x.id === result.id);
          if ( index === -1 ) {
            staffs.push(result);
          } else {
            staffs[index] = result;
          }
          this.setState({staffs, staff: null, staffError: null, modalStaff: false, isSubmitting: false});
        },
        error => {
          this.setState({
            staffError: error,
            isSubmitting: false
          });
        }
      )
    } else{
      this.setState({staffError: "Please make sure first and last name is filled out."})
    }
  }

  showAttendantForm(attendant) {
    this.setState({
      attendant: attendant,
      modalAttendant: true,
    })
  }

  handleAttendantSubmit() {
    let attendant = this.state.attendant;
    
    if ( attendant.firstName && attendant.lastName ) {
      this.setState({
        isSubmitting: true,
      })
      updateAttendant(attendant)
        .then(result => {
          let attendants = this.state.attendants;
          let index = attendants.findIndex(x=> x.id === result.id);
          if ( index === -1 ) {
            attendants.push(result);
          } else {
            attendants[index] = result;
          }
          this.setState({attendants, attendant: null, attendantError: null, modalAttendant: false, isSubmitting: false});
        }, error => {
          this.setState({
            attendantError: error,
            isSubmitting: false
          });
        }
)
    } else{
      this.setState({attendantError: "Please make sure first and last name is filled out."})
    }
  }

  modalToggle(modal) {
    if(modal==="User"){
      this.setState({
        modalUser: !this.state.modalUser,
        userResults: null
      })
    } else if(modal==="Staff") {
      this.setState({
        modalStaff: !this.state.modalStaff,
        staffError: null,
      })
    } else if(modal==="Attendant") {
      this.setState({
        modalAttendant: !this.state.modalAttendant,
        attendantError: null,
      })
    }
  }
  
  render() {
    const { t } = this.props;
    const { users, user, isLoadedUsers, isLoadedStaff, userResults, isUserResultsOpen, modalMode, modalStaffMode, staff, staffs, isLoadedAttendants, attendant, attendants, attendantFilterActive, staffFilterActive, error, staffError, attendantError } = this.state;
    const { settings } = this.context;
    const useStaffEntry = settings && settings.roomsUseStaffEntry.value=="True";
    return (
        <div className="reception-view">
          {isLoadedUsers && isLoadedStaff && isLoadedAttendants?
            <>
            <div className="row mb-4">
              <div className="col-12">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">{t("Attendants")} {attendantFilterActive?"":"("+ t("Inactive") + ")"}</h4>
                  <div className="d-flex align-items-center">
                    <div className="cursor-pointer" onClick={() => this.setState({attendantFilterActive:!attendantFilterActive})}>{t((attendantFilterActive?"Show Inactive":"Show Active"))}</div>
                    <Button className="mis-3" type="secondary" onClick={() => this.showAttendantForm({firstName: '', lastName: '', cardCode: '', active: false, new: true})}>{t("Add Attendant")}</Button>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th>{t("Active")}</th>
                      <th>{t("Card Code")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendants.filter(att => att.active===attendantFilterActive).sort((a,b)=>a.name.localeCompare(b.name)).map((att, i) => {
                      return <tr key={i}>
                        <td>{t(att.name)}</td>
                        <td>{att.active?<FontAwesomeIcon icon="check" />:null}</td>
                        <td>{att.cardCode}</td>
                        <td className="text-end"><Button type="secondary" onClick={() => this.showAttendantForm(att)}>{t("Edit")}</Button></td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
            </div>
              <Modal isOpen={this.state.modalAttendant} toggle={()=>this.modalToggle("Attendant")} centered className={"lang-" + i18n.language} modalClassName="modal-attendant">
                    <ModalHeader toggle={()=>this.modalToggle("Attendant")}>{attendant&&attendant.new?t("Add Attendant"):t("Edit Attendant")}</ModalHeader>
                    <ModalBody>
                      <>
                      {(attendantError ?
                        <Alert color="danger">{t(attendantError)}</Alert>
                      : null)}
                      <div>
                        <div className="form-group">
                          <label htmlFor="firstName">{t("First Name")}</label>
                          <input type="text" id="firstName" name="firstName" className="form-control" value={attendant?attendant.firstName:''} onChange={this.handleChangeAttendant} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">{t("Last Name")}</label>
                          <input type="text" id="lastName" name="lastName" className="form-control" value={attendant?attendant.lastName:''} onChange={this.handleChangeAttendant} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="cardCode">{t("Card Code")}</label>
                          <input type="text" id="cardCode" name="cardCode" className="form-control" value={attendant?attendant.cardCode:''} onChange={this.handleChangeAttendant} />
                        </div>
                        <div className="checkbox">
                          <label><input type="checkbox" name="active" checked={attendant?attendant.active:false} onChange={this.handleChangeAttendant} /> {t("Active")}</label>
                        </div>
                        <hr />
                        <div className="text-end">
                          <Button type="secondary" onClick={()=>this.modalToggle("Attendant")}>Cancel</Button> <Button onClick={this.handleAttendantSubmit} type="secondary" loading={this.state.isSubmitting}>{t("Save")}</Button>
                        </div>
                      </div>
                      </>
                    </ModalBody>
              </Modal>
            <div className="row mb-4">
              <div className="col-12">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">{useStaffEntry?t("Staff"):t("Receptionist")} {staffFilterActive?"":"("+ t("Inactive") + ")"} </h4>
                  <div className="d-flex align-items-center">
                    <div className="cursor-pointer" onClick={() => this.setState({staffFilterActive:!staffFilterActive})}>{t("Show " + (staffFilterActive?"Inactive":"Active"))}</div>
                    <Button className="mis-3" type="secondary" onClick={() => this.showStaffForm({new: true})}>{useStaffEntry?t("Add Staff Member"):t("Add Receptionist")}</Button>
                  </div>
                </div>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("Name")}</th>
                          <th>{t("Active")}</th>
                          {useStaffEntry?<th>{t("Pin Code")}</th>:null}
                          {useStaffEntry?<th>{t("Role")}</th>:null}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffs.filter(staff=>staff.active===staffFilterActive).sort((a,b)=>a.name.localeCompare(b.name)).map((staff, i) => {
                          return useStaffEntry || staff.role=="Receptionist"?
                          <tr key={i}>
                            <td>{staff.name}</td>
                            <td>{staff.active?<FontAwesomeIcon icon="check" />:null}</td>
                            {useStaffEntry?<td>{staff.pinCode}</td>:null}
                            {useStaffEntry?<td>{t(staff.role)}</td>:null}
                            <td className="text-end"><Button type="secondary" onClick={() => this.showStaffForm(staff)}>{t("Edit")}</Button></td>
                          </tr>
                          :null
                        })}
                      </tbody>
                    </table>
                  </div>
              </div>

              <Modal isOpen={this.state.modalStaff} toggle={() =>this.modalToggle("Staff")} centered className={"lang-" + i18n.language} modalClassName="modal-user">
                <ModalHeader toggle={() =>this.modalToggle("Staff")}>{staff&&staff.new?(useStaffEntry?t("Add Staff Member"):t("Add Receptionist")):(useStaffEntry?t("Edit Staff"):t("Edit Receptionist"))}</ModalHeader>
                <ModalBody>
                      <>
                      {(staffError ?
                        <Alert color="danger">{t(staffError)}</Alert>
                      : null)}
                      <div className="form-group">
                          <label htmlFor="firstName">{t("First Name")}</label>
                          <input type="text" id="firstName" name="firstName" className="form-control" value={staff?staff.firstName:''} onChange={this.handleStaffChange} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">{t("Last Name")}</label>
                          <input type="text" id="lastName" name="lastName" className="form-control" value={staff?staff.lastName:''} onChange={this.handleStaffChange} />
                        </div>
                        {useStaffEntry?<div className="form-group">
                          <label htmlFor="pinCode">{t("Pin Code")}</label>
                          <input type="text" id="pinCode" name="pinCode" className="form-control" value={staff?staff.pinCode:''} onChange={this.handleStaffChange} />
                        </div>:null}
                        {useStaffEntry?<div className="form-group">
                          <label>{t("Role")}</label>
                          <UncontrolledDropdown>
                          <DropdownToggle caret className="btn-block">
                            {staff&&staff.role?t(staff.role):t('Role')}
                          </DropdownToggle>
                          <DropdownMenu>
                            {staffRoles.map((role,i) => {
                              return <DropdownItem key={i} className={staff&&staff.role===role?"active":""} name="role" value={role} onClick={this.handleStaffChange}>{t(role)}</DropdownItem>
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        </div>:null}
                        <div className="checkbox">
                          <label><input type="checkbox" name="active" checked={staff?staff.active:false} onChange={this.handleStaffChange} /> {t("Active")}</label>
                        </div>
                        <hr />
                        <div className="text-end">
                          <Button className="mie-1" type="secondary" onClick={() => this.modalToggle("Staff")}>{t("Cancel")}</Button><Button type="secondary" onClick={() => this.handleStaffSubmit(!useStaffEntry)} loading={this.state.isSubmitting}>{t("Save")}</Button>
                        </div>
                      </>
                </ModalBody>
              </Modal>
            </div>
            <div className="row">
              <div className="col-12">
                {(error ?
                  <Alert color="danger">{t(error)}</Alert>
                : null)}
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">{t("Admin Users")}</h4>
                  <Button type="secondary" onClick={() => this.showUserForm({new: true}, 'search')}>{t("Add User")}</Button>
                </div>
                {['email', 'phone'].map((type,i)=>{
                  let filteredUsers = users.filter(u=>(u.phoneNumber===u.userName&&type==='phone')||(type==='email'&&u.phoneNumber!==u.userName));
                  if (filteredUsers.length===0) { return false; }
                  return <div key={i}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{type==='email'?t('Email'):t('Phone')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.map((user, i) => {
                          return <tr key={i}>
                            <td>{type==='email'&&user.email?user.email:user.userName}</td>
                            <td className="text-end"><Button type="secondary" onClick={() => this.showUserForm(user, 'password')}>{t("Change Password")}</Button> <Button type="secondary" onClick={() => this.showUserForm(user, 'remove')}>{t("Remove Admin")}</Button></td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                })}
              </div>

              <Modal isOpen={this.state.modalUser} toggle={() =>this.modalToggle("User")} centered className={"lang-" + i18n.language} modalClassName="modal-user">
                <ModalHeader toggle={() =>this.modalToggle("User")}>{user&&user.new?t("Add User"):t("Edit User")}</ModalHeader>
                <ModalBody>
                  <form onSubmit={this.handleUserSubmit}>
                    {modalMode==='search'? 
                      <>
                        <Dropdown isOpen={isUserResultsOpen} toggle={this.toggleUserResults}>
                          <div className="form-group  dir-ltr">
                            <label className="mb-0" htmlFor="username">{t("Email Address or Phone Number")}</label>
                            <div className="small mb-1">{t("Phone number or email must already exist; Use country code ie. 17185556666 for US Number")}</div>
                            {user&&user.id?
                              <div type="text" className="form-control disabled">{t("User")}: {user.id} - {(user.email!==null?user.email:'')+' '+(user.phoneNumber!==null?user.phoneNumber:'')}</div>
                            :
                              <input type="text" id="username" name="username" className="form-control dir-ltr" onChange={this.handleChangeUsername} onClick={()=>this.setState({isUserResultsOpen:true})} autoComplete="off" />
                            }
                            <DropdownToggle tag="span"></DropdownToggle>
                            {userResults&&userResults.length?
                              <DropdownMenu>
                                {userResults.map((s,i)=>
                                  <DropdownItem key={i} onClick={()=>this.showUserForm(s, 'search')}>{s.email}{s.phoneNumber}</DropdownItem>
                                )}
                              </DropdownMenu>
                            :null}              
                          </div>
                        </Dropdown>
                        <div className="text-end">
                           <Button type="secondary" disabled={!(user&&user.id)} loading={this.state.isSubmitting}>{t("Give Admin Rights")}</Button>
                        </div>
                        {user&&user.id?
                          null
                        :
                          <div className="text-center">
                            <hr/>
                            <div>{t("OR")}</div>
                            <Button type="secondary" onClick={()=>this.showUserForm({new:true}, 'add')}>{t("Create New Email User")}</Button>
                          </div>
                        }
                      </> 
                    :null}
                    {modalMode==='add'?
                      <>
                        <div className="form-group">
                          <label htmlFor="email">{t("Email")}</label>
                          <input type="text" id="email" name="email" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">{t("Password")}</label>
                          <input type="password" id="password" name="password" className="form-control" onChange={this.handleChange} />
                        </div>
                        <hr />
                        <div className="text-end">
                          <Button type="secondary" loading={this.state.isSubmitting}>{t("Create User")}</Button>
                        </div>
                      </>
                    :null}
                    {modalMode==='password'?
                      <>
                        <div className="form-group">
                          <label htmlFor="password">{t("Password")}</label>
                          <input type="password" id="password" name="password" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
                          <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="text-end">
                          <Button type="secondary" loading={this.state.isSubmitting}>{t("Save")}</Button>
                        </div>
                      </>
                    :null}
                    {modalMode==='remove'?
                      <>
                        <div className="form-group">
                        {t("Are you sure you want to remove admin access from this user?")}
                        </div>
                        <div className="text-end">
                          <Button type="secondary" loading={this.state.isSubmitting}>{t("Yes")}</Button>
                        </div>
                      </>
                    :null}
                  </form>
                </ModalBody>
              </Modal>

            </div>
            </>
          : (error ?
            <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
          : <Loader />)}
        </div>
    )
  }
}
export default withTranslation()(ManagerUserView);
