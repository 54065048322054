import { getAttendants as GetAttendants, updateAttendant as UpdateAttendant } from './../api';

function processAttendant(att) {
  att.name = att.firstName + ' ' + att.lastName
  return att;
}

export function getAttendants(getAll) {
  return new Promise((resolve, reject) => {
    GetAttendants(getAll)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let attendants = result.map((attendant,i) => {
              return processAttendant(attendant);
            })
            return resolve(attendants);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateAttendant(attendant) {
  return new Promise((resolve, reject) => {
    UpdateAttendant(attendant)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          return resolve(processAttendant(result.result));
        },
        error => reject(error)
      );
  })
}
