import React from 'react';
import { withTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appAuth } from "../../../router";
import { receptionistTabMenu } from '../../../global-props'
import LanguageSelector from '../../../components/language-selector';

class ReceptionistTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'Dashboard',
      showAdmin: false
    };

  }

  componentDidMount() {
    let page = this.props.page;
    this.setState({view: page});
    if((appAuth.authorization&&appAuth.authorization.roles.includes('Admin'))||(appAuth.authorization&&appAuth.authorization.username==="Reception")){
      this.setState({showAdmin: true})
    }
  }

  render() {
    const { t } = this.props;
    const { view, showAdmin } = this.state;
    return (
      <>
      <Nav className="reception-tabs font-weight-normal justify-content-between">
        <div className="d-flex flex-wrap">
        {receptionistTabMenu.map((item,i) => {
          return <NavItem key={i} className={view === item.title?'active':null}>
            <NavLink
             tag={Link}
             to={item.link}
             >
               <FontAwesomeIcon className="mie-2" icon={item.icon}/>
               {t(item.title)}
            </NavLink>
          </NavItem>
        })}
        </div>
        {showAdmin?
        <NavItem className={view === 'admin'?'active':null}>
          <NavLink
           tag={Link}
           to={'/manager/dashboard'}
           >
             <FontAwesomeIcon className="mie-2" icon="toolbox"/>
             {t("Admin")}
          </NavLink>
        </NavItem>
        :null}
      </Nav>
      <LanguageSelector />
      </>
  )}
}

export default withTranslation()(ReceptionistTabs);
