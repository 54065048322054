import React from "react";

import { withTranslation } from 'react-i18next';
import BodyHeader from "./../partials/BodyHeader";
import ReactSVG from 'react-svg';
import moneyOwed from "./../../../assets/images/icon-money-owed.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkinReservation, printReceipt, updateNote, createReservation, addReservationMerchandise } from "./../../../models/reservation";
import Moment from "react-moment";
import { Redirect } from 'react-router-dom';
import Button from './../../../components/button';

import { createPayment, getSavedCards } from "./../../../models/payment";
import { getMerchandise } from "./../../../models/setting";
import StripeProvider from './../../../components/stripeProvider';
import StripeTerminal from './../../../components/stripeTerminal';
import StripeCardForm from './../../../components/stripeCardForm';
import ICountCardForm from './../../../components/iCountCardForm';
import Loader from './../../../components/snippets/loader';
import { Alert, Modal, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { calendarDayStrings, cardBrands, tipItems } from './../../../global-props';
import CurrencyFormat from './../../../components/snippets/currency-format';
import HebrewDate from './../../../components/hebrewDate';
import LoaderSpinner from './../../../assets/images/icon-loader-spinner.svg';
import iconCalendar from './../../../assets/images/icon-calendar.svg';
import iconClock from './../../../assets/images/icon-clock.svg';
import iconClockExtra from './../../../assets/images/icon-clock-extra.svg';
import iconBath from './../../../assets/images/icon-bath.svg';
import iconShower from './../../../assets/images/icon-shower.svg';
import iconParking from './../../../assets/images/icon-parking.svg';
import iconSalon from './../../../assets/images/icon-salon.svg';
import iconWheelchair from './../../../assets/images/icon-wheelchair.svg';
import iconKallah from './../../../assets/images/icon-kallah.svg';
import iconBathPrivate from './../../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from './../../../assets/images/icon-shower-private.svg';
import { SettingsContext } from "../../../components/settings";
import i18n from './../../../i18n';

class CheckinPayment extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      reservationCode: "",
      requireFullPhoneNumber: false,
      isEditingReservationNote: false,
      isLoading: false,
      note: "",
      savedCards: null,
      isLoadingSavedCards: false,
      paymentReference: "",
      paymentAmount: props.reservation.balance || 0,
      paymentAmountFloat: props.reservation.balance,
      paymentView: props.reservation.paid?'paid':null,
      creditMode: null,
      reservationNote: props.reservation.notes || "",
      userNote: props.reservation.user.notes || "",
      tipAmount: "",
      donationAmount: "",
      discountAmount:"",
      selectedTipItem: "",
      feeAmount: "",
      feeDescription: "",
      useDiscountPrice: props.reservation.useDiscountPrice || false,
    }
    this.savedContext = context;

    this.handleChange = this.handleChange.bind(this);
    this.handleSaveNote = this.handleSaveNote.bind(this);
    this.handleEditReservation = this.handleEditReservation.bind(this);
    this.handleCashCheckReceived = this.handleCashCheckReceived.bind(this);
    this.handlePayLater = this.handlePayLater.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.handleDonation = this.handleDonation.bind(this);
    this.handleTip = this.handleTip.bind(this);
    this.handleFee = this.handleFee.bind(this);
    this.handleAddProducts = this.handleAddProducts.bind(this);
    this.handleGetSavedCards = this.handleGetSavedCards.bind(this);
    this.handleChargeNewCard = this.handleChargeNewCard.bind(this);
    this.handleChargeSavedCard = this.handleChargeSavedCard.bind(this);
    this.handleCapturePaymentIntent = this.handleCapturePaymentIntent.bind(this);
    this.handleChargeTerminalICount = this.handleChargeTerminalICount.bind(this);
    this.handleCheckinReservation = this.handleCheckinReservation.bind(this);
    this.handleReprint = this.handleReprint.bind(this);
    this.setupLineItems = this.setupLineItems.bind(this);
    this.modalToggleUserNote = this.modalToggleUserNote.bind(this);
    this.toggleDiscount = this.toggleDiscount.bind(this);
  }

  componentDidMount() {
    this.setupLineItems();
    const {reservation} = this.props;
    if(!reservation.reservationId && reservation.replacingReservationId){
      reservation.reservationId = reservation.replacingReservationId;
      reservation.replacingReservationId = null;
      this.props.handleUpdateReservation(reservation);
    }
    this.retrieveMerchandise();
    if(reservation.merchandise){
      reservation.merchandise.forEach(m =>{
        this.setState({
          ['Product'+m.item]:m.quantity
        })
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.state.paymentView === "credit" ) {
      if ( prevState.savedCards === null && !prevState.isLoadingSavedCards ) {
        this.handleGetSavedCards();
      }
    }
    if ( prevProps.reservation !== this.props.reservation && this.props.reservation.user) {
      this.setupLineItems();
      this.setState({
        paymentAmount: this.props.reservation.balance || 0,
        paymentAmountFloat: this.props.reservation.balance,
        reservationNote: this.props.reservation.notes || "",
        userNote: this.props.reservation.user.notes || "",
        useDiscountPrice: this.props.reservation.useDiscountPrice || false,
      })
      if (this.props.reservation.paid) {
        this.setState({paymentView: 'paid'})
      }
      if(this.props.reservation.merchandise){
        this.props.reservation.merchandise.forEach(m =>{
          this.setState({
            ['Product'+m.item]:m.quantity
          })
        })
      }  
    }
    if ( this.context !== this.savedContext ) {
      this.savedContext = this.context;
      this.setupLineItems();
    }
  }

  retrieveMerchandise() {
    getMerchandise().then(
      (result) => {
        let merch = result;
        this.setState({
          merch,
        });
      },
      (error) => {
        this.setState({ error });
      }
    );
  }

  setupLineItems() {
    const res = this.props.reservation;
    const { settings, selCardReader } = this.context;

    if (this.props.reservation.kallah) {
      this.roomTypeDisplay = { name: 'Kallah', icon: iconKallah, price: settings?settings.priceKallah.value:0 }
    } else if (this.props.reservation.handicapped) {
      this.roomTypeDisplay = { name: "Accessible", icon: iconWheelchair, price: settings?settings.priceBath.value:0 }
    }else if (this.props.reservation.privateRoom && this.props.reservation.bath) {
      this.roomTypeDisplay = { name: "Bath Private", icon: iconBathPrivate, price: settings?settings.pricePrivateBath.value:0 }
    } else if (this.props.reservation.privateRoom && !this.props.reservation.bath) {
        this.roomTypeDisplay = { name: "Shower Private", icon: iconShowerPrivate, price: settings?settings.pricePrivateShower.value:0 }
    } else if (this.props.reservation.bath) {
      this.roomTypeDisplay = { name: "Bath", icon: iconBath, price: settings?this.props.reservation.useDiscountPrice && settings.priceBathDiscount? settings.priceBathDiscount.value :settings.priceBath.value:0 }
    } else {
      this.roomTypeDisplay = { name: "Shower", icon: iconShower, price: settings?this.props.reservation.useDiscountPrice && settings.priceShowerDiscount? settings.priceShowerDiscount.value :settings.priceShower.value:0 }
    }
    this.setState({roomTypeDisplay: this.roomTypeDisplay});

    let lineItems = {
      type: 'cart',
      cart: {
        line_items: [
          {
            description: this.roomTypeDisplay.name ? this.roomTypeDisplay.name.charAt(0).toUpperCase() + this.roomTypeDisplay.name.slice(1) : '',
            quantity: 0,
            amount: parseFloat(this.roomTypeDisplay.price)*100,
          },
        ],
        total: Math.round(this.state.paymentAmount * 100),
        tax: null,
        currency: 'usd',
      }        
    }
    if ( res.parking ) {
      lineItems.cart.line_items.push({description: 'Parking', quantity: 0, amount: parseFloat(settings?settings.priceParking.value:0)*100});
    }
    if ( res.salonBoth ) {
      lineItems.cart.line_items.push({description: 'Mani+Pedi Polish', quantity: 0, amount: parseFloat(settings?settings.priceSalonBoth.value:0)*100});
    } else {
      if ( res.salonManicure ) {
        lineItems.cart.line_items.push({description: 'Manicure Polish', quantity: 0, amount: parseFloat(settings?settings.priceSalonManicure.value:0)*100});
      }
    }
    if ( res.doubleSlot ) {
      lineItems.cart.line_items.push({description: 'Extra Time', quantity: 0, amount: parseFloat(settings?settings.priceDoubleSlot.value:0)*100});
    }
    if ( res.payments ) {
      res.payments.map((payment) => lineItems.cart.line_items.push({description: payment.method, quantity: 0, amount: -payment.amount * 100}) );
    }

    let terminalId = selCardReader?selCardReader:null;
    this.setState({lineItems, terminalId});
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({[name]: value});
  }

  handleChangeMerchandise(e,item){
    let value = e.target.value;
    if(value.length>1&&value.startsWith("0")){
      value = value.substring(1);
    }
    this.setState({[item]:value})
  }

  handleChangeAmount(values) {
    let paymentAmount = values.value;
    let paymentAmountFloat = values.floatValue;
    this.setState({paymentAmount, paymentAmountFloat}, this.setupLineItems);
  }

  handleCheckinReservation() {
    const { selPrinter } = this.context;
    this.setState({
      isLoading: true,
      error: null,
    })
    checkinReservation(this.props.reservation.reservationId, { printerId: selPrinter ? selPrinter : '' })
          .then(result => {
            let res = result;
            this.props.handleUpdateReservation(res);
            this.setState({
              paymentView: 'paid',
              creditMode: null,
              isLoading: false,
            })
          }, error => {
        this.setState({error: error, isLoading: false});
          });
  }

  handleReprint() {
    const { selPrinter } = this.context;
    this.setState({
      isLoading: true,
      error: null,
    })
    printReceipt(this.props.reservation.reservationId, selPrinter)
    .then(result => {
        this.setState({
          isLoading: false,
      })
    }, error => {
      this.setState({error: error, isLoading: false});
        });      
      }

  handleGetSavedCards() {
    this.setState({isLoadingSavedCards:true});
    getSavedCards(this.props.reservation.userId)
      .then(result => {
        this.setState({
          savedCards: result,
          isLoadingSavedCards: false,
        })
      }, error => this.setState({error}))
  }

  handleCreatePayment(payment) {
    this.setState({
      isLoading: true,
      error: null,
    })
    createPayment(payment)
      .then(result => {
        this.props.handleUpdateReservation(result);
        if(payment.method==='donation'){
          this.setState({donationAmount: 0})
        } else if(payment.method==='tip'){
          this.setState({tipAmount: 0, selectedTipItem: ""})
        } else if(payment.method==='fee'){
          this.setState({feeAmount: "", feeDescription: ""})
        }
        if ( result.paid ) {
          this.setState({
            paymentView: 'paid',
            isLoading: false,
            confirmed: this.props.isEditingReservation?false:true,
            updated: this.props.isEditingReservation?true:false
          })
        } else {
          this.setState({
            paymentView: null,
            creditMode: null,
            paymentReference: "",
            paymentAmount: this.props.reservation.balance,
            isLoading: false,
            tipAmount: "",
            donationAmount: "",
            discountAmount: ""
          }, this.setupLineItems)
        }
      }, error => {
        this.setState({error: error.stripeErrorMessage?error.stripeErrorMessage:error.errorMessage,isLoading: false});
      })
  }

  handlePayLater() {
    // this.setState({
    //   renderRedirect: true,
    // });
    let res = this.props.reservation;
    res.mode = 'edit';
    res.replacingReservationId = res.reservationId;
    createReservation(res)
    .then(result => {
      this.setState({
        updated: true,
      })
    }, error => {
      this.setState({
        error,
        isSubmitting: false,
      })
    });
  }

  handleCashCheckReceived(e) {
    e.preventDefault();
    let reference = this.state.paymentReference;
    let payment = {
      method: reference!=="" ? 'check' : 'cash',
      amt: this.state.paymentAmount,
      reference: reference!=="" ? reference : null,
      reservationId: this.props.reservation.reservationId,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleDiscount(e) {
    e.preventDefault();
    let payment = {
      method: 'discount',
      amt: this.state.discountAmount,
      reservationId: this.props.reservation.reservationId,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleDonation(e) {
    e.preventDefault();
    let payment = {
      method: 'donation',
      amt: 0 - parseFloat(this.state.donationAmount),
      reference: 'Donation',
      reservationId: this.props.reservation.reservationId,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleTip(e) {
    e.preventDefault();
    let payment = {
      method: 'tip',
      amt: 0 - parseFloat(this.state.tipAmount),
      reference: 'Tip',
      reservationId: this.props.reservation.reservationId,
      tipNote: this.state.selectedTipItem,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleFee(e) {
    e.preventDefault();
    let payment = {
      method: 'fee',
      amt: 0 - parseFloat(this.state.feeAmount),
      reference: 'Fee',
      reservationId: this.props.reservation.reservationId,
      note: this.state.feeDescription,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleChargeNewCard(payload) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      reservationId: this.props.reservation.reservationId,
      paymentInfo: {sourceToken: payload.token.id},
      saveCard: true,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'new'});
  }

  handleChargeNewCardICount(paymentInfo) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      reservationId: this.props.reservation.reservationId,
      paymentInfo: paymentInfo,
      saveCard: true,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'new'});
  }

  handleChargeTerminalICount() {
    const { selCardReader } = this.context;
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      reservationId: this.props.reservation.reservationId,
      paymentInfo: {cardReaderId: selCardReader},
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleChargeSavedCard(card) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      reservationId: this.props.reservation.reservationId,
      paymentInfo: {sourceCard: card.id, cvv:this.state["CVV"+card.last4]},
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'saved'});
  }

  handleCapturePaymentIntent(paymentIntent) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      reservationId: this.props.reservation.reservationId,
      paymentIntent: paymentIntent.id,
      saveCard: true,
      useDiscountPrice: this.props.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'terminal'});
  }

  handleEditNote = () => {
    let { reservationNote } = this.state;
    const { reservation } = this.props;
    console.log(reservationNote, reservation.notes);
    if ( reservationNote === '' && reservation.notes ) { reservationNote = reservation.notes }
    this.setState({isEditingReservationNote: true, reservationNote})
  }

  handleAddProducts(e) {
    e.preventDefault();
    let { reservation } = this.props;
    let merch = [];
    this.state.merch.forEach((m,i) =>{
      if(this.state[`Product${m.item}`] && parseInt(this.state[`Product${m.item}`]) > 0){
        merch.push({item: m.item,price:m.price, quantity:this.state[`Product${m.item}`], total:m.price*this.state[`Product${m.item}`]})
      }
    })
    let merchTotal = 0;
    if(merch.length){
      merch.forEach(m =>{
        merchTotal = merchTotal + (parseFloat(m.price) * parseFloat(m.quantity));
      })
    }
    addReservationMerchandise(reservation.reservationId,merch)
    .then(res => {
      this.setState({
        updated: true,
      })
      this.props.handleUpdateReservation(res)
        .then(result=> this.setState({paymentView: null, paymentAmount: res.balance}));
    }, error => {
      this.setState({
        error,
        isSubmitting: false,
      })
    });
  }
  
  handleSaveNote(noteType) {
    let { reservationNote, userNote } = this.state;
    let res = {
      ...this.props.reservation,
      reservationNotes: noteType==='reservation'?reservationNote:this.props.reservation.notes,
      userNotes: noteType==='user'?userNote:this.props.reservation.user.notes,
      replacingReservationId: this.props.reservation.reservationId
    }

    this.props.handleUpdateReservation(res);
    this.setState({
      isEditingReservationNote: false,
      modalUserNote: false,
    })
    if ( res.reservationId ) { 
      this.setState({
        isSavingNote: true,
      })

      updateNote(res.reservationId, res.reservationNotes, res.userNotes)
        .then(result => {
          this.props.handleUpdateReservation(result);
          this.setState({
            isSavingNote: false,
          })
        }, error => {
          this.setState({
            error,
            isSavingNote: false,
          })
        });
    }
  }

  modalToggleUserNote() {
    this.setState({modalUserNote: !this.state.modalUserNote});
  }

  handleEditReservation(e) {
    let res = this.props.reservation;
    res.replacingReservationId = res.reservationId;
    res.reservationId = null;
    this.props.handleUpdateReservation(res,res.kallah?'reservation-kallah':this.props.isAdvancedReservation?'reservation-calendar':'checkin-roomtype');
  }

  toggleDiscount(e) {
    const {settings} = this.context;
    let discount = 0;
    if (this.props.reservation.bath) {
      this.roomTypeDisplay = { ...this.roomTypeDisplay, price: e.target.checked? settings.priceBathDiscount.value :settings.priceBath.value };
      discount = parseFloat(settings.priceBath.value) - parseFloat(settings.priceBathDiscount.value);
    } else {
      this.roomTypeDisplay = { ...this.roomTypeDisplay, price: e.target.checked? settings.priceShowerDiscount.value :settings.priceShower.value };
      discount = parseFloat(settings.priceShower.value) - parseFloat(settings.priceShowerDiscount.value);
    }
    this.setState({
        useDiscountPrice: e.target.checked,
        paymentAmount: e.target.checked?this.state.paymentAmount - discount:this.state.paymentAmount + discount
    });
    let res = {
      ...this.props.reservation,
      useDiscountPrice: e.target.checked,
      balance: e.target.checked?this.props.reservation.balance - discount:this.props.reservation.balance + discount,
     }  
    this.props.handleUpdateReservation(res);
  }

  paymentView() {
    const { selPrinter, selCardReader, settings } = this.context;
    const { t, reservation, isAdvancedReservation, updated } = this.props;
    const { paymentView, savedCards, paymentAmount, donationAmount, discountAmount, tipAmount, selectedTipItem, feeAmount, feeDescription, paymentReference, isLoading, creditMode, lineItems, isLoadingSavedCards, terminalId, useDiscountPrice, merch } = this.state;
    switch(paymentView) {
      case 'paid':
        return <div>
          {updated?<Alert color="success">{t("reservationUpdated")}</Alert>:null}
          <h3 className="mb-4">{t("Paid")} <b><CurrencyFormat value={reservation.amountPaid} /></b></h3>
          {reservation.reservationStatus==='Assigned'?
            <>
              <ul className="payment-summary">
                <li>{t("Access Number")}: {reservation.reservationCode}</li> 
                <li>{t("Assigned Room")}: {reservation.room.id}</li> 
                <li>{t("Attendant")}: {reservation.room.section.attendant.firstName} {reservation.room.section.attendant.lastName}</li> 
                <li>{t("Mikvah")}: {reservation.room.section.name}</li> 
              </ul>
              { selPrinter ?
              <Button type="secondary" size="lg" onClick={this.handleReprint} loading={isLoading}>{t("Print Receipt")}</Button>
            : null  }
              </>
          :null}
          {reservation.reservationStatus==='Waiting'?
            <>
              <ul className="payment-summary">
                <li>{t("Access Number")}: {reservation.reservationCode}</li>
              </ul>
              { selPrinter ?
              <Button type="secondary" size="lg" onClick={this.handleReprint} loading={isLoading}>{t("Print Receipt")}</Button>
              : null  }
              </>
          :null}
          {reservation.reservationStatus==='Reserved'?
            (isAdvancedReservation?
              <div className="balance-due mb-3">
                <ReactSVG src={moneyOwed} className="mb-3 icon" alt="Money Icon"/>
                <div className="title">{t("Balance Due")}:</div>
                <div className="money-amount"><CurrencyFormat value={reservation.balance} /></div>
              </div>
            :
              <Button type="secondary" size="lg" onClick={this.handleCheckinReservation} loading={isLoading}>{t("Check In")}</Button>
            )
          :null}
          <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'fee'})}>+ {t("Add Fee")}</button>
          <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'merchandise'})}>+ {t("Add Merchandise")}</button>
        </div>

      case 'cash':
        return <div className="payment-view payment-view-cash">
          <div className="title mb-3">{t("Balance Due")}: <CurrencyFormat value={reservation.balance} /></div>

          <form autoComplete="off" onSubmit={this.handleCashCheckReceived}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentAmount">{t("Amount Received")}</label>
                <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={paymentAmount} onValueChange={(values) => this.handleChangeAmount(values)} autoFocus={true} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentReference">{t("Check Number")}</label>
                <input type="text" className="form-control form-control-lg" id="paymentReference" name="paymentReference" value={paymentReference} onChange={this.handleChange} />
              </div>
            </div>
            <div>
              <Button type="secondary" size="lg" loading={isLoading}>{paymentReference?t("Accept Check"):t("Accept Cash")}</Button>
            </div>
          </form>
          <div className="text-end">
            <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
          </div>
        </div>

      case 'discount':
        return <div className="payment-view payment-view-discount">
          <div className="title mb-3">{t("Balance Due")}: <CurrencyFormat value={reservation.balance} /></div>

          <form autoComplete="off" onSubmit={this.handleDiscount} >
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentAmount">{t("Amount to Discount")}</label>
                <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={discountAmount} onValueChange={(values) => this.setState({discountAmount:values.value}, this.setupLineItems)} autoFocus={true} />
              </div>
            </div>
            <div>
              <Button type="secondary" disabled={!discountAmount} size="lg" loading={isLoading}>{t("Submit")}</Button>
            </div>
          </form>
          <div className="text-end">
            <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
          </div>
        </div>

      case 'donation':
        return <div className="payment-view payment-view-donation">

          <form autoComplete="off" onSubmit={this.handleDonation} >
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentAmount">{t("Amount")}</label>
                <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={donationAmount} onValueChange={(values) => this.setState({donationAmount:values.value}, this.setupLineItems)} autoFocus={true} />
              </div>
            </div>
            <div>
              <Button type="secondary"  disabled={!donationAmount} size="lg" loading={isLoading}>{t("Submit")}</Button>
            </div>
          </form>
          <div className="text-end">
            <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
          </div>
        </div>

      case 'tip':
        return <div className="payment-view payment-view-donation">

          <form autoComplete="off" onSubmit={this.handleTip} >
            <div class="form-row">
              <div className="form-group d-flex align-items-center">
                <div class="mie-3">
                  <label htmlFor="paymentAmount">{t("Amount")}</label>
                  <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={tipAmount} onValueChange={(values) => this.setState({tipAmount:values.value}, this.setupLineItems)} autoFocus={true} />
                </div>
                <UncontrolledDropdown>
                  <DropdownToggle caret>{selectedTipItem?t(selectedTipItem):t('Select')}</DropdownToggle>
                  {tipItems?
                    <DropdownMenu>
                      {tipItems.map((s,i)=>
                        <DropdownItem key={i} onClick={()=>this.setState({selectedTipItem: s})}>{t(s)}</DropdownItem>
                      )}
                    </DropdownMenu>
                  :null}              
                </UncontrolledDropdown>
              </div>
            </div>
            <Button type="secondary" disabled={!tipAmount} size="lg" loading={isLoading}>{t("Submit")}</Button>
          </form>
          <div className="text-end">
            <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
          </div>
        </div>

      case 'fee':
        return <div className="payment-view payment-view-donation">

          <form autoComplete="off" onSubmit={this.handleFee} >
            <div class="form-row">
              <div className="form-group d-flex align-items-center">
                <div class="mie-3">
                  <label htmlFor="paymentAmount">{t("Amount")}</label>
                  <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={feeAmount} onValueChange={(values) => this.setState({feeAmount:values.value}, this.setupLineItems)} autoFocus={true} />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>{t("Description")}</label>
                <input className="form-control" value={feeDescription} onChange={(e)=>this.setState({feeDescription: e.target.value})} />
              </div>
            </div>
            <Button type="secondary" disabled={!feeAmount} size="lg" loading={isLoading}>{t("Submit")}</Button>
          </form>
          <div className="text-end">
            <Button type="link btn-cancel" onClick={() => this.setState({paymentView: reservation.paid?'paid':null})}>{t("Cancel")}</Button>
          </div>
        </div>

      case 'credit':
        return <div className="payment-view payment-view-credit">
          <div className="title mb-3">{t("Balance Due")}: <CurrencyFormat value={reservation.balance} /></div>

          {creditMode===null||creditMode!=='terminal'?
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <div className="form-group col">
                <label htmlFor="paymentAmount">{t("Amount to Charge")}:</label>
                <CurrencyFormat type="text" className="form-control form-control-lg" id="paymentAmount" name="paymentAmount" value={paymentAmount} onValueChange={(values) => this.handleChangeAmount(values)} autoFocus={true} />
              </div>
            </div>
          </form>
          :null}

          {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe') && (creditMode===null||creditMode==='terminal')&&terminalId!==null?
            <StripeProvider>
              <StripeTerminal 
                reservation={reservation}
                paymentAmount={paymentAmount}
                isLoading={isLoading}
                lineItems={lineItems}
                readerId={terminalId}
                onReadyForCapture={(paymentIntent) => this.handleCapturePaymentIntent(paymentIntent)}
                handleUpdateCreditMode={(mode) => this.setState({creditMode: mode, error: null})}
                handleErrorMessage={(error) => this.setState({error, creditMode: null})}
                discoverError={(error) => this.setState({error, creditMode: 'saved'})}
              />
            </StripeProvider>
          :null}

          {(settings.ccProcessorType && settings.ccProcessorType.value==='icount') && (creditMode===null||creditMode==='terminal')&&selCardReader!==null?
            <Button type="secondary" size="sm" className="mb-2" loading={isLoading} onClick={this.handleChargeTerminalICount}>{t("Use Card Reader")}</Button>
          :null}
          
          {creditMode===null||creditMode==='saved'?
            <>
              <div className="saved-cards">
                <div className="header">
                  <div className="title">
                  {t("Saved Cards")}:
                  </div>
                  
                  {!isLoading?
                    <button className="btn btn-link" onClick={() => this.setState({creditMode: 'new'})}>+ {t("Add New Card")}</button>
                  :null}
                </div>
                
                {isLoading?
                  <ReactSVG className="loader-spinner dark mb-3" src={LoaderSpinner} />
                :
                  (isLoadingSavedCards?
                    <ReactSVG className="loader-spinner dark mb-3" src={LoaderSpinner} />
                  :
                    (savedCards?
                      <div className="saved-cards-buttons">
                          {(settings.ccProcessorType && settings.ccProcessorType.value==='icount')?
                            savedCards.map((card,i) => <div className="mb-3 d-flex justify-content-center" key={i}>
                            <div className="d-flex align-items-center btn-saved-card col-4 justify-content-end">
                              {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon icon={cardBrands[card.brand.toUpperCase()].icon} />:card.brand.toUpperCase()}
                              <div>•••• <span className="last4">{card.last4}</span></div>
                            </div>
                            <InputGroup className="col-5">
                              <Input maxLength="6" placeholder="CVV" name={"CVV"+card.last4} onChange={this.handleChange} value={this.state["CVV"+card.last4]} />
                              <InputGroupAddon addonType="append">
                                <Button type="secondary" disabled={!paymentAmount || !this.state["CVV"+card.last4] || (this.state["CVV"+card.last4] && this.state["CVV"+card.last4].length<3)} onClick={() => this.handleChargeSavedCard(card)} loading={isLoading}>
                                  <div className="amount">{t("Charge")} <CurrencyFormat value={paymentAmount} /></div>
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                            </div>
                        ):null}
                        {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?
                        savedCards.map((card,i) => <div className="mb-3" key={i}><Button type="secondary btn-saved-card" size="lg" disabled={!paymentAmount} onClick={() => this.handleChargeSavedCard(card)} loading={isLoading}>
                          {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon icon={cardBrands[card.brand.toUpperCase()].icon} />:card.brand.toUpperCase()}
                          <div>•••• <span className="last4">{card.last4}</span></div>
                          <div className="amount">{t("Charge")} <CurrencyFormat value={paymentAmount} /></div>
                        </Button></div>)
                        :null}
                      </div>
                    :
                      <div className="text-center text-muted">{t("No saved cards")}</div>
                    )
                  )
                }
              </div>
            </>
          :null}
          
          {creditMode==='new'?
            <div className="new-card">
              {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?
              <StripeProvider>
                <StripeCardForm
                  onGetToken={(payload) => this.handleChargeNewCard(payload)}
                  isLoading={isLoading}
                  handleIsLoading={(isLoading) => this.setState({isLoading: isLoading})}
                  paymentAmount={paymentAmount}
                  handleUpdateCreditMode={(mode) => this.setState({creditMode: mode})}
                />
              </StripeProvider>
              :null}
              {(settings.ccProcessorType && settings.ccProcessorType.value==='icount')?
                <ICountCardForm
                  onSubmit={(paymentInfo) => this.handleChargeNewCardICount(paymentInfo)}
                  isLoading={isLoading}
                  handleIsLoading={(isLoading) => this.setState({isLoading: isLoading})}
                />
              :null}
              <div className="text-end">
                <Button type="link btn-cancel" onClick={() => this.setState({creditMode: null})}>{t("Cancel")}</Button>
              </div>
            </div>
          :null}

          {creditMode===null?
            <div className="text-end">
              <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
            </div>
          :null}

        </div>

      case 'merchandise':
      return <div>
        <h4 className="section-title">{t("Add Products")}</h4>
        <form autoComplete="off" onSubmit={this.handleAddProducts} >
          <div className="form-group">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("Item")}</th>
                    <th>{t("Price")}</th>
                    <th>{t("Amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {merch.map((item, i) => {
                    return <tr key={i}>
                      <td>{item.item}</td>
                      <td><CurrencyFormat value={item.price} /></td>
                      <td style={{width: '25%'}}><input step="1" min="0" max="10" type="number" className={"form-control"} value={this.state['Product'+item.item]?this.state['Product'+item.item]:0} onChange={(e) => this.handleChangeMerchandise(e,'Product'+item.item)} /></td>
                    </tr>
                  })}
                </tbody>
              </table>
          </div>
          <div>
            <Button className="btn-secondary btn-block" large loading={isLoading}>{t("Submit")}</Button>
          </div>
        </form>
                
        <div className="text-end">
          <Button className="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
        </div>       
      </div>

      case 'balance':
      default:
        return <div>
          <div className="balance-due mb-3">
            <ReactSVG src={moneyOwed} className="mb-3 icon" alt="Money Icon"/>
            <div className="title">{t("Balance Due")}:</div>
            <div className="money-amount"><CurrencyFormat value={reservation.balance} /></div>
          </div>
          
          {reservation.merchandise && reservation.merchandise.length?
            <div className="payments">
              <h4 className="title text-start mb-1">{t("Merchandise")}</h4>
              <table className="table text-start">
                <thead>
                  <tr>
                    <th>{t("Item")}</th>
                    <th>{t("QTY")}</th>
                    <th className="text-end">{t("Price")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reservation.merchandise.map((m,i) => {
                    return <tr key={i}>
                      <td>{t(m.item)}</td>
                      <td>{m.quantity}</td>
                      <td className="text-end"><CurrencyFormat value={m.total?m.total:parseFloat(m.quantity) * parseFloat(m.price)} /></td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          :null}
          {reservation.payments.length?
            <div className="payments">
              <h4 className="title text-start mb-1">{t("Payments")}</h4>
              <table className="table text-start">
                <thead>
                  <tr>
                    <th>{t("Time")}</th>
                    <th>{t("Method")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Details")}</th>
                    <th className="text-end">{t("Amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reservation.payments.map((payment,i) => {
                    return <tr key={i}>
                      <td>
                        <Moment format={t("MM/DD")}>{payment.dateCreated}</Moment>
                        <HebrewDate className="mis-1" date={payment.dateCreated} day={true} month={true}/>
                      </td>
                      <td>{t(payment.method)} {payment.method==='Fee' && payment.note?' - ' + payment.note:null}{payment.method==='Tip'?(payment.tipNote?' - ' + t(payment.tipNote):' - ' + t('General')):null}</td>
                      <td>{t(payment.status)} {payment.status==='Approved'?<FontAwesomeIcon icon="check" />:null}</td>
                      <td>{t(payment.reference)}</td>
                      <td className="text-end"><CurrencyFormat value={payment.amount} /></td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          :null}

            {(settings.priceBathDiscount && settings.priceBathDiscount.value) || (settings.priceShowerDiscount && settings.priceShowerDiscount.value)?
            <div className="form-check">
              <input type="checkbox" className="form-check-input" checked={useDiscountPrice} onChange={this.toggleDiscount} id="discountPricing" name="discountPricing" /> 
              <label htmlFor="discountPricing" className="form-check-label input-wrap pie-4">{t("Request Discount Price")}</label>
            </div>
            :null}

          <div className="mb-3">
            {/* <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'donation'})}>+ {t("Add Donation / Shabbos Payment")}</button> */}
            <button className="btn btn-link mx-1 col-12" onClick={() => this.setState({paymentView: 'merchandise'})}>+ {t("Add Merchandise")}</button>
            <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'tip'})}>+ {t("Add Tip")}</button>
            <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'fee'})}>+ {t("Add Fee")}</button>
            <button className="btn btn-link mx-1" onClick={() => this.setState({paymentView: 'discount'})}>+ {t("Add Discount")}</button>
          </div>

          <div className="d-flex justify-content-center flex-wrap">
            {this.props.isAdvancedReservation && ['Pending', 'Reserved', 'Waiting'].includes(reservation.reservationStatus)?
              settings.reservationsRequirePrepayAll.value === 'False' && (settings.reservationsRequirePrepayParking.value === 'False' || (settings.reservationsRequirePrepayParking.value === 'True' && !reservation.parking))  ?<div className="col-6"><Button type="secondary mb-3" size="lg" onClick={this.handlePayLater}>{t("Pay Later")}</Button></div>:null
             :
             null}
            <div className="col-6"><Button type="secondary mb-3" size="lg" onClick={() => this.setState({paymentView: 'cash'})}>{t("Cash/Check")}</Button></div>
            <div className="col-6"><Button type="secondary" size="lg" onClick={() => this.setState({paymentView: 'credit'})}>{t("Credit/Debit")}</Button></div>
          </div>



        </div>
    }
  }

  render() {
    const { t, reservation } = this.props;
    const { error, roomTypeDisplay, confirmed, updated } = this.state;
    const { settings } = this.context;
    if (this.state.renderRedirect === true) {
      return <Redirect to='/receptionist/' />
    }

    let reservationMinutesTotal;

    if (settings!==null&&reservation.reservationId) {
      let reservationMinutes = parseFloat(settings['reservationMinutes'+(reservation.bath?'Bath':'Shower')].value);
      let reservationMinutesDoubleSlot = parseFloat(settings.reservationMinutesDoubleSlot.value);
      reservationMinutesTotal = reservationMinutes + (reservation.doubleSlot ? reservationMinutesDoubleSlot : 0);
    }


    return (
      reservation.reservationId?
        <>
          <BodyHeader goBack={this.props.goBack} isAdvancedReservation={this.props.isAdvancedReservation} isEditingReservation={this.props.isEditingReservation} />
          <div className="modal-body">
            <div className="modal-body-inner">
              <div className="checkin-reservation">
                <div className="title">{t("Reservation")}</div>
                <div className="reservation-number">{reservation.reservationCode}</div>

                <ul className="reservation-summary">
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconCalendar} />
                    </div>
                    <Moment format={t("dddd M/D")}>{reservation.reservationDate}</Moment>
                    <HebrewDate className="mis-1" date={reservation.reservationDate} day={true} month={true}/>
                  </li>
                  {settings?
                    <>
                      <li>
                        <div className="icon">
                          <ReactSVG src={iconClock} />
                        </div>
                        <Moment format={t("h:mma")}>{reservation.reservationTime}</Moment>-
                        <Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}}>{reservation.reservationTime}</Moment>
                      </li>
                      {roomTypeDisplay?
                        <li>
                          <div className="icon">
                            <ReactSVG src={roomTypeDisplay.icon} />
                          </div>
                          <span>{t("Room Type")}: <span className="text-capitalize">{t(roomTypeDisplay.name)}</span></span>
                          <div className="price"><CurrencyFormat value={roomTypeDisplay.price} /></div>
                        </li>
                      :null}
                      {reservation.parking?
                      <li>
                        <div className="icon">
                          <ReactSVG src={iconParking} />
                        </div>
                        {reservation.parking?t("Parking"):t("Walking")}
                        <div className="price"><CurrencyFormat value={settings.priceParking.value} /></div>
                      </li>
                      :null}
                      {reservation.salonBoth?
                        <li>
                          <div className="icon">
                            <ReactSVG src={iconSalon} />
                          </div>
                          {t("Mani+Pedi")}
                          <div className="price"><CurrencyFormat value={settings.priceSalonBoth.value} /></div>
                        </li>
                      :(reservation.salonManicure?
                        <li>
                          <div className="icon">
                            <ReactSVG src={iconSalon} />
                          </div>
                          {t("Manicure")}
                          <div className="price"><CurrencyFormat value={settings.priceSalonManicure.value} /></div>
                        </li>                  
                      :null)}
                      {reservation.doubleSlot?
                      <li>
                        <div className="icon">
                          <ReactSVG src={iconClockExtra} />
                        </div>
                        {t("Extra Time")}
                        <div className="price"><CurrencyFormat value={settings.priceDoubleSlot.value} /></div>
                      </li>
                      :null}
                    </>
                  :null}
                  {reservation.notes?
                    <li className="flex-wrap">
                      {t("Reservation Note")}:
                      <div className="text-start w-100">{reservation.notes}</div>
                    </li>
                  :null}
                  {reservation.user.notes?
                    <li className="flex-wrap">
                      {t("User Note")}:
                      <div className="text-start w-100">{reservation.user.notes}</div>
                    </li>
                  :null}
                </ul>

                
                {this.state.isEditingReservationNote?
                  <div className="text-start">
                    <div className="form-row">
                      <div className="form-group">
                        <label>{t("Note")}</label>
                        <textarea className="form-control" rows="3" name="reservationNote" value={this.state.reservationNote} onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Button className="btn btn-link mie-auto" onClick={()=>{this.modalToggleUserNote(); this.setState({isEditingReservationNote: false, userNote: this.state.userNote===""?this.state.reservationNote:this.state.userNote})}}>{t("Save for future")}</Button>
                    </div>
                    <div className="d-flex">
                      <Button className="btn btn-outline-secondary mis-auto mie-1" onClick={() => this.setState({isEditingReservationNote: false})}>{t("Cancel")}</Button>
                      <Button className="btn btn-secondary" onClick={()=>this.handleSaveNote('reservation')}>{t("Save")}</Button>
                    </div>
                    <hr />
                  </div>
                :
                  <div>
                    <Button className="btn btn-link" onClick={this.handleEditNote}>{reservation.notes?t('Edit'):t('Add')} {t("Note")}</Button>
                  </div>
                }
                {['Pending', 'Reserved', 'Waiting', 'Assigned'].includes(reservation.reservationStatus)?
                  <button className="btn btn-link" onClick={this.handleEditReservation}>{t("Edit Reservation")}</button>
                :null}
              
              </div>
              <div className="checkin-payment">
                {reservation.room && !reservation.room.bath && reservation.bath?<Alert color="danger">{t("This is a Bath appointment assigned to a Shower room. You can put this user in another room by selecting this reservation on the map and clicking 'Place User in Room'.")}</Alert>:null}
                {error?<Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>:null}
                {confirmed?<Alert color="success">{t("reservationCreated")}</Alert>:null}
                {updated?<Alert color="success">{t("reservationUpdated")}</Alert>:null}
                {this.paymentView()}
              </div>
            </div>
          </div>

          <Modal isOpen={this.state.modalUserNote} toggle={this.modalToggleUserNote} centered={true} className={"lang-" + i18n.language} modalClassName="modal-user-note">
            <div className="modal-header">
            {t("User Note")}
              <button type="button" onClick={this.modalToggleUserNote}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <ModalBody>
              <div className="text-start">
                <div className="form-group">
                  <label>{t("Note")}</label>
                  <textarea className="form-control" rows="3" name="userNote" value={this.state.userNote} onChange={this.handleChange}/>
                </div>
                <div className="d-flex">
                  <Button className="btn btn-outline-secondary mis-auto mie-1" onClick={this.modalToggleUserNote}>{t("Cancel")}</Button>
                  <Button className="btn btn-secondary" onClick={()=>this.handleSaveNote('user')}>{t("Save")}</Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      : <Loader />
    );
  }
}

export default withTranslation()(CheckinPayment);