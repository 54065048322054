import React, { Component } from 'react';
import { Modal, ModalBody } from "reactstrap";
import i18n from './../../i18n';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';
import { Link, Redirect } from "react-router-dom";
import Button from './../../components/button';

import { getRooms, getRoom, setRoomLanguage } from './../../models/room';
import { getSection } from './../../models/section';
import SignalR from './../../components/signalr';

import Clock from './../../components/clock';
import ZmanTevilah from './../../components/zman-tevilah';
import ScreenPrep from './../../components/screen-prep';
import ScreenAssigned from './../../components/screen-assigned';
import ScreenReady from './../../components/screen-ready';
import ScreenReadyChecking from './../../components/screen-ready-checking';
import ScreenChecking from './../../components/screen-checking';
import ScreenToMikvah from './../../components/screen-to-mikvah';
import ScreenDressing from './../../components/screen-dressing';
import ScreenNeedsCleaning from './../../components/screen-needscleaning';
import ScreenCleaning from './../../components/screen-cleaning';
import RoomNumber from './../../components/snippets/room-number';
import ReservationNumber from './../../components/snippets/room-reservation';
import RoomStatus from './../../components/snippets/room-status';
import RoomAttendant from './../../components/snippets/room-attendant';
import RoomArrived from './../../components/snippets/room-arrived';
import RoomButtons from './../../components/room-buttons';
import globeIcon from "./../../assets/images/icon-globe.svg";
import iconClose from "./../../assets/images/icon-close.svg";
import Loader from './../../components/snippets/loader';
import ScreenSaver from '../../components/screensaver';
import RoomAdmin from './../../components/room-admin';
import StaffCode from './../../components/staffCode';
import SpecialMode from './../../components/special-mode';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import { SettingsContext } from './../../components/settings';
import LanguageSelector from './../../components/language-selector';
import moment from 'moment';

function Screen(props) {
  switch (props.room.status) {
    case 'Assigned':
      return <ScreenAssigned room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>;
    case 'Prep':
      return <ScreenPrep room={props.room} settings={props.settings} handleUpdateRoom={props.handleUpdateRoom} playSound={props.playSound}/>;
    case 'ReadyForChecking':
    case 'ReadyForCheckingNext':
      return <ScreenReadyChecking room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'Checking':
      return <ScreenChecking room={props.room} section={props.section} isLoadedSection={props.isLoadedSection} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'ReadyForMikvah':
    case 'ReadyForMikvahNext':
      return <ScreenReady room={props.room} section={props.section} isLoadedSection={props.isLoadedSection} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'ToMikvah':
      return <ScreenToMikvah room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>;  
    case 'Dressing':
      return <ScreenDressing room={props.room} settings={props.settings} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'NeedsCleaning':
      return <ScreenNeedsCleaning room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'Cleaning':
      return <ScreenCleaning room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>
    case 'Offline':
    case 'Ready':
      return props.settings && props.settings.roomsUseStaffEntry.value=="True"?
      <StaffCode emptyRoom={true} room={props.room} handleUpdateRoom={props.handleUpdateRoom}/>
      :null
    default:
      return null
  }
}

class RoomView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isLoadedSection: false,
      connection: null,
      currentLanguage: i18n.language,
      modal: false,
      modalLangClass: ""
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.handleUpdateRoom = this.handleUpdateRoom.bind(this);
    this.handleYouAreNext = this.handleYouAreNext.bind(this);
    this.handleMusic = this.handleMusic.bind(this);
    this.notificationSound = new Audio(NotificationSoundFile);
    this.savedReservation = {parking: false}
  }

  componentDidMount() {
    if ( this.props.id ) {
      this.retrieveRoom();
      this.getLanguage();
    } else {
      let savedSelectedRoom = localStorage.getItem('selectedRoom');
      if ( savedSelectedRoom ) {
        this.setState({savedSelectedRoom});
        return;
      } else {
        this.retrieveRooms();
      }
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.id!==this.props.id) {
      this.retrieveRoom();
      this.setState({error: null})
    }
  }
  
  componentWillUnmount() {
    if ( this.props.id ) {
      if(this.roomMusic){
        this.roomMusic.pause();
      }
    }
  }

  getLanguage = (showModal) => {
    let language = localStorage.getItem('selectedLanguage');
    if(language) {
      i18n.changeLanguage(language);
      moment.locale(language);
      this.setState({currentLanguage: language})
    } else {
      if (showModal) {
        this.setState({modal: true});
      }
    }
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.setState({
            isLoadedRooms: true,
            rooms: rooms,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: true,
            error
          });
        }
      );
  }

  
  retrieveRoom() {
    getRoom(this.props.id)
      .then(
        result => {
          let room = result;
          if ( room.effectiveStatus==="Assigned" ) {
            if (room.reservation && room.reservation.user && room.reservation.user.language) {
              this.changeLanguage(room.reservation.user.language);
            } else{
              this.getLanguage(true);
            }
          }
          if ( ["NeedsCleaning", "Ready", "Offline"].includes(room.effectiveStatus) ) {
            this.clearRoomPreferences();
          }
          // if signalr for room update and in status that requires knowing about section then get section update
          if(["Checking", "ReadyForMikvah", "ReadyForMikvahNext"].includes(room.effectiveStatus) || (room.reservation && room.reservation.attendantInRoom && room.directToBor)){
            getSection(room.section.id)
              .then(
                result => {
                  let section = result;
                  this.setState({section, isLoadedSection: true})
                }
              )
          }
          if(!["Assigned", "Done", "NeedsCleaning", "Cleaning", "Ready", "Offline"].includes(room.effectiveStatus)){
            if(!room.dryDoorLocked && !room.privateRoom){
              this.setState({playLockDoor: true})
            } else{
              this.setState({playLockDoor: false})
            }
          }
          this.setState({
            isLoaded: true,
            room: room,
          });
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      );
  }

  handleUpdateRoom(room) {
    this.setState({room});
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({
      currentLanguage: i18n.language,
      modalLangClass: "slide-out-bck-br scale-down-br"
    })
    setRoomLanguage(this.props.id,lng)
      .then(
        result => { 
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      )

    localStorage.setItem('selectedLanguage', lng);
    setTimeout(() => this.setState({modal: false, modalLangClass: ''}), 400);
  }

  clearRoomPreferences = () => {
    localStorage.removeItem('selectedLanguage');
    localStorage.removeItem('timerEndTime');
    localStorage.removeItem('timerTimeLeft');
    // Put room back into english for cleaners
    i18n.changeLanguage('en');
    this.setState({currentLanguage: null});
    if(this.roomMusic){ this.roomMusic.pause();}
  }

  handleRoomSelected = (roomId) => {
    localStorage.setItem('selectedRoom', roomId);
  }

  handleYouAreNext(next) {
    if(this.roomMusic){
      this.roomMusic.pause();
    }
    setTimeout(()=>this.notificationSound.play(),500);
    if(this.roomMusic && !next){
      setTimeout(()=>this.roomMusic.play(),1500);
    }
  }

  handleMusic(link,volume,updateVolume) {
    if(!updateVolume){
      if(this.roomMusic){
        if(link){
          this.roomMusic.src = link;
          this.roomMusic.play();
        } else {
          this.roomMusic.pause();
        }
      } else{
        this.roomMusic = new Audio(link);
        this.roomMusic.volume = volume?volume*.01:0;
        this.roomMusic.play();
      }
    } else if(this.roomMusic && Number.isInteger(volume)){
      this.roomMusic.volume = volume*.01;
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { error, isLoaded, isLoadedSection, room, section, playLockDoor, currentLanguage, isLoadedRooms, rooms } = this.state;
    if ( this.state.savedSelectedRoom ) {
      return <Redirect to={`/room/${this.state.savedSelectedRoom}`} />;
    }
    if ( !this.props.id || this.props.id==='0' ) {
      return <div className={'container-page lang-' + i18n.language}>
        <header className="header">
          <div>
            <div className="header-left"></div>
            <div className="header-center">
              <Clock />
            </div>
            <div className="header-right"></div>
          </div>
        </header>
        <div className="mx-auto text-center" style={{width:"1000px"}}>
          <div className="container-body text-center">
            <h3 className="text-uppercase">{t("Please choose a room")}:</h3>
            <div className="d-flex flex-wrap justify-content-center">
              {isLoadedRooms && rooms.map((room,i) => {
                return <Link to={"/room/" + room.id} className="m-2" key={i} onClick={()=>this.handleRoomSelected(room.id)}><Button type="circle" text={room.id} /></Link>;
              })}
            </div>
          </div>
        </div>
      </div>;
    } else {
      if (error) {
        return <div className={'container-page vh-100 d-flex justify-content-center lang-' + i18n.language}>
          <SignalR setRoom={this.props.id} />
          <div className="w-50 mx-auto text-center">
            <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
          </div>
        </div>;
      } else if (!isLoaded) {
        return <div className={'container-page vh-100 d-flex justify-content-center lang-' + i18n.language}>
          <SignalR setRoom={this.props.id} />
          <div className="w-50 mx-auto text-center">
            <Loader />
          </div>
        </div>;
      } else {
        return (
          <SettingsContext.Consumer>
            {({settings}) => 
              <div className={'container-page view-room status-' + room.effectiveStatus.toLowerCase() + ' lang-' + i18n.language}>
                <SignalR setRoom={this.props.id} onRoomUpdated={() => this.retrieveRoom()} onYouAreNext={() => this.handleYouAreNext(true)} />
                <RoomAdmin room={room} handleUpdateRoom={this.handleUpdateRoom} />
                {settings!==null&&
                ['Friday', 'ErevYomTov'].includes(settings.scheduleType.value)&&room.reservation===null&&room.roomStatus==="Ready"?(
                  <SpecialMode room={room} type={settings.scheduleType.value} />
                ):(
                  <>
                    {["Ready","Offline","NeedsCleaning"].includes(room.status) && !room.staff?<ScreenSaver />:null}
                    <header className="header">
                      <div>
                        <div className="header-left">
                          <RoomNumber number={room.id} />
                          {room.reservation ?
                          <ReservationNumber number={room.reservation.reservationCode} />
                          : null}
                        </div>
                        <div className="header-center">
                          <Clock />
                        </div>
                        <div className="header-right">
                          <RoomStatus status={t(room.statusName)} color={room.statusColor} />
                        </div>
                      </div>
                      <div>
                        <div className="header-left">
                          {room.reservation ?
                          <RoomArrived arrived={room.reservation.timeArrived} />
                          : null}
                          {room.staff?
                            <div>{t("Staff")}: {room.staff.firstName} {room.staff.lastName}</div>
                          :null}
                        </div>
                        <div className="header-center header-zman">
                          <ZmanTevilah />
                        </div>
                        <div className="header-right">
                          <RoomAttendant attendant={room.section.attendant} />
                        </div>
                      </div>
                    </header>
                    <div className="container-body">
                      <LanguageSelector currentLanguage={currentLanguage} handleLanguageUpdated={this.changeLanguage}/>
                      <Modal
                        isOpen={this.state.modal}
                        size="full"
                        className={"modal-language" + this.state.modalLangClass}
                      >
                        <ModalBody>
                          <ReactSVG
                            className="icon"
                            src={globeIcon}
                            alt="Globe Icon"
                          />
                          <h4 className="modal-title">Select Language</h4>
                          <h4 className="modal-title">בחר שפה</h4>
                          <div className="btn-lang-container">
                            <Button className="btn btn-secondary btn-lang" onClick={() => this.changeLanguage('he')} >עברית</Button>
                            <Button className="btn btn-secondary btn-lang" onClick={() => this.changeLanguage('en')}>English</Button>
                          </div>
                        </ModalBody>
                      </Modal>

                      <Modal
                        isOpen={playLockDoor}
                        size="full"
                        className={"modal-language lang-" + i18n.language}
                      >
                    <div className="modal-header">
                      <Button
                        type="button close"
                        onClick={() => this.setState({ playLockDoor: false })}
                        aria-label="Close"
                      >
                        <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
                      </Button>
                    </div>
                    <ModalBody>
                      <FontAwesomeIcon className="icon" icon="lock-open" />
                      <h4 className="modal-title">{t("Please lock your door")}</h4>
                      </ModalBody>
                    </Modal>

                      <RoomButtons room={room} settings={settings} handleUpdateRoom={this.handleUpdateRoom} handleMusic={this.handleMusic} />
                      <Screen room={room} section={section} isLoadedSection={isLoadedSection} settings={settings} handleUpdateRoom={this.handleUpdateRoom} changeLanguage={this.changeLanguage} playSound={this.handleYouAreNext} />
                    </div>
                  </>
                )}
              </div>
            }
          </SettingsContext.Consumer>
        );
      }

    }

  }
}

export default withTranslation()(RoomView);
