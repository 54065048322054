import React from 'react';
import ReactSVG from 'react-svg';
import moment from 'moment';
import { Modal, ModalBody } from "reactstrap";
import iconClock from "../assets/images/icon-clock.svg";
import iconTimer from "../assets/images/icon-timer.svg";
import { withTranslation } from 'react-i18next';
import Button from './button';
import iconClose from "../assets/images/icon-close.svg";
import Clock from './clock';
import Timer from "./timer";


class ScreenSaverClock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format(),
      show: false,
    };
    
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem('modalClockOpen') === 'true' && localStorage.getItem('timerModalOpen') !== 'true'){
      this.setState({show: true});
    } else {
      this.hide();
    }
    document.addEventListener('touchstart', this.hide);
    document.addEventListener('mousemove', this.hide);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearTimeout(this.timeOut);
    document.removeEventListener('touchstart', this.hide);
    document.removeEventListener('mousemove', this.hide);
  }

  hide() {
    if(localStorage.getItem('modalClockOpen') === 'true'){
      localStorage.setItem('modalClockOpen', false);
    }
    this.setState({show: false});
    clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        if(localStorage.getItem('timerModalOpen') !== 'true'){
            localStorage.setItem('modalClockOpen', true);
            this.setState({show: true})
        }
      }, 40000);
  }

  render() {
    const { show } = this.state;
    const { text, t } = this.props;
    return (
          <Modal
            isOpen={show}
            className='modal-bigclock'
            size="full"
          >
            <div className="modal-header">
            <div className="icon">
              <ReactSVG src={iconClock} alt="Clock Icon" />
            </div>
            <div>
              <h4 className="modal-title">{t("Clock")}</h4>
            </div>
            <Button
             type="button close"
              aria-label="Close"
            >
              <span aria-hidden="true"><img src={iconClose} alt="Close"/></span>
            </Button>
              </div>
              <ModalBody >
                <Clock />
                {this.props.isTimerActive?
                <div className="timer-clock">
                  <ReactSVG className="icon" src={iconTimer} alt="Clock Icon" />              
                  <Timer timerButton={true} updateIsTimerPaused={this.props.updateIsTimerPaused} isTimerPaused={this.props.isTimerPaused} status={this.props.room.status} toggleTimerActive={this.props.toggleTimerActive} room={this.props.room} />
                </div>
                :null}
              </ModalBody>
          </Modal>)
  }
}

export default withTranslation()(ScreenSaverClock)