import React from 'react';
import { withTranslation } from 'react-i18next';

import { Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import RoomTile from '../../components/RoomTile';
import RoomDetails from './partials/room-details';
import RoomSummary from './room-summary';
import Emergency from '../../components/emergency';
import Loader from './../../components/snippets/loader';
import CubicleDetails from './partials/cubicle-details';

class RoomTiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedRoom: null,
      rooms: []
    };
    this.modalToggle = this.modalToggle.bind(this);
    this.modalToggleCubicle = this.modalToggleCubicle.bind(this);
    this.handleRoomClick = this.handleRoomClick.bind(this);
  }

  modalToggle() {
    this.setState({
      selectedRoom: null
    })
  }

  handleRoomClick(i) {
    this.setState({
      selectedRoom: i,
    })
  }

  modalToggleCubicle() {
    this.setState({
      selectedCubicle: null
    })
  }

  handleCubicleClick(i) {
    this.setState({
      selectedCubicle: i,
    })
  }

  roomView(section,i) {
    const { t, i18n, rooms, sections, updatedRooms } = this.props;
    let inMikvahIndex = rooms.findIndex(rm => rm.status === "InMikvah" && rm.section.id === section.id);
    inMikvahIndex = inMikvahIndex===-1?null:inMikvahIndex;
    return(
    <React.Fragment key={i}>
    {rooms.map((room, i) => {
      return room.section.id===section.id?
        <RoomTile key={i} i18n={i18n} t={this.props.t} room={room} updated={updatedRooms?updatedRooms.includes(room.id):null} time={this.props.time} settings={this.props.settings} onClick={() => this.handleRoomClick(i)} modalToggle={this.modalToggle} />
        :null
    })}
    {/* {[4,3,2,1].map((a,i) => {
      let busy = sections && sections.filter(sec => sec.id == a)[0].wetHallwayBusy;
      return a==section.id?
        <div key={i} className={"room-tile hallway-" + a + (busy ?" busyHallway":"")}></div>
        :null
    })} */}
     <div key={section.id} className={'room-tile room-tile-mikvah-' + section.id + ' room-tile-mikvah' + (section.attendant!=null && section.borStatus!="Offline" ? ' room-online' : ' no-attendant') + (inMikvahIndex ? ' clickable' : '')} onClick={inMikvahIndex?()=>this.handleRoomClick(inMikvahIndex):null}>
      <div className="body">
        <div className="container-status">
          {inMikvahIndex!==null ? <div className="status">{t("Room")} {rooms[inMikvahIndex].id}</div> : null}
          {section.borStatus!="Online"? <div className="status offline">{t("Offline")}</div> :null}
        </div>
        <div className="room-name">
          <div className="title">{t("Mikvah")}</div>
          <div className="letter">{section.name}</div>
        </div>
        <div className="attendant">
          { section.attendant!=null ?
            <div>
              <div>{section.attendant.firstName + " " + section.attendant.lastName}</div>
            </div>
          : t("No Attendant")}
          { section.helper!=null ?
            <div>
              <div>{section.helper.firstName + " " + section.helper.lastName}</div>
            </div>
          : null}
        </div>
      </div>
    </div>
    </React.Fragment>)
  }

  render() {
    const { selectedRoom, selectedCubicle } = this.state;
    const { t, isLoadedRooms, isLoadedCubicles, isLoadedSections, isLoadedActiveReception, activeReception, cubicles, rooms, activeCubicleCount, sections, activeSection, groupedRooms, error  } = this.props;
    const waitingRoomCount = (this.props.reservations && this.props.reservations.waiting.filter(res => !res.room&&!res.cubicle).length)||[];

    return <div className={(activeSection?'active-container-map ':'')+"container-map dir-ltr"}>
      {error!==null ? 
        <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
      :
        isLoadedRooms&&rooms ?
        <>
          <div className="room-tiles">
          {this.props.receptionistView ?
          <>
          {isLoadedRooms ?
            <RoomSummary rooms={rooms} />
          : <Loader />} 
            <div className="map-top">
              {this.props.reservations!=null ?
                <div className="waiting-room">
                  <div className="title">{t("Waiting")}</div>
                  <div className="slots">
                    {[1,2,3,4,5,6,7,8,9].map((cubicle,i) => {
                      return <div key={i} className={"slot" + (i<waitingRoomCount?" active":"")}></div>
                    })}
                  </div>
                </div>
              :null}
            </div>
            <div className="receptionist">
              <div className="title">{t("Receptionist")}</div>
              <div className="selection">
                   {isLoadedActiveReception?
                    activeReception.length?
                      <>
                      <div>{t("Active Receptionist")}:</div>
                      <div>{activeReception.map(rec => rec.name).join(', ')}</div>
                      </>
                      :  
                      <div>{t("No Active Receptionist")}</div>
                   :null}
              </div>
            </div>
            </>
          :null}
          <div className={"mapSections"+(activeSection? " activeSec activeSec"+activeSection.length:"")}>
          {isLoadedSections ? sections.sort((a,b)=>parseInt(b.id)-parseInt(a.id)).map((section, i) => {
            let inMikvahIndex = rooms.findIndex(rm => rm.status === "InMikvah" && rm.section.id === section.id);
            inMikvahIndex = inMikvahIndex===-1?null:inMikvahIndex;
            return(
            <React.Fragment key={i}>
            {(activeSection && activeSection.includes(section.id)) ||!activeSection?
              <div className={"mapSection section-"+section.id}>
                {this.roomView(section,i)}
            </div>
            :null}
            </React.Fragment>
            )})
            : <Loader /> }
            </div>
          </div>
        </>
        : <Loader /> }

        {isLoadedRooms&&rooms ?
          <RoomDetails modalToggle={this.modalToggle} room={rooms[selectedRoom]} groupedRooms={groupedRooms} handleRoomUpdated={(r) => this.props.handleRoomUpdated(r)} />
        :null}

        {isLoadedCubicles&&cubicles ?
          <CubicleDetails modalToggle={this.modalToggleCubicle} cubicle={cubicles[selectedCubicle]} handleCubiclesUpdated={(r) => this.props.handleCubiclesUpdated(r)} />
        :null}

        {isLoadedRooms&&rooms?
          <Emergency rooms={rooms} handleRoomUpdated={(r) => this.props.handleRoomUpdated(r)}/>
        :null}


    </div>
    
  }
}

export default withTranslation()(RoomTiles);

