import React from 'react';
import Button from '../../../components/button';
import { withTranslation } from 'react-i18next';

class RoomChecklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checklist: [
        {
          title: 'Remember to…',
          items: [
            'Wait at least five days from the onset of your period, at the end of which a proper “Hefsek Taharah” examination was completed – before sunset?',
            'Make the requisite examinations during the ‘Seven Clean Days’?',
            'Take a thorough bath and wash all areas and all hair?',
            'Comb all hair very well while wet to remove all knots?',
            'Shower and comb again if a bath was taken at home?',
            'If your night of immersion is on Motzei Shabbos, preparations should preferably be done before Shabbos.  You must clean your teeth, re-shower with warm water, soap and shampoo and re-comb hair at the Mikvah',
          ],
          selections: [],
        },{
          title: 'Remove…',
          items: [
            'Contact lenses',
            'Make-up (lipstick, face and eye make-up including mascara and false eyelashes)',
            'Nail polish',
            'Scabs that come off easily',
            'All false teeth',
            'Splinters',
            'Any jewelry, foreign object',
            'Dirt, oily/sticky residue (what does not come off with soap and water may be ignored)',
          ],
          selections: [],
        },{
          title: 'Clean…',
          items: [
            'Eyes',
            'Nose',
            'Ears and earring holes',
            'Teeth: brush, floss, remove all particles between teeth and rinse mouth',
            'Between fingers and toes, body surfaces and folds',
            'Navel',
            'Around all nails',
          ],
          selections: [],
        },{
          title: 'Don’t Rush…',
          items: [
            'Take as much time as necessary. Do not preoccupy yourself with anything other than the special task at hand.',
            'Cut finger and toe nails to the tips. Check around nails for hanging skin. (Please be careful that nails do not fall on the floor. Pregnant women also use the Mikvah.)',
            'Use restroom if necessary',
            'Do a final visual inspection and convey any uncertainty to the Mikvah attendant. She is there to assist you throughout the process. She could also refer you to a helpful Rabbinic authority.',
          ],
          selections: [],
        }
      ]
    }
  }

  onCheckboxClick = (section, selected, e) => {
    if(e.target.tagName === 'LABEL'){
      e.preventDefault();
    }
    let checkboxes = this.state.checklist[section].selections;
    const index = checkboxes.indexOf(selected);
    if (index < 0) {
      checkboxes.push(selected);
    } else {
      checkboxes.splice(index, 1);
    }
    this.setState(state => {
      state.checklist[section].selections = checkboxes;
      return state;
    })
  }

  render() {
    const { checklist } = this.state;
    const { t } = this.props;

    return <div>
      <div className="checklist-content row">
        {checklist.map((section,is)=><div key={is} className="col">
          <h4>{t(section.title)}</h4>
          {section.items.map((item,i)=><div key={i}>
            <Button className="btn-checklist" onClick={(e)=>this.onCheckboxClick(is,i,e)}>
              <div className="form-check">
                <input type="checkbox" className="form-check-input" readOnly checked={checklist[is].selections.includes(i)} id={"customCheck" + is + i} /> 
                <label htmlFor={"customCheck" + is + i} className="form-check-label">{t(item)}</label>
              </div>
            </Button>
          </div>)}
        </div>)}
      </div>

      <div className="w-50 mx-auto text-center">
        <h5 className="mb-4">{t("If, after having immersed in the Mikvah, you realize that any of the above steps may have been overlooked, a Rav should be consulted as to whether a repeat immersion is required.")}</h5>
        <h5>{t("In the merit of performing this holy Mitzva properly, may Hashem bless you and your family with many years of good health and happiness.")}</h5>
      </div>
    </div>
  }
}

export default withTranslation()(RoomChecklist);


