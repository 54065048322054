import React, { Component } from 'react';

import i18n from './../../i18n';
import Clock from './../../components/clock';
import ZmanTevilah from '../../components/zman-tevilah';
import Flipper from '../../components/flipper';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import SignalR from './../../components/signalr';
import ScreenSaver from '../../components/screensaver';
import { getCubicles } from '../../models/room';
import { Redirect, Link } from 'react-router-dom';
import Button from '../../components/button';
import RoomNumber from '../../components/snippets/room-number';
import ReservationNumber from './../../components/snippets/room-reservation';
import RoomArrived from '../../components/snippets/room-arrived';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../components/language-selector';
import ReactSVG from 'react-svg';
import logo from "./../../assets/images/MikvahLogo.png";
import RoomStatus from '../../components/snippets/room-status';
import { Alert } from 'reactstrap';
import { SettingsContext } from '../../components/settings';
import CubicleAdmin from '../../components/cubicle-admin';
import { logoImageAltText } from '../../global-props';

class CubicleView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isLoadedReservations: false,
      isLoadedCubicles: false,
      reservations: null,
    };
    this.notificationSound = new Audio(NotificationSoundFile);
  }

  componentDidMount() {
    if ( this.props.id ) {
      this.retrieveCubicles();
    } else {
      let savedSelectedCubicle = localStorage.getItem('selectedCubicle');
      if ( savedSelectedCubicle ) {
        this.setState({savedSelectedCubicle});
        return;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.props.id ) {
      if (this.state.cubicle && prevState.cubicle) {
        let shouldPlaySound = false;
        if(this.state.cubicle.reservation != null && this.state.cubicle.reservation.room != null && prevState.cubicle.reservation != null && prevState.cubicle.reservation.room === null){
          shouldPlaySound = true;
          console.log(`New Room for Code ${this.state.cubicle.reservation.reservationCode}`);
        }
        if (shouldPlaySound) { this.notificationSound.play(); }
      }
    }
  }

  retrieveCubicles() {
    getCubicles()
      .then(
        result => {
          this.setState({
            cubicles: result,
            cubicle: result.find(cubicle=>cubicle.id===parseInt(this.props.id)),
            isLoadedCubicles: true,
          });
        },
        error => {
          this.setState({
            isLoadedCubicles: false,
            error
          });
        }
      );
  }

  handleRoomSelected = (cubicleId) => {
    localStorage.setItem('selectedCubicle', cubicleId);
  }


  render() {
    const { isLoadedCubicles, cubicle, error } = this.state;
    const { t } = this.props;
    const digits = cubicle&&cubicle.reservation?(''+cubicle.reservation.reservationCode).split(''):null;
    const {settings} = this.context;
    if ( this.state.savedSelectedCubicle ) {
      return <Redirect to={`/cubicle/${this.state.savedSelectedCubicle}`} />;
    }
    if ( !this.props.id || this.props.id==='0' ) {
      return <div className={'container-page lang-' + i18n.language}>
        <header className="header">
          <div>
            <div className="header-left"></div>
            <div className="header-center">
              <Clock />
            </div>
            <div className="header-right"></div>
          </div>
        </header>
        <div className="mx-auto text-center" style={{width:"1000px"}}>
          <div className="container-body text-center">
            <h3 className="text-uppercase">{t("Please choose a cubicle")}:</h3>
            <div className="d-flex flex-wrap justify-content-center">
              {Array.from('x'.repeat(10)).map((roomNumber,i) => {
                return <Link to={"/cubicle/" + (i+1)} className="m-2" key={i} onClick={()=>this.handleRoomSelected(i+1)}><Button type="circle" text={i+1} /></Link>;
              })}
            </div>
          </div>
        </div>
      </div>;
    } else {
      return (
        <div className={'container-page view-cubicle lang-' + i18n.language}>
          <SignalR setReception={true} onReservationsUpdated={() => this.retrieveCubicles()} />
          {settings!== null?
            <CubicleAdmin adminCorrectCode={settings.roomAdminPasscode.value}/>
          :null}
          {error?
            <div className="container-body">
              <Alert color="danger">{error} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>Try again</span></Alert>
            </div>
          :null}
          {isLoadedCubicles?
            <div className="container-main">
              <header className="header">
                <div>
                  <div className="header-left">
                    <RoomNumber title="Cubicle" number={cubicle.id} />
                    {cubicle.reservation ?
                      <ReservationNumber number={cubicle.reservation.reservationCode} />
                    : null}
                  </div>
                  <div className="header-center">
                    <Clock />
                  </div>
                  <div className="header-right">
                    <RoomStatus status={t(cubicle.statusName)} color={cubicle.statusColor} />
                  </div>
                </div>
                <div>
                  <div className="header-left">
                    {cubicle.reservation ?
                      <RoomArrived arrived={cubicle.reservation.timeArrived} />
                    : null}
                  </div>
                  <div className="header-center">
                    <Clock type="fullDate" />
                  </div>
                  <div className="header-right">
                    <ZmanTevilah />
                  </div>
                </div>
              </header>
              <div className="container-body">
                <LanguageSelector />
                <div className="mx-auto mb-3">
                  <img src={logo} className="logo" alt={logoImageAltText} />
                </div>
                {cubicle.reservation?
                  <div className="room-reservation dir-ltr">
                    <div className="reservation-code">
                      <div className="title">{t('Code')}</div>
                      <div className="flippers flippers-lg">
                        {digits.map((digit, i) => {
                          return <Flipper type="single" key={i} text={digit}/>
                        })}
                      </div>
                    </div>
                    <div className="room-number">
                      <div className="title">{t('Room')}</div>
                      <div className={"flippers flippers-lg room-flipper" + (cubicle.reservation.room!=null ? ' active' : '')}>
                        <Flipper type="double" text={cubicle.reservation.room!=null ? cubicle.reservation.room.id : null} active={cubicle.reservation.room!=null ? true : false}/>
                      </div>
                    </div>
                  </div>
                : <ScreenSaver />}
              </div>
            </div>
          :null}
        </div>
      );
    }
  }
}

export default withTranslation()(CubicleView);
