import React, { Component } from 'react';

import ReceptionistTabs from './partials/tabs';
import CheckinCode from "./partials/checkinCode";
import CheckinPayment from './partials/checkinPayment';
import CheckinRoomType from './partials/checkinRoomType';
import i18n from '../../i18n';

class ReceptionistCheckInView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      view: !props.reservation?"checkin-code":"checkin-payment",
      reservation: props.reservation,
      history: [!props.reservation?"checkin-code":"checkin-payment"]
    };
    this.handleScreenChange = this.handleScreenChange.bind(this);
    this.handleUpdateReservation = this.handleUpdateReservation.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props ) {
      this.handleUpdateReservation({...this.props.reservation}, 'checkin-code');
    }
  }

  handleScreenChange(view) {
    this.setState({
      view: view,
      history: [...this.state.history, view]
    })
  }

  goBack() {
    let newHistory = [...this.state.history];
    newHistory.pop()
    let prevView = newHistory[newHistory.length-1];
    if(prevView){
      this.setState({
        view: prevView,
        history: newHistory
      })
    } else {
      this.setState({
        view: 'checkin-code',
      })
    }
  }

  handleUpdateReservation(res, screen) {
    return new Promise((resolve, reject) => {
      this.setState({
        reservation: res
      }, () => {
        if ( screen ) {
          this.handleScreenChange(screen);
        }
        return resolve(true)
      })
    })
  }

  currentView() {
    switch (this.state.view) {
      case 'checkin-code':
        return <CheckinCode
          userId={this.props.userId}
          reservation={this.state.reservation}
          error={this.state.error}
          handleUpdateReservation={this.handleUpdateReservation}
          handleScreenChange={this.handleScreenChange}
          checkin={true}
          walkin={this.props.walkin}
        />;
      case 'checkin-roomtype':
        return <CheckinRoomType
          reservation={this.state.reservation}
          handleUpdateReservation={this.handleUpdateReservation}
          handleScreenChange={this.handleScreenChange}
          goBack={this.goBack}
        />;
      case 'checkin-payment':
        return <CheckinPayment
          reservation={this.state.reservation}
          view={this.state.view}
          handleUpdateReservation={this.handleUpdateReservation}
          handleScreenChange={this.handleScreenChange}
          goBack={this.goBack}
        />;
      default:
        return true;
    }
  }

  render() {
    const { view } = this.state;
    const currentPageName = "Check In";
    return (
      <div className={'container-page view-receptionist-checkin lang-' + i18n.language}>
        <div className="container-main">
          <div className="container-body reception-background">
            <ReceptionistTabs />
            <div className={"container-checkin " + view}>
              {this.currentView()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReceptionistCheckInView;