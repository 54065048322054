import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import timerUpArrow from "../assets/images/icon-arrow-up.svg";
import timerDownArrow from "../assets/images/icon-arrow-down.svg";
import NotificationSoundFile from './../assets/sounds/timer-end.mp3';
import { setRoomAudio } from './../models/audio';
import Button from './../components/button';
import Moment from "react-moment";
import moment from "moment";

class Timer extends Component {
  constructor(props) {
    super(props);
    const timerEndTime = localStorage.getItem("timerEndTime");
    const timerTimeLeft = localStorage.getItem("timerTimeLeft");
    this.state = {
      minutes: 30,
      seconds: 0,
      timerTimeLeft: timerTimeLeft,
      timerEndTime: timerEndTime,
      isTimerActive: false,
      isTimerFinished: false,
      actualTime: moment(timerEndTime).diff(),
      prevVolume: this.props.room.reservation.audioVolume?this.props.room.reservation.audioVolume:null,
    };
    let prevVolume = this.props.room.reservation.audioVolume?this.props.room.reservation.audioVolume:null;
    let roomId = this.props.room.id;
    let notificationPlayCount = 1;
    this.notificationSound = new Audio(NotificationSoundFile);
    this.notificationSound.onended = function() {
      if(notificationPlayCount < 5){
        const notificationVolume = [.6, .8, 1, 1, .8];
        this.volume = notificationVolume[notificationPlayCount];
        notificationPlayCount++;
        this.play();
      } else if(prevVolume){
        setRoomAudio(roomId,null,prevVolume)
        .then(result => {})
    }
    };
    this.changeMinutes = this.changeMinutes.bind(this);
    this.minimizeModal = this.minimizeModal.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.cancelTimer = this.cancelTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
  }
  
  componentDidMount() {
    this.initTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timerTimeout);
    this.setState({
      isTimerActive: false,
      minutes: 30,
      seconds: 0,
      isTimerFinished: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isTimerPaused !== this.props.isTimerPaused) {
      this.initTimer();
    }
    if(prevProps.status !== this.props.status) {
      this.cancelTimer();
    }
  }

  initTimer = () => {
    const timerEndTime = localStorage.getItem("timerEndTime");
    const timerTimeLeft = localStorage.getItem("timerTimeLeft");
    if (
      timerEndTime &&
      moment(timerEndTime).diff() > 0
    ) {
      this.setState({
        isTimerActive: true
      });
      this.startCountdown();
    } else if (timerTimeLeft) {
      let future = moment().add(timerTimeLeft, "ms")._d;
      this.setState({
        isTimerActive: true,
        actualTime: moment(future).diff()
      });
    } else if (timerEndTime &&
      moment(timerEndTime).diff() <= 0) {
      this.setState({
        isTimerActive: true,
        isTimerFinished: true,
        actualTime: 0,
      });
    }

  }

  startCountdown() {
    const timerEndTime = localStorage.getItem("timerEndTime");
    if ( moment(timerEndTime).diff() > 0 ) {
      this.setState({
        actualTime: moment(timerEndTime).diff()
      });
      this.timerTimeout = setTimeout(() => {
        this.startCountdown();
      }, 1000);
    } else if ( moment(timerEndTime).diff() ) {
      this.handleTimerFinished();
      if ( this.props.timerButton ) {
        if(this.state.prevVolume){
          setRoomAudio(this.props.room.id,null,0)
          .then(result => {
            this.notificationSound.volume = .6;
            this.notificationSound.play();
          }, error => { 
            this.notificationSound.volume = .6;
            this.notificationSound.play();
          })
        } else{
          this.notificationSound.volume = .6;
          this.notificationSound.play();
        }
      }
    }      
  }

  handleTimerFinished() {
    this.setState({
      isTimerFinished: true,
    })
    clearTimeout(this.timerTimeout);
    localStorage.removeItem("timerTimeLeft");
  }

  changeMinutes(dir) {
    let minutes = this.state.minutes;
    if (dir==='up') {
      if (minutes < 59) {
        minutes += 1;
      }
    } else {
      if (minutes > 0) {
        minutes -= 1;
      }      
    }
    this.setState({
      minutes: minutes
    });
  }

  startTimer() {
    localStorage.setItem(
      "timerEndTime",
      moment()
        .add(this.state.minutes, "m")
        .add(this.state.seconds + 1, "s")
        .format()
    );
    const timerEndTime = localStorage.getItem("timerEndTime");
    this.setState({
      actualTime: moment(timerEndTime).diff(),
      isTimerFinished: false,
      isTimerActive: true
    });
    this.startCountdown();
    this.props.toggleTimerActive();
  }

  cancelTimer() {
    this.setState({
      isTimerActive: false,
      minutes: 30,
      seconds: 0,
    });
    this.handleTimerFinished();
    localStorage.removeItem("timerEndTime");
    this.notificationSound.pause();
    this.minimizeModal();
    this.props.toggleTimerActive()
  }

  pauseTimer() {
    clearTimeout(this.timerTimeout);
    localStorage.removeItem("timerEndTime");
    localStorage.setItem("timerTimeLeft", this.state.actualTime);
    this.props.updateIsTimerPaused(true);
  }

  resumeTimer() {
    localStorage.setItem("timerEndTime", moment().add(this.state.actualTime, "ms").format());
    localStorage.removeItem("timerTimeLeft");
    this.startCountdown()
    this.props.updateIsTimerPaused(false);
  }

  minimizeModal() {
    if (this.props.minimize) {
      this.props.minimize("timer");
    }
  }

  render() {
    const { t } = this.props;
    const { isTimerActive, isTimerFinished, actualTime, minutes, seconds } = this.state;
    if (this.props.timerButton) {
      return <div className={isTimerFinished?"blink":"reg"}>
      <Moment format="mm:ss">{actualTime}</Moment>
      </div>
    } else {
      return isTimerActive ? (
        <React.Fragment>
          <div className="timer">
            <div className={"number " + (isTimerFinished?"blink":"")}>
              <Moment format="mm:ss">{actualTime}</Moment>
            </div>
          </div>
          <div className="timerButtons">
            {!isTimerFinished?
              <React.Fragment>
                {localStorage.getItem("timerTimeLeft")?
                  <Button type="primary btn-lg" onClick={this.resumeTimer}>{t("Resume")}</Button>
                :
                  <Button type="primary btn-lg" onClick={this.pauseTimer}>{t("Pause")}</Button>
                }
              </React.Fragment>
            :null}
            <Button type="outline-primary btn-lg" onClick={this.cancelTimer}>{t("Cancel Timer")}</Button>
            {!isTimerFinished?
              <Button type="primary btn-lg" onClick={this.minimizeModal}>{t("Minimize")}</Button>
            :null}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="timer">
            <div className="arrows">
              <Button onClick={()=>this.changeMinutes('up')} type="circle" icon={timerUpArrow} />
              <Button onClick={()=>this.changeMinutes('down')} type="circle" icon={timerDownArrow} />
            </div>
            <div className="number">
              <Moment format="mm:ss">
                {moment({ minutes: minutes, seconds: seconds })}
              </Moment>
            </div>
          </div>
          <div>
            <Button type="primary btn-lg" onClick={this.startTimer}>{t("Start Timer")}</Button>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withTranslation()(Timer);