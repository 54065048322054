import {
  getReservations as GetReservations,
  getReservation as GetReservation,
  getReservationsByUser as GetReservationsByUser,
  createReservation as CreateReservation,
  checkinReservation as CheckinReservation,
  getAvailableReservations as GetAvailableReservations,
  printReceipt as PrintReceipt,
  updateReservationStatus as UpdateReservationStatus,
  addReservationMerchandise as AddReservationMerchandise,
  openGarageDoor as OpenGarageDoor,
  requestCubicle as RequestCubicle,
  updateNote as UpdateNote,
  sendSms as SendSms,
  getUsageReport as GetUsageReport,
  getListReport as GetListReport,
  assignRoom as AssignRoom,
  preassignRoom as PreassignRoom
} from './../api';
import moment from 'moment';
import { statuses } from './../global-props';

export function processReservations(result) {
  result.map(res => {
    let roomType;
    if(res.privateRoom){
      if ( res.bath ) {
        roomType = 'private-bath';
      } else if ( res.handicapped ) {
        roomType = 'private-wheelchair';
      } else {
        roomType = 'private-shower';
      }
    } else {
      if ( res.bath ) {
        roomType = 'bath';
      } else if ( res.handicapped ) {
        roomType = 'wheelchair';
      } else {
        roomType = 'shower';
      }
    }
    res.roomType = roomType;
    if (res.statusHistory && res.statusHistory.length>0) {
      res.statusHistory.map((s,i)=>{
        s.previousStatusLastUpdatedByIp = res.statusHistory[i-1]?res.statusHistory[i-1].statusLastUpdatedByIp:null;
        s.previousStatusLastUpdatedByUser = res.statusHistory[i-1]?res.statusHistory[i-1].statusLastUpdatedByUser:null;
        s.previousReservationStatusTitle = statuses[s.previousReservationStatus]?statuses[s.previousReservationStatus].longName?statuses[s.previousReservationStatus].longName:statuses[s.previousReservationStatus].name:null;
        s.newReservationStatusTitle = statuses[s.newReservationStatus]?statuses[s.newReservationStatus].longName?statuses[s.newReservationStatus].longName:statuses[s.newReservationStatus].name:null;
        let roomAssigned = res.roomAssignedHistory.find(rah=>moment(rah.dateCreated).isSame(s.previousStatusChange, 'minute'));
        s.roomAssigned = roomAssigned ? roomAssigned.roomId : null;
        return s;
      });
    }
    let donationsTipsFees  = res.payments.filter(payment=>payment.method==='Donation' || payment.method==='Tip' || payment.method==='Fee');
    if ( donationsTipsFees.length ) {
      donationsTipsFees.map(payment=>{
        payment.amount = Math.abs(parseFloat(payment.amount));
      });
    }

    res.donationTotal = res.donationAmount;
    res.tipTotal = res.tipAmount;

    return res;
  });

  return result;
}

function processReservationsByType(result) {
  let upcoming = result.filter(res => res.reservationStatus==="Reserved");
  let waiting = result.filter(res => res.reservationStatus==="Waiting" || res.reservationStatus==="Assigned").sort((a,b) => a.arrivedOnTime!==b.arrivedOnTime?(a.arrivedOnTime?-1:1):(moment(a.timeArrived).valueOf()-moment(b.timeArrived).valueOf()));
  let here = result.filter(res => res.reservationStatus!=="Reserved" && res.reservationStatus!=="Done");
  let completed = result.filter(res => res.reservationStatus==="Done");
  let ready = result.filter(res => res.reservationStatus==="ReadyForMikvah" || res.reservationStatus==="ReadyForMikvahNext");

  let reservations = {
    all: result,
    upcoming: upcoming,
    waiting: waiting,
    here: here,
    completed: completed,
    ready: ready,
  };

  return reservations;
}

/*export function getReservation(id) {
  return new Promise((resolve, reject) => {
    GetReservation(id)
      .then(
        result => resolve(processReservation(result)),
        error => reject(error)
      );
  })
}*/

export function getReservations(date=null, dateTo=null, kallah=false) {
  return new Promise((resolve, reject) => {
    GetReservations(date, dateTo,kallah)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let res = processReservations(result);
            res = processReservationsByType(res);
            return resolve(res);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getReservation(id) {
  return new Promise((resolve, reject) => {
    GetReservation(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let res = processReservations([result])[0];
            return resolve(res);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getReservationsByUser(id, dateFrom=null, dateTo=null) {
  return new Promise((resolve, reject) => {
    GetReservationsByUser(id, dateFrom, dateTo)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let res = processReservations(result);
            return resolve(res);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function createReservation(newres, ignoreCutoff=false, ignoreAvailability=false) {
  return new Promise((resolve, reject) => {
    CreateReservation(newres, ignoreCutoff, ignoreAvailability)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          let res = processReservations([result.result])[0];
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function updateReservationStatus(id, status) {
  return new Promise((resolve, reject) => {
    UpdateReservationStatus(id, status)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          let res = processReservations([result.result])[0];
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function addReservationMerchandise(id, merch) {
  return new Promise((resolve, reject) => {
    AddReservationMerchandise(id, merch)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          let res = processReservations([result.result])[0];
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function checkinReservation(id, {printerId=null}, userLanguage) {
  return new Promise((resolve, reject) => {
    CheckinReservation(id, {printerId}, userLanguage)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          let res = processReservations([result.result])[0];
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function printReceipt(id, printerId) {
  return new Promise((resolve, reject) => {
    PrintReceipt(id, printerId)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          return resolve(result);
        },
        error => reject(error)
      );
  })
}

export function updateNote(id, note=null, userNote=null) {
  return new Promise((resolve, reject) => {
    UpdateNote(id, note, userNote)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage)
          }
          let res = processReservations([result.result])[0];
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function getAvailableReservations(date, resId) {
  return new Promise((resolve, reject) => {
    GetAvailableReservations(date, resId)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let res = result;
            return resolve(res);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function openGarageDoor(reservationId, lat, lng) {
  return new Promise((resolve, reject) => {
    OpenGarageDoor(reservationId, lat, lng)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function requestCubicle(reservationId) {
  return new Promise((resolve, reject) => {
    RequestCubicle(reservationId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function sendSms(msg, users, numbers) {
  return new Promise((resolve, reject) => {
    SendSms(msg, users, numbers)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function assignRoom(reservationId, roomId, roomStatus) {
  return new Promise((resolve, reject) => {
    AssignRoom(reservationId, roomId, roomStatus)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function preassignRoom(reservationId, roomId) {
  return new Promise((resolve, reject) => {
    PreassignRoom(reservationId, roomId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function getUsageReport(date=null, dateTo=null) {
  return new Promise((resolve, reject) => {
    GetUsageReport(date, dateTo)
    .then(
      result => {
        if ( typeof result.Message === 'undefined' ) {
          let res = result;
          return resolve(res);
        }
        return reject(result.Message);
      },
      error => reject(error)
    );
  })
}

export function getListReport(date=null, dateTo=null) {
  return new Promise((resolve, reject) => {
    GetListReport(date, dateTo)
    .then(
      result => {
        if ( typeof result.Message === 'undefined' ) {
          let res = processReservations(result);
          return resolve(res);
        }
        return reject(result.Message);
      },
      error => reject(error)
    );
  })
}