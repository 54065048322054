import React from 'react';

import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Loader from './../../components/snippets/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { managerToolsMenu, managerAdvancedToolsMenu } from '../../global-props';
import { appAuth } from "../../router";

class ManagerDashboardView extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
    }

  }

  componentDidMount() {
    if(appAuth.authorization&&appAuth.authorization.roles.includes('Admin')){
      this.setState({showAdmin: true})
    }
  }
  
  render() {
    const { t } =  this.props;
    const { showAdmin } =  this.state;
    return (
      <div className="container manager-dash reception-view">
        <h2>{t("Manager Tools")}</h2>
        <div className="row manager-tools">
          {managerToolsMenu.map((item,i) => {
            return item.admin==showAdmin || !item.admin?
             <div className="col-6"><Link className="btn btn-lg" to={item.link}>
              <FontAwesomeIcon icon={item.icon} />
              {t(item.title)}
              </Link></div>
              :null
          })}
        </div>
        {showAdmin?
        <>
        <h2>{t("Advanced Tools")}</h2>
        <div className="row manager-tools">
          {managerAdvancedToolsMenu.map((item,i) => {
            return <div className="col-6"><Link className="btn btn-lg" to={item.link}>
              <FontAwesomeIcon icon={item.icon} />
              {t(item.title)}
              </Link></div>
          })}
        </div>
        </>
        :null}
      </div>
    )
  }
}
export default withTranslation()(ManagerDashboardView);
