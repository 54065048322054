import React, { Component } from 'react';
import { Alert, UncontrolledTooltip } from 'reactstrap';
import Moment from 'react-moment';

import { getReservations, requestCubicle } from './../../models/reservation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomTiles from './RoomTiles';
import RoomSummary from './room-summary';
import ProgressBar from './../../components/snippets/progress-bar';
import SignalR from './../../components/signalr';
import { getRooms, getCubicles } from './../../models/room';
import { getSections } from './../../models/section';
import { getActiveReception, updateActiveReception, deleteActiveReception } from './../../models/staff';
import Loader from './../../components/snippets/loader';
import LanguageSelector from './../../components/language-selector';
import moment from 'moment';
import ReservationDetails from './partials/reservation-details';
import RoomDetails from './partials/room-details';
import { SettingsContext } from '../../components/settings';
import Button from './../../components/button';

class ReceptionistMapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoadedRooms: false,
      isLoadedCubicles: false,
      isLoadedReservations: false,
      isLoadedActiveReception: false,
      errorReservations: null,
      modal: false,
      selectedRoom: null,
      summary: null,
      updatedRooms: null
    };
    this.modalToggle = this.modalToggle.bind(this);
    this.viewRoom = this.viewRoom.bind(this);
  }

  componentDidMount() {
    this.retrieveReservations();
    this.retrieveRooms();
    this.retrieveCubicles();
    this.retrieveSections();
    this.retrieveActiveReception();
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(() => this.setState({time: moment().format()}), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  retrieveRooms(updatedRooms) {
    getRooms()
      .then(
        result => {
          let rooms = result;
          let groupedRooms = rooms.reduce(function (r, a) {
            r[a.effectiveStatus] = r[a.effectiveStatus] || [];
            r[a.effectiveStatus].push(a);
            return r;
          },Object.create(null));
          this.setState({
            isLoadedRooms: true,
            rooms: rooms,
            groupedRooms,
            updatedRooms,
            error: null,
          });
          setTimeout(()=>this.setState({updatedRooms:null}),10000)
        },
        error => {
          this.setState({
            isLoadedRooms: true,
            error
          });
        }
      );
  }

  retrieveCubicles() {
    getCubicles()
      .then(
        result => {
          this.setState({
            isLoadedCubicles: true,
            cubicles: result,
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedCubicles: true,
            error
          });
        }
      );
  }

  retrieveActiveReception() {
    getActiveReception()
      .then(
        result => {
          this.setState({
            isLoadedActiveReception: true,
            activeReception: result
          });
        },
        error => {
          this.setState({
            isLoadedReception: true,
            error
          });
        }
      );
  }

  handleRoomUpdated(room) {
    const { rooms } = this.state;
    const index = rooms.findIndex(r => r.id === room.id);
    rooms[index] = room;
    this.setState({rooms});
  }

  handleCubiclesUpdated(cubicles) {
    this.setState({cubicles});
  }

  retrieveSections() {
    getSections()
      .then(
        result => {
          this.setState({
            isLoadedSections: true,
            sections: result,
          });
        },
        error => {
          this.setState({
            isLoadedSections: false,
            error
          });
        }
      );
  }
  
  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result;

          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
            errorReservations: null,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            errorReservations: error
          });
        }
      );
  }

  viewRoom(roomId) {
    this.setState({selectedRoom: roomId});
  }

  modalToggle() {
    this.setState({selectedRoom: null});
  }

  handleRequestCubicle = (e, reservationId) => {
    e.stopPropagation();
    this.setState({isSubmittingRequestCubicle: true});
    requestCubicle(reservationId)
      .then(result=>this.setState({isSubmittingRequestCubicle: false}));
  }


  render() {
    const { isLoadedRooms, isLoadedCubicles, isLoadedReservations, isLoadedSections, isLoadedActiveReception, errorReservations, rooms, cubicles, reservations, error, sections, activeReception, selectedReservation, isSubmittingRequestCubicle, selectedRoom, groupedRooms, updatedRooms } = this.state;
    let activeCubicleCount = cubicles?cubicles.filter(s=>['Occupied', 'Assigned'].includes(s.effectiveStatus)).length:0;

    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={(s) => this.retrieveRooms(s)} onSectionsUpdated={() => this.retrieveSections()} onReservationsUpdated={() => this.retrieveReservations()} onCubiclesUpdated={() => this.retrieveCubicles()} onReceptionUpdated={() => this.retrieveActiveReception()} />
        <div className={'container-page view-map view-receptionist-map'}>
          <div className="container-main">
            <div className="container-body">
              <SettingsContext.Consumer>
                {({settings}) =>
                  <RoomTiles receptionistView={true} reservations={reservations} groupedRooms={groupedRooms} updatedRooms={updatedRooms} rooms={rooms} isLoadedRooms={isLoadedRooms} cubicles={cubicles} isLoadedCubicles={isLoadedCubicles} error={error} isLoadedSections={isLoadedSections} sections={sections} isLoadedActiveReception={isLoadedActiveReception} activeReception={activeReception} handleRoomUpdated={(r) => this.handleRoomUpdated(r)} handleCubiclesUpdated={(r) => this.handleCubiclesUpdated(r)} activeCubicleCount={activeCubicleCount} time={this.state.time} settings={settings} />
                }
              </SettingsContext.Consumer>
            </div>
          </div>
        </div>
        {isLoadedRooms && rooms ?
          <RoomDetails modalToggle={this.modalToggle} room={rooms.find(r => r.id === selectedRoom)} groupedRooms={this.state.groupedRooms} handleRoomUpdated={(r) => this.handleRoomUpdated(r)} />
        :null}
      </div>
    );
  }
}

export default ReceptionistMapView;
