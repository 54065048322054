import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';
import StaffCode from './../components/staffCode';
import { SettingsContext } from "./../components/settings";
import room from "../views/room";

class ScreenNeedsCleaning extends React.Component {
  static contextType = SettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "Cleaning";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }

  render() {
    const { t, room } = this.props;
    const { error } = this.state;
    const { settings } = this.context;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}
        {room.staff || (settings && settings.roomsUseStaffEntry.value=="False")?
          <>
          <div className="instructions">
            {t("Press the button below when you begin cleaning")}
          </div>
          <Button type="circle-big" onClick={this.handleChangeStatus} loading={this.state.isUpdatingStatus} text={t("Start Cleaning")} />
          </>
        :null}
      {settings && settings.roomsUseStaffEntry.value=="True"?
      <StaffCode room={this.props.room} handleUpdateRoom={this.props.handleUpdateRoom}/>
      :null}
      </div>
    );
  }
}

export default withTranslation()(ScreenNeedsCleaning);
