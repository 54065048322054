import { getStaff as GetStaff, 
  updateStaff as UpdateStaff, 
  getActiveReception as GetActiveReception,
  updateActiveReception as UpdateActiveReception,
  deleteActiveReception as DeleteActiveReception
} from './../api';

function processStaff(staff) {
  staff.name = staff.firstName + ' ' + staff.lastName
  return staff;
}

export function getStaff(getAll) {
  return new Promise((resolve, reject) => {
    GetStaff(getAll)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let staff = result.map((staff,i) => {
              return processStaff(staff);
            })
            return resolve(staff);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateStaff(staff) {
  return new Promise((resolve, reject) => {
    UpdateStaff(staff)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          return resolve(processStaff(result.result));
        },
        error => reject(error)
      );
    })
  }
  
export function getActiveReception() {
  return new Promise((resolve, reject) => {
    GetActiveReception()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let staff = result.map((staff,i) => {
              return processStaff(staff);
            })
            return resolve(staff);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateActiveReception(staff) {
  return new Promise((resolve, reject) => {
    UpdateActiveReception(staff)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let staff = result.result.map((staff,i) => {
            return processStaff(staff);
          })
          return resolve(staff);
        },
        error => reject(error)
      );
    })
  }

  export function deleteActiveReception(staff) {
  return new Promise((resolve, reject) => {
    DeleteActiveReception(staff)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let staff = result.result.map((staff,i) => {
            return processStaff(staff);
          })
          return resolve(staff);
        },
        error => reject(error)
      );
    })
  }