import React from "react";
import { withTranslation } from 'react-i18next';
import BodyHeader from "./BodyHeader";
import { getUserByPhone } from "../../../models/user";
import { getReservationsByUser, getReservations } from "../../../models/reservation";
import { Alert, UncontrolledTooltip, Modal, ModalBody } from "reactstrap";
import Moment from 'react-moment';
import moment from 'moment';
import Button from './../../../components/button';
import HebrewDate from './../../../components/hebrewDate';
import { SettingsContext } from './../../../components/settings';
import NumberFormat from "react-number-format";
import PhoneInput from 'react-phone-input-2';
import './../../../assets/scss/react-phone-input.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from './../../../i18n';

class checkinNumber extends React.Component {
  static contextType = SettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      reservationCode: "",
      requireFullPhoneNumber: props.isAdvancedReservation||props.walkin,
      message: null,
      reservations: null,
      users: null,
      modalMultipleUsers: false,
      multipleUsers: [],
      isSubmitting: false,
      errorReservation: null,
      errorUser: null,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePhoneKeydown = this.handlePhoneKeydown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearReservations = this.handleClearReservations.bind(this);
    this.handleCreateReservation = this.handleCreateReservation.bind(this);
    this.handleChangeReservation = this.handleChangeReservation.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.handleNewPhoneNumber = this.handleNewPhoneNumber.bind(this);
    this.modalToggleMultipleUsers = this.modalToggleMultipleUsers.bind(this);
    this.groupBy = this.groupBy.bind(this);
  }

  componentDidMount() {
    if (!this.props.isAdvancedReservation) {
      this.retrieveReservations();
    }
    if (this.props.userId) {
      this.retrieveReservationsByUser(this.props.userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reservation !== this.props.reservation ) {
      this.handleClearReservations();
    }
    if ((prevProps.reservation !== this.props.reservation && this.props.reservation) && this.props.reservation.reservationStatus==="Reserved" && this.props.reservation.reservationId) {
      this.props.handleUpdateReservation(this.props.reservation, 'checkin-payment');
    }
  }

  handleChange(e) {
    //let numberLength = this.state.requireFullPhoneNumber?15:4;
    let numberLength = 15;
    let value = e.target.value;
    value = value.slice(0,numberLength);
    this.setState({reservationCode: value });
  }

  handlePhoneChange(value, data, event) {
    this.setState({reservationCode: value});
  }

  handlePhoneKeydown(event) {
    if (event.keyCode===13) {
      this.handleSubmit(event);
    }
  }

  retrieveUserByPhone(phone) {
    return new Promise((resolve, reject) => {
      getUserByPhone(phone, true)
      .then(
        result => {
          return resolve(result);
        },
        error => {
          this.setState({
            errorUser: error
          });
          return reject(error);
        }
      );
    })
  }

  retrieveReservations() {
    getReservations()
      .then(result => {
        this.setState({upcomingReservations: result.upcoming});
        this.groupBy(result.upcoming.slice(0,8))
      });
  }

  retrieveReservationsByUser(id) {
    const { settings } = this.context;
    getReservationsByUser(id)
      .then(
        result => {
          this.setState({
            isSubmitting: false,
            userId: id,
            users: null,
          })

          if ( result.length === 0 ) {
            if ( this.state.reservationCode.length <= 4 && !this.props.userId ) {
              //If reservation code is 4 digits and no reservations - require full phone number
              return this.setState({
                reservationCode: "",
                requireFullPhoneNumber: true,
              })
            }
            return this.handleCreateReservation();
          }

          let prevDay = parseInt(moment().subtract(settings&&settings.offset&&settings.offset.value,'seconds').format('HH')) < 5;
          let resTodayReserved = result.filter(res=>res.reservationStatus==="Reserved"&&moment(res.reservationDate).isSame(moment().subtract(prevDay?1:0,'days').subtract(settings&&settings.offset&&settings.offset.value,'seconds').startOf('day')));
          if ( resTodayReserved.length===1 ) {
            if ( this.props.isAdvancedReservation ) {
              return this.setState({
                message: "Existing reservations found.",
                reservations: resTodayReserved,
              });
            }
            return this.props.handleUpdateReservation(resTodayReserved.pop(), 'checkin-payment');
          }

          return this.setState({
            message: result.length===1?"Existing reservation found.":"Existing reservations found.",
            reservations: result,
          });

        },
        error => {
          this.setState({
            errorReservation: error
          });
        }
      );
  }

  handleCreateReservation(e,guest=false) {
    let userId = guest?null:this.state.userId;
    this.props.handleUpdateReservation({...this.props.reservation, userId: userId}, this.props.isAdvancedReservation?'reservation-setup':'checkin-roomtype');
  }

  handleClearReservations() {
    this.setState({
      message: null,
      reservations: null,
      requireFullPhoneNumber: this.props.isAdvancedReservation?true:false,
      reservationCode: "",
      users: null,
    });
  }

  handleChangeReservation(res) {
    res.replacingReservationId = res.reservationId;
    res.reservationId = null;
    this.props.handleUpdateReservation(res,this.props.isAdvancedReservation?'reservation-calendar':'checkin-roomtype');
  }

  handleSelectUser(user) {
    this.setState({
      isSubmitting: true,
    })
    this.retrieveReservationsByUser(user.id);
  }

  handleNewPhoneNumber() {
    this.props.handleUpdateReservation({...this.props.reservation, phoneNumber: this.state.reservationCode}, this.props.isAdvancedReservation?null:'checkin-roomtype');
  }

  handleClickReservation = res => {
    this.props.handleUpdateReservation(res);
  }

  handleClickKallah() {
    this.props.handleScreenChange('reservation-kallah');
  }

  groupBy(reservations) {
    let result = reservations.reduce(function (r, a) {
        r[a.reservationCode] = r[a.reservationCode] || [];
        r[a.reservationCode].push(a);
        return r;
    },Object.create(null));
    this.setState({groupedUpcoming:result});
    return result;
  }

  modalToggleMultipleUsers(e,users) {
    this.setState({modalMultipleUsers: !this.state.modalMultipleUsers, multipleUsers:users||[]});
  }

  handleSubmit(e) {
    e.preventDefault();
    let reservationCode = this.state.reservationCode.replace(/[^0-9]+/g,'');

    if ( reservationCode==="" ) {
      return false;
    }

    this.setState({
      isSubmitting: true,
      message: null,
      errorReservation: null,
      errorUser: null,
    });
    
    this.retrieveUserByPhone(reservationCode)
      .then(users => {
        if ( users.length===1 ) {
          //If one user is returned - search for existing reservations
          this.retrieveReservationsByUser(users[0].id)
        } else {
          if ( this.state.reservationCode.length <= 4 || (users.length === 0&&!this.state.requireFullPhoneNumber) ) {
            //If reservation code is 4 digits and multiple (or no users) - require full phone number
            this.setState({
              reservationCode: "",
              requireFullPhoneNumber: true,
            })
          } else {
            if ( users.length === 0 ) {
              //If full phone number and no users - create new user and reservation
              this.handleNewPhoneNumber();
            } else {
              //If full phone number and multiple users - present user with choice

              this.setState({
                reservationCode,
                message: "We found multiple users",
                users: users,
              });

            }
          }
          this.setState({
            isSubmitting: false,
          })
        }
      }, error => {
        this.setState({
          isSubmitting: false,
        })
      });
  }

  render() {
    const { t, checkin } = this.props;
    const { requireFullPhoneNumber, message, reservations, isSubmitting, errorUser, errorReservation, users, reservationCode, upcomingReservations, groupedUpcoming } = this.state;
    return (
      <>
        {checkin?<BodyHeader goBack={reservations?this.handleClearReservations:null} isAdvancedReservation={this.props.isAdvancedReservation} isEditingReservation={this.props.isEditingReservation} />:null}

        {message!==null?<div className="checkin-message"><Alert color="warning">{t(message)}</Alert></div>:null}
        {errorUser!==null?<div className="checkin-message"><Alert color="danger">{t(errorUser)}</Alert></div>:null}
        {errorReservation!==null?<div className="checkin-message"><Alert color="danger">{t(errorReservation)}</Alert></div>:null}

        {reservations!==null?
          <div className="">
            {reservations.map((res,i) => {
              return <div key={i} className="checkin-message d-flex align-items-center">
                <div className="mie-3">{res.reservationCode}{res.user.testUser?<small className="text-muted"> {t("Test")}</small>:null}</div>
                <div className="mie-3">{res.reservationStatus}</div>
                {res.room ?<div className="mie-3">Room: {res.room.id}</div>: null}
                <div>
                  <Moment format={t("MM/DD")}>{res.reservationDate}</Moment>
                  <HebrewDate className="mis-1" date={res.reservationDate} day={true} month={true}/>
                </div>
                {['Reserved','Pending'].includes(res.reservationStatus)&&moment(res.reservationDate).isSameOrAfter(moment(),'day')?
                  <Button className="mis-3" type="secondary" onClick={() => this.handleChangeReservation(res)}>{t("Edit Reservation")}</Button>
                :null}
              </div>
            })}
            <div className="checkin-message text-center">
              <hr />
              <Button type="secondary" size="lg" onClick={this.handleCreateReservation}>{t("Create New Reservation")}</Button>
            </div>
          </div>
        :
          users!==null?(
            <div className="checkin-message text-center">
              {users.map((user,i) => {
                return <div className="mb-3" key={i}><Button type="secondary mx-1" size="lg" onClick={() => this.handleSelectUser(user)} loading={isSubmitting}>{user.phoneNumber}{user.phoneNumber===reservationCode?" - " + t("Exact Match"):''}</Button></div>
              })}
              {users.find(user=>user.phoneNumber===reservationCode).length===0?
                <div className="text-center">
                  <hr />
                  <Button type="secondary" size="lg" onClick={this.handleNewPhoneNumber}>{t("New User")} - {reservationCode}</Button>
                </div>
              :null}
            </div>
          ):(
            <div className={"modal-body"+(!checkin?" pb-0":"")}>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="checkin-numbers">
                  {requireFullPhoneNumber?
                    <>
                      <div className="title">{t("Please enter the guests full phone number")}</div>
                      <PhoneInput specialLabel="" value={reservationCode} onChange={this.handlePhoneChange} onKeyDown={this.handlePhoneKeydown} country="us" inputProps={{required: true, autoFocus: true}} placeholder="0000000000"  preferredCountries={['us', 'il']} />
                    </>
                  :
                    <>
                      <div className="title">{t("Enter the last 4 digits of guest's phone number")}</div>
                      <NumberFormat
                        className="form-control number-input"
                        type="tel"
                        name="phoneNumber"
                        decimalScale={0}
                        allowNegative={false}
                        placeholder={requireFullPhoneNumber?"0000000000":"0000"}
                        value={reservationCode}
                        onChange={this.handleChange}
                        autoFocus={true}
                      />
                    </>
                  }
                  
                </div>

                <div className={"modal-footer"+(!checkin?" pb-0":"")}>
                  <Button type="secondary" size="lg" loading={isSubmitting}>{t("Continue")}</Button>
                  
                  {checkin?<Button type="secondary" size="lg" onClick={(e)=>this.handleCreateReservation(e,true)}>{t("Guest Reservation")}</Button>:null}
                  
                </div>

                {groupedUpcoming && upcomingReservations&&upcomingReservations.length?
                  <div className="text-center">
                    <hr />
                    <h5 className="title text-uppercase mb-4">{t("Upcoming Reservations")}</h5>
                    {Object.keys(groupedUpcoming).map((res,i)=><span key={i}>
                      {groupedUpcoming[res].length===1?
                      <Button type="outline-primary" className="mx-2 mb-2" onClick={()=>this.handleClickReservation(groupedUpcoming[res][0])}>
                        {groupedUpcoming[res][0].kallah?
                          <FontAwesomeIcon icon={['far', 'gem']} className="mie-2" />
                        :
                          <FontAwesomeIcon icon={groupedUpcoming[res][0].roomType} className="mie-2" />
                        }
                        <Moment format={t('h:mma')}>{groupedUpcoming[res][0].reservationTime}</Moment>
                        <FontAwesomeIcon icon="phone" className="mis-4 mie-2" />
                        {groupedUpcoming[res][0].reservationCode}
                        {groupedUpcoming[res][0].firstTime?
                          <>
                            <sup><FontAwesomeIcon className="mis-2 small" icon="asterisk" id={`icon-new-user-${i}`} /></sup>
                            <UncontrolledTooltip target={`icon-new-user-${i}`}>{t("New User")}</UncontrolledTooltip>
                          </>
                        :null}
                        {groupedUpcoming[res][0].user.testUser?<small className="text-muted"> {t("Test")}</small>:null}
                      </Button>
                      :
                      <Button type="outline-primary" className="mx-2 mb-2" onClick={(e)=>this.modalToggleMultipleUsers(e,groupedUpcoming[res])}>
                      <FontAwesomeIcon icon="phone" className="mis-4 mie-2" />
                      {groupedUpcoming[res][0].reservationCode}
                    </Button>
                      }
                    </span>)}
                  </div>
                :null}

              </form>
            </div>
          )
        }
          <Modal isOpen={this.state.modalMultipleUsers} toggle={this.modalToggleMultipleUsers} centered={true} className={"lang-" + i18n.language} modalClassName="modal-multiple-users">
          <div className="modal-header">
          {t("Multiple Matches")} 
            <button type="button" onClick={this.modalToggleMultipleUsers}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            <div className="text-center">
              <p>{t("Please ask user for full phone number.")}</p>
              {this.state.multipleUsers.map((res,i)=>
                <Button key={i} type="outline-primary" className="mx-2 mb-2" onClick={()=>this.handleClickReservation(res)}>
                  {res.kallah?
                    <FontAwesomeIcon icon={['far', 'gem']} className="mie-2" />
                  :
                    <FontAwesomeIcon icon={res.roomType} className="mie-2" />
                  }
                  <Moment format='h:mma'>{res.reservationTime}</Moment>
                  <FontAwesomeIcon icon="phone" className="mis-4 mie-2" />
                  {res.user.phoneNumber}
                  {res.firstTime?
                    <>
                      <sup><FontAwesomeIcon className="mis-2 small" icon="asterisk" id={`icon-new-user-${i}`} /></sup>
                      <UncontrolledTooltip target={`icon-new-user-${i}`}>{t("New User")}</UncontrolledTooltip>
                    </>
                  :null}
                  {res.user.testUser?<small className="text-muted"> {t("Test")}</small>:null}
                </Button>
              )}
              <div className="d-flex">
                <Button className="btn btn-outline-secondary mis-auto mie-1" onClick={this.modalToggleMultipleUsers}>{t("Cancel")}</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(checkinNumber);
