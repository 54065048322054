import { getConnectionToken as GetConnectionToken, 
  createPaymentIntent as CreatePaymentIntent, 
  createPayment as CreatePayment, 
  getSavedCards as GetSavedCards, 
  addSavedCard as AddSavedCard, 
  deleteSavedCard as DeleteSavedCard, 
  getPaymentReport as GetPaymentReport,
  getPaymentsByUser as GetPaymentsByUser
  } from '../api';
import { processReservations } from './reservation';

export function getSavedCards(userId) {
  return new Promise((resolve, reject) => {
    GetSavedCards(userId)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' && typeof result.errorMessage === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message||result.errorMessage);
        },
        error => reject(error)
      );
  })
}

export function addSavedCard(userId, paymentInfo) {
  return new Promise((resolve, reject) => {
    AddSavedCard(userId, paymentInfo)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}


export function deleteSavedCard(userId, cardId) {
  return new Promise((resolve, reject) => {
    DeleteSavedCard(userId, cardId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}


export function getConnectionToken() {
  return new Promise((resolve, reject) => {
    GetConnectionToken()
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let res = result;
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function createPaymentIntent(paymentIntent) {
  return new Promise((resolve, reject) => {
    CreatePaymentIntent(paymentIntent)
      .then(
        result => {
          if ( result.success === false ) {
            return reject(result.errorMessage);
          }
          let res = result.result;
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function createPayment(payment) {
  return new Promise((resolve, reject) => {
    CreatePayment(payment)
      .then(
        result => {
          let res = result;
          if ( result.success === false ) {
            return reject(result);
          }
          if (result.result) {
            res = processReservations([result.result])[0];
          }
          return resolve(res);
        },
        error => reject(error)
      );
  })
}

export function getPaymentReport(date=null, dateTo=null) {
  return new Promise((resolve, reject) => {
    GetPaymentReport(date, dateTo)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getPaymentsByUser(user, date=null, dateTo=null) {
  return new Promise((resolve, reject) => {
    GetPaymentsByUser(user, date, dateTo)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

/*export function getPayments() {
  return new Promise((resolve, reject) => {
    GetSections()
      .then(
        result => {
          if ( result !== '' ) {
            let sections = result.map((section,i) => {
              return processSection(section);
            })
            return resolve(sections);
          }
          return reject(error);
        },
        error => reject(error)
      );
  })
}*/

