import React from 'react';

import { Trans, withTranslation } from 'react-i18next';
import RoomNumber from '../../../components/snippets/room-number';
import RoomAttendant from '../../../components/snippets/room-attendant';
import RoomStatus from '../../../components/snippets/room-status';
import moment from 'moment'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, Alert, UncontrolledTooltip } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Collapse } from 'reactstrap';
import Button from './../../../components/button';
import HebrewDate from './../../../components/hebrewDate';
import SetStatusIcon from './../../../components/setStatusIcon';
import { supplyRequest, updateRoomStatus, reloadRoom, swapRooms } from './../../../models/room';
import { statuses } from './../../../global-props';
import { getReservation, printReceipt, assignRoom } from './../../../models/reservation';
import { getAttendants } from './../../../models/attendant';
import { unlockRoom, doorIOEvent } from '../../../models/room';
import { SettingsContext } from './../../../components/settings';
import CurrencyFormat from './../../../components/snippets/currency-format';
import ReactSVG from 'react-svg';
import iconCalendar from './../../../assets/images/icon-calendar.svg';
import iconClock from './../../../assets/images/icon-clock.svg';
import iconBath from './../../../assets/images/icon-bath.svg';
import iconShower from './../../../assets/images/icon-shower.svg';
import iconWheelchair from './../../../assets/images/icon-wheelchair.svg';
import iconKallah from './../../../assets/images/icon-kallah.svg';
import iconBathPrivate from './../../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from './../../../assets/images/icon-shower-private.svg';
import iconParking from './../../../assets/images/icon-parking.svg';
import iconSalon from './../../../assets/images/icon-salon.svg';
import iconClockExtra from './../../../assets/images/icon-clock-extra.svg';
import ReservationTimeline from './reservation-timeline';
import { Link } from 'react-router-dom';
import i18n from './../../../i18n';


class RoomDetails extends React.Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      time: null,
      modal: false,
      isUpdatingStatus: false,
      isStatusToggleOpen: false,
      reservationDetails: null,
      waitingReservation: null,
      isOpenStatus: false,
      isOpenSupplies: false,
      isOpenUserNotes: true,
      isOpenReservationNotes: true,
      isOpenExcludedAttendants: true,
      isLoadedAttendants: false,
      roomStatuses: [
        'Offline',
        'Ready',
        'NeedsCleaning',
        'Cleaning',
      ],
      reservationStatuses: [
        'Prep',
        // 'ReadyForChecking',
        // 'Checking',
        'ReadyForMikvah',
        'InMikvah',
        'Dressing',
        'Done',
      ],
      reservationChangeStatuses: [
        { status: 'Waiting', title:'Next Available Room'},
        { status: 'Canceled', title:'Cancel Reservation'},
        { status: 'Done', title:'Complete Reservation'}
      ]
    }
    this.modalToggle = this.modalToggle.bind(this);
    this.modalStatusToggle = this.modalStatusToggle.bind(this);
    this.modalRoomScreenToggle = this.modalRoomScreenToggle.bind(this);
    this.modalPlaceUserToggle = this.modalPlaceUserToggle.bind(this);
    this.modalSwapUserToggle = this.modalSwapUserToggle.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSupplyRequestCompleted = this.handleSupplyRequestCompleted.bind(this);
    this.statusToggle = this.statusToggle.bind(this);
    this.handleReprint = this.handleReprint.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
    this.setupSettings();

  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidUpdate(prevProps) {
    if ( this.props.room!==prevProps.room ) {
      this.setState({
        modal: true,
        isUpdatingStatus: false,
        isOpenStatus: false,
      })
      if ( this.props.room && this.props.room.reservation && (!prevProps.room || (prevProps.room.reservation && this.props.room.reservation.reservationId!==prevProps.room.reservation.reservationId))) {
        this.retrieveReservationDetails();
        this.setState({
          isOpenSupplies: this.props.room.reservation.supplies.filter(supply=>supply.status==='open').length>0
        });
      }
      if ( prevProps.room && prevProps.room.reservation && this.props.room && this.props.room.reservation && prevProps.room.reservation.reservationStatus!==this.props.room.reservation.reservationStatus ) {
        this.retrieveReservationDetails();
      }
      if(this.props.room && this.props.room.reservation && this.props.room.reservation.user && this.props.room.reservation.user.excludedAttendants && this.props.room.reservation.user.excludedAttendants.length>0 && !this.state.attendants){
        this.retrieveAttendants();
      }
      this.setupSettings();
    }
    if ( this.context !== this.savedContext ) {
      this.savedContext = this.context;
      this.setupSettings();
    }
  }

  setupSettings() {
    const {settings} = this.context;
    if (this.props.room && this.props.room.reservation && settings) {
      if (this.props.room.reservation.kallah) {
        this.roomTypeDisplay = { name: 'Kallah', icon: iconKallah, price: settings?settings.priceKallah.value:0 }
      } else if (this.props.room.reservation.handicapped) {
        this.roomTypeDisplay = { name: "Accessible", icon: iconWheelchair, price: settings?settings.priceBath.value:0 }
      } else if (this.props.room.reservation.privateRoom && this.props.reservation.bath) {
        this.roomTypeDisplay = { name: "Bath Private", icon: iconBathPrivate, price: settings?settings.pricePrivateBath.value:0 }
      } else if (this.props.room.reservation.privateRoom && !this.props.reservation.bath) {
          this.roomTypeDisplay = { name: "Shower Private", icon: iconShowerPrivate, price: settings?settings.pricePrivateShower.value:0 }
      } else if (this.props.room.reservation.bath) {
        this.roomTypeDisplay = { name: "Bath", icon: iconBath, price: settings?settings.priceBath.value:0 }
      } else {
        this.roomTypeDisplay = { name: "Shower", icon: iconShower, price: settings?settings.priceShower.value:0 }
      }
      this.setState({roomTypeDisplay: this.roomTypeDisplay});
    }
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  retrieveReservationDetails() {
    getReservation(this.props.room.reservation.reservationId)
      .then(result=>this.setState({reservationDetails: result}));
  }

  modalToggle() {
    this.props.modalToggle();
    this.setState(state => {
      state.modal = !this.state.modal;
      return state;
    });
  }

  modalStatusToggle() {
    this.setState({
      modalStatus: !this.state.modalStatus,
      waitingReservation: null,
    });
  }

  modalRoomScreenToggle() {
    this.setState({
      modalRoomScreen: !this.state.modalRoomScreen,
    });
  }

  modalPlaceUserToggle() {
    this.setState({modalPlaceUser: !this.state.modalPlaceUser});
  }

  modalSwapUserToggle() {
    this.setState({modalSwapUser: !this.state.modalSwapUser});
  }

  statusToggle() {
    this.setState({isStatusToggleOpen: !this.state.isStatusToggleOpen});
  }

  handleChangeStatus(status, reservationStatus) {
    let isAllowableReservationStatus = this.state.reservationChangeStatuses.filter(status=>status.status===reservationStatus).length;
    if (reservationStatus!==null && this.state.roomStatuses.includes(status) && !isAllowableReservationStatus) {
      return this.setState({modalStatus: true, tempStatus: status})
    }

    const roomId = this.props.room.id;
    const reservationId = this.props.room.reservation ? this.props.room.reservation.reservationId : null;
    this.setState({
      isUpdatingStatus: true,
      modalStatus: false,
    });
    updateRoomStatus(roomId, status, reservationStatus)
      .then(
        result => {
          this.props.handleRoomUpdated(result);
          if (reservationStatus==='Waiting') {
            getReservation(reservationId)
              .then(result=>this.setState({
                waitingReservation: result,
                modalStatus: true,
              }));
          }
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      ).finally(result => this.setState({isUpdatingStatus:false}));
  }

  handleSupplyRequestCompleted(supply) {  
    const roomId = this.props.room.id;
    let supplies = [supply.id];
    const action = 'complete';

    supplyRequest(roomId, supplies, action)
      .then(
        result => {
        },
        error => {
        }
      );
  }

  handleUnlockWetDoor = () => {
    this.setState({isUnlockingDoor: true});
    unlockRoom(this.props.room.id, 'wet')
      .then(result => {
        this.setState({isUnlockingDoor: false, showDoorUnlocked: true});
        setTimeout(() => {
          this.setState({showDoorUnlocked: false});
        }, 3000);
      }, error => {
        this.setState({error: error, isUnlockingDoor: false})
      });
  }

  handleDryDoor = (action) => {
    this.setState({isUpdatingDryDoor: true});
    doorIOEvent(this.props.room.id, action)
      .then(result => {
        this.setState({isUpdatingDryDoor: false});
      }, error => {
        this.setState({error: error, isUpdatingDryDoor: false})
      });
  }

  handleReloadRoomScreen = () => {
    this.setState({isReloadingRoomScreen: true});
    reloadRoom(this.props.room.id)
      .then(result => {
        this.setState({isReloadingRoomScreen: false, showPageReloaded: true});
        setTimeout(() => {
          this.setState({showPageReloaded: false});
        }, 3000);
      });
  }

  handleReprint() {
    const { receptionist } = this.context;
    this.setState({
      isLoading: true,
      error: null,
    })
    printReceipt(this.state.waitingReservation.reservationId, receptionist.printerId)
      .then(result => {
        this.setState({
          isLoading: false,
        })
      }, error => {
        this.setState({error: error, isLoading: false});
      });      
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  placeInRoom(room){
    this.setState({isPlacingRoom:true})
    assignRoom(this.props.room.reservation.reservationId,room,this.state.selectedNewRoomStatus)
      .then(result => {
        this.setState({
          isPlacingRoom:false,
          placeConfirmation: result.id,
          selectedPlaceRoom: null
        }, () => setTimeout(()=>this.setState({placeConfirmation:null}),15000))
      }, error => {
        this.setState({
          placeError:error,
          isPlacingRoom:false });
    });
  }

  swapRoom(room){
    this.setState({isSwappingRoom:true})
    swapRooms(this.props.room.id,room)
      .then(result => {
        this.setState({
          isSwappingRoom:false,
          swapConfirmation: {room1: result[0].id, room2: result[1].id},
          selectedSwapRoom: null
        }, () => setTimeout(()=>this.setState({swapConfirmation:null}),15000))
      }, error => {
        this.setState({
          swapError:error,
          isPlacingRoom:false });
    });
  }


  render() {
    const { t, room, groupedRooms } = this.props;
    const { time, roomStatuses, reservationStatuses, reservationChangeStatuses, isUpdatingStatus, isStatusToggleOpen, isOpenStatus, isOpenSupplies, isOpenUserNotes, isOpenReservationNotes, isOpenExcludedAttendants, reservationDetails, waitingReservation, isUnlockingDoor, showDoorUnlocked, isUpdatingDryDoor, isReloadingRoomScreen, showPageReloaded, error, placeError , isLoading, roomTypeDisplay, attendants, isLoadedAttendants, selectedPlaceRoom, selectedSwapRoom, selectedNewRoomStatus ,isPlacingRoom, isSwappingRoom, placeConfirmation, swapConfirmation, swapError } = this.state;
    const { settings } = this.context;
    let res = room && room.reservation ? room.reservation : null;
    let reservationMinutesTotal;

    if (settings!==null&&res&&res.reservationId) {
      let reservationMinutes = parseFloat(settings['reservationMinutes'+(res.bath?'Bath':'Shower')].value);
      let reservationMinutesDoubleSlot = parseFloat(settings.reservationMinutesDoubleSlot.value);
      reservationMinutesTotal = reservationMinutes + (res.doubleSlot ? reservationMinutesDoubleSlot : 0);
    }

    if ( room!=null ) {
      return <>
        <Modal isOpen={this.state.modal} toggle={this.modalToggle} centered={true} size="xl" className={"lang-" + i18n.language} modalClassName="modal-room-details">
          <div className="modal-header">
            <RoomNumber number={room.id} />
            {room.reservation!==null ?
              <div>
                <div className="title">{t("User")}</div>
                <div><Link to={{pathname: "../user/", state: { userId: res.user.id }}}>
                  {res.reservationCode}
                  {res.firstTime?
                    <>
                      <sup><FontAwesomeIcon className="mis-2 text-orange small" icon="asterisk" id={`icon-new-user-${res.reservationCode}`} /></sup>
                      <UncontrolledTooltip target={`icon-new-user-${res.reservationCode}`}>{t("New User")}</UncontrolledTooltip>
                    </>
                  :null}
                  </Link></div>
              </div>
            :null}
            <div>
              <div className="title">{t("Type")}</div>
              <div><FontAwesomeIcon icon={room.roomType} /></div>
            </div>
            <RoomAttendant attendant={room.section.attendant} />
            <div>
              <div className="title">{t("Mikvah")}</div>
              <div>{room.section.name}</div>
            </div>
            <Dropdown className="dropdown-room-status" isOpen={isStatusToggleOpen} toggle={this.statusToggle}>
              <DropdownToggle tag="div">
                <RoomStatus status={room.statusName} loading={isUpdatingStatus} color={room.statusColor} dropdown />
              </DropdownToggle>
              <DropdownMenu>
                {room.reservation!==null?
                  <>
                    {reservationStatuses.map((s,i) => {
                      let status = statuses[s];
                      // First, hide current status from list.
                      if (status.name!==room.statusName) {
                        return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s, room.reservation.reservationStatus)}>
                          {status.longName?t(status.longName):t(status.name)}
                        </DropdownItem>
                      } else {
                        return null;
                      }
                    })}
                    <DropdownItem divider />
                  </>
                :null}
                {roomStatuses.map((s,i) => {
                  let status = statuses[s];
                  let roomReservationStatus = room.reservation?room.reservation.reservationStatus:null;
                  // First, hide current status from list.
                  if (status.name!==room.effectiveStatus) {
                    return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s, roomReservationStatus)}>
                      {status.changeRequirements!=="no-reservation"?
                        <div>{t(status.name)}</div>
                      :t(status.name)}
                    </DropdownItem>
                  } else {
                    return null;
                  }
                })}
              </DropdownMenu>
            </Dropdown>
            <button type="button" onClick={this.modalToggle}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            <div className="col-main">
              {error?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :null}
              {room.reservation!==null ?
                <>
                  <div className="collapse-container">
                    <div className={"collapse-header" + (isOpenStatus?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenStatus: !state.isOpenStatus}))}>
                      <div className="title">
                        <div>{t("Current Session")}</div>
                      </div>
                      <div className="meta">
                        <div><span className="label">{t("Slot")}:</span> {room.reservation.advanceReservation?<><Moment format={t("h:mm")} date={room.reservation.reservationTime} />-<Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}} date={room.reservation.reservationTime} /></>:<>{t("Walk-in")}</>}</div>
                        <div><span className="label">{t("Arrived")}:</span> <Moment format={t("h:mma")} date={room.reservation.timeArrived} /></div>
                        <div className="right"><span className="label">{t("Expected End")}:</span> <Moment format={t("h:mma")} date={room.reservation.expectedEndTime} /></div>
                      </div>
                    </div>
                    <Collapse isOpen={isOpenStatus}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Status")}</th>
                            <th>{t("StatusSetBy")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Duration")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservationDetails!==null?
                            reservationDetails.statusHistory.map((s,i)=>{
                              if (s.previousReservationStatus==='Reserved') {
                                s.previousStatusChange=reservationDetails.reservationCreated;
                              }
                              return <React.Fragment key={i}>
                                <tr>
                                  <td>
                                    {t(s.previousReservationStatusTitle)}
                                    {s.previousReservationStatusTitle==='Assigned'?' - ' + t("Room") + " " + s.roomAssigned:null}
                                  </td>
                                  <td>
                                    {s.previousReservationStatusTitle=='Reserved'?<SetStatusIcon timeStamp={s.previousStatusChange} ipAddress={reservationDetails.createdByIp} setStatus={reservationDetails.createdByUser}/>:null}
                                    {s.previousStatusLastUpdatedByUser?<SetStatusIcon timeStamp={s.previousStatusChange} ipAddress={s.previousStatusLastUpdatedByIp} setStatus={s.previousStatusLastUpdatedByUser}/>:null}
                                  </td>
                                  <td><Moment format={t(moment(s.previousStatusChange).isSame(moment(res.reservationDate),'day')?"h:mma":"M/D h:mma")} date={s.previousStatusChange} /></td>
                                  <td>{moment.duration(moment(s.previousStatusChange).diff(s.dateCreated)).asSeconds()?
                                    moment.duration(moment(s.dateCreated).diff(s.previousStatusChange)).format("h:*mm:ss")
                                  :'0:00'}</td>

                                </tr>
                                {i===reservationDetails.statusHistory.length-1?
                                  <tr>
                                    <td>{t(s.newReservationStatusTitle)}</td>
                                    <td><SetStatusIcon timeStamp={s.dateCreated} ipAddress={s.statusLastUpdatedByIp} setStatus={s.statusLastUpdatedByUser}/></td>
                                    <td><Moment format={t(moment(s.dateCreated).isSame(moment(res.reservationDate),'day')?"h:mma":"M/D h:mma")} date={s.dateCreated} /></td>
                                    <td>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(s.dateCreated)).format("h:*mm:ss")}</td>
                                  </tr>
                                :null}
                              </React.Fragment>
                            })
                          :null}
                        </tbody>
                      </table>
                    </Collapse>
                  </div>

                  {room.reservation.supplies.length?
                    <div className="collapse-container">
                      <div className={"collapse-header" + (isOpenSupplies?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenSupplies: !state.isOpenSupplies}))}>
                        <div className="title">
                          <div className="rightSmall">{t("Supplies")}</div>
                        </div>
                      </div>
                      <Collapse isOpen={isOpenSupplies}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Requested")}</th>
                              <th>{t("Wait Time")}</th>
                              <th>{t("Status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {room.reservation.supplies.map((supply, i) => {
                              let waitTime = moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds');
                              if ( supply.status==="complete" ) {
                                waitTime = supply.dateCompleted;
                              } else if ( supply.status==="canceled" ) {
                                waitTime = supply.dateCanceled;
                                supply.status = "Canceled"
                              }
                              return( 
                                <tr key={i}>
                                  <td>{t(supply.item)}</td>
                                  <td>{moment.duration(moment(waitTime).diff(supply.dateRequested)).format("h:*mm:ss")}</td>
                                  <td>
                                    {supply.status==="open" ? (
                                      <Button text="Mark Completed" type="secondary" onClick={() => this.handleSupplyRequestCompleted(supply)} />
                                    ) : (
                                      <div className="status-name">{t(supply.status)}</div>
                                    )}
                                  </td>
                                </tr>
                              )})}
                          </tbody>
                        </table> 
                      </Collapse>
                    </div>
                  :null}
                      
                  {res&&reservationDetails&&reservationDetails.statusHistory?
                    <ReservationTimeline reservation={res} reservationDetails={reservationDetails} />
                  :null}

                  {res.notes!==null||res.user.notes!==null||res.user.internalNote!==null?
                    <div className="user-note collapse-container">
                      <div className={"collapse-header" + (isOpenReservationNotes?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenReservationNotes: !state.isOpenReservationNotes}))}>
                        <div className="title">{t("Notes")}</div>
                      </div>
                      <Collapse className="collapse-body" isOpen={isOpenReservationNotes}>
                        <div className="p-3">
                          {res.notes!==null?
                            <div className="mb-2">
                              <div className="font-weight-bold">{t("Reservation Note")}:</div>
                              <div>{res.notes}</div>
                            </div>
                          :null}
                          {res.user.notes!==null?
                          <div className="mb-2">
                            <div className="font-weight-bold">{t("Patron Note")}:</div>
                            <div>{res.user.notes}</div>
                          </div>
                          :null}
                          {res.user.internalNote!==null?
                          <div className="mb-2">
                            <div className="font-weight-bold">{t("Internal Note")}:</div>
                            <div>{res.user.internalNote}</div>
                          </div>
                          :null}                        </div>
                      </Collapse>
                    </div>
                  :null}

                  {room.reservation.user.excludedAttendants.length>0?
                    <div className="user-note collapse-container">
                      <div className={"collapse-header" + (isOpenExcludedAttendants?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenExcludedAttendants: !state.isOpenExcludedAttendants}))}>
                        <div className="title">{t("Excluded Attendants")}</div>
                      </div>
                      <Collapse className="collapse-body" isOpen={isOpenExcludedAttendants}>
                        <div className="p-3">
                          {isLoadedAttendants
                          ? 
                          room.reservation.user.excludedAttendants.map((ea,i) => {
                            let att = attendants.find(att=>att.id===ea.attendantId);
                            return <div key={i}>{att.name}</div>
                          })
                          :null}
                        </div>
                      </Collapse>
                    </div>
                  :null}

                </>
              :
                <>
                  <Alert color="warning" className="text-center">{t("No associated reservation.")}</Alert>
                </>
              }
            </div>
            <div className="col-side">

              {res!=null?
                <div className="checkin-reservation">
                  <ul className="reservation-summary">
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconCalendar} />
                      </div>
                      <Moment format={t("dddd M/D")}>{res.reservationDate}</Moment>
                      <HebrewDate className="mis-1" date={res.reservationDate} day={true} month={true}/>
                    </li>
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconClock} />
                      </div>
                      {res.advanceReservation?
                        <>
                          <Moment format={t("h:mma")}>{res.reservationTime}</Moment>-
                          <Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}}>{res.reservationTime}</Moment>
                        </>
                      :
                        <>{t("Walk-in")}</>
                      }
                    </li>
                    {roomTypeDisplay?
                      <li>
                        <div className="icon">
                          <ReactSVG src={roomTypeDisplay.icon} />
                        </div>
                        <span>{t("Room Type")}: <span className="text-capitalize">{t(roomTypeDisplay.name)}</span></span>
                        <div className="price"><CurrencyFormat value={roomTypeDisplay.price} /></div>
                      </li>
                    :null}
                    {res.parking?
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconParking} />
                      </div>
                      {res.parking?t("Parking"):t("Walking")}
                      <div className="price"><CurrencyFormat value={settings?settings.priceParking.value:0} /></div>
                    </li>
                    :null}
                    {res.salonManicure&&res.salonPedicure?
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconSalon} />
                      </div>
                      {t("Mani+Pedi Polish")}
                      <div className="price"><CurrencyFormat value={settings?settings.priceSalonBoth.value:0} /></div>
                    </li>
                    :null}
                    {res.salonManicure&&!res.salonPedicure?
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconSalon} />
                      </div>
                      {t("Manicure Polish")}
                      <div className="price"><CurrencyFormat value={settings?settings.priceSalonManicure.value:0} /></div>
                    </li>
                    :null}
                    {res.doubleSlot?
                    <li>
                      <div className="icon">
                        <ReactSVG src={iconClockExtra} />
                      </div>
                      {t("Extra Time")}
                      <div className="price"><CurrencyFormat value={settings?settings.priceDoubleSlot.value:0} /></div>
                    </li>
                    :null}
                  </ul>

                  <hr />
                </div>
              :null}

              <div className="buttons">
                <div>
                  <Button type="circle" icon={<FontAwesomeIcon icon="tablet-alt" />} onClick={this.modalRoomScreenToggle} />
                  <div className="title">{t("View Room Screen")}</div>
                </div>
                {res && ['Prep', 'Assigned'].includes(res.reservationStatus) && groupedRooms?
                  <>
                  <div>
                    <Button type="circle" icon={<FontAwesomeIcon icon={"location-arrow"} />} onClick={this.modalPlaceUserToggle} />
                    <div className="title">{t("Place User in Room")}</div>
                  </div>                
                  <div>
                    <Button type="circle" icon={<FontAwesomeIcon icon={"exchange-alt"} />} onClick={this.modalSwapUserToggle} />
                    <div className="title">{t("Swap Users")}</div>
                  </div>
                  </>                
                  :                
                  <>                
                  <div>
                    <Button type="circle" icon={<FontAwesomeIcon icon={showPageReloaded?"check":"redo-alt"} />} loading={isReloadingRoomScreen} disabled={showPageReloaded} onClick={this.handleReloadRoomScreen} />
                    <div className="title">{t("Refresh Room Screen")}</div>
                  </div>
                  <div>
                    <Button type="circle" loading={isUnlockingDoor} disabled={showDoorUnlocked} onClick={this.handleUnlockWetDoor} icon={<FontAwesomeIcon icon={showDoorUnlocked?"check":"door-open"} />} />
                    <div className="title">{t("Unlock Wet Door")}</div>
                  </div>
                  </>
                }
                <div>
                  <Button type="circle" loading={isUpdatingDryDoor} icon={<FontAwesomeIcon icon="tablet-alt" />} onClick={() => this.handleDryDoor(room.dryDoorLocked?'opened':'closed')} />
                  <div className="title">{t(room.dryDoorLocked?"Set Dry Door Unlock":"Set Dry Door Locked")}</div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalStatus} toggle={this.modalStatusToggle} centered className={"lang-" + i18n.language} modalClassName="modal-status">
          <ModalHeader toggle={this.modalStatusToggle}>{t("Status Change")}</ModalHeader>
          <ModalBody>
            {waitingReservation?
              <>
                {waitingReservation.reservationStatus==='Assigned'?
                  <>
                    <ul>
                      <li>{t("Access Number")}: {waitingReservation.reservationCode}</li> 
                      <li>{t("Assigned Room")}: {waitingReservation.room.id}</li> 
                      <li>{t("Attendant")}: {waitingReservation.room.section.attendant.firstName} {waitingReservation.room.section.attendant.lastName}</li> 
                      <li>{t("Mikvah")}: {waitingReservation.room.section.name}</li> 
                    </ul>
                    <Button type="secondary" size="lg" onClick={this.handleReprint} loading={isLoading}>{t("Print Receipt")}</Button>
                  </>
                :null}
                {waitingReservation.reservationStatus==='Waiting'?
                  <>
                    <ul>
                      <li>{t("Access Number")}: {waitingReservation.reservationCode}</li>
                      {/* <li>Waiting Code: {waitingReservation.waitingCode}</li> */}
                    </ul>
                    <Button type="secondary" size="lg" onClick={this.handleReprint} loading={isLoading}>{t("Print Receipt")}</Button>
                  </>
                :null}
              </>
            :
              <>
                <h4>{t("What should we do with the current reservation?")}</h4>
                <div className="text-center">
                {reservationChangeStatuses.map((ss,si) => {
                  return <Button type="secondary btn-lg mb-3" key={si} onClick={() => this.handleChangeStatus(this.state.tempStatus,ss.status)}>{t(ss.title)}</Button>
                })}
              </div>
              </>
            }
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalRoomScreen} toggle={this.modalRoomScreenToggle} centered size="xl" modalClassName="modal-room-screen">
          <ModalHeader toggle={this.modalRoomScreenToggle}>{t("Room")} {room.id}</ModalHeader>
          <ModalBody>
            {this.state.modalRoomScreen?
              <iframe title="Room Details" src={'/room/'+room.id} />
            :null}
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalPlaceUser} toggle={this.modalPlaceUserToggle} centered className={"lang-" + i18n.language} modalClassName="">
          <div className="modal-header">
          {t("Place User Into Room")}
            <button type="button" onClick={this.modalPlaceUserToggle}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            {placeError?<Alert color="warning">{t(placeError)}</Alert>:null}
            {placeConfirmation?<Alert color="success">{t("placeConfirmationMessage", { resultId: placeConfirmation })}</Alert>:null}
            <div className="text-start">
            <UncontrolledDropdown>
              <DropdownToggle caret>{selectedPlaceRoom?selectedPlaceRoom.id + ' - ' + t(selectedPlaceRoom.status):t('Rooms')}</DropdownToggle>
              <DropdownMenu>
                {groupedRooms && groupedRooms['Ready']?groupedRooms['Ready'].map((room,i)=>
                  room.section.attendant!==null && res && room.section.id===res.room.section.id?
                  <DropdownItem onClick={()=>this.setState({selectedPlaceRoom: {id: room.id, status: room.statusName}})} disabled={room.section.attendant==null} key={i}>{room.id}</DropdownItem>
                  :null
                ):null}
                <DropdownItem divider />
                {groupedRooms && groupedRooms['Ready']?groupedRooms['Ready'].map((room,i)=>
                  room.section.attendant!==null && res && room.section.id!==res.room.section.id?
                  <DropdownItem onClick={()=>this.setState({selectedPlaceRoom: {id: room.id, status: room.statusName}})} disabled={room.section.attendant==null} key={i}>{room.id}</DropdownItem>
                  :null
                ):null}
                <DropdownItem divider />
                {groupedRooms && groupedRooms['Offline']?groupedRooms['Offline'].map((room,i)=>
                  room.section.attendant!==null?
                  <DropdownItem onClick={()=>this.setState({selectedPlaceRoom: {id: room.id, status: room.statusName}})} disabled={room.section.attendant==null} key={i}>{room.id} - {t("Offline")}</DropdownItem>
                  :null
                ):null}
                </DropdownMenu>
              </UncontrolledDropdown>
            {selectedPlaceRoom?
            <UncontrolledDropdown className="mt-3">
              <DropdownToggle caret>{selectedNewRoomStatus?t(statuses[selectedNewRoomStatus].name):t('Status of Current Room')}</DropdownToggle>
                <DropdownMenu>
                {roomStatuses.map((s,i) => {
                  let status = statuses[s];
                  return <DropdownItem key={i} onClick={() => this.setState({selectedNewRoomStatus: s})}>
                      <div>{t(status.name)}</div>
                  </DropdownItem>
                })}
                </DropdownMenu>
              </UncontrolledDropdown>
              :null}
              <div className="d-flex">
                <Button className="btn btn-secondary mis-auto" disabled={selectedPlaceRoom&&selectedNewRoomStatus?false:true} loading={isPlacingRoom} onClick={() => this.placeInRoom(selectedPlaceRoom.id)}>{t("Confirm")}</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalSwapUser} toggle={this.modalSwapUserToggle} centered className={"lang-" + i18n.language} modalClassName="">
          <div className="modal-header">
            {t("Swap Users in Rooms")}
            <button type="button" onClick={this.modalSwapUserToggle}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            {swapError?<Alert color="warning">{t(swapError)}</Alert>:null}
            {swapConfirmation?<Alert color="success">{t("swapConfirmationMessage", { room1: swapConfirmation['room1'], room2: swapConfirmation['room2']})}</Alert>:null}
            <div className="text-start">
            <UncontrolledDropdown>
              <DropdownToggle caret>{selectedSwapRoom?selectedSwapRoom.id + ' - ' + t(selectedSwapRoom.status):t('Rooms')}</DropdownToggle>
              <DropdownMenu>
                {groupedRooms && groupedRooms['Prep'] ?groupedRooms['Prep'].map((r,i)=>
                  <DropdownItem onClick={()=>this.setState({selectedSwapRoom: {id: r.id, status: r.statusName}})} disabled={r.id===room.id||room.section.attendant==null} key={i}>{r.id} - {t(r.effectiveStatus)}</DropdownItem>
                ):null}
                {groupedRooms && groupedRooms['Assigned'] ?groupedRooms['Assigned'].map((r,i)=>
                  <DropdownItem onClick={()=>this.setState({selectedSwapRoom: {id: r.id, status: r.statusName}})} disabled={r.id===room.id||room.section.attendant==null} key={i}>{r.id} - {t(r.effectiveStatus)}</DropdownItem>
                ):null}
                </DropdownMenu>
              </UncontrolledDropdown>
              <div className="d-flex">
                <Button className="btn btn-secondary mis-auto" disabled={selectedSwapRoom?false:true} loading={isSwappingRoom} onClick={() => this.swapRoom(selectedSwapRoom.id)}>{t("Confirm")}</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    } else {
      return null;
    }
  }
}

export default withTranslation()(RoomDetails);
