import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';

class ScreenPrep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
      modalSupplyPrompt: false,
      isSubmittingSupplyRequest: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }


  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "AttendantLeavingRoom";
    this.setState({
      isUpdatingStatus: true,
    })
    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
          this.setState({isUpdatingStatus: false})
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        });
  }

  render() {
    const { t } = this.props;
    const { error } = this.state;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
        : null}
        <Button type="circle-big" onClick={this.handleChangeStatus} loading={this.state.isUpdatingStatus} text={t("To Mikvah")} />
      </div>
    );
  }
}

export default withTranslation()(ScreenPrep);
