import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import Flipper from './../components/flipper';
import Button from './../components/button';
import i18n from './../i18n';

class SectionMapAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hiddenPressTimeout: 1000,
      adminCorrectCode: this.props.adminCorrectCode ? this.props.adminCorrectCode : '',
      adminCode: '',
      isAuthenticated: false,
      isOpenAdvanced: false,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isActivated&&this.state.isActivated) {
      this.setState({
        isAuthenticated: false,
        isOpenAdvanced: false,
        adminCode: '',
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.touchTimeout);
  }

  handleTouchStart = () => {
    this.touchTimeout = setTimeout(()=>this.setState({isActivated: true}), this.state.hiddenPressTimeout);    
  }

  handleTouchEnd = () => {
    clearTimeout(this.touchTimeout);
  }

  toggle = () => {
    this.setState({
      isActivated: !this.state.isActivated,
    })
  }

  toggleAdvanced = () => {
    this.setState({
      isOpenAdvanced: !this.state.isOpenAdvanced,
    })
  }

  handlePress = (key) => {
    let input = this.state.adminCode;
    if ( key === 'del' && input.length!==0 ) { input = input.slice(0, -1); }
    if ( typeof key === 'number' && (input.length<4) ) { input = input + key; }
    this.setState({
      adminCode: input,
    })
    if ( /*key === 'enter' &&*/ input.length>=4 ) {
      if ( input===this.state.adminCorrectCode ) {
        setTimeout(()=>{
          this.setState({isAuthenticated: true});
        },100);
      } else {
        this.setState({adminCode: ''});
      }
    }
  }
    
  modalStatusToggle = () => {
    this.setState({
      modalStatus: !this.state.modalStatus,
    });
  }

  handleClearMikvah = () => {
    localStorage.removeItem('selectedSectionMap');
  }


  render() {
    const { isActivated, isAuthenticated, adminCode, isUpdatingStatus, isOpenAdvanced } = this.state;
    const { t, beforeCheckinAllowed } = this.props;
    return <div className="mikvah-admin" onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}>
      <Modal className={"modal-mikvah-admin lang-" + i18n.language} isOpen={isActivated} centered toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{t("Admin Menu")}</ModalHeader>
        <ModalBody>
          <div>
            {isAuthenticated?(
              <div className="text-center">
                  <ul className="list-unstyled pis-0">
                    <li>
                      <Button type="secondary btn-lg mb-3" onClick={()=>window.location.reload(true)}>{t("Refresh Screen")}</Button>
                    </li>
                    <li>
                      <Link to="/attendant/sectionmap/" onClick={this.handleClearMikvah}><Button type="secondary btn-lg mb-3">{t("Clear Section Map")}</Button></Link>
                    </li>                                                      
                  </ul>
              </div>
            ):(
              <>
                <div className="wrapper-number">
                  <div className={"flippers justify-content-center mb-4"}>
                    {[0,0,0,0].map((v,i) => {
                      return <Flipper type="single" key={i} text={adminCode[i]?'•':''} />
                    })}
                  </div>
                </div>
                <NumericKeyboard layout="tel" onPress={this.handlePress} />
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>;
  }
}

export default withTranslation()(SectionMapAdmin);