import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from './button';
import CreditCardInput from 'react-credit-card-input';

class ICountCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationCode: "",
      isSubmitting: false,
      error: null,
      confirmed: false
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleIsLoading(true);
    let ccExpiration = this.state.ccExp.split("/");
    let paymentInfo = {
      cardNumber: this.state.cardNumber,
      ccExpMonth: ccExpiration[0].trim(),
      ccExpYear: "20" + ccExpiration[1].trim(),
      cvv: this.state.cvv,
      cardHolderId: this.state.cardHolderId
    }
    this.props.onSubmit(paymentInfo)
  }

  handleChange(e, name) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({[name]: value});
  }

  render() {
    const { t, isLoading } = this.props;
    const { cardNumber, cvv, cardHolderId, ccExp } = this.state;
    return <form onSubmit={this.handleSubmit} autoComplete="off">
      <div className="form-row dir-ltr">
        <CreditCardInput
        cardNumberInputProps={{ value: cardNumber, onChange: (e) => this.handleChange(e, 'cardNumber') }}
        cardExpiryInputProps={{ value: ccExp, onChange: (e) => this.handleChange(e, 'ccExp') }}
        cardCVCInputProps={{ value: cvv, onChange: (e) => this.handleChange(e, 'cvv') }}
        containerClassName="mb-3 col"
        fieldClassName= "form-control cc-input"
        customTextLabels={{
          invalidCardNumber: t('Invalid Card Number'),
          expiryError: {
            invalidExpiryDate: t('Invalid Expiration Date'),
            monthOutOfRange: t('Invalid Expiration Date'),
            yearOutOfRange: t('Invalid Expiration Date'),
            dateOutOfRange: t('Invalid Expiration Date')
          },
          invalidCvc: t('Invalid CVV'),
          cardNumberPlaceholder: t('Card Number'),
          expiryPlaceholder: t('MM/YY'),
          cvcPlaceholder: t('CVV'),
        }}
        />
        </div>
        <div className="form-row">
        <div className="form-group col">
          <label htmlFor="cardHolderId">{t("Cardholder Id")}</label>
          <input type="text" id="cardHolderId" className="form-control chid dir-ltr" onChange={(e) => this.handleChange(e, "cardHolderId")} value={cardHolderId} name="cardHolderId"/>
        </div>
      </div>
      <div className="d-flex">
        <Button type="secondary mx-auto btn-lg" disabled={this.props.disabled} loading={isLoading}>{this.props.paymentButtonText?t(this.props.paymentButtonText):t('Charge Card')}</Button>
      </div>
    </form>
  }
}
export default withTranslation()(ICountCardForm);