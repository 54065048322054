import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert, UncontrolledDropdown } from 'reactstrap';
import ReceptionistTabs from './partials/tabs';
import Loader from './../../components/snippets/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserByPhone, getUser } from "./../../models/user";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { paymentItems, cardBrands, tipItems } from './../../global-props';
import { createPayment, getSavedCards } from "./../../models/payment";
import StripeProvider from './../../components/stripeProvider';
import StripeTerminal from './../../components/stripeTerminal';
import StripeCardForm from './../../components/stripeCardForm';
import ICountCardForm from './../../components/iCountCardForm';
import { SettingsContext } from "./../../components/settings";
import CurrencyFormat from './../../components/snippets/currency-format';
import Button from './../../components/button';
import NumberFormat from "react-number-format";
import { Link } from 'react-router-dom';
import i18n from './../../i18n';

class ReceptionistPaymentView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userResults: null,
      isUserResultsOpen: false,
      enteredPhone: '',
      selectedPaymentItem: null,
      otherPaymentItem: '',
      paymentAmount: '',
      creditMode: null,
      selectedUser: null,
      savedCards: null,
      isLoadingSavedCards: false,
      paymentReference: '',
      activeUserResult: 0,
      paymentType: '',
      selectedTipItem: '',
    };

    this.toggleUserResults = this.toggleUserResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleCashCheckReceived = this.handleCashCheckReceived.bind(this);
    this.handleGetSavedCards = this.handleGetSavedCards.bind(this);
    this.handleChargeNewCard = this.handleChargeNewCard.bind(this);
    this.handleChargeSavedCard = this.handleChargeSavedCard.bind(this);
    this.handleCapturePaymentIntent = this.handleCapturePaymentIntent.bind(this);
    this.setupLineItems = this.setupLineItems.bind(this);
    this.handleChargeTerminalICount = this.handleChargeTerminalICount.bind(this);
  }

  componentDidMount() {
    if (this.props.userId) {
      this.retrieveUserById(this.props.userId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props ) {
      this.setState({selectedUser:null, enteredPhone: '', savedCards: null})
    }
    if ( this.state.paymentView === "credit" ) {
      if ( prevState.savedCards === null && !prevState.isLoadingSavedCards && (this.state.selectedUser && this.state.selectedUser.id !== 0) ) {
        this.handleGetSavedCards();
      }
    }
  }

  setupLineItems() {
    let lineItems = {
      type: 'cart',
      cart: {
        line_items: [
          {
            description: this.state.selectedPaymentItem,
            quantity: 0,
            amount: parseFloat(this.state.paymentAmount)*100,
          },
        ],
        total: Math.round(this.state.paymentAmount * 100),
        tax: null,
        currency: 'usd',
      }        
    }
    this.setState({lineItems});
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({[name]: value});
  }

  handleChangePhone(e) {
    let numberLength = 15;
    let value = e.target.value;
    value = value.slice(0,numberLength);

    this.setState({ enteredPhone: value, userResults: null, error: null })
    if ( value.length >= 4) {
      getUserByPhone(value)
      .then(
        result => {
          this.setState({
            userResults: result,
            isUserResultsOpen: true,
          })
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
      
    }
  }

  retrieveUserById(userId) {
    getUser(userId)
      .then(result=>this.handleSelectUser(result));
  }

  handleSelectUser(user) {
    if (user) {
      this.setState({ enteredPhone: user.phoneNumber, selectedUser: user });
    }
  }

  toggleUserResults() {
    this.setState({isUserResultsOpen: !this.state.isUserResultsOpen});
  }

  handleGetSavedCards() {
    if (this.state.selectedUser) {
      this.setState({isLoadingSavedCards:true});
      getSavedCards(this.state.selectedUser.id)
        .then(result => {
          this.setState({
            savedCards: result,
            isLoadingSavedCards: false,
          })
        }, error => this.setState({error}))
    }
  }

  handleCreatePayment(payment) {
    payment.userId = this.state.selectedUser.id!==0 ? this.state.selectedUser.id : null;
    this.setState({
      isLoading: true,
      error: null,
    })
    createPayment(payment)
      .then(() => {
        if(this.state.selectedPaymentItem==='Tip'){
          let paymentTip = {
            method: 'tip',
            amt: 0 - parseFloat(this.state.paymentAmount),
            reference: 'Tip',
            tipNote: this.state.selectedTipItem
          }
          createPayment(paymentTip)
          .then(() => {
            this.setState({
              paymentView: 'paid',
              isLoading: false,
            })
          })        
        } else{
          this.setState({
            paymentView: 'paid',
            isLoading: false,
          })
        }
      }, error => {
        this.setState({error: error.stripeErrorMessage?error.stripeErrorMessage:error.errorMessage, isLoading: false});      
      })
  }

  handleCashCheckReceived(e) {
    e.preventDefault();
    let selectedPaymentItem = this.state.selectedPaymentItem;
    let otherPaymentItem = this.state.otherPaymentItem;
    let reference = this.state.paymentReference;
    let payment = {
      method: reference!=="" ? 'check' : 'cash',
      amt: this.state.paymentAmount,
      reference: reference!=="" ? reference : null,
      note: selectedPaymentItem != "Other"? selectedPaymentItem : otherPaymentItem,
      tipNote: selectedPaymentItem==='Tip'?this.state.selectedTipItem:null
    }
    this.setState({ paymentType: reference!=="" ? 'Check' : 'Cash' })
    this.handleCreatePayment(payment);
  }

  handleChargeNewCard(payload) {
    let selectedPaymentItem = this.state.selectedPaymentItem;
    let otherPaymentItem = this.state.otherPaymentItem;
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentInfo: {sourceToken: payload.token.id},
      saveCard: true,
      note: selectedPaymentItem != "Other"? selectedPaymentItem : otherPaymentItem,
      tipNote: selectedPaymentItem==='Tip'?this.state.selectedTipItem:null
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'new', paymentType: payload && payload.token && payload.token.card && payload.token.card.last4 ? `Card ending in ${payload.token.card.last4}`: null});
  }

  handleChargeNewCardICount(paymentInfo) {
    let selectedPaymentItem = this.state.selectedPaymentItem;
    let otherPaymentItem = this.state.otherPaymentItem;
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentInfo: paymentInfo,
      saveCard: true,
      note: selectedPaymentItem != "Other"? selectedPaymentItem : otherPaymentItem,
      tipNote: selectedPaymentItem==='Tip'?this.state.selectedTipItem:null
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'new', paymentType: paymentInfo ? `Card ending in ${paymentInfo.cardNumber.slice(paymentInfo.cardNumber.length - 4)}`: null});
  }

  handleChargeTerminalICount() {
    let selectedPaymentItem = this.state.selectedPaymentItem;
    let otherPaymentItem = this.state.otherPaymentItem;
    const { selCardReader } = this.context;
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentInfo: {cardReaderId: selCardReader},
      note: selectedPaymentItem != "Other"? selectedPaymentItem : otherPaymentItem,
      tipNote: selectedPaymentItem==='Tip'?this.state.selectedTipItem:null
    }
    this.handleCreatePayment(payment);
  }

  handleChargeSavedCard(card) {
    let selectedPaymentItem = this.state.selectedPaymentItem;
    let otherPaymentItem = this.state.otherPaymentItem;
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentInfo: {sourceCard: card.id, cvv:this.state["CVV"+card.last4]},
      note: selectedPaymentItem != "Other"? selectedPaymentItem : otherPaymentItem,
      tipNote: selectedPaymentItem==='Tip'?this.state.selectedTipItem:null
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'saved', paymentType: `Card ending in ${card.last4}`});
  }

  handleCapturePaymentIntent(paymentIntent) {
    let payment = {
      method: 'creditcard',
      amt: this.state.paymentAmount,
      paymentInfo: {paymentIntent: paymentIntent.id},
      saveCard: true,
    }
    this.handleCreatePayment(payment);
    this.setState({creditMode: 'terminal'});
  }

  handleKeyDown = e => {
    const { activeUserResult, userResults } = this.state;

    if (e.keyCode===13) {
      this.handleSelectUser(userResults[activeUserResult]);
      this.setState({
        activeUserResult: 0,
        isUserResultsOpen: false,      
      })
    } else if (e.keyCode===38) {
      if (activeUserResult===0) return;
      this.setState({activeUserResult: activeUserResult - 1});
    } else if (e.keyCode===40) {
      if (activeUserResult-1===userResults.length) return;
      this.setState({activeUserResult: activeUserResult + 1});
    }
  }

  startOver = () => {
    this.setState({
      selectedUser:null,
      paymentAmount: null,
      paymentView: null,
      enteredPhone: null
    });
  }

  paymentView() {
    const { t } =  this.props;
    const { paymentView, savedCards, paymentAmount, paymentReference, isLoading, creditMode, lineItems, isLoadingSavedCards, selectedUser, selectedPaymentItem, selectedTipItem, otherPaymentItem, paymentType } = this.state;
    const {selCardReader, settings } = this.context;
    let terminalId = selCardReader?selCardReader:null;
    switch(paymentView) {
      case 'paid':
        return <div style={{maxWidth: "360px", margin: "0 auto"}}>
          <div className="d-flex justify-content-between"><h5>{t("Amount")}:</h5> <h5><b><CurrencyFormat value={paymentAmount} /></b></h5></div>
          {paymentType ? <div className="d-flex justify-content-between"><h5 className="">{t("Paid With")}:</h5> <h5><b>{paymentType}</b></h5></div> : null}
          {selectedPaymentItem ? <div className="d-flex justify-content-between"><h5 className="">{t("For")}:</h5> <h5><b>{selectedPaymentItem != "Other"? selectedPaymentItem==='Tip'?t(selectedPaymentItem) + ' - ' + t(selectedTipItem):t(selectedPaymentItem) : t(otherPaymentItem)}</b></h5></div> : null}
          <div className="mt-3"><Link to="/receptionist">{t("Close")}</Link></div>
        </div>

      case 'cash':
        return <div className="payment-view payment-view-cash">
          <form autoComplete="off" onSubmit={this.handleCashCheckReceived}>
            {paymentAmount!==''?
              <>
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="paymentReference">{t("Check Number")} <span className="small text-muted d-block">({t("Optional")})</span></label>
                    <input type="text" className="form-control form-control-lg" name="paymentReference" value={paymentReference} onChange={this.handleChange} />
                  </div>
                </div>
                <div>
                  <Button type="secondary" size="lg" loading={isLoading}>{paymentReference?t("Accept Check"):t("Accept Cash")}</Button>
                </div>
              </>
            :
              <Alert color="warning">{t("Please enter an amount.")}</Alert>
            }
            <div className="text-end">
              <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
            </div>
          </form>
        </div>

      case 'credit':
        return <div className="payment-view payment-view-credit">

          {paymentAmount!==''?
            <>
              {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe') && (creditMode===null||creditMode==='terminal')&&terminalId!==null?
                <StripeProvider>
                  <StripeTerminal 
                    paymentAmount={paymentAmount}
                    userId={selectedUser.id!==0?selectedUser.id:null}
                    isLoading={isLoading}
                    lineItems={lineItems}
                    readerId={terminalId}
                    onReadyForCapture={(paymentIntent) => this.handleCapturePaymentIntent(paymentIntent)}
                    handleUpdateCreditMode={(mode) => this.setState({creditMode: mode, error: null})}
                    handleErrorMessage={(error) => this.setState({error, creditMode: null})}
                    discoverError={(error) => this.setState({error, creditMode: null})}
                  />
                </StripeProvider>
              :null}

              {(settings.ccProcessorType && settings.ccProcessorType.value==='icount') && (creditMode===null||creditMode==='terminal')&&selCardReader!==null?
                <Button type="secondary" size="sm" className="mb-2" loading={isLoading} onClick={this.handleChargeTerminalICount}>{t("Use Card Reader")}</Button>
              :null}
              
              {(creditMode===null||creditMode==='saved') && selectedUser.id !== 0 ?
                <>
                  <div className="saved-cards">
                    <div className="header">
                      <div className="title mb-3">
                      {t("Charge Saved Payment Method")}:
                      </div>
                      
                      {!isLoading?
                        <button className="btn btn-link mb-3" onClick={() => this.setState({creditMode: 'new'})}>+ {t("Add Card")}</button>
                      :null}
                    </div>
                    
                    {isLoading?
                      <Loader />
                    :
                      (isLoadingSavedCards?
                        <Loader />
                      :
                        (savedCards && savedCards.length?
                          <div className="saved-cards-buttons">
                            {(settings.ccProcessorType && settings.ccProcessorType.value==='icount')?
                              savedCards.map((card,i) => <div className="mb-3 d-flex justify-content-center" key={i}>
                              <div className="d-flex align-items-center btn-saved-card col-4 justify-content-end">
                                {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon icon={cardBrands[card.brand.toUpperCase()].icon} />:card.brand.toUpperCase()}
                                <div>•••• <span className="last4">{card.last4}</span></div>
                              </div>
                              <InputGroup className="col-5">
                                <Input maxLength="6" placeholder="CVV" name={"CVV"+card.last4} onChange={this.handleChange} value={this.state["CVV"+card.last4]} />
                                <InputGroupAddon addonType="append">
                                  <Button type="secondary" disabled={!paymentAmount || !this.state["CVV"+card.last4] || (this.state["CVV"+card.last4] && this.state["CVV"+card.last4].length<3)} onClick={() => this.handleChargeSavedCard(card)} loading={isLoading}>
                                    <div className="amount">{t("Charge")} <CurrencyFormat value={paymentAmount} /></div>
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                              </div>
                          ):null}
                          {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?
                          savedCards.map((card,i) => <div className="mb-3" key={i}><Button type="secondary btn-saved-card" size="lg" disabled={!paymentAmount} onClick={() => this.handleChargeSavedCard(card)} loading={isLoading}>
                            {cardBrands[card.brand.toUpperCase()]?<FontAwesomeIcon icon={cardBrands[card.brand.toUpperCase()].icon} />:card.brand.toUpperCase()}
                            <div>•••• <span className="last4">{card.last4}</span></div>
                            <div className="amount">{t("Charge")} <CurrencyFormat value={paymentAmount} /></div>
                          </Button></div>)
                          :null}
                          </div>
                        :
                          <div className="text-center text-muted">{t("No saved cards")}</div>
                        )
                      )
                    }
                  </div>
                </>
              :null}
              
              {(creditMode===null&&selectedUser.id == 0) ||creditMode==='new'?
                <div className="new-card">
                  <div className="title mb-3">
                  {t("Enter a new card")}:
                  </div>
                  {(settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?
                  <StripeProvider>
                    <StripeCardForm
                      onGetToken={(payload) => this.handleChargeNewCard(payload)}
                      isLoading={isLoading}
                      handleIsLoading={(isLoading) => this.setState({isLoading: isLoading})}
                      paymentAmount={paymentAmount}
                      handleUpdateCreditMode={(mode) => this.setState({creditMode: mode})}
                    />
                  </StripeProvider>
                  :null}
                  {(settings.ccProcessorType && settings.ccProcessorType.value==='icount')?
                    <ICountCardForm
                      onSubmit={(paymentInfo) => this.handleChargeNewCardICount(paymentInfo)}
                      isLoading={isLoading}
                      handleIsLoading={(isLoading) => this.setState({isLoading: isLoading})}
                    />
                  :null}
                  {creditMode!==null?
                  <div className="text-end">
                    <Button type="link btn-cancel" onClick={() => this.setState({creditMode: null})}>{t("Cancel")}</Button>
                  </div>
                  :null}
                </div>
              :null}
            </>          
          :
            <Alert color="warning">{t("Please enter an amount.")}</Alert>
          }          

          {creditMode===null?
            <div className="text-end">
              <Button type="link btn-cancel" onClick={() => this.setState({paymentView: null})}>{t("Cancel")}</Button>
            </div>
          :null}
        </div>

      case 'balance':
      default:
        return <div>
          <div className="mt-4">
            <div className="title mb-3">
            {t("Choose a payment method")}:
            </div>
            <Button type="secondary mx-1" size="lg" onClick={() => this.setState({paymentView: 'cash'})}>{t("Cash/Check")}</Button>
            <Button type="secondary mx-1" size="lg" onClick={() => this.setState({paymentView: 'credit'})}>{t("Credit/Debit")}</Button>
          </div>
        </div>
    }
  }


  render() {
    const { t } = this.props;
    const { error, enteredPhone, userResults, isUserResultsOpen, selectedUser, selectedTipItem, selectedPaymentItem, otherPaymentItem, paymentAmount, paymentView, activeUserResult } = this.state;
    const currentPageName = "Payment"
    return (
      <div className={'container-page view-payment lang-' + i18n.language}>
        <div className="container-main">
          <div className="container-body reception-background">
            <ReceptionistTabs page={currentPageName}/>
            <div className="container-checkin">
              <div className="modal-header">
                <div className="header-title"><h4>{t("Payment")}</h4></div>
              </div>

              {error!==null?
                <div className="checkin-message">
                  <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                </div>
              :null}

              <div className="modal-body">
                <div className="mx-auto w-100 text-center">
                  {!selectedUser?
                    <>
                      <div className="checkin-numbers w-75 mx-auto mb-4">
                        <div className="title mb-3">{t("Enter the last 4 digits of guest's phone number")}</div>
                        <form autoComplete="off" onSubmit={(e)=>e.preventDefault()}>
                          <Dropdown inNavbar isOpen={isUserResultsOpen} toggle={this.toggleUserResults}>
                            <NumberFormat
                              className="form-control number-input"
                              type="tel"
                              name="phoneNumber"
                              decimalScale={0}
                              allowNegative={false}
                              placeholder="0000"
                              value={enteredPhone}
                              onChange={this.handleChangePhone}
                              onClick={()=>this.setState({isUserResultsOpen:true})}
                              onKeyDown={this.handleKeyDown}
                              autoFocus={true}
                            />
                            <DropdownToggle tag="span"></DropdownToggle>
                            {userResults&&userResults.length?
                              <DropdownMenu>
                                {userResults.map((s,i)=>
                                  <DropdownItem key={i} active={i===activeUserResult} className="d-flex justify-content-between" onClick={()=>this.handleSelectUser(s)}>
                                    <div>{t("User ID")}: {s.id}</div>
                                    <div>{s.phoneNumber}</div>
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            :null}              
                          </Dropdown>
                        </form>
                      </div>

                      <Button className="btn btn-link small text-user btn-sm" onClick={()=>this.setState({selectedUser:{id:0}})}>{t("Continue without a user")}</Button>
                    </>
                  :
                    <>

                      <div className="mb-4">
                        {paymentView == 'paid' ? <h2 className="">{t("Payment Received")}</h2>: null}
                        {selectedUser.id !== 0 ?<span><b>{t("User ID")}:</b> {selectedUser.id}</span>: null}
                        {selectedUser.phoneNumber?<span className="mis-4"><b>{t("Phone")}:</b> {selectedUser.phoneNumber}</span>:null}
                      </div>
                      {paymentView!=='paid'?
                        <div className="payment-view">
                          <div className="form-row">
                            <div className="form-group col">
                              <label htmlFor="paymentAmount">{t("Amount")}</label>
                              <CurrencyFormat type="text" className="form-control dir-ltr" id="paymentAmount" name="paymentAmount" value={paymentAmount} onValueChange={(values) => this.setState({paymentAmount: values.value}, this.setupLineItems)} autoComplete="off" />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col">
                              <label className="mie-2">{t("Type")}</label>
                              <UncontrolledDropdown>
                                <DropdownToggle caret>{selectedPaymentItem?t(selectedPaymentItem):t('Select')}</DropdownToggle>
                                {paymentItems?
                                  <DropdownMenu>
                                    {paymentItems.map((s,i)=>
                                      <DropdownItem key={i} onClick={()=>this.setState({selectedPaymentItem: s}, this.setupLineItems)}>{t(s)}</DropdownItem>
                                    )}
                                  </DropdownMenu>
                                :null}              
                              </UncontrolledDropdown>
                            </div>
                          </div>
                          {selectedPaymentItem==='Tip'?
                            <div className="form-row">
                              <div className="form-group col">
                                <label className="mie-2">{t("Tip For")}</label>
                                <UncontrolledDropdown>
                                  <DropdownToggle caret>{selectedTipItem?t(selectedTipItem):t('Select')}</DropdownToggle>
                                  {tipItems?
                                    <DropdownMenu>
                                      {tipItems.map((s,i)=>
                                        <DropdownItem key={i} onClick={()=>this.setState({selectedTipItem: s})}>{t(s)}</DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  :null}              
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          :null}
                          {selectedPaymentItem==='Other'?
                            <div className="form-row">
                              <div className="form-group col">
                                <label>{t("Other")}</label>
                                <input className="form-control" value={otherPaymentItem} onChange={(e)=>this.setState({otherPaymentItem: e.target.value})} />
                              </div>
                            </div>
                          :null}
                        </div>
                      :null}

                      {this.paymentView()}

                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ReceptionistPaymentView);
