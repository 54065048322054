import React, { Component } from 'react';

import './App.scss';

import AppRouter from './router.js';
import CacheBuster from './components/cachebuster';
import ErrorBoundary from './ErrorBoundary';

import './fontAwesome';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  render() {
    return (
      <ErrorBoundary><CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return <>
            <AppRouter />
            <div className="version">v{global.appVersion}</div>
          </>;
        }}
      </CacheBuster></ErrorBoundary>
    );
  }
}

export default App;
