import React from 'react';
import { withTranslation } from 'react-i18next';
import Hebcal from 'hebcal';
import moment from 'moment';

function HebrewDate(props) {
    const { t, className, date, day, month, year, monthNext } = props;
    let d;
    let m;
    let y;
    let endMonth;
    let endMonthHdate;
    let endMonthName;
    let showNM;
    let gyear = moment(date).format('YYYY');
    let gmonth = moment(date).format('M');
    let gday = moment(date).format('D');
    const hdate = new Hebcal.HDate(new Date(gyear,gmonth-1,gday));
    if(day){
      d = Hebcal.gematriya(hdate.getDate());
    }
    if(year){
      y = Hebcal.gematriya(hdate.getFullYear(),3);
    }
    if(month||monthNext){
      m = hdate.getMonthName('h');
    }
    if(monthNext){
      endMonth = moment(gmonth).endOf('month').format('D');    
      endMonthHdate = new Hebcal.HDate(new Date(gyear,gmonth-1,endMonth));
      endMonthName = endMonthHdate.getMonthName('h');
      showNM = endMonthName !== m;
    }
    return (
      <span className={"d-inline-block " + (className||"")}>
        {day?d:null} {month?m:null}{monthNext && showNM?"-"+endMonthName:null} {year?y:null}
      </span>
    );
}

export default withTranslation()(HebrewDate);