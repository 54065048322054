import React, { Component } from 'react';

import Clock from './../../components/clock';
import ZmanTevilah from './../../components/zman-tevilah';
import RoomTiles from './../receptionist/RoomTiles';
import SignalR from './../../components/signalr';
import LanguageSelector from './../../components/language-selector';
import { getRooms } from './../../models/room';
import { getSections } from './../../models/section';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import moment from 'moment';
import i18n from './../../i18n';
import { SettingsContext } from './../../components/settings';

class AttendantMapView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoadedRooms: false,
      modal: false,
      selectedRoom: null,
      error: null,
    };
     
    this.notificationSound = new Audio(NotificationSoundFile);
    this.playNotificationSound = this.playNotificationSound.bind(this);
  }

  componentDidMount() {
    this.retrieveRooms();
    this.retrieveSections();
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(() => this.setState({time: moment().format()}), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.setState({
            isLoadedRooms: true,
            rooms: rooms,
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  handleRoomUpdated(room) {
    const { rooms, selectedRoom } = this.state;
    rooms[selectedRoom] = room;
    this.setState({rooms});
  }

  retrieveSections() {
    getSections()
      .then(
        result => {
          this.setState({
            isLoadedSections: true,
            sections: result,
          });
        },
        error => {
          this.setState({
            isLoadedSections: false,
            error
          });
        }
      );
  }

  playNotificationSound(isReadyMikvah) {
    const {settings} = this.context;
    if((settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True")&&isReadyMikvah){
      return false;
    } else{
      this.notificationSound.play();
    }
  }

  render() {
    const { rooms, isLoadedRooms, error, isLoadedSections, sections } = this.state;
    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} onSectionsUpdated={() => this.retrieveSections()} onNewSupplyRequest={() => this.playNotificationSound()} onNewReadyForMikvah={() => this.playNotificationSound(true)} onNewReadyForChecking={() => this.playNotificationSound()}/>
        <div className={'container-page view-map view-attendant-map lang-' + i18n.language}>
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-left">
                  <ZmanTevilah />
                </div>
                <div className="header-right">
                  <Clock />
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <LanguageSelector />
            <div className="container-body">
              <SettingsContext.Consumer>
                {({settings}) =>
                  <RoomTiles rooms={rooms} isLoadedRooms={isLoadedRooms} error={error} isLoadedSections={isLoadedSections} sections={sections} handleRoomUpdated={(r) => this.handleRoomUpdated(r)} time={this.state.time} settings={settings} />
                }
              </SettingsContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AttendantMapView;
