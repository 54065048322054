import React from 'react';
import { withTranslation } from 'react-i18next';

function RoomAttendant(props) {
  const { t, attendant } = props;
  return <div className="room-attendant">
    <div className="title">{t('Attendant')}</div>
    {attendant?
      <span>{props.attendant.firstName} {props.attendant.lastName} </span>
    :
      <span className="no-attendant text-danger">No Attendant</span>
    }
    
  </div>
}

export default withTranslation()(RoomAttendant);