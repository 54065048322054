import { getUserByPhone as GetUserByPhone, 
  getAdminUsers as GetAdminUsers, 
  getUsersByUsername as GetUsersByUsername, 
  getUser as GetUser, 
  changeUserRole as ChangeUserRole, 
  changeExcludedAttendants as ChangeExcludedAttendants, 
  patchUser as PatchUser, 
  newUserInfo as NewUserInfo, 
  setLicenseRegistration as SetLicenseRegistration 
} from './../api';

function processUser(user) {
  return user;
}

export function getUserByPhone(phone, isKiosk) {
  return new Promise((resolve, reject) => {
    GetUserByPhone(phone, isKiosk)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processUser(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

/*export function getUser(id) {
  return new Promise((resolve, reject) => {
    GetUser(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let user = processUser(result);
            return resolve(user);
          }
          return reject(error);
        },
        error => reject(error)
      );
  })
}*/

export function getAdminUsers() {
  return new Promise((resolve, reject) => {
    GetAdminUsers()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let users = result.map((user,i) => {
              return processUser(user);
            })
            return resolve(users);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getUsers(username) {
  return new Promise((resolve, reject) => {
    GetUsersByUsername(username)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let users = result.map((user,i) => {
              return processUser(user);
            })
            return resolve(users);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getUser(userId) {
  return new Promise((resolve, reject) => {
    GetUser(userId)
      .then(
        result => {
          if ( result === null) { return reject('User not found.') }
          if ( typeof result.Message === 'undefined' ) {
            let user = processUser(result);
            return resolve(user);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function changeUserRole(userId, role, mode) {
  return new Promise((resolve, reject) => {
    ChangeUserRole(userId, role, mode)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function changeExcludedAttendants(userId, attendantId, mode) {
  return new Promise((resolve, reject) => {
    ChangeExcludedAttendants(userId, attendantId, mode)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function patchUser(userId, patch) {
  return new Promise((resolve, reject) => {
    PatchUser(userId, patch)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function newUserInfo(userId, info) {
  return new Promise((resolve, reject) => {
    NewUserInfo(userId, info)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function setLicenseRegistration(userId, content) {
  return new Promise((resolve, reject) => {
    SetLicenseRegistration(userId, content)
      .then(response => response.json())
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}
