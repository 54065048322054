import React from "react";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setStatusIcons } from './../global-props';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';


function SetStatusIcon(props){
  let { t , setStatus, timeStamp, ipAddress } = props;
  timeStamp = moment(timeStamp).utc();
  return (<><div>
      <span id={"status-"+timeStamp}>{setStatusIcons[setStatus] && setStatusIcons[setStatus].icon?<FontAwesomeIcon icon={setStatusIcons[setStatus].icon} />:setStatus}</span>
    </div>
    {ipAddress?
    <UncontrolledTooltip target={"status-"+timeStamp}>
      {ipAddress}
    </UncontrolledTooltip>
  :null}
  </>)
}

export default withTranslation()(SetStatusIcon);
