import React from 'react';
import ReactSVG from 'react-svg';
import LoaderFile from './../../assets/images/icon-loader-spinner.svg';

function Loader(props) {
  return <div className="loader">
    <ReactSVG src={LoaderFile} />
  </div>
}

export default Loader;