import React from 'react';
import NumberFormat from 'react-number-format';

function CurrencyFormat(props) {
  let { value } = props;
  let modifiedValue = false;
  let amount = String(value);
  // add trailing 0 to numbers like 1.5
  let components = amount.split('.');
  if ( components.length > 1 && components[1].length < 2) {
    modifiedValue = parseFloat(value).toFixed(2);
  } 
  let newProps = {...props};
  newProps.value = modifiedValue !== false ? modifiedValue : props.value;

  return <NumberFormat {...newProps} thousandSeparator={true} prefix={'$'} decimalScale={2} displayType={props.type==='text'?'input':'text'} />
}

export default CurrencyFormat;