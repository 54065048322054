import React from 'react';

import { Trans, withTranslation } from 'react-i18next';
import RoomNumber from '../../../components/snippets/room-number';
import RoomAttendant from '../../../components/snippets/room-attendant';
import RoomStatus from '../../../components/snippets/room-status';
import moment from 'moment'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, Alert } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from './../../../components/button';
import HebrewDate from './../../../components/hebrewDate';
import SetStatusIcon from './../../../components/setStatusIcon';
import { getRooms, supplyRequest, updateRoomStatus } from './../../../models/room';
import { updateReservationStatus, requestCubicle, updateNote, printReceipt, assignRoom, preassignRoom } from './../../../models/reservation';
import { getAttendants } from './../../../models/attendant';
import { statuses, locations } from './../../../global-props';
import CurrencyFormat from './../../../components/snippets/currency-format';
import ReactSVG from 'react-svg';
import iconCalendar from './../../../assets/images/icon-calendar.svg';
import iconClock from './../../../assets/images/icon-clock.svg';
import iconBath from './../../../assets/images/icon-bath.svg';
import iconShower from './../../../assets/images/icon-shower.svg';
import iconWheelchair from './../../../assets/images/icon-wheelchair.svg';
import iconKallah from './../../../assets/images/icon-kallah.svg';
import iconBathPrivate from './../../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from './../../../assets/images/icon-shower-private.svg';
import iconParking from './../../../assets/images/icon-parking.svg';
import iconSalon from './../../../assets/images/icon-salon.svg';
import iconClockExtra from './../../../assets/images/icon-clock-extra.svg';
import { getReservation } from './../../../models/reservation';
import { SettingsContext } from './../../../components/settings';
import ReservationTimeline from './reservation-timeline';
import i18n from './../../../i18n';
import Loader from './../../../components/snippets/loader';


class ReservationDetails extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      time: null,
      modal: true,
      refresh: false,
      isUpdatingStatus: false,
      isStatusToggleOpen: false,
      isOpenStatus: false,
      isOpenPayments: false,
      isOpenMerchandise: false,
      isOpenSupplies: false,
      isOpenUserNotes: true,
      isOpenReservationNotes: true,
      isOpenExcludedAttendants: true,
      isEditingReservationNote: false,
      isLoading: false,
      reservationDetails: null,
      reservationNote: props.reservation.notes || "",
      userNote: props.reservation.user.notes || "",
      internalNote: props.reservation.user.internalNote || "",
      isLoadedAttendants: false,
      attendants: [],
      reservationStatuses: [
        //'Reserved',
        //'Arrived',
        //'Waiting',
        //'Assigned',
        'Prep',
        'ReadyForMikvah',
        //'InMikvah',
        'Dressing',
        'Done',
        //'Canceled',
        //'Replaced',
      ]
    }
    this.savedContext = context;

    this.modalToggle = this.modalToggle.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSupplyRequestCompleted = this.handleSupplyRequestCompleted.bind(this);
    this.statusToggle = this.statusToggle.bind(this);
    this.modalToggleUserNote = this.modalToggleUserNote.bind(this);
    this.modalToggleCancelReservation = this.modalToggleCancelReservation.bind(this);
    this.modalTogglePlaceUser = this.modalTogglePlaceUser.bind(this);
    this.modalToggleAssignUser = this.modalToggleAssignUser.bind(this);
    this.handleCancelReservation = this.handleCancelReservation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setupSettings = this.setupSettings.bind(this);
    this.handleReprint = this.handleReprint.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
    this.retrieveReservationDetails();
    this.setupSettings();

    this.setState({
      isOpenSupplies: this.props.reservation.supplies.filter(supply=>supply.status==='open').length>0
    });
    if(this.props.reservation.user.excludedAttendants && this.props.reservation.user.excludedAttendants.length>0){
      this.retrieveAttendants();
    }
  }

  componentDidUpdate(prevProps) {
    if ( this.context !== this.savedContext ) {
      this.savedContext = this.context;
      this.setupSettings();
    }
    if (  this.props.reservation && prevProps.reservation && prevProps.reservation.reservationStatus!==this.props.reservation.reservationStatus ) {
      this.retrieveReservationDetails();
    }
  }

  setupSettings() {
    const {settings} = this.context;
    if (this.props.reservation) {
      if (this.props.reservation.kallah) {
        this.roomTypeDisplay = { name: 'Kallah', icon: iconKallah, price: settings?settings.priceKallah.value:0 }
      } else if (this.props.reservation.handicapped) {
        this.roomTypeDisplay = { name: "Accessible", icon: iconWheelchair, price: settings?settings.priceBath.value:0 }
      } else if (this.props.reservation.privateRoom && this.props.reservation.bath) {
        this.roomTypeDisplay = { name: "Bath Private", icon: iconBathPrivate, price: settings?settings.pricePrivateBath.value:0 }
      } else if (this.props.reservation.privateRoom && !this.props.reservation.bath) {
          this.roomTypeDisplay = { name: "Shower Private", icon: iconShowerPrivate, price: settings?settings.pricePrivateShower.value:0 }
      } else if (this.props.reservation.bath) {
        this.roomTypeDisplay = { name: "Bath", icon: iconBath, price: settings?settings.priceBath.value:0 }
      } else {
        this.roomTypeDisplay = { name: "Shower", icon: iconShower, price: settings?settings.priceShower.value:0 }
      }
      this.setState({roomTypeDisplay: this.roomTypeDisplay});
    }
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  retrieveReservationDetails() {
    getReservation(this.props.reservation.reservationId)
      .then(result=>{
        this.setState({reservationDetails: result})
      });
  }

  modalToggle() {
    this.props.modalToggle();
    this.setState({modal: !this.state.modal});
  }

  statusToggle() {
    this.setState({isStatusToggleOpen: !this.state.isStatusToggleOpen});
  }

  handleChangeStatus(status) {
    const roomId = this.props.reservation.room.id;
    this.setState({
      isUpdatingStatus: true,
    });
    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.setState({
            isUpdatingStatus: false,
          });
          this.props.handleReservationUpdated(result.reservation);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }

  handleCancelReservation() {
    const reservationId = this.props.reservation.reservationId;
    const status = 'Canceled';
    this.modalToggleCancelReservation();
    this.setState({
      isUpdatingStatus: true,
    });
    updateReservationStatus(reservationId, status)
      .then(
        result => {
          this.setState({
            isUpdatingStatus: false,
          });
          this.props.handleReservationUpdated(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
    
  }

  handleSupplyRequestCompleted(supply) {  
    const roomId = this.props.reservation.room.id;
    let supplies = [supply.id];
    const action = 'complete';

    supplyRequest(roomId, supplies, action)
      .then(
        result => {
          console.log(result);
        },
        error => {
        }
      );
  }

  handleSaveNote(noteType) {
    let { reservationNote, userNote } = this.state;
    let res = {
      ...this.props.reservation,
      reservationNotes: noteType==='reservation'?reservationNote:this.props.reservation.notes,
      userNotes: noteType==='user'?userNote:this.props.reservation.user.notes,
      replacingReservationId: this.props.reservation.reservationId
    }

    this.setState({
      isSavingNote: true,
      error: null,
    })

    updateNote(res.reservationId, res.reservationNotes, res.userNotes)
      .then(result => {
        this.props.handleReservationUpdated(result);
        this.setState({
          isSavingNote: false,
          isEditingReservationNote: false,
          modalUserNote: false,
        })
      }, error => {
        this.setState({
          error,
          isSavingNote: false,
        })
      });
  }

  modalToggleUserNote() {
    this.setState({modalUserNote: !this.state.modalUserNote});
  }

  modalToggleCancelReservation() {
    this.setState({modalCancelReservation: !this.state.modalCancelReservation});
  }

  modalTogglePlaceUser() {
    this.setState({modalPlaceUser: !this.state.modalPlaceUser});
  }
  
  modalToggleAssignUser() {
    if(!this.props.rooms){
      getRooms()
      .then(
        result => {
          this.setState({
            isLoadedRooms: true,
            rooms: result,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: true,
            error
          });
        }
      );
    }
    this.setState({modalAssignUser: !this.state.modalAssignUser});
  }
  
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({[name]: value});
  }

  handleRequestCubicle = () => {
    this.setState({isSubmittingRequestCubicle: true});
    let reservationId = this.props.reservation.reservationId;
    requestCubicle(reservationId)
      .then(result=>this.setState({isSubmittingRequestCubicle: false}));
  }

  handleReprint() {
    const { receptionist } = this.context;
    this.setState({
      isLoading: true,
      error: null,
    })
    printReceipt(this.props.reservation.reservationId, receptionist.printerId)
      .then(result => {
        this.setState({
          isLoading: false,
        })
      }, error => {
        this.setState({error: error, isLoading: false});
      });      
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  placeInRoom(room){
    this.setState({isPlacingRoom:true})
    assignRoom(this.props.reservation.reservationId,room)
      .then(result => {
        this.setState({
          isPlacingRoom:false,
          selectedPlaceRoom:null,
          placeConfirmation: result.id,
        }, () => setTimeout(()=>this.setState({placeConfirmation:null}),15000))
      }, error => {
        this.setState({
          placeError:error,
          isPlacingRoom:false });
    });
  }

  preAssignRoom(room){
    this.setState({isAssigningRoom:true})
    preassignRoom(this.props.reservation.reservationId,room)
      .then(result => {
        this.props.handleReservationUpdated(result);
        this.setState({
          isAssigningRoom:false,
          selectedAssignRoom:null,
          // assignConfirmation: 'User successfully preassigned to room ' + result.roomIdPreassign
        }, () => setTimeout(()=>this.setState({assignConfirmation:null}),15000))
      }, error => {
        this.setState({
          assignError:error,
          isAssigningRoom:false });
    });
  }


  render() {
    const { t, reservation: res, groupedRooms } = this.props;
    const { time, reservationStatuses, isUpdatingStatus, isStatusToggleOpen, isLoading, isOpenStatus, isOpenPayments, isOpenMerchandise, isOpenSupplies, isOpenReservationNotes, isOpenExcludedAttendants, isOpenUserNotes, isSavingNote, reservationDetails, error, placeError, assignError, roomTypeDisplay, isSubmittingRequestCubicle, attendants, isLoadedAttendants, selectedPlaceRoom, selectedAssignRoom, isPlacingRoom, isAssigningRoom, placeConfirmation, assignConfirmation, rooms } = this.state;
    const { settings, receptionist } = this.context;
    let reservationMinutesTotal;
    const alternateLocation = locations.filter(loc => loc.name===res.alternateLocation);

    if ( res!=null ) {
      if (settings!==null&&res.reservationId) {
        let reservationMinutes = parseFloat(settings['reservationMinutes'+(res.bath?'Bath':'Shower')].value);
        let reservationMinutesDoubleSlot = parseFloat(settings.reservationMinutesDoubleSlot.value);
        reservationMinutesTotal = reservationMinutes + (res.doubleSlot ? reservationMinutesDoubleSlot : 0);
      }

      return (
        <Modal isOpen={this.state.modal} toggle={this.modalToggle} centered={true} size="xl" className={"lang-" + i18n.language} modalClassName="modal-reservation-details">
          <div className="modal-header">
            {res.room?
              <>
                <RoomNumber number={res.room.id} />
                <div>
                  <div className="title">{t("Type")}</div>
                  <div><FontAwesomeIcon icon={res.roomType} /></div>
                </div>
                <RoomAttendant attendant={res.room.section.attendant} />
                <div>
                  <div className="title">{t("Mikvah")}</div>
                  <div>{res.room.section.id}</div>
                </div>
              </>
            :
              (['Done', 'Canceled'].includes(res.reservationStatus)&&res.roomId?
                <RoomNumber number={res.roomId} />
              :
              null
            )}
            <div>
              <div className="title">{t("User")}</div> 
              <div><Link to={{pathname: "../user/", state: { userId: res.user.id }}}>{res.reservationCode}</Link></div>
            </div>
            {res.user.firstName?
            <div>
              <div className="title">{t("Name")}</div> 
              <div>{res.user.firstName} {res.user.lastName}</div>
            </div>
            :null}

            <div>
              <div className="title">{t("Phone")}</div> 
              <div>{res.user.phoneNumber}</div>
            </div>

            {/* {res.reservationStatus === 'Waiting' ?
              <div>
                <div className="title">Waiting Code</div> 
                <div>{res.waitingCode}</div>
              </div>
            : null} */}
            
            {res.cubicle?
              <div>
                <div className="title">{t("Cubicle")}</div> 
                <div>{res.cubicle.id}</div>
              </div>
            : null}
            
            {res.reservationStatus === 'Reserved' || res.reservationStatus === 'Pending' ?
              <div>
                <div className="title">{t("Balance Due")}</div> 
                <div><CurrencyFormat value={res.balance} /></div>
              </div>
            : null}

            {res.roomIdPreassign && !res.room?
              <div>
                <div className="title">{t("Preassign")}</div> 
                <div>{res.roomIdPreassign}</div>
              </div>
            : null}

            {res.room!==null?
              <Dropdown className="mis-auto" isOpen={isStatusToggleOpen} toggle={this.statusToggle}>
                <DropdownToggle tag="div">
                  <RoomStatus title="Reservation Status" status={statuses[res.reservationStatus].name} loading={isUpdatingStatus} color={statuses[res.reservationStatus].color} dropdown />
                </DropdownToggle>
                <DropdownMenu>
                  {reservationStatuses.map((s,i) => {
                    let status = statuses[s];
                    return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s)}>{t(status.name)}</DropdownItem>
                  })}
                </DropdownMenu>
              </Dropdown>
            :
              <RoomStatus title="Reservation Status" status={statuses[res.reservationStatus].name} loading={isUpdatingStatus} color={statuses[res.reservationStatus].color} />
            }
            <button type="button" onClick={this.modalToggle}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            <div className="col-main">
              {error?<Alert color="warning">{t(error)}</Alert>:null}
              <div className="collapse-container">
                <div className={"collapse-header" + (isOpenStatus?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenStatus: !state.isOpenStatus}))}>
                  <div className="title">{t("Current Session")}</div>
                  <div className="meta">
                    <div><span className="label">{t("Slot")}:</span> {res.advanceReservation?<><Moment format={t("h:mm")} date={res.reservationTime} />-<Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}} date={res.reservationTime} /></>:<>{t("Walk-in")}</>}</div>
                    {res.timeArrived?
                      <>
                        <div className="right"><span className="label">{t("Arrived")}:</span> <Moment format={t("h:mma")} date={res.timeArrived} /></div>
                        <div className="right"><span className="label">{t("Expected End")}:</span> <Moment format={t("h:mma")} date={res.expectedEndTime} /></div>
                      </>
                    :null}
                  </div>
                </div>
                <Collapse className="collapse-body" isOpen={isOpenStatus}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("Status")}</th>
                        <th>{t("StatusSetBy")}</th>
                        <th>{t("Time")}</th>
                        <th>{t("Duration")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservationDetails!==null?
                        (reservationDetails.statusHistory.length?
                          reservationDetails.statusHistory.map((s,i)=>{
                            return <React.Fragment key={i}>
                              <tr>
                                <td>
                                  {t(s.previousReservationStatusTitle)}
                                  {s.previousReservationStatusTitle==='Assigned'?' - '+ t("Room") + " " + s.roomAssigned:null}
                                </td>
                                <td>
                                  {s.previousReservationStatusTitle=='Reserved'?<SetStatusIcon timeStamp={s.previousStatusChange} ipAddress={reservationDetails.createdByIp} setStatus={reservationDetails.createdByUser}/>:null}
                                  {s.previousStatusLastUpdatedByUser?<SetStatusIcon timeStamp={s.previousStatusChange} ipAddress={s.previousStatusLastUpdatedByIp} setStatus={s.previousStatusLastUpdatedByUser}/>:null}
                                </td>
                                <td><Moment format={t(moment(s.previousStatusChange).isSame(moment(res.reservationDate),'day')?"h:mma":"M/D h:mma")} date={s.previousStatusChange} /></td>
                                <td>{moment.duration(moment(s.previousStatusChange).diff(s.dateCreated)).asSeconds()?
                                  moment.duration(moment(s.dateCreated).diff(s.previousStatusChange)).format("h:*mm:ss")
                                :'0:00'}</td>

                              </tr>
                              {i===reservationDetails.statusHistory.length-1?
                                <tr>
                                  <td>{t(s.newReservationStatusTitle)}</td>
                                  <td><SetStatusIcon timeStamp={s.dateCreated} ipAddress={s.statusLastUpdatedByIp} setStatus={s.statusLastUpdatedByUser}/></td>
                                  <td><Moment format={t(moment(s.dateCreated).isSame(moment(res.reservationDate),'day')?"h:mma":"M/D h:mma")} date={s.dateCreated} /></td>
                                  <td>{!['Done','Canceled'].includes(s.newReservationStatusTitle)?moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(s.dateCreated)).format("h:*mm:ss"):null}</td>

                                </tr>
                              :null}
                            </React.Fragment>
                          })
                        :
                          <tr>
                            <td>{t("Reserved")}</td>
                            <td>{<SetStatusIcon timeStamp={res.reservationCreated} ipAddress={reservationDetails.createdByIp} setStatus={reservationDetails.createdByUser}/>}</td>
                            <td><Moment format={t(moment(res.reservationCreated).startOf('day').isSame(moment(res.reservationDate),'day')?"h:mma":"M/D h:mma")} date={res.reservationCreated} /></td>
                            <td>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(res.reservationCreated)).format("h:*mm:ss")}</td>
                          </tr>
                        )
                      :null}
                    </tbody>
                  </table>
                </Collapse>
              </div>

              {res.supplies.length!==0?
                <div className="collapse-container">
                  <div className={"collapse-header" + (isOpenSupplies?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenSupplies: !state.isOpenSupplies}))}>
                    <div className="title">{t("Supplies")}</div>
                  </div>
                  <Collapse className="collapse-body" isOpen={isOpenSupplies}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("Requested")}</th>
                          <th>{t("Wait Time")}</th>
                          <th>{t("Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.supplies.map((supply, i) => {
                          let waitTime = moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds');
                          if ( supply.status==="complete" ) {
                            waitTime = supply.dateCompleted;
                          } else if ( supply.status==="canceled" ) {
                            waitTime = supply.dateCanceled;
                            supply.status = "Canceled"
                          }
                          return( 
                            <tr key={i}>
                              <td>{t(supply.item)}</td>
                              <td>{moment.duration(moment(waitTime).diff(supply.dateRequested)).format("h:*mm:ss")}</td>
                              <td>
                                {supply.status==="open" ? (
                                  <Button text="Mark Completed" type="secondary" onClick={() => this.handleSupplyRequestCompleted(supply)} />
                                ) : (
                                  <div className="status-name">{t(supply.status)}</div>
                                )}
                              </td>
                            </tr>
                          )})}
                      </tbody>
                    </table> 
                  </Collapse>
                </div>
              :null}

              <div className="payments collapse-container">
                <div className={"collapse-header" + (isOpenPayments?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenPayments: !state.isOpenPayments}))}>
                  <div className="title">{t("Payments")}</div>
                  <div className="meta">
                    {res.paid?
                      <div className="badge badge-secondary">{t("Paid")}</div>
                    :
                      <div><span className="label">{t("Balance Due")}:</span> <CurrencyFormat value={res.balance} /></div>
                    }
                  </div>
                </div>
                <Collapse className="collapse-body" isOpen={isOpenPayments}>
                  {res.payments.length?
                    <table className="table text-start">
                      <thead>
                        <tr>
                          <th>{t("Time")}</th>
                          <th>{t("Method")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Details")}</th>
                          <th className="text-end">{t("Amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.payments.map((payment,i) => {
                          return <tr key={i}>
                            <td>
                              <Moment format={t("MM/DD")}>{payment.dateCreated}</Moment>
                              <HebrewDate className="mis-1" date={payment.dateCreated} day={true} month={true}/>
                            </td>
                            <td>{t(payment.method)} {payment.method==='Fee' && payment.note?' - ' + payment.note:null}{payment.method==='Donation' && payment.note?' - ' + payment.note:null}{payment.method==='Tip'?(payment.tipNote?' - ' + t(payment.tipNote):' - ' + t("General")):null}{payment.method==='CreditCard'&&payment.transactionID&&(settings && settings.ccProcessorType && settings.ccProcessorType.value==='stripe')?<a href={"https://dashboard.stripe.com/payments/"+payment.transactionID} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="file-invoice-dollar" className="mis-2" /></a>:null}</td>
                            <td>{t(payment.status)} {payment.status==='Approved'?<FontAwesomeIcon icon="check" className="mis-2" />:null}{payment.status==='Error'? " - " + payment.note:null}</td>
                            <td>{t(payment.reference)}</td>
                            <td className="text-end"><CurrencyFormat value={payment.amount} /></td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  :
                    <Alert className="m-2 text-center" color="warning">{t("There are no payments for this reservation.")}</Alert>
                  }
                </Collapse>
              </div>

              {res.merchandise && res.merchandise.length?
              <div className="merchandise collapse-container">
                <div className={"collapse-header" + (isOpenMerchandise?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenMerchandise: !state.isOpenMerchandise}))}>
                  <div className="title">{t("Merchandise")}</div>
                </div>
                <Collapse className="collapse-body" isOpen={isOpenMerchandise}>
                    <table className="table text-start">
                      <thead>
                        <tr>
                          <th>{t("Item")}</th>
                          <th>{t("QTY")}</th>
                          <th className="text-end">{t("Price")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.merchandise.map((m,i) => {
                          return <tr key={i}>
                              <td>{t(m.item)}</td>
                              <td>{m.quantity}</td>
                              <td className="text-end"><CurrencyFormat value={m.total?m.total:parseFloat(m.quantity) * parseFloat(m.price)} /></td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                </Collapse>
              </div>
              :null}

              {res.notes!==null||res.user.notes!==null||res.user.internalNote!==null?
                <div className="user-note collapse-container">
                  <div className={"collapse-header" + (isOpenReservationNotes?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenReservationNotes: !state.isOpenReservationNotes}))}>
                    <div className="title">{t("Notes")}</div>
                  </div>
                  <Collapse className="collapse-body" isOpen={isOpenReservationNotes}>
                    <div className="p-3">
                      {res.notes!==null?
                        <div className="mb-2">
                          <div className="font-weight-bold">{t("Reservation Note")}:</div>
                          <div>{res.notes}</div>
                        </div>
                      :null}
                      {res.user.notes!==null?
                      <div className="mb-2">
                        <div className="font-weight-bold">{t("Patron Note")}:</div>
                        <div>{res.user.notes}</div>
                      </div>
                      :null}
                      {res.user.internalNote!==null?
                      <div className="mb-2">
                        <div className="font-weight-bold">{t("Internal Note")}:</div>
                        <div>{res.user.internalNote}</div>
                      </div>
                      :null}
                    </div>
                  </Collapse>
                </div>
              :null}

              {res.user.excludedAttendants.length>0?
                <div className="user-note collapse-container">
                  <div className={"collapse-header" + (isOpenExcludedAttendants?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenExcludedAttendants: !state.isOpenExcludedAttendants}))}>
                    <div className="title">{t("Excluded Attendants")}</div>
                  </div>
                  <Collapse className="collapse-body" isOpen={isOpenExcludedAttendants}>
                    <div className="p-3">
                      {isLoadedAttendants
                      ? 
                      res.user.excludedAttendants.map((ea,i) => {
                        let att = attendants.find(att=>att.id===ea.attendantId);
                        return <div key={i}>{att.name}</div>
                      })
                      :null}
                    </div>
                  </Collapse>
                </div>
              :null}

              {res&&reservationDetails&&reservationDetails.statusHistory?
                <ReservationTimeline reservation={res} reservationDetails={reservationDetails} />
              :null}

              {res&&res.reservationId?
                <div className="res-id">{res.reservationId}</div>
              :null}

            </div>
            <div className="col-side">
              <div className="checkin-reservation">

                <ul className="reservation-summary">
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconCalendar} />
                    </div>
                    <Moment format={t("dddd M/D")}>{res.reservationDate}</Moment>
                    <HebrewDate className="mis-1" date={res.reservationDate} day={true} month={true}/>
                  </li>
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconClock} />
                    </div>
                    {res.advanceReservation?
                    <>
                      <Moment format={t("h:mma")}>{res.reservationTime}</Moment>-
                      <Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}}>{res.reservationTime}</Moment>
                    </>
                    :
                      <>{t("Walk-in")}</>
                    }
                  </li>
                  {roomTypeDisplay?
                    <li>
                      <div className="icon">
                        <ReactSVG src={roomTypeDisplay.icon} />
                      </div>
                      <span>{t("Room Type")}: <span className="text-capitalize">{t(roomTypeDisplay.name)}</span></span>
                      <div className="price"><CurrencyFormat value={roomTypeDisplay.price} /></div>
                    </li>
                  :null}
                  {alternateLocation.length?
                    <li>
                      <div className="icon"><ReactSVG src={alternateLocation[0].icon} /></div>
                      {alternateLocation[0].title}
                    </li>
                  :null}
                  {res.parking?
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconParking} />
                    </div>
                    {res.parking?t("Parking"):t("Walking")}
                    <div className="price"><CurrencyFormat value={settings?settings.priceParking.value:0} /></div>
                  </li>
                  :null}
                  {res.salonManicure&&res.salonPedicure?
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconSalon} />
                    </div>
                    {t("Mani+Pedi Polish")}
                    <div className="price"><CurrencyFormat value={settings?settings.priceSalonBoth.value:0} /></div>
                  </li>
                  :null}
                  {res.salonManicure&&!res.salonPedicure?
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconSalon} />
                    </div>
                    {t("Manicure Polish")}
                    <div className="price"><CurrencyFormat value={settings?settings.priceSalonManicure.value:0} /></div>
                  </li>
                  :null}
                  {res.doubleSlot?
                  <li>
                    <div className="icon">
                      <ReactSVG src={iconClockExtra} />
                    </div>
                    {t("Extra Time")}
                    <div className="price"><CurrencyFormat value={settings?settings.priceDoubleSlot.value:0} /></div>
                  </li>
                  :null}
                </ul>
              </div>

              <hr />

              {this.state.isEditingReservationNote?
                <div className="text-start mb-3">
                  <div className="form-group">
                    <label>{t("Note")}</label>
                    <textarea className="form-control" rows="3" name="reservationNote" value={this.state.reservationNote} onChange={this.handleChange}/>
                  </div>
                  <div className="mb-3">
                  </div>
                  <div className="d-flex">
                    <Button className="btn btn-outline-secondary mie-auto" onClick={()=>{this.modalToggleUserNote(); this.setState({isEditingReservationNote: false, userNote: this.state.userNote===""?this.state.reservationNote:this.state.userNote})}}>{t("Save for future")}</Button>
                    <Button className="btn btn-outline-secondary mie-1" onClick={() => this.setState({isEditingReservationNote: false})}>{t("Cancel")}</Button>
                    <Button className="btn btn-secondary" loading={isSavingNote} onClick={()=>this.handleSaveNote('reservation')}>{t("Save")}</Button>
                  </div>
                  <hr />
                </div>
              :
                <div className="mb-3">
                  <Button className="btn btn-secondary" size="lg" onClick={() => this.setState({isEditingReservationNote: true})}>{res.notes?t('Edit'):t('Add')} {t("Note")}</Button>
                </div>
              }

              {res.reservationStatus === 'Waiting' && res.cubicle===null && false ?
                <div className="mb-3">
                  <Button type="secondary" size="lg" onClick={this.handleRequestCubicle} loading={isSubmittingRequestCubicle}>{t("Request Cubicle")}</Button>
                </div>
              :null}
              {groupedRooms && ['Pending', 'Reserved', 'Waiting'].includes(res.reservationStatus)?
                <div className="mb-3">
                    <Button type="secondary" size="lg" onClick={this.modalTogglePlaceUser}>{t("Place into Room")}</Button>
                </div>
              :null}
              {['Reserved'].includes(res.reservationStatus)?
                <div className="mb-3">
                    <Button type="secondary" size="lg" onClick={this.modalToggleAssignUser}>{t("Preassign to Room")}</Button>
                </div>
              :null}
              {['Reserved', 'Assigned'].includes(res.reservationStatus) ?
                <div className="mb-3">
                  <Link to={{pathname: "../reservation/", state: { reservation: res, isEditingReservation: true }}}>
                    <Button type="secondary" size="lg">{t("Edit Reservation")}</Button>
                  </Link>
                </div>
              :null}
              {res.reservationStatus === 'Reserved' ?
                <div className="mb-3">
                  <Link to={{pathname: "../checkin/", state: { reservation: res }}} onClick={this.modalToggle}>
                    <Button type="secondary" size="lg">{t("Check In")}</Button>
                  </Link>
                </div>
              :null}
              <div className="mb-3">
                <Link to={{pathname: "../reservation/", state: { reservation: res, isEditingReservation: true }}}>
                  <Button type="secondary" size="lg">{!res.paid?t("Pay"):t("Payments")}</Button>
                </Link>
              </div>
              {['Pending', 'Reserved', 'Waiting'].includes(res.reservationStatus) ?
                <div className="mb-3">
                  <Button type="secondary" size="lg" onClick={this.modalToggleCancelReservation}>{t("Cancel Reservation")}</Button>
                </div>
              :null}
              {receptionist ?
                <Button type="secondary" size="lg" onClick={this.handleReprint} loading={isLoading}>{t("Print Receipt")}</Button>
              :null}

            </div>

            <Modal isOpen={this.state.modalUserNote} toggle={this.modalToggleUserNote} centered={true} className={"lang-" + i18n.language} modalClassName="modal-user-note">
              <div className="modal-header">
              {t("User Note")}
                <button type="button" onClick={this.modalToggleUserNote}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <ModalBody>
                <div className="text-start">
                  <div className="form-group">
                    <label>{t("Note")}</label>
                    <textarea className="form-control" rows="3" name="userNote" value={this.state.userNote} onChange={this.handleChange}/>
                  </div>
                  <div className="d-flex">
                    <Button className="btn btn-outline-secondary mis-auto mie-1" onClick={this.modalToggleUserNote}>{t("Cancel")}</Button>
                    <Button className="btn btn-secondary" loading={isSavingNote} onClick={()=>this.handleSaveNote('user')}>{t("Save")}</Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.modalCancelReservation} toggle={this.modalToggleCancelReservation} centered={true} className={"lang-" + i18n.language} modalClassName="modal-cancel-reservation">
              <div className="modal-header">
              {t("Cancel Reservation")}
                <button type="button" onClick={this.modalToggleCancelReservation}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <ModalBody>
                <div className="text-start">
                  <p>{t("reservePaymentCancelReservationPrompt")}</p>
                  <div className="d-flex">
                    <Button className="btn btn-secondary mis-auto" onClick={this.handleCancelReservation}>{t("Confirm")}</Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.modalPlaceUser} toggle={this.modalTogglePlaceUser} centered={true} className={"lang-" + i18n.language} modalClassName="modal-place-user">
              <div className="modal-header">
              {t("Place User Into Room")}
                <button type="button" onClick={this.modalTogglePlaceUser}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <ModalBody>
                {placeError?<Alert color="warning">{t(placeError)}</Alert>:null}
                {placeConfirmation?<Alert color="success">{t("placeConfirmationMessage", { resultId: placeConfirmation })}</Alert>:null}
                <div className="text-start">
                <UncontrolledDropdown>
                  <DropdownToggle caret>{selectedPlaceRoom?selectedPlaceRoom.id + ' - ' + t(selectedPlaceRoom.status):t('Rooms')}</DropdownToggle>
                  <DropdownMenu>
                  {groupedRooms && groupedRooms['Ready']?groupedRooms['Ready'].map((room,i)=>
                    room.section.attendant!==null?
                      <DropdownItem onClick={()=>this.setState({selectedPlaceRoom: {id: room.id, status: room.statusName}})} disabled={room.section.attendant==null} key={i}>{room.id}</DropdownItem>
                    :null
                  ):null}
                  <DropdownItem divider />
                  {groupedRooms && groupedRooms['Offline']?groupedRooms['Offline'].map((room,i)=>
                   room.section.attendant!==null?
                    <DropdownItem onClick={()=>this.setState({selectedPlaceRoom: {id: room.id, status: room.statusName}})} disabled={room.section.attendant==null} key={i}>{room.id} - {t("Offline")}</DropdownItem>
                    :null
                  ):null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="d-flex">
                    <Button className="btn btn-secondary mis-auto" disabled={selectedPlaceRoom?false:true} loading={isPlacingRoom} onClick={() => this.placeInRoom(selectedPlaceRoom.id)}>{t("Confirm")}</Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.modalAssignUser} toggle={this.modalToggleAssignUser} centered={true} className={"lang-" + i18n.language} modalClassName="modal-place-user">
              <div className="modal-header">
              {t("Preassign User to Room")}
                <button type="button" onClick={this.modalToggleAssignUser}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <ModalBody>
                {assignError?<Alert color="warning">{t(assignError)}</Alert>:null}
                {assignConfirmation?<Alert color="success">{t(assignConfirmation)}</Alert>:null}
                <div className="text-start">
                {res.roomIdPreassign?
                  <div className="mb-2"><Trans i18nKey="userCurrentlyPreassigned">User is currently preassigned to room {{preassignedId: res.roomIdPreassign}}</Trans></div>
                :null}
                <UncontrolledDropdown>
                  <DropdownToggle caret>{selectedAssignRoom?selectedAssignRoom.id:res.roomIdPreassign?res.roomIdPreassign:t('Rooms')}</DropdownToggle>
                  <DropdownMenu>
                    {rooms && rooms.map((room,i)=>
                      !res.bath || res.bath == room.bath?
                      <DropdownItem className={selectedAssignRoom && selectedAssignRoom.id==room.id?'active':!selectedAssignRoom && room.id==res.roomIdPreassign?'active':''} onClick={()=>this.setState({selectedAssignRoom: {id: room.id}})} key={i}>{room.id}</DropdownItem>
                      :null
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="d-flex justify-content-end">
                {res.roomIdPreassign?
                  <Button className="btn btn-secondary mie-3" loading={isAssigningRoom} onClick={() => this.preAssignRoom(0)}>{t("Remove Preassigned Room")}</Button>
                :null}
                  <Button className="btn btn-secondary" disabled={selectedAssignRoom && (selectedAssignRoom.id !== res.roomIdPreassign)?false:true} loading={isAssigningRoom} onClick={() => this.preAssignRoom(selectedAssignRoom.id)}>{t("Confirm")}</Button>
                </div>
                </div>
              </ModalBody>
            </Modal>

          </ModalBody>
        </Modal>
      )
    } else {
      return null;
    }
  }
}

export default withTranslation()(ReservationDetails);
