import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from './button';
import i18n from './../i18n';
import ReactSVG from 'react-svg';
import iconEmergency from '../assets/images/icon-emergency.svg';
import EmergencySoundFile from '../assets/sounds/emergency.mp3';
import { updateRoomStatus } from './../models/room';
import { SettingsContext } from './settings';

class Emergency extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      emergency: [],
      emergencyOpen: false
    };
    this.notificationSound = new Audio(EmergencySoundFile);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    let emergency = this.props.rooms.filter(room => room.emergencyCall);
    this.setState({
      emergency,
      emergencyOpen: emergency.length>0
    })
    if(emergency.length>0){
      this.notificationSound.loop = true;
      this.notificationSound.play();
    } else{
      if(this.notificationSound){
        this.notificationSound.pause();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.rooms !== this.props.rooms){
      let emergency = this.props.rooms.filter(room => room.emergencyCall);
      this.setState({
        emergency,
        emergencyOpen: emergency.length>0
      })
      if(emergency.length>0){
        this.notificationSound.loop = true;
        this.notificationSound.play();
      } else{
        if(this.notificationSound){
          this.notificationSound.pause();
        }
      }
    }
  }

  handleChangeStatus(status) {
    const roomId = this.state.emergency[0].id;
    updateRoomStatus(roomId, status)
      .then(
        result => {
        },
        error => {
          this.setState({
            error
          });
        }
      );
  }

  toggle() {
    this.setState({
      emergencyOpen: !this.state.emergencyOpen
    })
    setTimeout(() => {
      this.setState({
        emergencyOpen: this.state.emergency.length>0
      })  
    }, 30000);
  }

  render() {
    const { emergency, emergencyOpen } = this.state;
    const { t } = this.props;
    return <div className="emergency">
      <Modal className={"modal-emergency lang-" + i18n.language} size="xl" isOpen={emergencyOpen} centered>
      <ModalHeader toggle={this.toggle}>{t("Emergency")}</ModalHeader>
        <ModalBody>
          <ReactSVG className="emergency-icon" src={iconEmergency} />          
          <h1 className="modal-title">{t("emergencyRoom", { room: emergency.map(r=>r.id).join(',') })}</h1>
          <Button className="btn-primary" size="lg" onClick={() => this.handleChangeStatus('ClearEmergencyCall')}>{t("Dismiss")}</Button>
        </ModalBody>
      </Modal>
    </div>;
  }
}

export default withTranslation()(Emergency);