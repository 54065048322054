/* CacheBuster component */
import React from 'react';

import packageJson from '../../package.json';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
}

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (typeof(caches) !== 'undefined') {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        //window.location.reload(true);
        window.location.href = this.updateQueryStringParameter(window.location.href, 'v', this.state.latestVersion);
      }
    };
  }

  componentDidMount() {
    this.retrieveVersion();
    this.intervalID = setInterval(()=>this.retrieveVersion(), 10 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  retrieveVersion() {
    fetch('/meta.json?c=' + Date.now())
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false, latestVersion });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          if(this.state.loading || !this.state.isLatestVersion){
            this.setState({ loading: false, isLatestVersion: true, latestVersion });
          }
          window.history.replaceState && window.history.replaceState(
            null, '', window.location.pathname + window.location.search.replace(/[\?&]v=[^&]+/, '').replace(/^&/, '?') + window.location.hash
          );
        }
      }).catch(() => {
        console.error('Error fetching "/meta.json". Make sure the file exists. Run the "prebuild" script to regenerate the file.');
        this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}