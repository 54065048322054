import { getSections as GetSections, getSection as GetSection, setSectionAttendant as SetSectionAttendant, setSectionHelper as SetSectionHelper, setBorStatus as SetBorStatus, } from '../api';

function processSection(section) {
  return section;
}

export function getSections() {
  return new Promise((resolve, reject) => {
    GetSections()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let sections = result.map((section,i) => {
              return processSection(section);
            })
            return resolve(sections);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getSection(id) {
  return new Promise((resolve, reject) => {
    GetSection(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            let section = result
            processSection(section);
            return resolve(section);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function setSectionAttendant(id, attendantId) {
  return new Promise((resolve, reject) => {
    SetSectionAttendant(id, attendantId)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function setSectionHelper(id, attendantId) {
  return new Promise((resolve, reject) => {
    SetSectionHelper(id, attendantId)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function setBorStatus(id, newStatus) {
  return new Promise((resolve, reject) => {
    SetBorStatus(id, newStatus)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}
