import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from "react-router-dom";
import { Alert } from 'reactstrap';
import i18n from './../../i18n';

import { getRooms } from './../../models/room';

import { getSections } from './../../models/section';
import { getAttendants } from './../../models/attendant';
import Clock from './../../components/clock';
import Button from './../../components/button';
import ZmanTevilah from './../../components/zman-tevilah';
import LanguageSelector from './../../components/language-selector';
import Emergency from './../../components/emergency';
import moment from 'moment';
import SignalR from './../../components/signalr';
import Loader from './../../components/snippets/loader';
import { SettingsContext } from '../../components/settings';
import RoomSummary from './../receptionist/room-summary';
import AttendantListView from '../attendant/partials/attendantList';
import MikvahAdmin from './../../components/mikvah-admin';

class MikvahView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      sections: null,
      isLoadedRooms: false,
      isLoadedAttendants: false,
      supplyRequests: null,
      isUpdatingStatus: false,
    };
    
  }

  componentDidMount() {
    if ( this.props.id ) {
      this.retrieveRooms();
      if (this.props.id==='0') {
        localStorage.removeItem('selectedMikvah');
      }
    } else {
      let savedSelectedRoom = localStorage.getItem('selectedMikvah');
      if ( savedSelectedRoom ) {
        this.setState({savedSelectedRoom});
        return;
      }
    }
    this.retrieveSections();
    this.retrieveAttendants();
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.processRooms(rooms);
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  retrieveSections() {
    getSections()
      .then(result => {
        let sections = result;
        let section = sections.find(section=>section.id===this.props.id);
        let attendant = section && section.attendant ? section.attendant : null;
        this.setState({
          attendant,
          sections,
          isLoadedSections: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  processRooms(rooms) {
    const {settings} = this.context;
    let supplies = rooms.map((room, i) => {
      let hasActiveRequest = false;
      if ( settings && room.reservation!=null ) {
        room.reservation.supplies.forEach((supply) => {
          if (supply.status === 'open') { hasActiveRequest = true; }
          supply.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes()>settings.waitingForSuppliesMinutes.value;
          supply.overTimeMinutes = supply.isOverTime?moment.duration(moment(this.state.time).subtract(settings.waitingForSuppliesMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes():null;
        });
        room.reservation.room = { id: room.id, section: room.section };
      }
      if (settings && (room.effectiveStatus==='ReadyForMikvah'||room.effectiveStatus==='ReadyForMikvahNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForMikvahMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForMikvahMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      if (settings && (room.effectiveStatus==='ReadyForChecking'||room.effectiveStatus==='ReadyForCheckingNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForCheckingMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForCheckingMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      return hasActiveRequest ? room.reservation : null;
    }).filter(room => room!=null);
    this.setState({
      isLoadedRooms: true,
      supplyRequests: supplies,
      rooms: rooms,
    });
  }
  
  handleRoomSelected = (roomId) => {
    localStorage.setItem('selectedMikvah', roomId);
  }

  render() {
    const { t } = this.props;
    const { isLoadedRooms, rooms, supplyRequests, error, isUpdatingStatus, sections, isLoadedSections, attendant, isLoadedAttendants, attendants } = this.state;
    const {settings} = this.context;

    if ( this.state.savedSelectedRoom ) {
      return <Redirect to={`/mikvah/${this.state.savedSelectedRoom}`} />;
    }
    if ( !this.props.id || this.props.id==='0' ) {
      return (
        <div>
          <div className={'container-page view-mikvah view-tablet lang-' + i18n.language}>
            <div className="container-main">
              <header className="header w-100">
                <div>
                  <div className="header-center">
                    <Clock />
                  </div>
                </div>
                <div>
                  <div className="header-center">
                    <Clock type="fullDate" />
                  </div>
                </div>
              </header>
              <LanguageSelector />
              <div className="container-body text-center">
                <div className="sidebar-top">
                  {error?
                    <div>
                      <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                    </div>
                  :
                    <div>
                      <h3 className="text-uppercase">{t("Please choose a mikvah room")}:</h3>
                      <div className="d-flex justify-content-center">
                        {isLoadedSections?
                          sections.map((section,i) => {
                            return <Link to={"/mikvah/" + section.id} className="mie-3" key={i} onClick={() => this.handleRoomSelected(section.id)}><Button type="circle" text={section.name} /></Link>;
                          })
                        :<Loader />}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} onSectionsUpdated={() => this.retrieveSections()} />
        {settings!== null?
          <MikvahAdmin adminCorrectCode={settings.roomAdminPasscode.value}/>
        :null}
        <div className={'container-page view-mikvah view-tablet lang-' + i18n.language}>
          <div className="container-main flex-row">
            <header className="header">
                <div className="header-top flex-column">
                  <Clock />
                  <Clock type="fullDate" />
                </div>
                <div className="d-block">
                  <div className="header-zman flex-column">
                    <ZmanTevilah />
                  </div>
                  {isLoadedRooms && rooms.length?                
                  <div className="header-summary">
                    <RoomSummary rooms={rooms} />
                  </div>
                :null}
                </div>
            </header>
            <LanguageSelector />
            <AttendantListView isLoadedRooms={rooms} rooms={rooms} attendant={attendant} isLoadedSections={isLoadedSections} sections={sections} isLoadedAttendants={isLoadedAttendants} attendants={attendants} supplyRequests={supplyRequests} id={this.props.id} mikvahView={true} />
            {isLoadedRooms && rooms?
              <Emergency rooms={rooms} />
            :null}
          </div>
          <div className="container-sidebar">
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MikvahView);
