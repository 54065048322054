import { getSettings as GetSettings, 
  getPublicSettings as GetPublicSettings, 
  updateSetting as UpdateSetting, 
  getPrinterStatus as GetPrinterStatus, 
  getPrinters as GetPrinters, 
  getCardReaders as GetCardReaders, 
  getSchedule as GetSchedule, 
  updateSchedule as UpdateSchedule, 
  getLanguage as GetLanguage, 
  setLanguageText as SetLanguageText, 
  setLanguageDescription as SetLanguageDescription, 
  reloadAllScreens as ReloadAllScreens, 
  submitContactForm as SubmitContactForm, 
  openGarageGate as OpenGarageGate,
  addBannerImage as AddBannerImage,
  getMerchandise as GetMerchandise,
  addMerchandise as AddMerchandise
} from './../api';
import { settings, publicSettings } from './../global-props';


function processSettings(result) {
  result.map(setting => {
    if ( settings[setting.Key] ) {
      settings[setting.Key]["prevValue"] = setting.Value;
      settings[setting.Key]["value"] = setting.Value;
    } else {
      console.log(setting);
    }
    return setting;
  })
  return settings;
}

function processPublicSettings(result) {
  result.map(setting => {
    if ( publicSettings[setting.Key] ) {
      publicSettings[setting.Key]["prevValue"] = setting.Value;
      publicSettings[setting.Key]["value"] = setting.Value;
    } else {
      console.log(setting);
    }
    return setting;
  })
  return publicSettings;
}

export function getSettings() {
  return new Promise((resolve, reject) => {
    GetSettings()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processSettings(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getPublicSettings() {
  return new Promise((resolve, reject) => {
    GetPublicSettings()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processPublicSettings(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateSetting(setting, value) {
  return new Promise((resolve, reject) => {
    UpdateSetting(setting, value)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processSettings(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getPrinterStatus() {
  if (window.printersPromise) return window.printersPromise;
  window.printersPromise = new Promise((resolve, reject) => {
    GetPrinterStatus()
      .then(
        result => {
          window.printersPromise = null;
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
  return window.printersPromise;
}

export function getSchedule() {
  return new Promise((resolve, reject) => {
    GetSchedule()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateSchedule(schedule) {
  return new Promise((resolve, reject) => {
    UpdateSchedule(schedule)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function getLanguage() {
  return new Promise((resolve, reject) => {
    GetLanguage()
      .then(
        result => {
          return resolve(result);
        },
        error => reject(error)
      );
  })
}

export function setLanguageText(params) {
  return new Promise((resolve, reject) => {
    SetLanguageText(params)
      .then(
        result => {
          return resolve(result);
        },
        error => reject(error)
      );
  })
}

export function setLanguageDescription(key ,desc) {
  return new Promise((resolve, reject) => {
    SetLanguageDescription(key, desc)
      .then(
        result => {
          return resolve(result);
        },
        error => reject(error)
      );
  })
}

export function reloadAllScreens() {
  return new Promise((resolve, reject) => {
    ReloadAllScreens()
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function submitContactForm(data) {
  return new Promise((resolve, reject) => {
    SubmitContactForm(data)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function openGarageGate() {
  return new Promise((resolve, reject) => {
    OpenGarageGate()
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function getPrinters() {
  return new Promise((resolve, reject) => {
    GetPrinters()
      .then(
        result => resolve(result),
        error => reject(error)
      );
  })
}

export function getCardReaders() {
  return new Promise((resolve, reject) => {
    GetCardReaders()
      .then(
        result => resolve(result),
        error => reject(error)
      );
  })
}

export function addBannerImage(image) {
  return new Promise((resolve, reject) => {
    AddBannerImage(image)
      .then(response => response.json())
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function getMerchandise(url) {
  return new Promise((resolve, reject) => {
    GetMerchandise(url).then(
      (result) => {
        if (typeof result.Message === 'undefined') {
          return resolve(result);
        }
        return reject(result.Message);
      },
      (error) => reject(error)
    );
  });
}

export const addMerchandise = (items) => {
  return new Promise((resolve, reject) => {
    AddMerchandise(items)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}