import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';
import moment from "moment";
import Moment from "react-moment";
import { SettingsContext } from "./settings";
class ScreenChecking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
      leaveTevillah: false
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLoadedSection !== this.props.isLoadedSection || prevProps.section !== this.props.section || prevProps.room.reservation.attendantInRoom !== this.props.room.reservation.attendantInRoom){
      const { room, section } = this.props;
      if(room && section && room.reservation.attendantInRoom){
        if(room.directToBor && !section.borBusyDirectToBor) {
          this.setState({leaveTevillah: true})
        } else if(!room.directToBor && !section.wetHallwayBusy && !section.borBusy) {
          this.setState({leaveTevillah: true})
        } else {
          this.setState({leaveTevillah: false})
        }
      } else {
        this.setState({leaveTevillah: false})
      }
    }
  }

  handleChangeStatus(status) {
    let roomId = this.props.room.id;

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }


  render() {
    const { t, room } = this.props;
    const { leaveTevillah, error } = this.state;
    return (
      <div className="body-right attendantInRoom">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}

        <SettingsContext.Consumer>
          {({settings})=> {
            if (settings) {
              let zmanTevilah = settings.zmanTevillah.value;
              return moment().isBefore(moment(zmanTevilah))?
                <Alert color="warning">{t('Please Note')}: {t('Tevilah begins at')} <Moment format={t("h:mma")}>{zmanTevilah}</Moment>. </Alert>
              :null
            }
          }}
        </SettingsContext.Consumer>

        <div className="instructions">
          {t("Balanit in the room.")} {t("Choose progress to continue")}:
        </div>
        <div>
          <Button type="circle" className="mie-5" onClick={() => this.handleChangeStatus("Prep")} loading={this.state.isUpdatingStatus} text={t("Continue Prep")} />
          {room.privateRoom
          ?
          <Button className="mb-4" type="circle-big" onClick={() => this.handleChangeStatus("Dressing")} loading={this.state.isUpdatingStatus} text={t("Tevilah Completed")} />
          :
          <>
          <Button className="mb-4" type="circle-big" onClick={() => this.handleChangeStatus("ToMikvah")} disabled={!leaveTevillah} loading={this.state.isUpdatingStatus} text={t("Leave to Mikvah")} />
          <Button type="circle" className="mis-5" onClick={() => this.handleChangeStatus("ReadyForMikvah")} loading={this.state.isUpdatingStatus} text={t("Ready For Tevilah")} />
          </>
        }
        </div>
      </div>
    );
  }
}

export default withTranslation()(ScreenChecking);
