import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import { supplyRequest } from "./../models/room";
import Button from './../components/button';
import { Alert } from 'reactstrap';
import { Modal, ModalBody } from 'reactstrap';
import iconClose from "../assets/images/icon-close.svg";
import i18n from './../i18n';
import moment from 'moment';


class ScreenPrep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
      modalSupplyPrompt: false,
      isSubmittingSupplyRequest: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleCancelSupplyRequest = this.handleCancelSupplyRequest.bind(this);
    this.handleCompleteSupplyRequest = this.handleCompleteSupplyRequest.bind(this);
  }

  componentDidMount() {
    this.setupSound();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  toggleModalSupplyPrompt = () => {
    this.setState({
      modalSupplyPrompt: !this.state.modalSupplyPrompt,
    })
  }

  handleChangeStatus(dirStatus) {
    let openSupplyRequests = this.props.room.reservation.supplies.filter(supply=>supply.status==="open");
    if (openSupplyRequests.length > 0) {
      this.setState({
        modalSupplyPrompt: true
      })
    } else {
      const { settings } = this.props;
      let roomId = this.props.room.id;
      let status;
      if(dirStatus){
        status = dirStatus;
      } else {
        if(settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True"){
          status = "ReadyForChecking";
        } else {
          status = "ReadyForMikvah"; 
        }
      }
      this.setState({
        isUpdatingStatus: true,
      })
      updateRoomStatus(roomId, status)
        .then(
          result => {
            if(result.reservation.timeConfirmedInfo){
              clearTimeout(this.timer);
            }
            this.props.handleUpdateRoom(result);
            this.setState({
              isUpdatingStatus: false,
            });
          },
          error => {
            this.setState({
              isUpdatingStatus: false,
              error
            });
          });
    }
  }
  
  handleCancelSupplyRequest() {
    const roomId = this.props.room.id;
    let supplyList = null;
    const action = 'cancel';

    this.setState({
      isSubmittingSupplyRequest: true
    })

    supplyRequest(roomId, supplyList, action)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
          this.handleChangeStatus();
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      ).finally(()=>{
        this.setState({
          isSubmittingSupplyRequest: false,            
        });
      });

  }

  handleCompleteSupplyRequest(isAttendantRequest) {  
    const roomId = this.props.room.id;
    const action = 'complete';
    this.setState({isUpdatingStatus: true})
    supplyRequest(roomId, null, action, isAttendantRequest)
      .then(
        result => {
          console.log(result);
          this.setState({isUpdatingStatus: false})      
        },
        error => {
        }
      );
  }

  setupSound() {
    const { settings } = this.props;
    if (!this.isSoundSetup && !this.props.room.reservation.timeConfirmedInfo && (settings && settings.reservationsNeedToConfirmInfo.value=="True")) {
      this.isSoundSetup = true;
      this.timer = setTimeout(()=>this.playSound(), 10000);
    }
  }

  playSound() {
    this.props.playSound()
    this.timer = setTimeout(()=>this.playSound(), 15000);
  }


  render() {
    const { t, room, settings } = this.props;
    const { error } = this.state;
    let openSupplyRequests = room.reservation.supplies.filter(supply=>supply.status==="open");
    let startedHelpRequests = room.reservation.supplies.filter(supply=>supply.status==="started"&&supply.isAttendantRequest);
    let hefsekDay = moment().subtract(7,'d').format('dddd');
    return (
      <div className={"body-right" + (room.reservation.attendantInRoom && startedHelpRequests.length?" attendantInRoom":"")}>
        {error!=null
        ? <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
        : null}
        {!room.reservation.timeConfirmedInfo && (settings && settings.reservationsNeedToConfirmInfo.value=="True")?
          <div>
            <div className="confirm-instructions"><Trans i18nKey="confirmHefsek">
              Please confirm your Hefsek Taharah took place last {{hefsekDay}}.
            </Trans></div>
            <Button type="circle-medium" onClick={()=> this.handleChangeStatus("ConfirmedInfo")} loading={this.state.isUpdatingStatus} text={t("Confirm")} />
          </div>      
        :
        <>
        {room.reservation.attendantInRoom && startedHelpRequests.length ?
        <>
        <div className="instructions">
          <div className="mb-3">{t("Balanit in the room.")} {t("Choose progress to continue")}:</div>
          <Button type="circle" className="mie-4" onClick={() => this.handleCompleteSupplyRequest(true)} loading={this.state.isUpdatingStatus} text={t("Balanit Exit")} />
          <Button type="circle-big" onClick={() => this.handleChangeStatus(settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True"?"Checking":"ToMikvah")} loading={this.state.isUpdatingStatus} text={t(settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True"?"Begin Checking":"Leave To Mikvah")} />
        </div>
        </>
        :
        <>
        <div className="instructions">
          {t("roomPrepInstructions")}
        </div>
        <Button type="circle-big" onClick={() => this.handleChangeStatus()} loading={this.state.isUpdatingStatus} text={t("I’m Ready")} />
        </>
        }

        <Modal isOpen={this.state.modalSupplyPrompt} toggle={this.toggleModalSupplyPrompt} className={"modal-status lang-" + i18n.language} size="full">
          <div className="modal-header">
            <Button type="button close mis-auto" onClick={this.toggleModalSupplyPrompt} aria-label="Close"><span aria-hidden="true"><img src={iconClose} alt="Close" /></span></Button>
          </div>
          <ModalBody className="d-flex flex-column text-center">
            <div className="mt-auto">
              <h3 className="h1">{t("Supplies Requested")}</h3>
              <h4>{t("supplyRequestBlockingReady", { count: openSupplyRequests.length })}</h4>
            </div>
            <div className="d-flex mt-auto justify-content-center">
              <Button type="primary" size="lg" className="mx-3" onClick={this.handleCancelSupplyRequest} disabled={this.state.isSubmittingSupplyRequest} loading={this.state.isSubmittingSupplyRequest}>{t("Cancel Supply Request")}</Button>
              <Button type="primary" size="lg" className="mx-3" onClick={this.toggleModalSupplyPrompt}>{t("Wait for Supplies")}</Button>
            </div>
          </ModalBody>
        </Modal>
        </>
      }
      </div>
    );
  }
}

export default withTranslation()(ScreenPrep);
