import React from 'react';
import { withTranslation } from 'react-i18next';

import { getAudioStreams, getAudioZones, setAudio } from './../../models/audio';
import Loader from './../../components/snippets/loader';
import { Alert } from 'reactstrap';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import Slider from '@material-ui/core/Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ManagerAudioView extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isLoadedStreams: false,
      isLoadedZones: false,
      streams: null,
      zones: null,
      error: null,
    }

    this.handleChangeStream = this.handleChangeStream.bind(this);
    this.handleChangeVolume = this.handleChangeVolume.bind(this);
    this.handleUpdateZone = this.handleUpdateZone.bind(this);
  }

  componentDidMount() {
    this.retrieveAudioStreams();
    this.retrieveAudioZones();
  }

  retrieveAudioStreams() {
    getAudioStreams()
      .then(result => {
        let streams = result;
        this.setState({
          streams,
          isLoadedStreams: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveAudioZones() {
    getAudioZones()
      .then(result => {
        let zones = result;
        this.setState({
          zones,
          isLoadedZones: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  handleChangeStream(zoneId, streamId) {
    let zones = this.state.zones.map((zone,i) => {
      if (zone.id===zoneId) {
        zone.audioStreamId = streamId;
        zone.isSubmitting = true
      }
      return zone;
    });
    this.setState({zones});
    this.handleUpdateZone(zoneId, streamId, null);
  }

  handleChangeVolume(zoneId, volume) {
    let zones = this.state.zones.map((zone,i) => {
      if (zone.id===zoneId) {
        zone.volume = volume;
      }
      return zone;
    });
    this.setState({zones});
    clearTimeout(this.volumeDebounceTimer);
    this.volumeDebounceTimer = setTimeout((e) => {
      this.handleUpdateZone(zoneId, null, Math.round(volume));    
    }, 300);
  }

  handleUpdateZone(zoneId, streamId, volume) {
    setAudio(zoneId, streamId, volume)
      .then(result => {
        this.setState({isSubmitting: false})
        this.retrieveAudioZones();
      })
  }
  
  render() {
    const { t } = this.props;
    const { isLoadedStreams, zones, streams, isLoadedZones, error } = this.state;
    return (
      <div className="reception-view">
        <div className="">
          {isLoadedStreams&&isLoadedZones?
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th width="30%"></th>
                      <th width="40%">{t("Stream")}</th>
                      <th width="30%">{t("Volume")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {zones.map((zone,i) => {
                      let zoneStream = zone.audioStream;
                      let zoneStreamName = zoneStream!==null ? zoneStream.name : t("No Stream");
                      return <tr key={i}>
                        <td>{t(zone.name)}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle caret={!zone.isSubmitting} className="btn-block">
                              {zone.isSubmitting?<Loader />:zoneStreamName}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className={zoneStream===null?"active":""} onClick={() => this.handleChangeStream(zone.id, 0)}>{t("No Stream")}</DropdownItem>
                              <DropdownItem divider />
                              {streams.map((stream,i) => {
                                return <DropdownItem key={i} className={zoneStream && zoneStream.id===stream.id?"active":""} onClick={() => this.handleChangeStream(zone.id, stream.id)}>{t(stream.name)}</DropdownItem>
                              })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon className="mie-1" icon="volume-up" />
                            <div className="mie-3">{zone.volume}</div>
                            <Slider
                              value={zone.volume}
                              steps={10}
                              valueLabelDisplay="auto"
                              onChange={(e,value) => this.handleChangeVolume(zone.id, value)}
                            />
                          </div>                          
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          : (error ?
            <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
          : <Loader />)}
        </div>
      </div>
    )
  }
}
export default withTranslation()(ManagerAudioView);
