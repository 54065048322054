import React from 'react';
import i18n from './../i18n';
import { withTranslation } from 'react-i18next';
import Clock from './../components/clock';
import RoomNumber from './../components/snippets/room-number';
import RoomStatus from './../components/snippets/room-status';
import ZmanTevilah from './../components/zman-tevilah';
import SpecialModeImage from './../assets/images/special-mode.png';

class SpecialMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { t, room, type } = this.props;
    return <div>
      <div className={'container-page view-room special-mode status-' + room.effectiveStatus.toLowerCase() + ' lang-' + i18n.language}>
        <header className="header">
          <div>
            <div className="header-left">
              <RoomNumber number={room.id} />
            </div>
            <div className="header-center">
              <Clock />
            </div>
            <div className="header-right">
              <RoomStatus status={type==='ErevYomTov'?'Yom Tov':'Shabbos'} color="green" />
            </div>
          </div>
          <div>
            <div className="header-left">
            </div>
            <div className="header-center">
              <ZmanTevilah />
            </div>
            <div className="header-right">
            </div>
          </div>
        </header>
        <div className="container-body">
          <div>
            <img src={SpecialModeImage} className="image" alt="Special Mode" />
          </div>
          <div>
            <div className="display-1">{t('Good '+(type==='ErevYomTov'?'Yom Tov':'Shabbos'))}</div>
            <div className="h5">{t("specialModeInstructions")}</div>
          </div>
        </div>
      </div>                
    </div>
  }
}

export default withTranslation()(SpecialMode);