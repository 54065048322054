import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Moment from 'react-moment';
import { Alert } from 'reactstrap';
import { statuses } from './../../global-props';
import i18n from './../../i18n';

import { getUsageReport, getListReport } from './../../models/reservation';
import { getPaymentReport } from './../../models/payment';
import ReceptionistTabs from '../receptionist/partials/tabs';
import CurrencyFormat from './../../components/snippets/currency-format';
import NumberFormat from "react-number-format";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Loader from './../../components/snippets/loader';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/he';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/button';
import { CSVLink } from 'react-csv';

class UsageReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isLoadedListReport: false,
      isLoadedUsageExport: false,
      usageReport: null,
      enteredPhone: '',
      selectedDays: { from: moment().subtract(1, 'months').toDate(), to: moment().toDate() },
    };

    this.changeDay = this.changeDay.bind(this);
    this.retrieveUsageReport = this.retrieveUsageReport.bind(this);
    this.generateListCSV = this.generateListCSV.bind(this);
    this.generatePaymentCSV = this.generatePaymentCSV.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const dt = location&&location.state&&location.state.dt;
    const dtTo = location&&location.state&&location.state.dtTo;
    if(dt){
      this.retrieveUsageReport(moment(dt).format('YYYY-MM-DD'),dtTo?moment(dtTo).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'));
      this.setState({
        selectedDays: {from:moment(dt).toDate(), to: dtTo?moment(dtTo).toDate():moment().toDate()}
      })
    } else{
      this.retrieveUsageReport();
    }
  }

  retrieveUsageReport(date=null, dateTo=null) {
    this.setState({isLoadedListReport: false})
    const { selectedDays } = this.state;
    getUsageReport(date===null ? moment(selectedDays.from).format('YYYY-MM-DD') : date, dateTo===null ?moment(selectedDays.to).format('YYYY-MM-DD'):dateTo)
      .then(
        result => {
          this.setState({
            usageReport: result,
            isLoaded: true,
          }, () => this.generateUsageCSV())
          this.generateListCSV(date,dateTo)
          this.generatePaymentCSV(date,dateTo)
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      );      
  } 

  changeDay(day, type) {
    const { selectedDays } = this.state;
    const { from, to } = selectedDays;
    let setSelectedDays;
    let diff = to ? moment(to).diff(moment(from),'days') + 1 : 1;

    if (day==='today') {
      setSelectedDays = { from: moment().toDate(), to: null };
    } else if (day==='prev') {
      setSelectedDays = { from: moment(from).subtract(diff, 'days').toDate(), to: to ? moment(to).subtract(diff, 'days').toDate() : null };
    } else if (day==='next') {
      setSelectedDays = { from: moment(from).add(diff, 'days').toDate(), to: to ? moment(to).add(diff, 'days').toDate() : null };
    } else {
      if (type==='from') {
        setSelectedDays = {...selectedDays, from: day}
      } else {
        setSelectedDays = from ? {...selectedDays, to: day} : {from: day, to: day};
      }
    }

    this.setState({ selectedDays: setSelectedDays, enteredPhone: '', selectedUser: null, userResults: null, error: null }, () => {const {selectedDays } = this.state;this.retrieveUsageReport(moment(selectedDays.from).format('YYYY-MM-DD'), (selectedDays.to ? moment(selectedDays.to).format('YYYY-MM-DD') : null))});
  }

  generateListCSV(date=null, dateTo=null){
    const { t } = this.props;
    const {selectedDays} = this.state;
    getListReport(date===null ? moment(selectedDays.from).format('YYYY-MM-DD') : date, dateTo===null ?moment(selectedDays.to).format('YYYY-MM-DD'):dateTo)
      .then(
        result => {
          let reservations = result;
          const csvExport = reservations&&reservations.length?[[t('User ID'), t('Code'), t('New User'), t('Test User'), t('Date'), t('Time'), t('Zman Tevilah') , t('Open Time') , t('Last Entry Time'), t('Status'), t('Room Type'), t('Tip'), t('Donation'), t('Balance'), t('Room'), t('Attendant'), t('Checked In By'), t('Arrived'), t('Assigned'), t('Time In Prep'), t('Supplies Requested'), t('Wait For Supplies Time'), t('Wait For Checking Time'), t('Wait For Mikvah Time'), t('Time In Mikvah'), t('Time In Dressing'), t('Completed'), t('Notes')], ...reservations.map(res=>[ 
            res.userId,
            '=""' + res.reservationCode + '""',
            (res.firstTime?'*':''),
            (res.user.testUser?t('Test'):''),
            moment(res.reservationDate).format(t('MM/DD/YY')),
            (res.advanceReservation?moment(res.reservationTime).format(t('h:mma')):res.user.phoneNumber.slice(-10)=='0000000000'?t("Guest"):t("Walk-in")),
            (res.zmanTevillah?moment(res.zmanTevillah).format(t('h:mma')):''),
            (res.openTime?moment(res.openTime).format(t('h:mma')):''),
            (res.lastEntryTime?moment(res.lastEntryTime).format(t('h:mma')):''),
            t(statuses[res.reservationStatus].name),
            res.kallah?t('Kallah'):((res.roomType==='bath'?t('Bath'):'')+(res.roomType==='shower'?t('Shower'):'')+(res.roomType==='handicap'?t('Accessible'):'')) + (res.parking?' - ' + t('Parking'):'') + (res.doubleSlot?' - ' + t('Extra Time'):'') + (res.salonManicure||res.salonBoth?' - ' + t('Salon'):''),
            res.tipAmount!=null?'$' + res.tipAmount:'',
            res.donationAmount!=null?'$' + res.donationAmount:'',
            res.paid?t('Paid'):'$' + res.balance,
            res.room!=null?res.room.id:'' + (['Done', 'Canceled'].includes(res.reservationStatus)?res.roomId:''),
            res.attendant!=null?res.attendant.firstName + ' ' + res.attendant.lastName:'',
            res.checkedInByUser!=null?res.checkedInByUser:'',
            res.timeArrived!=null?moment(res.timeArrived).format(t('h:mma')):'',
            res.timeAssigned!=null?moment(res.timeAssigned).format(t('h:mma')):'',
            res.durationPrep!=null?moment.duration(res.durationPrep).format(t("*h:mm:ss")):'',
            res.suppliesItems!=null?t(res.suppliesItems):'',
            res.durationWaitForSupplies!=null?moment.duration(res.durationWaitForSupplies).format(t("*h:mm:ss")):'',
            res.durationWaitForChecking!=null?moment.duration(res.durationWaitForChecking).format(t("*h:mm:ss")):'',
            res.durationWaitForMikvah!=null?moment.duration(res.durationWaitForMikvah).format(t("*h:mm:ss")):'',
            res.durationInMikvah!=null?moment.duration(res.durationInMikvah).format(t("*h:mm:ss")):'',
            res.durationDressing!=null?moment.duration(res.durationDressing).format(t("*h:mm:ss")):'',
            res.timeCompleted!=null?moment(res.timeCompleted).format(t('h:mma')):'',
            (res.notes!=null?res.notes:'') + (res.user.notes!=null?res.user.notes:'')
            ])]:null;
          const csvFilename = `reservations-${moment(selectedDays.from).format(t('MM-DD-YYYY')) + (selectedDays.to ? '-' + moment(selectedDays.to).format(t('MM-DD-YYYY')) : '')}.csv`;
          this.setState({csvExport,csvFilename, isLoadedListReport: true})      
        },
        error => {
          this.setState({
            error
          });
        }
      );      
  }

  generatePaymentCSV(date=null, dateTo=null){
    const { t } = this.props;
    const {selectedDays} = this.state;
    getPaymentReport(date===null ? moment(selectedDays.from).format('YYYY-MM-DD') : date, dateTo===null ?moment(selectedDays.to).format('YYYY-MM-DD'):dateTo)
      .then(
        result => {
          let payments = result;
          const csvPaymentsExport = payments&&payments.length?[[t('Date'), t('User'), t('Amount'), t('Method'), t('Payment Id'), t('Transaction Id'), t('Reservation Id'), t('Attendant'), t('Status')], ...payments.map(pay=>[ 
            moment(pay.dateCreated).format(t('MM/DD/YY')),
            '=""' + `${pay.user&&pay.user.phoneNumber?pay.user.phoneNumber.substring(pay.user.phoneNumber.length-4):null}` + '""',
            '$' + pay.amount,
            t(pay.method),
            pay.id,
            pay.transactionID,
            pay.reservationID,
            pay.reservation?pay.reservation.attendantId:null,
            t(pay.status),
            ])]:null;
          const csvPaymentsFilename = `payments-${moment(selectedDays.from).format(t('MM-DD-YYYY')) + (selectedDays.to ? '-' + moment(selectedDays.to).format(t('MM-DD-YYYY')) : '')}.csv`;
          this.setState({csvPaymentsExport,csvPaymentsFilename, isLoadedPaymentsExport: true})      
        },
        error => {
          this.setState({
            error
          });
        }
      );      
  }

  generateUsageCSV(){
    const { t } = this.props;
    const {usageReport, selectedDays} = this.state;
    const csvUsageExport = usageReport&&Object.keys(usageReport).length?[[t('Item'), t('Value')],
      [t('Total Reservations'), usageReport.reservations],
      [t('Bath'),usageReport.reservationsBath],
      [t('Shower'),usageReport.reservationsShower],
      [t('New Users'),usageReport.reservationsNewUser],
      [t('Reception Check In'),usageReport.reservationsCheckedInByReception],
      [t('Kiosk Check In'),usageReport.reservationsCheckedInByKiosk],
      [t('Extra Time') , usageReport.reservationsDoubleSlot],
      // ['Parking', usageReport.reservationsParking],
      [t('Accessible'), usageReport.reservationsHandicapped],
      [t('Kallah'), usageReport.reservationsKallah],
      [t('Avg. On Time for Reservation'), moment.duration(usageReport.durationArrivedVsScheduled).format(t("*h:mm:ss"))],
      [t('Avg. Bath Prep Time'), moment.duration(usageReport.durationPrepBath).format(t("*h:mm:ss"))],
      [t('Avg. Shower Prep Time'), moment.duration(usageReport.durationPrepShower).format(t("*h:mm:ss"))],
      [t('Avg. To Deliver Supplies'), moment.duration(usageReport.durationWaitForSupplies).format(t("*h:mm:ss"))],
      [t('Time ready for Checking (Avg.)'), moment.duration(usageReport.durationWaitForChecking).format(t("*h:mm:ss"))],
      [t('Avg. Ready For Mikvah'), moment.duration(usageReport.durationWaitForMikvah).format(t("*h:mm:ss"))],
      [t('Avg. Bath Reservation Total Time'), moment.duration(usageReport.durationTotalBath).format(t("*h:mm:ss"))],
      [t('Avg. Shower Reservation Total Time'), moment.duration(usageReport.durationTotalShower).format(t("*h:mm:ss"))],
      [t('Avg. Room Cleaning Time'),moment.duration(usageReport.durationCleaning).format(t("*h:mm:ss"))],
    ].concat(usageReport.supplies.length? usageReport.supplies.map(supply => [supply.item, supply.quantity]): []):null;
    const csvUsageFilename = `usage-${moment(selectedDays.from).format(t('MM-DD-YYYY')) + (selectedDays.to ? '-' + moment(selectedDays.to).format(t('MM-DD-YYYY')) : '')}.csv`;
    this.setState({csvUsageExport,csvUsageFilename, isLoadedUsageExport: true})      
  }

  render() {
    const { t } = this.props;
    const { isLoaded, isLoadedListReport, isLoadedUsageExport, isLoadedPaymentsExport, selectedDays, error, usageReport, csvExport, csvFilename, csvUsageExport, csvUsageFilename, csvPaymentsExport, csvPaymentsFilename } = this.state;
    const currentPageName = "Reports";

    return (
      <div className={'container-page view-manager lang-' + i18n.language}>
        <div className="container-main">
          <div className="container container-body manager-background">
            <ReceptionistTabs page="admin"/>
            <div className="reception-view">
              <div className="mb-3 d-flex justify-content-center">
                <div className="text-center">
                  <div className="form-group d-flex align-items-end justify-content-center">
                    <label className="mie-2">{t("Date")}:</label> 
                    <DayPickerInput format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,selectedDays: [selectedDays.from, {from: selectedDays.from, to: selectedDays.to}], modifiers: {start: selectedDays.from, end: selectedDays.to ? selectedDays.to : selectedDays.from}, disabledDays: { after: selectedDays.to }}} formatDate={formatDate} parseDate={parseDate} value={selectedDays.from} onDayChange={day=>this.changeDay(day,'from')} placeholder={t("MM/DD/YY")} />
                    <div className="mb-2 mx-1">&mdash;</div>
                    <DayPickerInput format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,selectedDays: [selectedDays.from, {from: selectedDays.from, to: selectedDays.to}], modifiers: {start: selectedDays.from, end: selectedDays.to ? selectedDays.to : selectedDays.from}, disabledDays: { before: selectedDays.from }}} formatDate={formatDate} parseDate={parseDate} value={selectedDays.to?selectedDays.to:''} onDayChange={day=>this.changeDay(day,'to')} placeholder={t("MM/DD/YY")} />
                  </div>
                  <div class="d-flex justify-content-center">
                    {csvExport && isLoadedListReport?<div><CSVLink className="btn btn-secondary mie-2" data={csvExport} filename={csvFilename} target="_blank">{t("Download Reservation Details")}</CSVLink></div>:isLoadedListReport && !csvExport?null:<Loader />}
                    {csvUsageExport && isLoadedUsageExport?<div className=""><CSVLink className="btn btn-secondary mie-2" data={csvUsageExport} filename={csvUsageFilename} target="_blank">{t("Download Summary")}</CSVLink></div>:isLoadedUsageExport && !csvUsageExport?null:<Loader />}
                    {csvPaymentsExport && isLoadedPaymentsExport?<div className=""><CSVLink className="btn btn-secondary" data={csvPaymentsExport} filename={csvPaymentsFilename} target="_blank">{t("Download Payments")}</CSVLink></div>:isLoadedPaymentsExport && !csvPaymentsExport?null:<Loader />}
                  </div>
                </div>
              </div>
              {error!==null?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :
                ( isLoaded ?
                  <div>
                    <h4 className="text-center font-weight-bold">{t("Summary")}</h4>
                    <ul className="usage-summary">
                      <li>
                        <div>{t("Total Reservations")}</div>
                        <div className="font-weight-bold">{usageReport.reservations}</div>
                      </li>
                      <li>
                        <div>{t("Bath")}</div>
                        <div>{usageReport.reservationsBath}</div>
                      </li>
                      <li>
                        <div>{t("Shower")}</div>
                        <div>{usageReport.reservationsShower}</div>
                      </li>
                      <li>
                        <div>{t("New Users")}</div>
                        <div>{usageReport.reservationsNewUser}</div>
                      </li>
                      <li>
                        <div>{t("Reception Check In")}</div>
                        <div>{usageReport.reservationsCheckedInByReception}</div>
                      </li>
                      <li className="divider">
                        <div>{t("Kiosk Check In")}</div>
                        <div>{usageReport.reservationsCheckedInByKiosk}</div>
                      </li>
                      <li>
                        <div className="font-weight-bold">{t("Options")}</div>
                      </li>
                      <li>
                        <div>{t("Extra Time")}</div>
                        <div>{usageReport.reservationsDoubleSlot}</div>
                      </li>
                      {/* <li>
                        <div>Parking</div>
                        <div>{usageReport.reservationsParking}</div>
                      </li> */}
                      <li>
                        <div>{t("Accessible")}</div>
                        <div>{usageReport.reservationsHandicapped}</div>
                      </li>
                      <li className="divider">
                        <div>{t("Kallah")}</div>
                        <div>{usageReport.reservationsKallah}</div>
                      </li>
                      <li>
                        <div className="font-weight-bold">{t("Time Averages")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. On Time for Reservation")}</div>
                        <div>{moment.duration(usageReport.durationArrivedVsScheduled).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. Bath Prep Time")}</div>
                        <div>{moment.duration(usageReport.durationPrepBath).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. Shower Prep Time")}</div>
                        <div>{moment.duration(usageReport.durationPrepShower).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. To Deliver Supplies")}</div>
                        <div>{moment.duration(usageReport.durationWaitForSupplies).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Time ready for Checking (Avg.)")}</div>
                        <div>{moment.duration(usageReport.durationWaitForChecking).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. Ready For Mikvah")}</div>
                        <div>{moment.duration(usageReport.durationWaitForMikvah).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. Bath Reservation Total Time")}</div>
                        <div>{moment.duration(usageReport.durationTotalBath).format("*h:mm:ss")}</div>
                      </li>
                      <li>
                        <div>{t("Avg. Shower Reservation Total Time")}</div>
                        <div>{moment.duration(usageReport.durationTotalShower).format("*h:mm:ss")}</div>
                      </li>
                      <li className="divider">
                        <div>{t("Avg. Room Cleaning Time")}</div>
                        <div>{moment.duration(usageReport.durationCleaning).format("*h:mm:ss")}</div>
                      </li>
                      {usageReport.supplies.length?
                        <li>
                          <div className="font-weight-bold">{t("Supplies Requested")}</div>
                        </li>
                      :null}
                      {usageReport.supplies.map((supply,i) => {
                        return <li className={usageReport.supplies.length-1==i?'divider':''} key={i}>
                          <div>{t(supply.item)}</div>
                          <div>{t(supply.quantity)}</div>
                          </li>
                      })}
                      <li>
                        <div className="font-weight-bold">{t("Payments")}</div>
                      </li>
                      {usageReport.payments.map((item,i) => {
                        return <li className={usageReport.payments.length-1==i && usageReport.merchandise.length?"divider":""} key={i}>
                          <div>{t(item.method)}:</div>
                          <div>
                            <NumberFormat
                              prefix={'$'}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              value={item.amount}
                              displayType={'text'}
                            />
                          </div>
                        </li>
                      })}
                      {usageReport.merchandise.length?
                      <>
                        <li>
                          <div className="font-weight-bold">{t("Merchandise")}</div>
                        </li>
                        {usageReport.merchandise.map((item,i) => {
                          return <li key={i}>
                            <div>{item.item}:</div>
                            <div>{item.quantity} {t("Sold")}</div>
                            <div>
                              <NumberFormat
                                prefix={'$'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={item.total}
                                displayType={'text'}
                              />
                            </div>
                          </li>
                        })}
                        </>
                        :null}
                    </ul>
                  </div>
                : <Loader /> )
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UsageReport);