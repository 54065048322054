import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import AttendantListView from './partials/attendantList';
import RoomSummary from '../receptionist/room-summary';
import Loader from '../../components/snippets/loader';
import Button from '../../components/button';
import Clock from '../../components/clock';
import Emergency from '../../components/emergency';
import ZmanTevilah from '../../components/zman-tevilah';
import Shkia from '../../components/shkia';
import SignalR from '../../components/signalr';
import LanguageSelector from '../../components/language-selector';
import ProgressBar from '../../components/snippets/progress-bar';
import { getRooms } from '../../models/room';
import { getSections } from '../../models/section';
import { getAttendants } from './../../models/attendant';
import { getReservations } from './../../models/reservation';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import moment from 'moment';
import i18n from './../../i18n';
import { SettingsContext } from '../../components/settings';
import SpecialModeImage from '../../assets/images/special-mode.png';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';

class AttendantAllTasks extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoadedRooms: false,
      modal: false,
      selectedRoom: null,
      error: null,
      showAllRooms: false,
      isLoadedReservations: false,
      reservations: null,
    };
     
    this.notificationSound = new Audio(NotificationSoundFile);
    this.playNotificationSound = this.playNotificationSound.bind(this);
  }

  componentDidMount() {
    this.retrieveReservations();
    this.retrieveRooms();
    this.retrieveSections();
    this.retrieveAttendants();
  }

  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result;

          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            errorReservations: error
          });
        }
      );
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.processRooms(rooms);
          this.setState({
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  retrieveSections() {
    getSections()
      .then(
        result => {
          let sections = result;
          this.setState({
            isLoadedSections: true,
            sections,
          });
        },
        error => {
          this.setState({
            isLoadedSections: false,
            error
          });
        }
      );
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  processRooms(rooms) {
    const {settings} = this.context;
    let supplies = rooms.map((room, i) => {
      let hasActiveRequest = false;
      if ( settings && room.reservation!=null ) {
        room.reservation.supplies.forEach((supply) => {
          if (supply.status === 'open') { hasActiveRequest = true; }
          supply.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes()>settings.waitingForSuppliesMinutes.value;
          supply.overTimeMinutes = supply.isOverTime?moment.duration(moment(this.state.time).subtract(settings.waitingForSuppliesMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes():null;
        });
        room.reservation.room = { id: room.id, section: room.section };
      }
      if (settings && (room.effectiveStatus==='ReadyForMikvah'||room.effectiveStatus==='ReadyForMikvahNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForMikvahMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForMikvahMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      if (settings && (room.effectiveStatus==='ReadyForChecking'||room.effectiveStatus==='ReadyForCheckingNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForCheckingMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForCheckingMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      return hasActiveRequest ? room.reservation : null;
    }).filter(room => room!=null);
    this.setState({
      isLoadedRooms: true,
      supplyRequests: supplies,
      rooms: rooms,
    });
  }

  playNotificationSound(isReadyMikvah) {
    const {settings} = this.context;
    if((settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True")&&isReadyMikvah){
      return false;
    } else{
      this.notificationSound.play();
    }
  }

  render() {
    const { t } = this.props;
    const { rooms, isLoadedRooms, isLoadedSections,sections, isLoadedAttendants, attendants, supplyRequests, isLoadedReservations, reservations } = this.state;
    const {settings} = this.context;
    let kallahReservationsCount = isLoadedReservations && reservations.all.filter(res=>res.kallah).length;
    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} onReservationsUpdated={() => this.retrieveReservations()} onSectionsUpdated={() => this.retrieveSections()} onNewSupplyRequest={() => this.playNotificationSound()} onNewReadyForMikvah={() => this.playNotificationSound(true)} onNewReadyForChecking={() => this.playNotificationSound()} />
        <div className={'container-page view-attendant-sectionmap view-all-tasks view-tablet lang-' + i18n.language}>
          <div className="container-main">
          <header className="header">
              <div>
                <div className="header-left all-task-zmanim">
                  <Shkia />
                  <ZmanTevilah />
                </div>
                <div className="header-center">
                {isLoadedRooms && rooms.length?                
                  <RoomSummary hideTitle={true} rooms={rooms} />
                :null}
                {isLoadedReservations?
                <>
                <div className="res-overview">
                  <div className="reservation-count">
                    <div className="title d-flex justify-content-between">
                    {t("Reservation Overview")} 
                      <span>
                        {kallahReservationsCount?<span className="mie-3"><FontAwesomeIcon className="mie-1" icon={['far', 'gem']} />{kallahReservationsCount}</span>:null}
                        <span className="num d-inline-block">{reservations.all.length}</span>
                      </span>
                    </div>
                  <ProgressBar reservations={reservations} />
                  <div className="row text-center">
                    <div className="col">{reservations.completed.length} {t("CompletedRooms")}</div>
                    <div className="col">{reservations.here.length} {t("Here")}</div>
                    <div className="col">{reservations.upcoming.length} {t("Upcoming")}</div>
                  </div></div></div></>
                :null}
                </div>
                <div className="header-right">
                  <Clock />
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <LanguageSelector />
            {isLoadedRooms?
            <>
            <div className="allTasks-container d-flex flex-wrap h-100">
              {settings!== null && ['Friday', 'ErevYomTov'].includes(settings.scheduleType.value)?
                <div className="col-12 special-mode text-center">
                  {settings!== null && moment().isBefore(moment(settings.zmanTevillah.value))?
                    <div>
                      <Alert color="warning">{t('Please Note')}: {t('Tevilah begins at')} <Moment format={t("h:mma")}>{settings.zmanTevillah.value}</Moment>.</Alert>
                    </div>
                  :null}
                  <div>
                    <img src={SpecialModeImage} className="image" alt="Special Mode" />
                  </div>
                  <div>
                    <div className="display-1">{t('Good '+(settings!== null && settings.scheduleType.value==='ErevYomTov'?'Yom Tov':'Shabbos'))}</div>
                  </div>
                </div>
                :
              ['1','2','3','4','5','6'].map(sec=>{
              return (<div key={sec} className="col-4 mb-4 d-flex">
                <AttendantListView allTaskView={true} isLoadedRooms={rooms} rooms={rooms} isLoadedSections={isLoadedSections} sections={sections} isLoadedAttendants={isLoadedAttendants} attendants={attendants} supplyRequests={supplyRequests} id={sec} />
              </div>)
              })}
            </div>
            <Emergency rooms={rooms} />
            </>
            :<Loader />}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AttendantAllTasks);