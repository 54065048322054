import React from 'react';

import { withTranslation } from 'react-i18next';
import RoomNumber from '../../../components/snippets/room-number';
import RoomStatus from '../../../components/snippets/room-status';
import moment from 'moment'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Collapse } from 'reactstrap';
import Button from './../../../components/button';
import { updateCubicleStatus, unlockCubicle } from './../../../models/room';
import { statuses } from './../../../global-props';
import { getReservation } from './../../../models/reservation';
import { SettingsContext } from "./../../../components/settings";
import { Link } from 'react-router-dom';
import i18n from './../../../i18n';


class CubicleDetails extends React.Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      time: null,
      modal: false,
      isUpdatingStatus: false,
      isStatusToggleOpen: false,
      reservationDetails: null,
      waitingReservation: null,
      isOpenStatus: false,
      cubicleStatuses: [
        'Offline',
        'Available',
        'NeedsCleaning',
      ],
    }
    this.modalToggle = this.modalToggle.bind(this);
    this.modalCubicleScreenToggle = this.modalCubicleScreenToggle.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.statusToggle = this.statusToggle.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidUpdate(prevProps) {
    if ( this.props.cubicle!==prevProps.cubicle ) {
      this.setState({
        modal: true,
        isUpdatingStatus: false,
        isOpenStatus: false,
      })
      if (this.props.cubicle&&this.props.cubicle.reservation&&(!prevProps.cubicle|| this.props.cubicle.reservation.reservationId!==prevProps.cubicle.reservation.reservationId)) {
        this.retrieveReservationDetails();
      }
    }
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  retrieveReservationDetails() {
    getReservation(this.props.cubicle.reservation.reservationId)
      .then(result=>this.setState({reservationDetails: result}));
  }

  modalToggle() {
    this.props.modalToggle();
    this.setState(state => {
      state.modal = !this.state.modal;
      return state;
    });
  }

  modalCubicleScreenToggle() {
    this.setState({
      modalCubicleScreen: !this.state.modalCubicleScreen,
    });
  }

  statusToggle() {
    this.setState({isStatusToggleOpen: !this.state.isStatusToggleOpen});
  }

  handleChangeStatus(status) {
    const cubicleId = this.props.cubicle.id;
    this.setState({
      isUpdatingStatus: true,
    });
    updateCubicleStatus(cubicleId, status)
      .then(
        result => {
          this.props.handleCubiclesUpdated(result);
        },
        error => {
          this.setState({
            error
          });
        }
      ).finally(result => this.setState({isUpdatingStatus:false}));
  }

  handleUnlockDoor = () => {
    this.setState({isUnlockingDoor: true});
    unlockCubicle(this.props.cubicle.id)
      .then(result => {
        this.setState({isUnlockingDoor: false, showDoorUnlocked: true});
        setTimeout(() => {
          this.setState({showDoorUnlocked: false});
        }, 3000);
      }, error => {
        this.setState({error: error, isUnlockingDoor: false})
      });
  }

  render() {
    const { t, cubicle } = this.props;
    const { time, cubicleStatuses, isUpdatingStatus, isStatusToggleOpen, isOpenStatus, reservationDetails, isUnlockingDoor, showDoorUnlocked, error } = this.state;
    const { settings } = this.context;
    let reservationMinutesTotal;

    if (settings!==null&&cubicle&&cubicle.reservation&&cubicle.reservation.reservationId) {
      let reservationMinutes = parseFloat(settings['reservationMinutes'+(cubicle.reservation.bath?'Bath':'Shower')].value);
      let reservationMinutesDoubleSlot = parseFloat(settings.reservationMinutesDoubleSlot.value);
      reservationMinutesTotal = reservationMinutes + (cubicle.reservation.doubleSlot ? reservationMinutesDoubleSlot : 0);
    }

    if ( cubicle!=null ) {
      return <>
        <Modal isOpen={this.state.modal} toggle={this.modalToggle} centered={true} size="xl" className={"lang-" + i18n.language} modalClassName="modal-room-details modal-cubicle-details">
          <div className="modal-header">
            <RoomNumber title="Cubicle" number={cubicle.id} />
            {cubicle.reservation!==null?
              <div>
                <div className="title">{t("User")}</div> 
                <div><Link to={{pathname: "../user/", state: { userId: cubicle.reservation.user.id }}}>{cubicle.reservation.reservationCode}</Link></div>
              </div>
            :null}
            {/* {cubicle.reservation&&cubicle.reservation.waitingCode?
              <div>
                <div className="title">Waiting Code</div> 
                <div>{cubicle.reservation.waitingCode}</div>
              </div>                
            :null} */}
            <Dropdown className="dropdown-cubicle-status" isOpen={isStatusToggleOpen} toggle={this.statusToggle}>
              <DropdownToggle tag="div">
                <RoomStatus title="Cubicle Status" status={cubicle.statusName} loading={isUpdatingStatus} color={cubicle.statusColor} dropdown />
              </DropdownToggle>
              <DropdownMenu>
                {cubicleStatuses.map((s,i) => {
                  let status = statuses[s];
                  // First, hide current status from list.
                  if (status.name!==cubicle.statusName) {
                    return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s)}>
                      {status.changeRequirements!=="no-reservation"?
                        <div>{t(status.name)}</div>
                      :status.name}
                    </DropdownItem>
                  } else {
                    return null;
                  }
                })}
              </DropdownMenu>
            </Dropdown>
            <button type="button" onClick={this.modalToggle}className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <ModalBody>
            <div className="col-main">
              {error?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :null}
              {cubicle.reservation!==null ?
                <>
                  <div className="collapse-container">
                    <div className={"collapse-header" + (isOpenStatus?"":" collapsed")} onClick={()=>this.setState(state => ({isOpenStatus: !state.isOpenStatus}))}>
                      <div className="title">
                        <div>{t("Current Session")}</div>
                      </div>
                      <div className="meta">
                        <div><span className="label">{t("Slot")}:</span> {cubicle.reservation.advanceReservation?<><Moment format="h:mm" date={cubicle.reservation.reservationTime} />-<Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}} date={cubicle.reservation.reservationTime} /></>:<>{t("Walk-in")}</>}</div>
                        <div><span className="label">{t("Arrived")}:</span> <Moment format={t("h:mma")} date={cubicle.reservation.timeArrived} /></div>
                      </div>
                    </div>
                    <Collapse isOpen={isOpenStatus}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Status")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Duration")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservationDetails!==null?
                            reservationDetails.statusHistory.map((s,i)=>{
                              if (s.previousReservationStatus==='Reserved') {
                                s.previousStatusChange=reservationDetails.reservationCreated;
                              }
                              return <React.Fragment key={i}>
                                <tr>
                                  <td>{t(s.previousReservationStatus)}</td>
                                  <td><Moment format={t("M/D h:mma")} date={s.previousStatusChange} /></td>
                                  <td>{moment.duration(moment(s.previousStatusChange).diff(s.dateCreated)).asSeconds()?
                                    moment.duration(moment(s.dateCreated).diff(s.previousStatusChange)).format("h:*mm:ss")
                                  :'0:00'}</td>

                                </tr>
                                {i===reservationDetails.statusHistory.length-1?
                                  <tr>
                                    <td>{t(s.newReservationStatusTitle)}</td>
                                    <td><Moment format={t("M/D h:mma")} date={s.dateCreated} /></td>
                                    <td>{moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(s.dateCreated)).format("h:*mm:ss")}</td>

                                  </tr>
                                :null}
                              </React.Fragment>
                            })
                          :null}
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                </>
              :
                <>
                  <Alert color="warning" className="text-center">{t("No associated reservation.")}</Alert>
                </>
              }
            </div>
            <div className="col-side">
              <div className="buttons">
                <div>
                  <Button type="circle" loading={isUnlockingDoor} disabled={showDoorUnlocked} onClick={this.handleUnlockDoor} icon={<FontAwesomeIcon icon={showDoorUnlocked?"check":"door-open"} />} />
                  <div className="title">{t("Unlock")}</div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalCubicleScreen} toggle={this.modalCubicleScreenToggle} centered size="xl" className={"lang-" + i18n.language} modalClassName="modal-cubicle-screen">
          <ModalHeader toggle={this.modalCubicleScreenToggle}>{t("Cubicle")} {cubicle.id}</ModalHeader>
          <ModalBody>
            {this.state.modalCubicleScreen?
              <iframe title="Cubicle Details" src={'/cubicle/'+cubicle.id} />
            :null}
          </ModalBody>
        </Modal>
      </>
    } else {
      return null;
    }
  }
}

export default withTranslation()(CubicleDetails);
