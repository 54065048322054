import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { SettingsContext } from './../components/settings';

class _StripeProvider extends React.Component {
  static contextType = SettingsContext;
  
  constructor() {
    super();
    this.state = {
      stripe: null,
    }
  }

  componentDidMount() {
    let {settings} = this.context;
    let stripe_pk = settings.stripePK.value;
    if ( window.Stripe ) {
      this.setState({
        stripe: window.Stripe(stripe_pk),
      })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe(stripe_pk),
        })
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements fonts={[{family: 'Lato', cssSrc: 'https://fonts.googleapis.com/css?family=Lato'}]}>
          {this.props.children}
        </Elements>
      </StripeProvider>
    );
  }
}

export default _StripeProvider;
