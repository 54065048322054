import React, { Component } from 'react';

import ReceptionistTabs from './partials/tabs';
import CheckinCode from "./partials/checkinCode";
import ReservationSetup from './partials/reservationSetup';
import ReservationCalendar from './partials/reservationCalendar';
import CheckinPayment from './partials/checkinPayment';
import ReservationKallah from './partials/reservationKallah';
import { getReservation } from './../../models/reservation';
import i18n from './../../i18n';

class ReceptionistReservationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      view: !props.reservation?"reservation-calendar":"checkin-payment",
      reservation: props.reservation&&props.reservation.reservationId?{user:{},roomType:''}:props.reservation,
      history: [!props.reservation?"reservation-calendar":"checkin-payment"],
      isEditingReservation: props.isEditingReservation,
    };
    this.handleScreenChange = this.handleScreenChange.bind(this);
    this.handleUpdateReservation = this.handleUpdateReservation.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (this.props.reservation&&this.props.reservation.reservationId) {
      getReservation(this.props.reservation.reservationId)
        .then(result=>this.setState({reservation: result}))
    }
    if(this.props.kallah){
      this.setState({view: 'reservation-kallah'});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props ) {
      this.handleUpdateReservation({user:{},roomType:''}, 'reservation-calendar');
    }
  }

  handleScreenChange(view) {
    this.setState({
      view: view,
      history: [...this.state.history, view]
    })
  }

  handleUpdateReservation(res, screen, updated=false) {
    return new Promise((resolve, reject) => {
      this.setState({
        reservation: res,
        updated
      }, () => {
        if ( screen ) {
          this.handleScreenChange(screen);
        }
        return resolve(true)
      })
    })
  }

  goBack() {
    let newHistory = [...this.state.history];
    newHistory.pop()
    let prevView = newHistory[newHistory.length-1];
    if(prevView){
      this.setState({
        view: prevView,
        history: newHistory
      })
    } else {
      this.setState({
        view: 'reservation-calendar',
      })
    }
  }

  currentView() {
    switch (this.state.view) {
      case 'checkin-code':
        return <CheckinCode isEditingReservation={this.state.isEditingReservation} userId={this.props.userId} reservation={this.state.reservation} handleUpdateReservation={this.handleUpdateReservation} handleScreenChange={this.handleScreenChange} isAdvancedReservation={true} />;
      case 'reservation-calendar':
        return <ReservationCalendar isEditingReservation={this.state.isEditingReservation} userId={this.props.userId} reservation={this.state.reservation} handleUpdateReservation={this.handleUpdateReservation} handleScreenChange={this.handleScreenChange} goBack={this.goBack}/>;
      case 'reservation-setup':
        return <ReservationSetup isEditingReservation={this.state.isEditingReservation} userId={this.props.userId} reservation={this.state.reservation} handleUpdateReservation={this.handleUpdateReservation} handleScreenChange={this.handleScreenChange} isAdvancedReservation={true} goBack={this.goBack} />;
      case 'checkin-payment':
        return <CheckinPayment isEditingReservation={this.state.isEditingReservation} reservation={this.state.reservation} handleUpdateReservation={this.handleUpdateReservation} handleScreenChange={this.handleScreenChange} isAdvancedReservation={true} updated={this.state.updated} goBack={this.goBack} />;
      case 'reservation-kallah':
        return <ReservationKallah isEditingReservation={this.state.isEditingReservation} reservation={this.state.reservation} handleUpdateReservation={this.handleUpdateReservation} handleScreenChange={this.handleScreenChange} isAdvancedReservation={true} updated={this.state.updated} goBack={this.goBack} />;
      default:
        return true;
    }
  }

  render() {
    const { view } = this.state;
    return (
      <div className={'container-page view-receptionist-checkin lang-' + i18n.language}>
        <div className="container-main">
          <div className={"container-body reception-background" + (view=='reservation-setup'?' background-setup':'')}>
            <ReceptionistTabs />
            <div className={"container-checkin " + view}>
              {this.currentView()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReceptionistReservationView;