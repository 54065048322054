import React from 'react';
import { withTranslation } from 'react-i18next';

import { getSchedule } from './../../models/setting';
import Loader from './../../components/snippets/loader';
import { Alert } from 'reactstrap';
import Button from './../../components/button';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import Moment from 'react-moment';
import { scheduleTypes } from './../../global-props';
import { updateSchedule } from '../../api';
import i18n from './../../i18n';

class ManagerScheduleView extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isLoadedSchedule: false,
      error: null,
      modalAttendant: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  componentDidMount() {
    this.retrieveSchedule();
  }

  retrieveSchedule() {
    getSchedule()
      .then(result => {
        let schedule = result;
        this.setState({
          schedule,
          isLoadedSchedule: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  handleChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let scheduleItem = this.state.scheduleItem;
    scheduleItem[name] = value;
    this.setState({scheduleItem});
  }


  handleBlur(event) {
    const { t } = this.props;
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let scheduleItem = this.state.scheduleItem;

    if (name==='openTime' || name==='lastEntryTime') {
      value = moment(value, t('h:mma'), true);
      value = moment(value).isValid() ? moment(value).format(t('h:mma')) : '';
    }

    scheduleItem[name] = value;
    this.setState({scheduleItem});
  }

  showScheduleForm(item) {
    const { t } = this.props;
    let scheduleItem = {...item};
    scheduleItem.openTime = moment(item.openTime).format(t('h:mma'));
    scheduleItem.lastEntryTime = moment(item.lastEntryTime).format(t('h:mma'));
    this.setState({
      scheduleItem,
      modal: true,
    })
  }

  handleSubmit(e, reset=false) {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
    })

    let scheduleItem = this.state.scheduleItem;
    let tempScheduleItem = {...this.state.scheduleItem};
    let scheduleItemDate = moment(scheduleItem.date).format('L');
    tempScheduleItem.openTime = moment(scheduleItemDate + ' ' + scheduleItem.openTime, 'L LT').format('YYYY-MM-DDTHH:mm:ss');
    tempScheduleItem.lastEntryTime = moment(scheduleItemDate + ' ' + scheduleItem.lastEntryTime, 'L LT').format('YYYY-MM-DDTHH:mm:ss');
    tempScheduleItem.restoreDefault = reset;

    if ( moment(tempScheduleItem.openTime).isValid() && moment(tempScheduleItem.lastEntryTime).isValid() ) {
      updateSchedule(tempScheduleItem)
        .then(result=>{
          this.retrieveSchedule();
          this.setState({isSubmitting:false, modal:false, scheduleItem: null})
        })
    }
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    const { t } = this.props;
    const { schedule, isLoadedSchedule, scheduleItem, error } = this.state;
    return (
      <div className="reception-view">
          {isLoadedSchedule?
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th></th>
                    <th>{t("Hebrew Date")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Open")}</th>
                    <th>{t("Zman Tevilah")}</th>
                    <th>{t("Last Entry")}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.map((item, i) => {
                    return <tr key={i}>
                      <td><Moment date={item.date} format="dddd" /></td>
                      <td><Moment date={item.date} format="LL" />{item.fastDay?' - ' + t('Fast Day'):''}</td>
                      <td>{item.hebrewDayHe} {item.hebrewMonthHe}, {item.hebrewYearHe}{item.hebrewNote?' - '+t(item.hebrewNote):''}</td>
                      <td>{t(item.scheduleType.replace(/([a-z])([A-Z])/g, "$1 $2" ))}</td>
                      <td><Moment date={item.openTime} format={t("h:mma")} /></td>
                      <td><Moment date={item.zmanTevillah} format={t("h:mma")} /></td>
                      <td><Moment date={item.lastEntryTime} format={t("h:mma")} /></td>
                      <td>{item.scheduleOverride?t('Overridden'):''}</td>
                      <td><Button type="secondary" onClick={() => this.showScheduleForm(item)}>{t("Override")}</Button></td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          : (error ?
            <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
          : <Loader />)}

          <Modal isOpen={this.state.modal} toggle={this.modalToggle} centered className={"lang-" + i18n.language} modalClassName="modal-schedule">
            <ModalHeader toggle={this.modalToggle}>{t("Edit Schedule")}</ModalHeader>
            <ModalBody>
              {scheduleItem?
                <form  autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="firstName">{t("Date")}</label>
                    <div className="form-control">
                      <Moment date={scheduleItem.date} format="dddd, LL" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName">{t("Hebrew Date")}</label>
                    <div className="form-control">
                      {scheduleItem.hebrewDay} {scheduleItem.hebrewMonth}, {scheduleItem.hebrewYear}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("Schedule Type")}</label>
                    <UncontrolledDropdown>
                      <DropdownToggle caret className="btn-block">{t(scheduleItem.scheduleType.replace(/([a-z])([A-Z])/g, "$1 $2"))}</DropdownToggle>
                      <DropdownMenu>
                        {scheduleTypes.map((item,i)=><DropdownItem key={i} onClick={()=>this.setState({scheduleItem: {...scheduleItem,scheduleType:item} })} active={scheduleItem.scheduleType===item}>
                            {t(item.replace(/([a-z])([A-Z])/g, "$1 $2"))}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="form-group">
                    <label htmlFor="openTime">{t("Open Time")}</label>
                    <input name="openTime" className="form-control" onBlur={this.handleBlur} value={scheduleItem.openTime} onChange={this.handleChange} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastEntryTime">{t("Last Entry")}</label>
                    <input name="lastEntryTime" className="form-control" onBlur={this.handleBlur} value={scheduleItem.lastEntryTime} onChange={this.handleChange} />
                  </div>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onBlur={this.handleBlur} onChange={this.handleChange} value={scheduleItem.allowAutoextend} defaultChecked={scheduleItem.allowAutoextend} name="allowAutoextend" /> 
                    <label htmlFor="allowAutoextend">{t("Allow Auto-Extend")}</label>
                  </div>
                  <hr />
                  <div className="d-flex">
                    <Button type="secondary" onClick={this.handleSubmit} loading={this.state.isSubmitting}>{t("Save")}</Button>
                    {scheduleItem.scheduleOverride?<Button type="secondary" className="ml-3" onClick={(e) => this.handleSubmit(e,true)} loading={this.state.isSubmitting}>{t("Restore Default")}</Button>:null}
                  </div>
                </form>
              :null}
            </ModalBody>
          </Modal>

        </div>
    )
  }
}
export default withTranslation()(ManagerScheduleView);
