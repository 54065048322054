import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getTimes } from '../models/times';

class Shkia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      time: null
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.refresh(),
      360000
    );
    this.retrieveShkia();
  }

  componentDidUpdate(prevProps){
    if(prevProps.date !== this.props.date || prevProps.zmanim !== this.props.zmanim){
      this.retrieveShkia();
    }
  }


  retrieveShkia() {
    const { zmanim } = this.props;
    if(zmanim){
      this.setState({
        isLoaded: true,
        time: zmanim.shkia
      })
    } else{
      if(!this.props.date){
      let localStorageTime = localStorage.getItem('shkia');
      let today = moment().startOf('day');
  
      if ( localStorageTime && moment(localStorageTime).startOf('day').isSame(today) ) {
        this.setState({
          isLoaded: true,
          time: localStorageTime
        })
        return false;
      }
    }
      getTimes(this.props.date||null)
        .then(
          result => {
            if(!this.props.date){
              localStorage.setItem('shkia', result.shkia);
            }
            this.setState({
              isLoaded: true,
              time: result.shkia
            })
          }
        );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  refresh() {
    this.retrieveShkia();
  }

  render() {
    const { t, className } = this.props;
    const { isLoaded, time } = this.state;
    return isLoaded ? (
      <div className={"closing-time " + (className?className:"")}>
        <div className="title">{t("Shkia")}</div>
        <Moment format={t("h:mm A")} element="span">{time}</Moment>
      </div>
    ) : null;
  }
}

export default withTranslation()(Shkia);