import React from 'react';
import { withTranslation } from 'react-i18next';
import { getWeather as GetWeather } from './../../api';
import moment from 'moment';

class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      weather: null,
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.refresh(),
      360000
    );
    this.retrieveWeather();
  }

  retrieveWeather() {
    let localWeather = localStorage.getItem('weather');

    if ( localWeather ) {
      localWeather = JSON.parse(localWeather);
      if ( moment.unix(localWeather.currently.time).isAfter(moment().subtract(5, 'minutes'))   ) {
        this.setState({
          weather: localWeather,
          isLoaded: true,
        })
        return true;
      }
    }


    GetWeather()
      .then(response => {
        let result = response.result;
        if ( result ) {
          let fontIcons = {
            "clear-day": "A",
            "clear-night": "I",
            "partly-cloudy-day": "C",
            "partly-cloudy-night": "J",
            "cloudy": "O",
            "rain": "S",
            "sleet": "S",
            "snow": "W",
            "wind": "Z",
            "fog": "N"
          }
          result.currently.fontIcon = fontIcons[result.currently.icon];

          localStorage.setItem('weather', JSON.stringify(result));
          this.setState({
            weather: result,
            isLoaded: true,
          })
        }
      }, error => error);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  refresh() {
    this.retrieveWeather();
  }

  render() {
    const { isLoaded, weather } = this.state;
    return isLoaded ?
    <div className="weather">
      <div className="icon">
        {weather.currently.fontIcon}
      </div>
      <div className="temp">
        {Math.round(weather.currently.temperature)}<sup>&deg;</sup>
      </div>
      <div className="description">
        {weather.minutely?weather.minutely.summary:null}<br/>
      </div>
    </div>
    : null
  }
}

export default withTranslation()(Weather);