import { getRoom as GetRoom, 
  getRooms as GetRooms, 
  getCleaningRooms as GetCleaningRooms, 
  getCubicles as GetCubicles, 
  updateRoomStatus as UpdateRoomStatus, 
  requestSupplies, 
  unlockRoom as UnlockRoom, 
  reloadRoom as ReloadRoom, 
  updateCubicleStatus as UpdateCubicleStatus, 
  unlockCubicle as UnlockCubicle, 
  swapRooms as SwapRooms,
  staffEntry as StaffEntry,
  doorIOEvent as DoorIOEvent,
  setRoomLanguage as SetRoomLanguage
 } from './../api';
import { statuses } from './../global-props';
import { processReservations } from './reservation';


function processRoom(room) {
  let statusProp = statuses[room.effectiveStatus]
  if ( statusProp!=null ) {
    room.status = room.effectiveStatus;
    room.statusName = statusProp.name;
    room.statusColor = statusProp.color;
  }

  room.reservation = room.reservation ? processReservations([room.reservation])[0] : null;

  let roomType;
  if ( room.bath ) {
    roomType = 'bath';
  } else if ( room.handicapped ) {
    roomType = 'wheelchair';
  } else {
    roomType = 'shower';
  }
  room.roomType = roomType;

  return room;
}

export function getRoom(id) {
  return new Promise((resolve, reject) => {
    GetRoom(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processRoom(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getRooms() {
  return new Promise((resolve, reject) => {
    GetRooms()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            result.map((room,i) => {
              return processRoom(room);
            })
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getCleaningRooms() {
  return new Promise((resolve, reject) => {
    GetCleaningRooms()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            result.roomsToClean.map((room,i) => {
              return processRoom(room);
            })
            result.roomsInCleaning.map((room,i) => {
              return processRoom(room);
            })
            result.roomsToCleanFull.map((room,i) => {
              return processRoom(room);
            })
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getCubicles() {
  return new Promise((resolve, reject) => {
    GetCubicles()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            result.map((cubicle,i) => {
              return processRoom(cubicle);
            })
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function updateCubicleStatus(id, status) {
  return new Promise((resolve, reject) => {
    UpdateCubicleStatus(id, status)
      .then(
        result => {
          if (result.success) {
            result.result.map((cubicle,i) => {
              return processRoom(cubicle);
            })
            return resolve(result.result);            
          }
          return reject(result.errorMessage)
        },
        error => reject(error)
      );
  })
}

export function unlockCubicle(id) {
  return new Promise((resolve, reject) => {
    UnlockCubicle(id)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function reloadRoom(roomId) {
  return new Promise((resolve, reject) => {
    ReloadRoom(roomId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function unlockRoom(roomId, doorType) {
  return new Promise((resolve, reject) => {
    UnlockRoom(roomId, doorType)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function updateRoomStatus(roomId, status, reservationStatus) {
  return new Promise((resolve, reject) => {
    UpdateRoomStatus(roomId, status, reservationStatus)
      .then(
        result => result.success ? resolve(processRoom(result.result)) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function setRoomLanguage(roomId, language) {
  return new Promise((resolve, reject) => {
    SetRoomLanguage(roomId, language)
      .then(
        result => result.success ? resolve(result.message) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function swapRooms(roomA, roomB) {
  return new Promise((resolve, reject) => {
    SwapRooms(roomA, roomB)
      .then(
        result => result.success ? resolve(processRoom(result.result)) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function supplyRequest(roomId, supply, action, isAttendantRequest) {
  return new Promise((resolve, reject) => {
    requestSupplies(roomId, supply, action, isAttendantRequest)
      .then(
        result => result.success ? resolve(processRoom(result.result)) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function staffEntry(roomId, pinCode, clearRoom) {
  return new Promise((resolve, reject) => {
    StaffEntry(roomId, pinCode, clearRoom)
      .then(
        result => result.success ? resolve(processRoom(result.result)) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function doorIOEvent(roomId, action) {
  return new Promise((resolve, reject) => {
    DoorIOEvent(roomId, action)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}
