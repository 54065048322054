import React from 'react';
import Flipper from '../flipper';
import { withTranslation } from 'react-i18next';

function RoomStatus(props) {
  const { t } = props;
  return <div className="room-status">
    <div className="title">{t(props.title?props.title:'Room Status')}</div>
    <Flipper type={"status text-"+props.color} text={props.status} loading={props.loading} dropdown={props.dropdown} />
  </div>
}

export default withTranslation()(RoomStatus);