import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import BodyHeader from "./BodyHeader";
import { createReservation, preassignRoom } from "../../../models/reservation";
import { getRooms } from './../../../models/room';
import { Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Moment from 'react-moment';
import moment from 'moment';
import Button from './../../../components/button';
import Shkia from "./../../../components/shkia";
import ZmanTevilah from "./../../../components/zman-tevilah";
import KallahCount from "../../../components/kallah-count";
import HebrewDate from './../../../components/hebrewDate';
import { getTimes } from '../../../models/times';
import PhoneInput from 'react-phone-input-2';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/he';
import './../../../assets/scss/react-phone-input.scss';
import ReactSVG from 'react-svg';
import iconCalendar from './../../../assets/images/icon-calendar.svg';
import iconKallah from './../../../assets/images/icon-kallah.svg';
import { ReactJewishDatePicker } from "react-jewish-datepicker";
import i18n from './../../../i18n';


class reservationKallah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationCode: "",
      isSubmitting: false,
      error: null,
      confirmed: false,
      key: 1,
      reservationTime: {hour: null, minutes: null},
      zmanim: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeDay = this.changeDay.bind(this);
  }

  componentDidMount() {
    const { reservation } = this.props;
    if(reservation){
      this.setState({
        confirmed: true,
        confirmedReservation: reservation,
        selectedDay: moment(reservation.reservationDate).toDate(),
        reservationTime: {hour:moment(reservation.reservationTime).format('HH'), minutes:moment(reservation.reservationTime).format('mm')},
        selectedAssignRoom: reservation.roomIdPreassign?{id: reservation.roomIdPreassign}: null
      })
      this.changeDay(moment(reservation.reservationDate).toDate(),'selectedDay')
  }
    this.retrieveRooms()
  }


  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  handlePhoneChange(value, data, event) {
    this.setState({[data]: value});
  }

  changeDay(day,dayType,calendarType) {
    if(calendarType === 'hebrew'){
        day = day.date;
    }
    this.setState({ [dayType]: day, key: this.state.key+1 });
    if(dayType=="selectedDay"){
      getTimes(moment(day).format('YYYY-MM-DD'))
        .then(
          result => {
            this.setState({
              zmanim: result
            })
          }
        );
    }
  }

  retrieveRooms(){
    getRooms()
    .then(
      result => {
        this.setState({
          isLoadedRooms: true,
          rooms: result,
        });
      },
      error => {
        this.setState({
          isLoadedRooms: true,
          error
        });
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { kallahName, mothersName, selectedDay, selectedWeddingDay, phoneOne, phoneTwo, notes, reservationTime, confirmedReservation } = this.state;
    let note = `Kallah Name: ${kallahName}\nWedding Date: ${moment(selectedWeddingDay).format("MMMM D, YYYY")}\nMother's Name: ${mothersName}\nMother's Number: ${phoneTwo}\nConfirmed: \nAdditional Notes: ${notes}`
    // let note = `שם כלה: ${kallahName}\nתאריך חתונה: ${moment(selectedWeddingDay).format("DD/MM/YY")}\nשם האם: ${mothersName||""}\nמספר טלפון של האם: ${phoneTwo||""}\nמאושר: \nהערות: ${notes||""}`;
    let res;
    if(this.state.editing){
    res = {
        ...confirmedReservation,
        reservationTime: reservationTime.hour && reservationTime.minutes?moment(selectedDay).format('YYYY-MM-DD')+ 'T'+reservationTime.hour+':'+reservationTime.minutes:moment(selectedDay).format('YYYY-MM-DD')+ 'T06:00',
      }
    } else{
      res = {
        advanceReservation: true,
        kallah: true,
        bath: true,
        reservationTime: reservationTime.hour && reservationTime.minutes?moment(selectedDay).format('YYYY-MM-DD')+ 'T'+reservationTime.hour+':'+reservationTime.minutes:moment(selectedDay).format('YYYY-MM-DD')+ 'T06:00',
        phoneNumber: phoneOne,
        reservationNotes: note
      }
    }
    this.setState({
      isSubmitting: true,
      error: null,
    });
    createReservation(res, true, true)
    .then(result => {
      this.props.handleUpdateReservation(result);
      if(this.state.selectedAssignRoom){
       return preassignRoom(result.reservationId,this.state.selectedAssignRoom.id)
       .then(result => {
         this.setState({
           confirmed: true,
           confirmedReservation: result,
           isSubmitting: false,
           editing: false
         })}, error => {
         this.setState({
          isSubmitting: false,
          error:error,
        });
       });
      } else{
        this.setState({
          confirmed: true,
          confirmedReservation: result,
          isSubmitting: false,
          editing: false
        })
      }    
    }, error => {
      this.setState({isSubmitting: false, error})
    });
    
  }

  render() {
    const { t } = this.props;
    const { kallahName, mothersName, phoneOne, phoneTwo, selectedDay, selectedWeddingDay, notes, isSubmitting, error, confirmed, confirmedReservation, reservationTime, zmanim, isLoadedRooms, rooms, selectedAssignRoom, editing} = this.state;
    return (
      <>
        <BodyHeader goBack={false} isAdvancedReservation={this.props.isAdvancedReservation} isEditingReservation={this.props.isEditingReservation} isKallahReservation={true} />

        {error?<div className="checkin-message"><Alert color="danger">{t(error)}</Alert></div>:null}
        {confirmed && confirmedReservation && !editing?
          <div className="modal-body">
            <div>{!this.props.reservation?t("Kallah Reservation has been created. Please view the details below."):t("Kallah Reservation details below.")}</div>
            <ul className="reservation-summary">
              <li>
                <div className="icon">
                  <ReactSVG src={iconCalendar} />
                </div>
                <Moment format={t("MM/DD")}>{confirmedReservation.reservationTime}</Moment> 
                <HebrewDate className="mis-1" date={confirmedReservation.reservationTime} day={true} month={true}/>
                {(reservationTime.hour&&reservationTime.minutes)||this.props.reservation?<Moment className="mis-2" format={t("h:mm A")}>{confirmedReservation.reservationTime}</Moment>:null}
              </li>
              <li>
                <div className="icon">
                  <ReactSVG src={iconKallah} />
                </div>
                <span>{t("Room Type")}: <span className="text-capitalize">{t("Kallah")}</span></span>
              </li>
              <li>
                {confirmedReservation.roomIdPreassign?
                    <span>{t("Preassign")}: <span className="text-capitalize">{confirmedReservation.roomIdPreassign}</span></span>
                :null}
              </li>
              <li className="flex-wrap">
                {t("Reservation Details")}:
                <div className="text-start w-100 user-note">{confirmedReservation.notes}</div>
              </li>
            </ul>
            <div className="d-flex">
              <Button type="secondary" size="md" onClick={() => this.setState({editing: true, confirmedReservation: {...confirmedReservation, replacingReservationId: confirmedReservation.replacingReservationId?confirmedReservation.replacingReservationId:confirmedReservation.reservationId, reservationId: null}})} loading={isSubmitting}>{t("Edit")}</Button>
              <Button className="ml-2" type="secondary" size="md" onClick={() => this.props.handleScreenChange('checkin-payment')} loading={isSubmitting}>{t("Open")}</Button>
            </div>
          </div>
        :
        <div className="modal-body">
          <form onSubmit={this.handleSubmit} className="w-100" autoComplete="off">
          {!editing?
            <>
            <div className="form-group">
              <label htmlFor="kallahName">{t("Kallah Name")}</label>
              <input type="text" className="form-control" name="kallahName" value={kallahName} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="phoneOne">{t("Kallah Number")}</label>
              <PhoneInput specialLabel="" containerClass="dir-ltr" value={phoneOne} name="phoneOne" onChange={(val) => this.handlePhoneChange(val,'phoneOne')} country="us" placeholder="0000000000"  preferredCountries={['us', 'il']} />
            </div>
            <div className="form-group">
              <label htmlFor="weddingDate" className="mie-2">{t("Wedding Date")}</label>
              <DayPickerInput format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker dir-ltr'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,disabledDays: { before: moment().toDate() }}} formatDate={formatDate} parseDate={parseDate} value={selectedWeddingDay} onDayChange={day=>this.changeDay(day,'selectedWeddingDay','english')} placeholder={t("MM/DD/YY")} />
              <ReactJewishDatePicker
                key={this.state.key}
                value={selectedWeddingDay}
                isHebrew={i18n.language=="he"?true:false}
                onClick={day=>this.changeDay(day,'selectedWeddingDay','hebrew')}
              />            
            </div>
            </>
            :null}
            <div className="form-group">
              <label htmlFor="weddingDate" className="mie-2">{t("Reservation Date")}</label>
              <DayPickerInput format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker dir-ltr'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,disabledDays: { before: moment().toDate() }}} formatDate={formatDate} parseDate={parseDate} value={selectedDay} onDayChange={day=>this.changeDay(day,'selectedDay','english')} placeholder={t("MM/DD/YY")} />
              <ReactJewishDatePicker
                key={this.state.key}
                value={selectedDay}
                isHebrew={i18n.language=="he"?true:false}
                onClick={day=>this.changeDay(day,'selectedDay','hebrew')}
              />            
            </div>
            {selectedDay && zmanim?
              <>
              <div className="closing-time">
                <div className="title">{t("Open Time")}</div>
                <Moment format={t("h:mm A")} element="span">{zmanim.openTime}</Moment>
              </div>
              <Shkia className="mb-4" zmanim={zmanim} />
              <ZmanTevilah className="mb-4" zmanim={zmanim} />              
              <KallahCount className="mb-4" date={moment(selectedDay).format('YYYY-MM-DD')} />
              </>
            :null}
            <div className="form-group d-flex">
              <label htmlFor="reservationTime" className="mie-2">{t("Reservation Time")}</label>
              <div className={'slot custom d-flex'}>
                <UncontrolledDropdown>
                   <DropdownToggle className="reservationTime" caret>{reservationTime.hour?reservationTime.hour:t("Hour")}</DropdownToggle>
                     <DropdownMenu>
                       <DropdownItem onClick={()=>this.setState({reservationTime: {hour: null, minutes: null}})}>{t("Clear")}</DropdownItem>
                       {Array.from('x'.repeat(24)).map((val, i) =>{
                         let h = i<10?'0'+i:`${i}`;
                         return <DropdownItem className={h==reservationTime.hour?'active':''} key={i} onClick={()=>this.setState({reservationTime: {...this.state.reservationTime,hour:h}})}>{h}</DropdownItem>
                       }
                       )}
                     </DropdownMenu>
                 </UncontrolledDropdown>
                 <span className="px-1">:</span>
                 <UncontrolledDropdown>
                   <DropdownToggle className="reservationTime" caret>{reservationTime.minutes?reservationTime.minutes:t("Minutes")}</DropdownToggle>
                     <DropdownMenu>
                       <DropdownItem onClick={()=>this.setState({reservationTime: {hour: null, minutes: null}})}>{t("Clear")}</DropdownItem>
                       {Array.from('x'.repeat(12)).map((val, i) =>{
                         let m = i*5;
                         m = m<10?'0'+m:`${m}`;
                         return <DropdownItem className={m==reservationTime.minutes?'active':''} key={i} onClick={()=>this.setState({reservationTime: {...this.state.reservationTime,minutes:m}})}>{m}</DropdownItem>
                       }
                       )}
                     </DropdownMenu>
                 </UncontrolledDropdown>
                </div>
            </div>
            <div className="form-group d-flex preassign-room">
              <label className="mie-2">{t("Preassign to Room")}</label>
              <div className={'d-flex'}>
              <UncontrolledDropdown>
                  <DropdownToggle caret>{selectedAssignRoom?selectedAssignRoom.id:t('Rooms')}</DropdownToggle>
                  <DropdownMenu>
                    {rooms && rooms.map((room,i)=>
                      <DropdownItem className={selectedAssignRoom && selectedAssignRoom.id==room.id?'active':''} onClick={()=>this.setState({selectedAssignRoom: {id: room.id}})} key={i}>{room.id}</DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                </div>
            </div>
            {!editing?
            <>
            <div className="form-group">
              <label htmlFor="mothersName">{t("Mother's Name")}</label>
              <input type="text" className="form-control" name="mothersName" value={mothersName} onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="phoneTwo">{t("Mother's Phone Number")}</label>
              <PhoneInput specialLabel="" containerClass="dir-ltr" value={phoneTwo} name="phoneTwo" onChange={(val) => this.handlePhoneChange(val,'phoneTwo')} country="us" placeholder="0000000000"  preferredCountries={['us', 'il']} />
            </div>
            <div className="form-group">
              <label htmlFor="notes">{t("Notes")}</label>
              <textarea type="textarea" className="form-control" name="notes" value={notes} onChange={this.handleChange} />            
            </div>
            </>
            :null}
            <div className="reservation-footer">
              <Button type="secondary" size="lg" loading={isSubmitting}>{t("Submit")}</Button>
              {editing?<div className="mt-2 cursor-pointer" onClick={() => this.setState({editing: false})}>Cancel</div>:null}
            </div>
          </form>
        </div>
        }
      </>
    );
  }
}

export default withTranslation()(reservationKallah);
