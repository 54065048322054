import React from 'react';
import { withTranslation } from 'react-i18next';

import { getSettings, updateSetting, reloadAllScreens, addBannerImage, getMerchandise, addMerchandise } from '../../models/setting';
import Loader from '../../components/snippets/loader';
import { Alert,  } from 'reactstrap';
import Button from '../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { images_url } from '../../global-props';
import ImageUploader from 'react-images-upload';

class ManagerSettings extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      //settings: settings, 
      isLoaded: false,
      zmanType: null,
      zmanSaved: false,
      zmanValue: "",
      isImagesLoading: false,
      bannerImage: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.addProductFields = this.addProductFields.bind(this);
    this.updateProductFields = this.updateProductFields.bind(this);
    this.submitMerchandise = this.submitMerchandise.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleZmanBlur = this.handleZmanBlur.bind(this);
    this.retrieveSettings = this.retrieveSettings.bind(this);
    this.onZmanChange = this.onZmanChange.bind(this);
    this.saveBannerImage = this.saveBannerImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    this.retrieveSettings();
    this.retrieveMerchandise();
  }

  retrieveSettings() {
    getSettings()
      .then(result => {
        let settings = result;
        this.setState({
          settings,
          zmanType: settings.zmanMinutes.value>0?"ZmanMinutes":"ZmanDegrees", 
          zmanValue: settings.zmanMinutes.value>0?settings.zmanMinutes.value:settings.zmanDegrees.value, 
          isLoaded: true,
        })
      }, error => this.setState({error, isLoaded: true}));
  }

  retrieveMerchandise() {
    getMerchandise()
      .then(
       result => {
        let products = result;
        this.setState({
          products,
        });
      },
      (error) => {
        this.setState({ error });
      }
    );
  }

  submitMerchandise(){
    let { products } = this.state;
    this.setState({submittingMerch: true})
    addMerchandise(products)
    .then(
      result => {
       let products = result.result;
       this.setState({
         products,
         submittingMerch: false
       });
     },
     (error) => {
       this.setState({ error, submittingMerch: false});
     }
   );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked?"True":"False") : target.value;
    const name = target.name;

    if(name === 'zmanValue'){
      this.setState({
        zmanValue: value
      })
    } else {
      var settings = {...this.state.settings};
      settings[name].value = value;
      this.setState({settings});
    }
  }

  handleBlur(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked?1:0) : target.value;
    const name = target.name;

    if ( value !== this.state.settings[name].prevValue ) {
      updateSetting(name, value).then(result => {
        let settings = result;
        settings[name].isSaved = true;
        this.setState({
          settings,
        });
        setTimeout(()=>{
          settings[name].isSaved = false;
          this.setState({
            settings,
          })
        },2000);
      }, error => {
        this.setState({error});
      })
    }

  }

  handleZmanBlur(event) {
    const target = event.target;
    const value = target.value;
    let zmanDegrees;
    let zmanMinutes;
    if(this.state.zmanType === "ZmanDegrees"){
      zmanDegrees = value > 0?value:0;
      zmanMinutes = 0;
    } else if(this.state.zmanType === "ZmanMinutes"){
      zmanDegrees = 0;
      zmanMinutes = value > 0?value:0;
    }
    if ( value !== this.state.zmanType.prevValue ) {
      updateSetting('zmanDegrees', zmanDegrees).then(result => {
        updateSetting('zmanMinutes', zmanMinutes).then( result => {
          let settings = result;
          this.setState({
            settings,
            zmanSaved: true
          });
          setTimeout(()=>{
            this.setState({
              settings,
              zmanSaved: false
            })
          },2000);
      }, error => {
        this.setState({error});
      })
    }, error => {
        this.setState({error});
      })
    }
  }

  saveBannerImage(picture){
    if(picture.length>0){
      this.setState({isImagesLoading: true})
      var formData = new FormData();
      picture.map((image, index)=>{
        formData.append(`image${index}`, image); 
      }); 
      addBannerImage(formData)
      .then(result=>{
        this.setState({isImagesLoading: false, bannerImage: result})
        }, error => {this.setState({error, isImagesLoading: false}) }
      )}
  }

  removeImage() {
      updateSetting('websiteBannerImage', '').then(result => {
        let settings = result;
        this.setState({
          settings,
        });
      }, error => {
        this.setState({error});
      })

  }

  onZmanChange(e){
    this.setState({
      zmanType: e.target.value
    })
  }

  updateProductFields(e,num,type){
    let { products } = this.state;
    if(type !== 'remove'){
      products[num-1][type] = e.target.value;
      this.setState({products});
    } else if(type === 'remove'){
      products.splice(num-1,1);
      this.setState({products: [...products]});
    }
  }

  addProductFields(e){
    let { products } = this.state;
    e.preventDefault();
    products[products.length] = {id: Math.random()};
    this.setState({products: [...products]});
  }

  handleReloadAllScreens() {
    reloadAllScreens()
  }
  
  render() {
    const { t, showAdvanced } = this.props;
    const { settings, products, isLoaded, error, zmanType, zmanSaved, bannerImage, isImagesLoading, submittingMerch } = this.state;
    return (
      <div className="reception-view">
        <div>
          {isLoaded?
            <div>
              {error?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :null}

              {settings?
                <>
                  {showAdvanced?
                    <div className="my-5">
                      <Alert color="warning">{t("Warning: Changes to any of these settings may cause the application to stop working. Only change these settings if you know what you're doing.")}</Alert>
                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Advanced Settings")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "smtpHost",
                              "smtpUser",
                              "smtpPassword",
                              "emailFrom",
                              "emailFromName",
                              "publicAppBaseUrl",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "locationLat",
                              "locationLong",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "stripeSK",
                              "stripePK",
                              "weatherDarkSkyKey",
                              'webrelayAddress'
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "NetMask",
                              "DMXNodeAddress",
                              "DMXNodePort",
                              "DMXBlinkOnMs",
                              "DMXBlinkOffMs",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "QSCAddress",
                              "QSCPort",
                              "QSCUser",
                              "QSCPassword",
                              "QSCPagingObject",
                              "QSCVolumeLow",
                              "QSCGainMax",
                              "QSCGainMin",
                              "QSCGainLow",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "hartmannServerAddress",
                              "hartmannApiLogin",
                              "hartmannApiPassword",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            {[
                              "hartmannUseDryHallwayCodes",
                            ].map((s,i)=>{
                              return <SettingInput type="checkbox" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "twilioSID",
                              "twilioAuthToken",
                              "twilioMessagingServiceSid",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            {[
                              "googleSiteKey",
                              "googlePrivateKey",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            <hr />
                            <div className="card card-body bg-light">
                              <p><Button className="btn-secondary" onClick={this.handleReloadAllScreens}>{t("Force Refresh All Screens")}</Button></p>
                              <p className="text-muted small">{t('To refresh a room screen: Go to the map, click on a room and click the "Refresh Room Screen" button.')}</p>
                            </div>
                            <div className="mt-4">
                              <h3>{t("Zman Tevillah")}</h3>
                              <p>{t("Check with your Rav before changing these settings")}</p>
                              {[
                                "shkiaOffsetMinutes",
                              ].map((s,i)=>{
                                return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                              })}
                              <div className="row">
                              <div className="col-12 mb-3">
                                <label for="zmanMinutes2">{t("Zman Tevillah Time")}</label>
                                  <select className="form-control" name="ZmanType" value={zmanType} onChange={this.onZmanChange}>
                                      <option value="ZmanDegrees">{t("Degrees of sun below horizon")}</option>
                                      <option value="ZmanMinutes">{t("Minutes after sunset")}</option>
                                  </select>
                                </div>
                                <div className="col-12">
                                  <input type="text" className={"form-control"} name="zmanValue" onChange={this.handleChange} onBlur={this.handleZmanBlur} defaultValue={settings.zmanMinutes.value>0?settings.zmanMinutes.value:settings.zmanDegrees.value} />
                                  <div className={"input-group-append fade" + (zmanSaved.isSaved?" show":"")}><FontAwesomeIcon icon="check" className="text-success" /></div>
                                </div>
                              </div>
                              {[
                                "zmanMinutes2",
                                "zmanMinutes72",
                              ].map((s,i)=>{
                                return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                              })}
                              {[
                                "yomTovTwoDays",
                              ].map((s,i)=>{
                                return <SettingInput type="checkbox" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  :
                    <div className="my-5">
                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Reservation Capacity")}</h3>
                        <p>{t("Enter the number of allowable reservations per type.")}</p>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "roomsAvailable",
                              "roomsAvailableShower",
                              "roomsAvailableBath",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "roomsAvailableFriday",
                              "roomsAvailableHandicapped",
                              "roomsAvailablePrivateRoom",
                              // "parkingAvailable",
                              // "parkingAvailableHandicapped",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Time Allotment")}</h3>
                        <p>{t("Enter the number of minutes a reservation will be alloted.")}</p>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "reservationMinutesShower",
                              "reservationMinutesBath",
                              "reservationMinutesDoubleSlot",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "reservationMinutesFriday",
                              "cleaningMinutes",
                              // "salonMinutes",
                              "lateGraceMinutes",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Schedule: General")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "reservationSlotIncrement",
                              "reservationStartAtSameTime",
                              "openBeforeZmanMinutes",
                              "reservationCutOffBeforeOpenHours",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "checkinBeforeOpenMinutes",
                              "openEarliestHour",
                              "openMinimumMinutes",
                              "openUntilWinterHour",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Schedule: Friday")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "reservationSlotIncrementFriday",
                              "openBeforeZmanMinutesFriday",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "openMinutesFriday",
                              "reservationCutOffBeforeOpenHoursFriday",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Schedule: Motzei Shabbos")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "openAfterShabbosMinutes",
                              "openAfterShabbosLatestTime",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "openAfterShabbosMinimumMinutes",
                              "openAfterShabbosUntilWinterHour",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Schedule: Auto-Extend")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "addTimeMinSlots",
                              "addTimeCutoffHours",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "openMaxMinutes",
                              "openUntilMaxHour",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("SMS Notifications")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "notificationDayBeforeDefaultHour",
                              "notificationDayOfDefaultHour",
                              "notificationReminderShortLink",
                              "feedbackLink",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "notificationDayOfSpecialText",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Homepage")}</h3>
                        <div className="row">
                          <div className="col-7">
                          {[
                              "websiteHomePageMessage",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" maxLength="1000" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-5">
                            {[
                              "websiteBannerDestinationUrl",
                            ].map((s,i)=>{
                              return <SettingInput type="text" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="d-flex col-12">
                            <ImageUploader
                              withIcon={true}
                              buttonText= 'Add Banner Image'
                              label="Max file size: 5mb, accepted file types: jpg, png. Please upload a 720x300 size banner."
                              onChange={this.saveBannerImage}
                              imgExtension={['.jpg', '.png']}
                              maxFileSize={5242880}
                              singleImage={true}
                              labelClass="w-50"
                            />
                            {(settings.websiteBannerImage && settings.websiteBannerImage.value) || bannerImage?
                              !isImagesLoading?
                                <div className="col-sm-6">
                                  <img src={images_url + (settings.websiteBannerImage.value)} />
                                  <div className="cursor-pointer mt-3" onClick={this.removeImage}>Delete Banner Image</div>
                                </div>
                                :<Loader />
                            : null}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Reservation Instructions")}</h3>
                        <div className="row">
                          <div className="col-6">
                          {[
                              "reservationInstructions",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" maxLength="1000" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "reservationInstructionsFriday",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" maxLength="1000" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Reservation Messages")}</h3>
                        <div className="row">
                          <div className="col-6">
                          {[
                              "reservationMessage",
                              "reservationMessageNothingAvailable",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" maxLength="1000" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "reservationMessageFriday",
                            ].map((s,i)=>{
                              return <SettingInput type="textarea" maxLength="1000" t={this.props.t} key={i} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Status Alerts")}</h3>
                        <p>{t("A notification will be shown to attendants if a reservation remains in a certain status for longer than this time. Enter the number of minutes.")}</p>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "assignedMinutes",
                              "prepShowerMinutes",
                              "prepBathMinutes",
                              "prepDoubleSlotExtraMinutes",
                              "waitingForSuppliesMinutes",
                              "readyForCheckingMinutes",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "checkingMinutes",
                              "readyForMikvahMinutes",
                              "inMikvahMinutes",
                              "dressingMinutes",
                              "needsCleaningMinutes",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Prices")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "priceShower",
                              "priceShowerDiscount",
                              "priceBath",
                              "priceBathDiscount",
                              // "priceParking",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              "pricePrivateShower",
                              "pricePrivateBath",                              
                              "priceKallah",
                              // "priceSalonManicure",
                              // "priceSalonBoth",
                              // "priceDoubleSlot",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 card card-body bg-light">
                        <h3>{t("Misc.")}</h3>
                        <div className="row">
                          <div className="col-6">
                            {[
                              "roomAdminPasscode",
                              "phoneNumber",
                              "receptionExtension",
                              "reservationRefundCutOffHours",
                              "dailyPMHour",
                              "dailyShutdownHour",
                              "staffAutoClearMinutes",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                            {[
                              "emailNewUserInfo"
                            ].map((s,i)=>{
                              if(settings.sendEmailForNewUsers.value=="True"){
                              return <SettingInput key={i} t={this.props.t} setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                              }
                            })}
                          </div>
                          <div className="col-6">
                            {[
                              // "reservationsRequirePrepayParking",
                              "reservationsRequirePrepayFriday",
                              "reservationsRequirePrepayAll",
                              "reservationsMaximizeUtilization",
                              "reservationsMaximizeUtilizationFriday",
                              "reservationDoubleSlotStartEarly",
                            ].map((s,i)=>{
                              return <SettingInput key={i} t={this.props.t} type="checkbox" setting={settings[s]} handleChange={this.handleChange} handleBlur={this.handleBlur} />
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 card bg-light">
                        <div className="card-body">
                          <h3>Products For Sale</h3>
                          <div className="text-muted mb-2 font-weight-normal">Enter any products that you wish to offer users for purchase during their reservation. Remember to save changes after update.</div>
                            {products && products.map((val, i) => 
                              <Products key={val.id} setA={this.updateProductFields} item={val.item} price={val.price} num={i+1} />
                            )}
                            <div className="row">
                              <div className="col-6"><Button onClick={(e) => this.addProductFields(e)} disabled={products && products.length>9} className="btn-secondary">+ Add Product</Button></div>
                              <div className="col-6 text-end"><Button onClick={this.submitMerchandise} loading={submittingMerch} className="btn-secondary">Save Changes</Button></div>
                            </div>
                        </div>
                      </div>                   
                    </div>
                  }
                </>
              :null}

            </div>
          :<Loader />}
        </div>
      </div>
    )
  }
}
export default withTranslation()(ManagerSettings);

function SettingInput(props) {
  const { t, setting } = props;

  if (setting) {
    switch (props.type) {
      case 'checkbox':
        return <div className="form-check">
          <input type="checkbox" className="form-check-input" checked={setting.value==='True'} id={setting.name} name={setting.name} onChange={props.handleChange} onBlur={props.handleBlur} /> 
          <label htmlFor={setting.name} className="form-check-label input-wrap pie-4 w-100">
            {t(setting.title)}
            <div className={"input-group-append fade" + (setting.isSaved?" show":"")}><FontAwesomeIcon icon="check" className="text-success" /></div>
          </label>
        </div>
      case 'textarea':
        return <div className="form-group">
          <label htmlFor={setting.name}>{setting.title}</label>
          {setting.hint?
            <div className="text-muted small mb-2">{t(setting.hint)}</div>
          :null}
          <div className="input-wrap">
            <textarea id={setting.name} rows="4" name={setting.name} className="form-control" value={setting.value} onChange={props.handleChange} onBlur={props.handleBlur} />
            <div className={"input-group-append fade" + (setting.isSaved?" show":"")}><FontAwesomeIcon icon="check" className="text-success" /></div>
          </div>
        </div>
      default:
        return <div className="form-group">
          <label htmlFor={setting.name}>{t(setting.title)}</label>
          {setting.hint?
            <div className="text-muted small mb-2">{t(setting.hint)}</div>
          :null}
          <div className="input-wrap">
            <input type="text" id={setting.name} name={setting.name} className="form-control" value={setting.value} onChange={props.handleChange} onBlur={props.handleBlur} />
            
            <div className={"input-group-append fade" + (setting.isSaved?" show":"")}><FontAwesomeIcon icon="check" className="text-success" /></div>
          </div>
        </div>
    }
  }
  return null;
}

function Products(props) {
  const { setA, num, item, price } = props;
  return (
    <div className="row mb-2">
      <div className="col-sm-7 mb-2 mb-sm-0">
        <label htmlFor={`p${num}name`}>{`Product ${num} Name`}</label>
        <input onChange={(e) => setA(e,num,'item')} defaultValue={item} name={`p${num}name`} type="text" className={"form-control"}/>
      </div>
      <div className="col-9 col-sm-3">
        <label htmlFor={`p${num}price`}>{`Product ${num} Price`}</label>
        <input onChange={(e) => setA(e,num,'price')} defaultValue={price} name={`p${num}price`} type="number" className={"form-control"}/>
      </div>
      <div className="col-3 col-sm-2 remove-product">
        <FontAwesomeIcon onClick={(e) => setA(e,num,'remove')} name="Remove Product" className="" icon={['far', 'trash-alt']} />      
      </div>
    </div>
  )
}
