import React from 'react';
import { withTranslation } from 'react-i18next';

function ProgressBar(props) {
  const { reservations } = props;
  return reservations ?
  <div className="progress progress-reservations">
    <div className="progress-bar progress-bar-completed" style={{ width: reservations.completed.length / reservations.all.length * 100 + "%" }}></div>
    <div className="progress-bar progress-bar-here" style={{ width: reservations.here.length / reservations.all.length * 100 + "%" }}></div>
    <div className="progress-bar progress-bar-upcoming" style={{ width: reservations.upcoming.length / reservations.all.length * 100 + "%" }}></div>
  </div>
  :
  null
}

export default withTranslation()(ProgressBar);