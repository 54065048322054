import React, { Component } from 'react';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import ReactSVG from 'react-svg';
import { Link, Redirect } from "react-router-dom";
import { Alert } from 'reactstrap';

import { getUserByPhone } from "./../../models/user";
import { getReservationsByUser } from "./../../models/reservation";
import { checkinReservation } from "./../../models/reservation";
import { getRoom } from "./../../models/room";
import { createPayment } from "./../../models/payment";
import moment from 'moment';
import Moment from 'react-moment';
import iconPrinter from "./../../assets/images/icon-printer.svg";
import iconClock from './../../assets/images/icon-clock.svg';
import iconClockExtra from './../../assets/images/icon-clock-extra.svg';
import iconSalon from './../../assets/images/icon-salon.svg';
import iconParking from './../../assets/images/icon-parking.svg';
import iconShower from './../../assets/images/icon-shower.svg';
import iconBath from './../../assets/images/icon-bath.svg';
import iconWheelchair from './../../assets/images/icon-wheelchair.svg';
import iconKallah from './../../assets/images/icon-kallah.svg';
import moneyOwed from "./../../assets/images/icon-money-owed.svg";
import Clock from './../../components/clock';
import Flipper from './../../components/flipper';
import logo from "./../../assets/images/MikvahLogo.png";
import Button from './../../components/button';
import LoaderSpinner from './../../assets/images/icon-loader-spinner.svg';
import ScreenSaver from '../../components/screensaver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageSelector from '../../components/language-selector';
import SignalR from '../../components/signalr';
import { SettingsContext } from './../../components/settings';
import KioskAdmin from './../../components/kiosk-admin';
import CurrencyFormat from './../../components/snippets/currency-format';
import { logoImageAltText } from '../../global-props';

class KioskView extends Component {
  static contextType = SettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      reservationCode: "",
      currentScreen: "welcome",
      isSubmitting: false,
      requireFullPhoneNumber: false,
      hideScreensaver: false,
      time: moment().format(),
    };
  
    this.handlePress = this.handlePress.bind(this);
    this.handleCheckin = this.handleCheckin.bind(this);
    this.handleScreenChange = this.handleScreenChange.bind(this);
    this.handleEntryDoorOpened = this.handleEntryDoorOpened.bind(this);
    this.handleKioskPrinterSelected = this.handleKioskPrinterSelected.bind(this);
    this.handleChargeTerminalICount = this.handleChargeTerminalICount.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
    let savedPrinter = localStorage.getItem('selectedPrinter');
    if ( savedPrinter ) {
      this.setState({savedPrinter});
      return;
    }
  }
  
  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.intervalID);
  }
  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }


  retrieveUserByPhone(phone) {
    return new Promise((resolve, reject) => {
      getUserByPhone(phone, true)
      .then(
        result => resolve(result),
        error => reject(error)
      );
    })
  }

  retrieveReservationsByUser(id) {
    const { selCardReader, settings } = this.context;
    getReservationsByUser(id)
      .then(
        result => {
          let prevDay = parseInt(moment().subtract(settings&&settings.offset&&settings.offset.value,'seconds').format('HH')) < 5;
          let resTodayReservedPaid = result.filter(res=>['Reserved','Assigned','Waiting'].includes(res.reservationStatus)&&moment(res.reservationDate).isSame(moment().subtract(prevDay?1:0,'days').subtract(settings&&settings.offset&&settings.offset.value,'seconds').startOf('day'))&&(res.paid||selCardReader));
          if ( resTodayReservedPaid.length===1 ) {
            this.setState({
              reservation: resTodayReservedPaid.pop(),
            },() => {
              const { reservation } = this.state;
              if (reservation.kallah) {
                this.roomTypeDisplay = { name: 'Kallah', icon: iconKallah, price: 'priceKallah' }
              } else if (reservation.handicapped) {
                this.roomTypeDisplay = { name: "Accessible", icon: iconWheelchair, priceName: 'priceBath' }
              } else if (reservation.bath) {
                this.roomTypeDisplay = { name: "Bath", icon: iconBath, priceName: reservation.useDiscountPrice? 'priceBathDiscount' :'priceBath' }
              } else {
                this.roomTypeDisplay = { name: "Shower", icon: iconShower, priceName: reservation.useDiscountPrice? 'priceShowerDiscount' : 'priceShower' }
              }
              this.setState({roomTypeDisplay: this.roomTypeDisplay});
            })
            return this.handleScreenChange("confirm");
          }
          return this.handleScreenChange("error");

        },
        error => {
          this.setState({
            errorReservation: error
          });
        }
      );
  }

  handlePress(key) {
    const { requireFullPhoneNumber } = this.state;
    let input = this.state.reservationCode;
    if ( key === 'del' && input.length!==0 ) { input = input.slice(0, -1); }
    if ( typeof key === 'number' && (requireFullPhoneNumber?input.length<15:input.length<4) ) { input = input + key; }
    this.setState({
      reservationCode: input
    })
    clearTimeout(this.timeoutCode);
    this.timeoutCode = setTimeout(() => {
      this.handleScreenChange("welcome", { resetReservationCode: true })
      i18n.changeLanguage("en");
    },15000);
    if ( (key === 'enter' && input.length>=4) || (!requireFullPhoneNumber&&input.length>=4) ) {
      this.setState({isSubmitting: true});
      this.retrieveUserByPhone(input)
        .then(users => {
          if ( users.length===1 ) {
            this.retrieveReservationsByUser(users[0].id)
          } else if ( users.length > 1 ) {
            if ( input.length <= 4 ) {
              this.setState({
                reservationCode: "",
                requireFullPhoneNumber: true,
                isSubmitting: false,
              })
            } else {
              return this.handleScreenChange("error");
            }
          } else {
            return this.handleScreenChange("error");
          }
        }, error => this.handleScreenChange("error"));
    }
  }

  handleChargeTerminalICount() {
    const { selCardReader } = this.context;
    let payment = {
      method: 'creditcard',
      amt: this.state.reservation.balance,
      reservationId: this.state.reservation.reservationId,
      paymentInfo: {cardReaderId: selCardReader},
      useDiscountPrice: this.state.reservation.useDiscountPrice
    }
    this.handleCreatePayment(payment);
  }

  handleCreatePayment(payment) {
    this.setState({
      isSubmitting: true,
      error: null,
    })
    createPayment(payment)
      .then(result => {
        if ( result.paid ) {
          this.setState({
            currentScreen: 'print',
            isSubmitting: false
          })
        }
      }, error => {
        this.setState({error: error.stripeErrorMessage?error.stripeErrorMessage:error.errorMessage,isSubmitting: false});
      })
  }

  handleCheckin() {
    this.setState({isSubmitting: true});
    let printerId = this.state.savedPrinter;
    checkinReservation(this.state.reservation.reservationId, {printerId}, i18n.language)
      .then(result => {
        this.setState({isSubmitting: false});
        let res = result;
        this.setState({
          reservation: res
        })
        this.handleScreenChange("end");
      }, error => this.handleScreenChange("error"));
  }

  toggleLanguage = (lang) => {
    i18n.changeLanguage(lang);
    moment.locale(lang);
    localStorage.setItem('selectedLanguage', lang);
  }

  handleScreenChange(screen, args, lang) {
    let resetReservationCode = false;
    if ( args!=null ) {
      if ( args.resetReservationCode!==null ) {
        resetReservationCode = args.resetReservationCode;
      }
    }
    if(lang){
      this.toggleLanguage(lang);
    }
    if (screen === "end" || screen === "error") {
      setTimeout(() => {
        this.handleScreenChange("welcome", { resetReservationCode: true })
      },10000);
    } 
    if (screen === "confirm") {
      this.timeoutConfirm = setTimeout(() => {
        this.handleScreenChange("welcome", { resetReservationCode: true })
      },60000);
    } else {
      clearTimeout(this.timeoutConfirm);
    }
    if (screen === "pay") {
      this.timeoutPay = setTimeout(() => {
        this.handleScreenChange("welcome", { resetReservationCode: true })
      },120000);
    } else {
      clearTimeout(this.timeoutConfirm);
    }
    if (screen === "print") {
      this.timeoutPrint = setTimeout(() => {
        this.handleScreenChange("welcome", { resetReservationCode: true })
      },60000);
    } else {
      clearTimeout(this.timeoutPrint);
    }
    if (screen === "enterCode") {
      console.log('trigger');
      this.timeoutCode = setTimeout(() => {
        this.handleScreenChange("welcome", { resetReservationCode: true })
      },15000);
    } else {
      clearTimeout(this.timeoutCode);
    }
    if (screen === "welcome") {
      this.toggleLanguage('he');
      localStorage.setItem('selectedLanguage','he')
    }
    this.setState({
      currentScreen: screen,
      isSubmitting: false,
      requireFullPhoneNumber: resetReservationCode ? false : this.state.requireFullPhoneNumber,
      reservationCode: resetReservationCode ? "" : this.state.reservationCode,
      error: null
    })
  }

  handleKioskPrinterSelected = (savedPrinter) => {
    this.setState({savedPrinter})
  }

  handleEntryDoorOpened() {
    if (this.props.id==='lobby') {
      this.setState({
        hideScreensaver: true,
      }, () => {
        this.timer = setTimeout(()=>{this.setState({hideScreensaver:false})}, 2 * 60 * 1000);
      });
    }
  }

  render() {
    const { t } = this.props;
    const { reservationCode, currentScreen, reservation, roomTypeDisplay, requireFullPhoneNumber, isSubmitting, savedPrinter, hideScreensaver, error } = this.state;
    const he = i18n.getFixedT('he');
    const en = i18n.getFixedT('en');

    if ( !savedPrinter && false ) {
      return (
        <div>
          <div className={'container-page view-mikvah view-tablet'}>
            <div className="container-main">
              <header className="header">
                <div>
                  <div className="header-center">
                    <Clock />
                  </div>
                </div>
                <div>
                  <div className="header-center">
                    <Clock type="fullDate" />
                  </div>
                </div>
              </header>
              <SettingsContext.Consumer>
              {({allPrinters, selPrinter, changePrinter}) => (
              (!selPrinter ?
              <div className="container-body text-center">
                <div className="sidebar-top">
                  <div>
                    <h3 className="text-uppercase">Please choose a kiosk printer:</h3>
                    <div className="d-flex flex-wrap justify-content-center choose-printer">
                    {allPrinters?
                      allPrinters.length > 1 ?
                        allPrinters.map((printer,i) => {
                          return <div className="mx-2" key={i}><Button type="circle" text={printer.printerName} onClick={() => { this.handleKioskPrinterSelected(printer.id);changePrinter(printer.id)}} /></div>;
                        })
                      : () => {this.handleKioskPrinterSelected(allPrinters[0].id); changePrinter(allPrinters[0].id)}
                    :null}
                    </div>
                  </div>
                </div>
              </div>
              :null))}
              </SettingsContext.Consumer>
            </div>
          </div>
        </div>
      )
    }

    let screen = null;
    switch ( currentScreen ) {
      case "welcome":
        screen = <div className="w-100">
          <SettingsContext.Consumer>
            {({settings}) => {
            if(settings){
            let openTime = settings.openTime ? settings.openTime.value : null;
            let checkinbeforeopen = settings.checkinBeforeOpenMinutes && settings.checkinBeforeOpenMinutes.value ? settings.checkinBeforeOpenMinutes.value : null;
            let entryCutoff = moment(openTime).subtract(checkinbeforeopen,'m');
            return checkinbeforeopen && moment(this.state.time).subtract(settings.offset&&settings.offset.value,'seconds').isBefore(moment(entryCutoff))?
            <>
              {!hideScreensaver?
              <ScreenSaver text="Touch screen to begin" />
              :null}
             <div className="instructions">
              <h2 className="">{he('Please Wait Here')}</h2>
              <h3 className="">{he('The Mikvah is not open yet.')}</h3> 
              <h3 className="">{he('We will open at') + " "} 
              <Moment className="time" format={he("h:mm A")} element="span">{entryCutoff}</Moment>
              </h3>
            </div>
             <div className="instructions">
              <h2 className="">{en('Please Wait Here')}</h2>
              <h3 className="">{en('The Mikvah is not open yet.')}</h3> 
              <h3 className="">{en('We will open at')  + " "} 
              <Moment className="time" format={en("h:mm A")} element="span">{entryCutoff}</Moment>
              </h3>
            </div>
            </>
            : <>
            <div className="instructions">
              <h1 className="mb-3">{he('Check In')}</h1>
              <div className="btn-row">
                <Button type="primary" size="lg" onClick={() => this.handleScreenChange("enterCode", null, "he")}>{he('Get Started')}</Button>
              </div>
            </div>
            <div className="instructions">
              <h1 className="mb-3">{en('Check In')}</h1>
              <div className="btn-row">
                <Button type="primary" size="lg" onClick={() => this.handleScreenChange("enterCode",null, "en")}>{en('Get Started')}</Button>
              </div>
            </div>
            </>
          }}}
        </SettingsContext.Consumer>
        </div>
        break;
      case "enterCode":
        screen = <div>
          {requireFullPhoneNumber?
            <div className="instructions">
              {t("kioskKeypadInsructionsFullNumber")}
            </div>
          :
            <div className="instructions">
              {t("kioskKeypadInsructions")}
            </div>
          }
          <div className="wrapper-phonenumber">
            {isSubmitting?
              <ReactSVG className="loader-spinner dark large" src={LoaderSpinner} />
            :
              <div className={"flippers"}>
              {requireFullPhoneNumber?
                <Flipper type="full" text={reservationCode} />
              :
                [0,0,0,0].map((v,i) => {
                  return <Flipper type="single" key={i} text={reservationCode[i]} />
                })
              }
              </div>
            }
          </div>
          <NumericKeyboard layout="tel" onPress={this.handlePress} />
        </div>
        break;
      case "confirm":
        screen = <div>
          <div className="instructions">
            {t('kioskReviewReservationTitle')}
          </div>
          <SettingsContext.Consumer>
            {({settings}) => {
            if(settings){
              let reservationMinutesTotal;

              if (settings!==null&&reservation.reservationId) {
                let reservationMinutes = parseFloat(settings['reservationMinutes'+(reservation.bath?'Bath':'Shower')].value);
                let reservationMinutesDoubleSlot = parseFloat(settings.reservationMinutesDoubleSlot.value);
                reservationMinutesTotal = reservationMinutes + (reservation.doubleSlot ? reservationMinutesDoubleSlot : 0);
              }          
              return <>
              <div className="checkin-reservation">
                <div className="title">{t("Reservation")}</div>
                <div className="reservation-number">{reservation.reservationCode}</div>
            <ul className="reservation-summary">
              <li>
                <div className="icon">
                  <ReactSVG src={iconClock} />
                </div>
                <Moment format={t("h:mma")}>{reservation.reservationTime}</Moment>-
                <Moment format={t("h:mma")} add={{minutes: reservationMinutesTotal}}>{reservation.reservationTime}</Moment>
              </li>
              {roomTypeDisplay?
                <li>
                  <div className="icon">
                    <ReactSVG src={roomTypeDisplay.icon} />
                  </div>
                  <span>{t("Room Type")}: <span className="text-capitalize">{t(roomTypeDisplay.name)}</span></span>
                  {/* <div className="price"><CurrencyFormat value={settings[roomTypeDisplay.priceName].value} /></div> */}
                </li>
              :null}
              {reservation.parking?
              <li>
                <div className="icon">
                  <ReactSVG src={iconParking} />
                </div>
                {reservation.parking?t("Parking"):t("Walking")}
                {/* <div className="price"><CurrencyFormat value={settings.priceParking.value} /></div> */}
              </li>
              :null}
              {reservation.salonBoth?
                <li>
                  <div className="icon">
                    <ReactSVG src={iconSalon} />
                  </div>
                  {t("Mani+Pedi")}
                  {/* <div className="price"><CurrencyFormat value={settings.priceSalonBoth.value} /></div> */}
                </li>
              :(reservation.salonManicure?
                <li>
                  <div className="icon">
                    <ReactSVG src={iconSalon} />
                  </div>
                  {t("Manicure")}
                  {/* <div className="price"><CurrencyFormat value={settings.priceSalonManicure.value} /></div> */}
                </li>                  
              :null)}
              {reservation.doubleSlot?
              <li>
                <div className="icon">
                  <ReactSVG src={iconClockExtra} />
                </div>
                {t("Extra Time")}
                {/* <div className="price"><CurrencyFormat value={settings.priceDoubleSlot.value} /></div> */}
              </li>
              :null}
              {reservation.balance>0?
              <li>
                <div className="icon">
                  <ReactSVG src={moneyOwed} />
                </div>
                {t("Balance")}:
                <div className="price"><CurrencyFormat value={reservation.balance} /></div>
              </li>
              :null}
              </ul>
              </div>
            </>
            }}}
            </SettingsContext.Consumer>
          <div className="mb-4">
            <Button className="mie-3" size="lg" type="secondary" onClick={() => this.handleScreenChange('updateAtReception')}>{t('Change Details')}</Button>
            <Button size="lg" type="primary" onClick={() => this.handleScreenChange(reservation.balance>0?'pay':'print')}>{reservation.balance>0?t('Pay and Check-In'):t('Check-In')}</Button>
          </div>
          <div>
            <Button type="outline-primary" onClick={() => this.handleScreenChange('enterCode', { resetReservationCode: true })}>{t('Cancel')}</Button>
          </div>
        </div>
        break;
      case "pay":
        screen = 
        <div className="kiosk-pay">
          {error?<Alert color="danger">{t(error)}</Alert>:null}
          <SettingsContext.Consumer>
            {({settings, selCardReader}) => {
              if((settings.ccProcessorType && settings.ccProcessorType.value==='icount') && selCardReader){
                return <div className="mb-5">
                  <div className="instructions">
                    {t('kioskPayInstructions')}
                  </div>
                  <div className="balance">
                    <div className="icon">
                      <ReactSVG src={moneyOwed} />
                    </div>
                    {t("Balance")}:
                    <div className="price"><CurrencyFormat value={reservation.balance} /></div>
                  </div>
                  <Button type="circle-big" text={t("Pay by card")} loading={isSubmitting} onClick={this.handleChargeTerminalICount}></Button>
                </div>
              }
            }}
          </SettingsContext.Consumer>
          <div>
            <Button className="mie-3" type="outline-primary" onClick={() => this.handleScreenChange('enterCode', { resetReservationCode: true })}>{t('Cancel')}</Button>
          </div>
        </div>
        break;
      case "print":
        screen = <div>
          <div className="instructions">
            {savedPrinter?t('kioskCheckinInstructionsReceipt'):t('kioskCheckinInstructions')}
          </div>
          <div className="mb-5">
            <Button type="circle-big" icon={<ReactSVG src={iconPrinter} />} text={t("Check In")} loading={isSubmitting} onClick={this.handleCheckin}></Button>
          </div>
          <div>
            <Button type="outline-primary" onClick={() => this.handleScreenChange('enterCode' , { resetReservationCode: true })}>{t('Cancel')}</Button>
          </div>
        </div>
        break;
      case "end":
        screen = <div>
          <div className="instructions">
            <div className="large">
              {t('kioskYouAreCheckedIn')}
            </div>
            {this.props.id==='parking'?
              <>
                {reservation.reservationStatus==="Waiting"||(reservation.reservationStatus==="Assigned"&&reservation.room.id>=15)?<FontAwesomeIcon icon="arrow-circle-right" className="icon-arrow" />:null}
                {reservation.reservationStatus==="Assigned"&&reservation.room.id<=14?<FontAwesomeIcon icon="arrow-circle-left" className="icon-arrow" />:null}
              </>
            :null}
            {reservation.reservationStatus==="Waiting" && !reservation.roomIdPreassign?
              <div>{savedPrinter?t('kioskTakeReceiptWaitingRoom'):t('kioskWaitingRoom')}</div>
            :null}
            {reservation.reservationStatus==="Waiting" && (reservation.roomIdPreassign || reservation.kallah)?
              <div>{t('preassignedWaitingText')}</div>
            :null}
            {reservation.reservationStatus==="Assigned"?
              <div>{savedPrinter?t('kioskAssignedReceipt'):t('kioskAssigned')} {reservation.room.id}</div>
            :null}
          </div>
        </div>
        break;
      case "updateAtReception":
        screen = <div>
          <div className="instructions">
            <div className="large">
              {t('Please visit the receptionist to change the details of your reservation.')}
            </div>
          </div>
        </div>
        break;
      case "error":
        screen =  <div>
        <div className="instructions">
          <div className="large">
            {t('kioskErrorTitle')}
          </div>
          <div>{t('kioskErrorSubtitle')}</div>
        </div>
      </div>
        break;
      default:
        screen = <div></div>
        break;
    }

    return (
      <div className={'container-page view-kiosk lang-' + i18n.language}>
        <SignalR setExitScreen onEntryDoorOpen={() => this.handleEntryDoorOpened()} />
        <SettingsContext.Consumer>
        {({settings, changePrinter}) =>{
          if(settings){
            let openTime = settings.openTime ? settings.openTime.value : null;
            let checkinbeforeopen = settings.checkinBeforeOpenMinutes && settings.checkinBeforeOpenMinutes.value ? settings.checkinBeforeOpenMinutes.value : null;
            let entryCutoff = moment(openTime).subtract(checkinbeforeopen,'m'); 
          return <KioskAdmin changePrinter={changePrinter} handleKioskPrinterSelected={this.handleKioskPrinterSelected} handleEarlyCheckin={this.handleScreenChange} beforeCheckinAllowed={checkinbeforeopen && moment(this.state.time).subtract(settings.offset&&settings.offset.value,'seconds').isBefore(moment(entryCutoff))} />
          }
        }}
        </SettingsContext.Consumer>
        <header className="header">
          <div>
            <div className="header-left">
              <img src={logo} className="logo" alt={logoImageAltText}/>            
            </div>
            <div className="header-right">
              <Clock />
              <Clock type="fullDate" />
            </div>
          </div>
        </header>
        <div className="container-body">
          <LanguageSelector />
          {screen}
        </div>
      </div>
    );
  }
}

export default withTranslation()(KioskView);
