import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getReservations } from '../models/reservation';
import { Alert } from "reactstrap";
import HebrewDate from './hebrewDate';

class KallahCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      time: null,
      reservations: null
    };
  }

  componentDidMount() {
    this.retrieveKallahCount();
  }

  componentDidUpdate(prevProps){
    if(prevProps.date !== this.props.date){
      this.retrieveKallahCount();
    }
  }

  retrieveKallahCount() {
    let { date } = this.props;
    let rangeBegin = moment(date).subtract(3,'d').format('YYYY-MM-DD');
    let rangeEnd = moment(date).add(3,'d').format('YYYY-MM-DD');
    getReservations(rangeBegin, rangeEnd, true)
      .then(
        result => {
          let reservationDates = {};
          result.all.forEach(res => {
            return reservationDates[res.reservationDate]?reservationDates[res.reservationDate]+=1:reservationDates[res.reservationDate]=1;
          })
          this.setState({reservationDates, isLoaded: true})
        }, error => {
          this.setState({error});
        }
      );
  }

  render() {
    const { t, className } = this.props;
    const { isLoaded, error, reservationDates } = this.state;
    return isLoaded && Object.keys(reservationDates).length ? (
     <div className={"kallah-count " + className}>
       <div>{t("KallahReservationsAroundDate")}</div>
       {Object.keys(reservationDates).map((res,i) => {
         return <div className="kallah-count-day"><Moment format={t("MM/DD")}>{res}</Moment> <HebrewDate date={res} day={true} month={true}/>: <b>{reservationDates[res]}</b></div>
       })}
     </div>
    ) : 
    error?
      <Alert color="danger">{t(error)}</Alert>
    :null;
  }
}

export default withTranslation()(KallahCount);