import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from "react-router-dom";
import i18n from './../../i18n';

import AttendantListView from './partials/attendantList';
import RoomSummary from './../receptionist/room-summary';
import Loader from './../../components/snippets/loader';
import Button from './../../components/button';
import Clock from './../../components/clock';
import ZmanTevilah from './../../components/zman-tevilah';
import RoomTiles from './../receptionist/RoomTiles';
import SignalR from './../../components/signalr';
import LanguageSelector from './../../components/language-selector';
import { getRooms } from './../../models/room';
import { getSections } from './../../models/section';
import { getAttendants } from './../../models/attendant';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import moment from 'moment';
import { SettingsContext } from './../../components/settings';
import SectionMapAdmin from './../../components/section-map-admin';

class AttendantMapView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoadedRooms: false,
      isLoadedAttendants: false,
      supplyRequests: null,
      modal: false,
      selectedRoom: null,
      error: null,
      showFullMap: false
    };
     
    this.notificationSound = new Audio(NotificationSoundFile);
    this.playNotificationSound = this.playNotificationSound.bind(this);
    this.handleShowFullMap = this.handleShowFullMap.bind(this);
  }

  componentDidMount() {
    if ( this.props.id ) {
    this.retrieveRooms();
    this.setUpTick();
      if (this.props.id==='0') {
        localStorage.removeItem('selectedSectionMap');
      }
    } else {
      let savedSelectedSection = localStorage.getItem('selectedSectionMap');
      if ( savedSelectedSection ) {
        this.setState({savedSelectedSection});
        return;
      }
    }
    this.retrieveSections();
    this.retrieveAttendants();
  }

  setUpTick() {
    this.intervalID = setInterval(() => this.setState({time: moment().format()}), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.processRooms(rooms);
          this.setState({
            error: null,
          });
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  handleRoomUpdated(room) {
    const { rooms, selectedRoom } = this.state;
    rooms[selectedRoom] = room;
    this.setState({rooms});
  }

  retrieveSections() {
    getSections()
      .then(
        result => {
          let sections = result;
          let section = sections.find(section=>section.id===this.props.id);
          let attendant = section && section.attendant ? section.attendant : null;
          let attendantSections = sections.filter(section => section.attendant && attendant && section.attendant.id===attendant.id).map(section => section.id);  
          this.setState({
            isLoadedSections: true,
            sections: result,
            attendant,
            attendantSections
          });
        },
        error => {
          this.setState({
            isLoadedSections: false,
            error
          });
        }
      );
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  processRooms(rooms) {
    const {settings} = this.context;
    let supplies = rooms.map((room, i) => {
      let hasActiveRequest = false;
      if ( settings && room.reservation!=null ) {
        room.reservation.supplies.forEach((supply) => {
          if (supply.status === 'open') { hasActiveRequest = true; }
          supply.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes()>settings.waitingForSuppliesMinutes.value;
          supply.overTimeMinutes = supply.isOverTime?moment.duration(moment(this.state.time).subtract(settings.waitingForSuppliesMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes():null;
        });
        room.reservation.room = { id: room.id, section: room.section };
      }
      if (settings && (room.effectiveStatus==='ReadyForMikvah'||room.effectiveStatus==='ReadyForMikvahNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForMikvahMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForMikvahMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      if (settings && (room.effectiveStatus==='ReadyForChecking'||room.effectiveStatus==='ReadyForCheckingNext')) {
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForCheckingMinutes.value;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForCheckingMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      }
      return hasActiveRequest ? room.reservation : null;
    }).filter(room => room!=null);
    this.setState({
      isLoadedRooms: true,
      supplyRequests: supplies,
      rooms: rooms,
    });
  }

  playNotificationSound(id, isReadyMikvah) {
    const {settings} = this.context;
    if((settings && settings.reservationsCheckingBeforeReadyForMikvah && settings.reservationsCheckingBeforeReadyForMikvah.value==="True")&&isReadyMikvah){
      return false;
    } else{
      let room = this.state.rooms.filter(room => room.id === id)[0];
      if(this.state.attendantSections.includes(room.section.id)){
        this.notificationSound.play();
      }
    }
  }

  handleSectionSelected = (sectionId) => {
    localStorage.setItem('selectedSectionMap', sectionId);
  }

  handleShowFullMap() {
    this.setState({
      showFullMap: !this.state.showFullMap,
    })
    setTimeout(() => this.setState({showFullMap: false}), 10000)
  }

  render() {
    const { t } = this.props;
    const { rooms, isLoadedRooms, error, isLoadedSections, showFullMap, sections, attendantSections, isLoadedAttendants, attendants, attendant, supplyRequests } = this.state;
    if ( this.state.savedSelectedSection ) {
      return <Redirect to={`/attendant/sectionmap/${this.state.savedSelectedSection}`} />;
    }
    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} onSectionsUpdated={() => this.retrieveSections()} onNewSupplyRequest={(id) => this.playNotificationSound(id)} onNewReadyForMikvah={(id) => this.playNotificationSound(id, true)} onNewReadyForChecking={(id) => this.playNotificationSound(id)} />
        <SettingsContext.Consumer>
          {({settings}) =>
            settings!== null?
              <SectionMapAdmin adminCorrectCode={settings.roomAdminPasscode.value}/>
            :null
          }
        </SettingsContext.Consumer>
        <div className={'container-page view-attendant-sectionmap view-tablet lang-' + i18n.language}>
          <div className="container-main">
          <header className="header">
              <div>
                <div className="header-left d-flex align-items-end">
                  <div>
                    <Button className="mb-2" type="primary" onClick={this.handleShowFullMap}>{t(showFullMap?"Show Section Map":"Show Full Map")}</Button>
                    <ZmanTevilah />
                  </div>
                </div>
                <div className="header-center d-flex">
                {isLoadedRooms && rooms.length?
                  <>                
                  <RoomSummary rooms={rooms} />
                  </>
                :null}
                </div>
                <div className="header-right">
                  <Clock />
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <LanguageSelector />
            <div className="sectionmap-container justify-content-center">
              {!this.props.id || this.props.id==='0' ?
                <div className="mt-5">
                  <h3 className="text-uppercase text-center">{t("Please choose a section")}:</h3>
                  <div className="d-flex justify-content-center">
                    {isLoadedSections?
                      sections.map((section,i) => {
                        return <Link to={"/attendant/sectionmap/" + section.id} className="mie-3" key={i} onClick={() => this.handleSectionSelected(section.id)}><Button type="circle" text={section.name} /></Link>;
                      })
                    :<Loader />}
                  </div>
                </div>          
              :
              <>
              {showFullMap?
                null
              :
              <AttendantListView isLoadedRooms={rooms} rooms={rooms} attendant={attendant} isLoadedSections={isLoadedSections} sections={sections} isLoadedAttendants={isLoadedAttendants} attendants={attendants} supplyRequests={supplyRequests} id={this.props.id} />
            }             
              <SettingsContext.Consumer>
                {({settings}) =>
                  <RoomTiles activeSection={showFullMap?null:attendantSections} rooms={rooms} isLoadedRooms={isLoadedRooms} error={error} isLoadedSections={isLoadedSections} sections={sections} handleRoomUpdated={(r) => this.handleRoomUpdated(r)} time={this.state.time} settings={settings} />
                }
              </SettingsContext.Consumer>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AttendantMapView);