import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import SignalR from '../../components/signalr';
import Loader from '../../components/snippets/loader';
import Button from '../../components/button';
import ReceptionistTabs from './partials/tabs';
import { SettingsContext } from '../../components/settings';
import { getPrinterStatus, getPrinters, getCardReaders } from '../../models/setting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n';

class ReceptionistDevicesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      printers: null,
      recPrinters: null
    };

  }

  componentDidMount() {
    this.retrievePrinters();
    this.retrieveCardReaders();
  }

  retrievePrinters() {
    this.setState({isLoaded: false});
    getPrinterStatus()
      .then(
        result => {
          let printers = result;
          this.setState({
            printers,
            isLoaded: true,
          });
        },
        error => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      );
      getPrinters()
      .then(
        result => {
          let recPrinters = result.filter(printer => printer.printerType == 'reception');
          this.setState({
            recPrinters: recPrinters,
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }
  retrieveCardReaders = () => {
    getCardReaders()
      .then(
        result => {
          this.setState({
            cardReaders: result,
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }

  render() {
    const { t } = this.props;
    const { isLoaded, printers, error, recPrinters, cardReaders } = this.state;
    const currentPageName = "Devices"
    return (
      <div className={'container-page lang-' + i18n.language}>
        <SignalR setReception={true} onPrinterTrouble={() => this.retrievePrinters()} />
        <div>
          <div className="container-body reception-background">
            <ReceptionistTabs page={currentPageName}/>
            <div className="reception-view">
              {error!==null?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :
                ( isLoaded ?
                  <div className="container">
                    <div className="row">
                      {printers.map((printer,i)=>{
                        return <div className="col" key={i}>
                          <div className="card">
                            <div className="card-body text-center">
                              <FontAwesomeIcon icon="receipt" className={'h1 my-3 text-' + (printer.status==='ok'?'muted':'danger')} />
                              <h3>{t(printer.name)}</h3>
                            </div>
                            {printer.message?
                              <div className="card-footer">
                                <div>{t(printer.message)}</div>
                              </div>
                            :null}
                            {printer.status==='ok'?
                              <div className="card-footer text-success text-center">
                                <FontAwesomeIcon icon="check" /> {t("Connected")}
                              </div>
                            :null}
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                : <Loader /> )
              }
              <SettingsContext.Consumer>
                {({selPrinter, changePrinter, selCardReader, changeCardReader}) => (
                  <>
                  {recPrinters ?
                    recPrinters.length > 1 || (recPrinters.length == 1 && recPrinters[0].id !== selPrinter) ?
                    <>
                    <div className="container-body">
                      <h3 className="text-uppercase text-center">{t("Select Receptionist printer")}:</h3>
                      <div className="d-flex flex-wrap justify-content-center choose-printer">
                        {recPrinters.map((printer,i) => {
                          return <div className="mx-2" key={i}><Button active={selPrinter == printer.id ?true:false} disabled={selPrinter == printer.id ?true:false}  type="circle" text={printer.printerName} onClick={() => changePrinter(printer.id)} /></div>;
                        })}
                      </div>
                    </div>
                    </>
                  : null
                :null}
                {cardReaders?
                  cardReaders.length > 1 || (cardReaders.length == 1 && cardReaders[0].stripeTerminalId !== selCardReader)?
                  <>
                  <div className="container-body">
                  <h3 className="text-uppercase text-center">{t("Please select a card reader")}:</h3>
                  <div className="d-flex flex-wrap justify-content-center choose-printer">
                    {
                      cardReaders.map((cardReader,i) => {
                        return <div className="mx-2" key={i}><Button active={selCardReader == cardReader.stripeTerminalId ?true:false} disabled={selCardReader == cardReader.stripeTerminalId ?true:false} type="circle" text={cardReader.readerName} onClick={() => changeCardReader(cardReader.stripeTerminalId)} /></div>;
                      })
                    }
                  </div>
                  </div>
                  </>
                  :null  
                :null}
                </>
                )}
              </SettingsContext.Consumer>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation()(ReceptionistDevicesView);
