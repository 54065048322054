import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';
class ScreenReadyChecking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "Prep";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }


  render() {
    const { t } = this.props;
    const { error } = this.state;
    let readyForCheckingPosition = this.props.room.readyForCheckingPosition;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}

        <div className="instructions">
          {t("You are ready for checking and waiting for an attendant")}
        </div>
        <div className="room-queue">
         {readyForCheckingPosition?
          <>
            <p className="title">{t("There are")}</p>
            <p className="number">{readyForCheckingPosition}</p>
            <p className="title">{t("Ahead of you")}</p>
          </>
         :
          <>
            <p className="text-next">{t("You are next")}</p>
            <p className="instructions mb-0">{t("The attendant will be there shortly")}</p>
          </>
         }
        </div>
        <Button type="secondary" size="lg" onClick={this.handleChangeStatus} loading={this.state.isUpdatingStatus} text={t("Cancel")} />
      </div>
    );
  }
}

export default withTranslation()(ScreenReadyChecking);
