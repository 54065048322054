import React from "react";
import { withTranslation, Trans } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from './../components/button';
import moment from 'moment';

class ScreenDressing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format(),
      error: null,
      isUpdatingStatus: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  setUpTick() {
    this.tick();
    this.intervalID = setInterval(
      () => this.tick(),
      5000
    );
  }

  tick() {
    const { settings } = this.props;
    let overtime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(this.props.room.effectiveStatusLastChange)).asMinutes()>(settings && settings.dressingMinutes.value);
    if(overtime && this.state.overTimeMessage){
      clearInterval(this.intervalID);
    }
    this.setState({
      time: moment().format(),
      overTimeMessage: overtime
    });
  }

  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "Done";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }

  render() {
    const { t, room } = this.props;
    const { error, overTimeMessage } = this.state;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}

        <div className="instructions">
          {overTimeMessage?
            <p>{t("overtimeInstructions")}</p>
          :null}
          {!room.privateRoom?<p>{t("DoneInstructions")}</p>:null}
          <p>{t("itemsLeftOver")}</p>
          {!room.privateRoom?<p>{t("roomDressingInstructions")}</p>:null}
          <p><Trans i18nKey={room.reservation.parking?'roomDressingExitParking':'roomDressingExitStreet'} components={[<FontAwesomeIcon icon="parking" />]} /></p>
          {room.privateRoom?<Button type="circle-big" onClick={() => this.handleChangeStatus()} loading={this.state.isUpdatingStatus} text={t("I’m Done")} />:null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ScreenDressing);
