import React from 'react';
import Flipper from '../flipper';
import { withTranslation } from 'react-i18next';

function RoomNumber(props) {
  const { t, title } = props;
  return <div className="room-number">
    <div className="title">{title?t(title):t('Room')}</div>
    <Flipper type="double" text={props.number} />
  </div>
}

export default withTranslation()(RoomNumber);