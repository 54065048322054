import React, { Component, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Moment from 'react-moment';
import { Alert, UncontrolledDropdown, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { statuses } from './../../global-props';

import { getReservations, getReservationsByUser, sendSms } from './../../models/reservation';
import { getUserByPhone } from "./../../models/user";
import { getAttendants } from './../../models/attendant';
import ReceptionistTabs from './partials/tabs';
import SignalR from './../../components/signalr';
import CurrencyFormat from './../../components/snippets/currency-format';
import ReservationDetails from './partials/reservation-details';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Loader from './../../components/snippets/loader';
import HebrewDate from './../../components/hebrewDate';
import ReactSVG from 'react-svg';
import iconExcludedAttendant from "../../assets/images/icon-excluded-attendant.svg";
import iconMerchandise from "../../assets/images/icon-merchandise.svg";
import iconToggleDatepicker from "../../assets/images/icon-toggle-datepicker.svg";
import iconToggleRangeMinus from "../../assets/images/icon-toggle-range-minus.svg";
import iconToggleRangePlus from "../../assets/images/icon-toggle-range-plus.svg";
import iconBathPrivate from '../../assets/images/icon-bath-private.svg';
import iconShowerPrivate from '../../assets/images/icon-shower-private.svg';
import iconWalkin from "../../assets/images/icon-walkin.svg";
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/he';
import { ReactJewishDatePicker } from "react-jewish-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/button';
import { withRouter, Link } from 'react-router-dom';
import { appAuth } from "../../router";
import { useTable, useSortBy } from 'react-table';

class ReceptionistReservationsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      modalReservation: false,
      selectedReservation: null,
      reservations: null,
      enteredPhone: '',
      selectedDays: { from: moment().toDate(), to: this.props.kallah?moment().add(1, 'year').toDate():null },
      basicJewishDateRange: {startDate:moment().toDate(),endDate: this.props.kallah?moment().add(1, 'year').toDate():null},
      userResults: null,
      isUserResultsOpen: false,
      openSms: false,
      sendToSms: [],
      sendToSmsAll: [],
      smsMessage: '',
      smsPhone: '',
      toggleAll: false,
      smsError: "",
      smsModal: false,
      smsResult: [],
      isLoadedAttendants: false,
      attendants: [],
      hebrewDayPicker:(this.props.i18n.language === 'he'),
      key: 1,
      range: false,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.smsModalToggle = this.smsModalToggle.bind(this);
    this.toggleUserResults = this.toggleUserResults.bind(this);
    this.toggleSMS = this.toggleSMS.bind(this);
    this.toggleSmsAll = this.toggleSmsAll.bind(this);
    this.viewReservation = this.viewReservation.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.changeDay = this.changeDay.bind(this);
  }

  componentDidMount() {
    this.retrieveReservations();
    this.retrieveAttendants();
    if(appAuth.authorization&&appAuth.authorization.roles.includes('Admin')){
      this.setState({showAdmin: true})
    }
  }

  retrieveAttendants() {
    getAttendants()
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveReservations(date=null, dateTo=null, hideLoader=false) {
    const { selectedDays, selectedUser } = this.state;
    let userId = selectedUser?selectedUser.id:null;
    if (!hideLoader) { this.setState({isLoaded: false}); }
    if ( userId===null ) {
      getReservations(date===null ? moment(selectedDays.from).format('YYYY-MM-DD') : date, dateTo===null ?selectedDays.to?moment(selectedDays.to).format('YYYY-MM-DD'):null:dateTo, this.props.kallah?true:false)
        .then(
          result => {
            let reservations = result;
            let tipTotal = reservations.all.reduce((accum,curr) => accum + parseFloat(curr.tipAmount),0)
            let payments = reservations.all.map((res) => res.payments)
            let paymentsFlat = Array.prototype.concat.apply([], payments).filter(pay => pay.method==='Tip');
            let groupedTips = paymentsFlat.reduce(function (r, a) {
              r[a.tipNote] = r[a.tipNote] || [];
              r[a.tipNote].push(a);
              return r;
            },Object.create(null));
            Object.keys(groupedTips).map((tipFor) => {
              groupedTips[tipFor] = groupedTips[tipFor].reduce((accum,curr) => {
              return accum + curr.amount
            },0)})
            this.setState({
              reservations: reservations,
              isLoaded: true,
              openSms: false,
              toggleAll: false,
              sendToSmsAll: [],
              sendToSms: [],
              tipTotal,
              groupedTips,
            });            
          },
          error => {
            this.setState({
              isLoaded: false,
              error
            });
          }
        );      
    } else {
      let dateFrom = moment('2019-01-01').format('YYYY-MM-DD');
      let dateTo = moment().add(1, 'week').format('YYYY-MM-DD');
      getReservationsByUser(userId, dateFrom, dateTo)
        .then(
          result => {
            let reservations = result;
            this.setState({
              reservations: {all: reservations},
              isLoaded: true,
            });
          },
          error => {
            console.log(error);
            this.setState({
              isLoaded: false,
              error
            });
          }
        );
      
    }
  }

  viewReservation(event,resId,userId) {
    if(event.target.type !== 'checkbox'){
      this.setState({selectedReservation: this.state.reservations.all.findIndex(res=>res.reservationId===resId), modalReservation: true});
    } else{
      this.checkSMS(userId);
    }
  }

  changeDay(day, type) {
    const { selectedDays } = this.state;
    const { from, to } = selectedDays;
    let setSelectedDays;
    let diff = to ? moment(to).diff(moment(from),'days') + 1 : 1;

    if (day==='today') {
      setSelectedDays = { from: moment().toDate(), to: null };
    } else if (day==='prev') {
      setSelectedDays = { from: moment(from).subtract(diff, 'days').toDate(), to: to ? moment(to).subtract(diff, 'days').toDate() : null };
    } else if (day==='next') {
      setSelectedDays = { from: moment(from).add(diff, 'days').toDate(), to: to ? moment(to).add(diff, 'days').toDate() : null };
    } else {
      if (type==='from') {
        setSelectedDays = {...selectedDays, from: day}
      } else {
        setSelectedDays = from ? {...selectedDays, to: day} : {from: day, to: day};
      }
    }

    this.setState({ selectedDays: setSelectedDays, basicJewishDateRange: {startDate:setSelectedDays.from,endDate: setSelectedDays.to}, enteredPhone: '', selectedUser: null, userResults: null, key: this.state.key+1, error: null }, () => {const {selectedDays } = this.state;this.retrieveReservations(moment(selectedDays.from).format('YYYY-MM-DD'), (selectedDays.to ? moment(selectedDays.to).format('YYYY-MM-DD') : null))});
  }

  changeHebrewDay(fromDay, toDay){
    const { selectedDays } = this.state;
    const { from, to } = selectedDays;
    let setSelectedDays;

    if(fromDay){
      setSelectedDays = {...selectedDays, from: fromDay}
    } 
    if(toDay){
      setSelectedDays = {...selectedDays, to: toDay}
    }

    this.setState({ selectedDays: setSelectedDays, basicJewishDateRange: {startDate:setSelectedDays.from,endDate: setSelectedDays.to}, enteredPhone: '', selectedUser: null, userResults: null, key: this.state.key+1, error: null }, () => {const {selectedDays } = this.state;this.retrieveReservations(moment(selectedDays.from).format('YYYY-MM-DD'), (selectedDays.to ? moment(selectedDays.to).format('YYYY-MM-DD') : null))});
  }

  handleChangePhone(e) {
    let numberLength = 15;
    let value = e.target.value;
    value = value.slice(0,numberLength);

    this.setState({ enteredPhone: value, userResults: null, error: null })
    if ( value.length >= 4) {
      getUserByPhone(value)
      .then(
        result => {
          this.setState({
            userResults: result,
            isUserResultsOpen: true,
          })
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
      
    }
  }

  handleSMSChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
  
      if(name !== 'smsPhone' || (name === 'smsPhone' && value.match(/^[0-9,]+$/)) || !value){
        this.setState({[name]: value});
      } 
    }

  handleSelectUser(user) {
    this.setState({ selectedDays: { from: null, to: null }, key:this.state.key+1, basicJewishDateRange: {startDate:undefined,endDate: undefined}, enteredPhone: user.phoneNumber, selectedUser: user }, ()=>this.retrieveReservations(null))
  }

  modalToggle() {
    this.setState({selectedReservation: null});
  }

  smsModalToggle() {
    this.setState({smsModal: !this.state.smsModal});
  }

  toggleUserResults() {
    this.setState({isUserResultsOpen: !this.state.isUserResultsOpen});
  }

  toggleSMS() {
    let smsAll = [];
    if(!this.state.sendToSmsAll.length){
      this.state.reservations.all.forEach(res => {
        if(!smsAll.includes(res.userId)){
          smsAll = [...smsAll, res.userId];
        }
      })
      this.setState({sendToSmsAll: smsAll})
    }
    this.setState({openSms: !this.state.openSms});
  }

  toggleSmsAll() {
    if(!this.state.toggleAll){
      this.setState({
        sendToSms: this.state.sendToSmsAll,
        toggleAll: !this.state.toggleAll,
      })
    } else {
      this.setState({
        sendToSms: [],
        toggleAll: !this.state.toggleAll,
      })
    }
  }

  submitSMS() {
    this.setState({smsModal: true})
    sendSms(this.state.smsMessage, this.state.sendToSms ,this.state.smsPhone.split(","))
    .then(
      result => {
        this.setState({
          smsResult: result,
          openSms: false,
          smsMessage: "",
          smsPhone: "",
          sendToSms: this.state.sendToSmsAll
        })
      },
      error => {
        this.setState({
          smsError: error
        });
      })
  }

  checkSMS(userId) {
    if(this.state.sendToSms.includes(userId)){
      var array = [...this.state.sendToSms];
      var index = array.indexOf(userId);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({sendToSms: array},() => {
          if(this.state.sendToSms.length < this.state.sendToSmsAll.length){
            this.setState({toggleAll: false})
          }
        });      
      }
    } else{
      this.setState({
        sendToSms: [...this.state.sendToSms, userId]
      })
    }
  }

  handleReservationUpdated(res) {
    this.setState(prevState => ({
      reservations:{
        ...prevState.reservations,
        all: Object.assign([], prevState.reservations.all, {[this.state.selectedReservation]:  res})        
      }
    }))
  }

  // generateCSV(){
  //   const { t } = this.props;
  //   const {reservations, selectedUser, selectedDays} = this.state;

  //   const csvExport = reservations&&reservations.all.length?[['Code',' Date', 'Time', 'Status', 'Room Type', 'Balance', 'Room', 'Arrived', 'Assigned', 'Completed', 'Notes'], ...reservations.all.map(res=>[ 
  //     res.reservationCode + (res.firstTime?'*':'') + (res.user.testUser?' - Test':''),
  //     moment(res.reservationTime).format('MM/DD/YY'),
  //     (res.advanceReservation?moment(res.reservationTime).format('h:mma'):'Walk-in'),
  //     statuses[res.reservationStatus].name,
  //     res.kallah?'Kallah':((res.roomType==='bath'?'Bath':'')+(res.roomType==='shower'?'Shower':'')+(res.roomType==='handicap'?'Accessible':'')) + (res.parking?' - Parking':'') + (res.doubleSlot?' - Extra Time':'') + (res.salonManicure||res.salonBoth?' - Salon':''),
  //     res.paid?'Paid':<CurrencyFormat value={res.balance} />,
  //     res.room!=null?res.room.id:'' + (['Done', 'Canceled'].includes(res.reservationStatus)?res.roomId:''),
  //     res.timeArrived!=null?moment(res.timeArrived).format('h:mmA'):'',
  //     res.timeAssigned!=null?moment(res.timeAssigned).format('h:mmA'):'',
  //     res.timeCompleted!=null?moment(res.timeCompleted).format('h:mmA'):'',
  //     (res.notes!=null?res.notes:'') + (res.user.notes!=null?res.user.notes:'')
  //     ])]:null;
  //   const csvFilename = selectedUser ? `reservations-${selectedUser.phoneNumber}.csv` : `reservations-${moment(selectedDays.from).format('MM-DD-YYYY') + (selectedDays.to ? '-' + moment(selectedDays.to).format('MM-DD-YYYY') : '')}.csv`;
  //   this.setState({csvExport,csvFilename})
  // }

  render() {
    const { t, i18n, kallah } = this.props;
    const { isLoaded, reservations, selectedReservation, selectedDays, enteredPhone, userResults, isUserResultsOpen, selectedUser, error, openSms, sendToSms, smsMessage, smsPhone, toggleAll, smsError, smsResult, groupedTips, showAdmin, attendants, isLoadedAttendants, hebrewDayPicker, range, basicJewishDateRange } = this.state;
    const currentPageName = "Reservations";
    let kallahReservationsCount = reservations && reservations.all.filter(res=>res.kallah).length;

    return (
      <div className={'container-page'}>
        <SignalR setReception={true} onReservationsUpdated={() => this.retrieveReservations(null, null, true)} onCubiclesUpdated={() => this.retrieveReservations(null, null, true)} />
        <div>
          <div className={"container-body reception-background lang-" + i18n.language}>
          <ReceptionistTabs page={currentPageName}/>
            <div className="reception-view pt-0">
              <div className="d-flex flex-wrap fixed-head">
                <div className="">
                  <div className="form-group d-flex align-items-center mie-3">
                    <div className="mie-2 cursor-pointer" onClick={() => this.setState({hebrewDayPicker:!this.state.hebrewDayPicker})}><ReactSVG className="icon-toggle" src={iconToggleDatepicker}/></div>
                    {this.props.managerMenu||showAdmin?<div className="mie-2 cursor-pointer" onClick={() => this.setState({range:!this.state.range})}>{range?<ReactSVG className="icon-toggle" src={iconToggleRangeMinus}/>:<ReactSVG className="icon-toggle" src={iconToggleRangePlus}/>}</div>:null}
                    <span className="">
                      {hebrewDayPicker?
                        <ReactJewishDatePicker
                          key={this.state.key}
                          value={range?basicJewishDateRange.endDate?basicJewishDateRange:undefined:selectedDays.from?selectedDays.from:undefined}
                          isHebrew={i18n.language=="he"?true:false}
                          onClick={(fromDay,toDay)=> this.changeHebrewDay(fromDay?fromDay.date:null,toDay?toDay.date:null)}
                          isRange={range}
                        />            
                    :
                      <DayPickerInput format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker dir-ltr'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,selectedDays: [selectedDays.from, {from: selectedDays.from, to: selectedDays.to}], modifiers: {start: selectedDays.from, end: selectedDays.to ? selectedDays.to : selectedDays.from}, disabledDays: { after: selectedDays.to }}} formatDate={formatDate} parseDate={parseDate} value={selectedDays.from} onDayChange={day=>this.changeDay(day,'from')} placeholder={t("MM/DD/YY")} />
                    }
                    </span>
                    
                    {!hebrewDayPicker && (range || kallah)?
                    <>
                    <div className="mb-2 mx-1">&mdash;</div>
                    <span className=""><DayPickerInput  format={t("MM/DD/YY")} inputProps={{className: 'form-control form-control-datepicker dir-ltr'}} dayPickerProps={{localeUtils:MomentLocaleUtils, locale:i18n.language,selectedDays: [selectedDays.from, {from: selectedDays.from, to: selectedDays.to}], modifiers: {start: selectedDays.from, end: selectedDays.to ? selectedDays.to : selectedDays.from}, disabledDays: { before: selectedDays.from }}} formatDate={formatDate} parseDate={parseDate} value={selectedDays.to?selectedDays.to:''} onDayChange={day=>this.changeDay(day,'to')} placeholder={t("MM/DD/YY")} /></span>
                    </>
                    :null} 
                    {selectedDays && !kallah?
                    <div className="mis-2">
                      <div className="btn-group dir-ltr" role="group" aria-label="Date change">
                        {selectedDays.from?<Button className="btn-secondary" onClick={() => this.changeDay(i18n.dir()=='rtl'?'next':'prev')}><FontAwesomeIcon className="mie-2" icon="chevron-left" />{i18n.dir()=='rtl'?t("Next"):t("Previous")}</Button>:null}
                        <Button className="btn-secondary mis-2 mie-2" disabled={moment(selectedDays.from).isSame(moment(),'day')} onClick={() => this.changeDay('today')}>
                          {selectedDays.from?(moment(selectedDays.from).isSame(moment(),'day')?t("Today"):moment(selectedDays.from).format('dddd')):t("Date")}
                        </Button>
                        {selectedDays.from?<Button className="btn-secondary" onClick={() => this.changeDay(i18n.dir()=='rtl'?'prev':'next')}>{i18n.dir()=='rtl'?t("Previous"):t("Next")}<FontAwesomeIcon className="mis-2" icon="chevron-right" /></Button>:null}
                      </div>
                    </div>
                    :null}

                    {(this.props.managerMenu || showAdmin) ?                      
                      <UncontrolledDropdown className=" mis-2">
                        <DropdownToggle><FontAwesomeIcon icon="ellipsis-v" /></DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="" onClick={() => this.toggleSMS()}>{t("Send SMS")}</DropdownItem>
                          {!kallah?<DropdownItem tag={Link} to={{pathname: "/manager/reservations/report", state: {dt:selectedDays.from, dtTo: selectedDays.to}}} className="">{t("Summary Report")}</DropdownItem>:null}
                          {kallah?
                          <DropdownItem className="" onClick={() => this.props.history.push("/receptionist/reservations")}>{t("All Reservations")}</DropdownItem>
                          :
                          <DropdownItem className="" onClick={() => this.props.history.push("/receptionist/reservations/kallah")}>{t("Kallah Reservations")}</DropdownItem>
                          }
                        </DropdownMenu>

                      </UncontrolledDropdown>
                    :null}
                  </div>
                </div>
                <div className="mx-auto form-group p-2">
                  {reservations&&reservations.all.length?t("receptionistReservationsCount",{count:reservations.all.length}):null}
                  {kallahReservationsCount?<span className="mis-2 d-inline-block">{kallahReservationsCount}<FontAwesomeIcon className="mis-1" icon={['far', 'gem']} /></span>:null}
                  {reservations&&reservations.upcoming&&reservations.upcoming.length?<span className="mis-2">{reservations.upcoming.length} {t("Upcoming")}</span>:null}
                </div>
                <div>
                  <Dropdown isOpen={isUserResultsOpen} toggle={this.toggleUserResults}>
                    <div className="form-group">
                      <label className="mie-2">{t("Phone")}:</label>
                      <div className="d-inline-block">
                        <input className='form-control dir-ltr' value={enteredPhone} onClick={()=>this.setState({isUserResultsOpen:true})} onChange={this.handleChangePhone} />
                        <DropdownToggle tag="span"></DropdownToggle>
                        {userResults&&userResults.length?
                          <DropdownMenu>
                            {userResults.map((s,i)=>
                              <DropdownItem key={i} onClick={()=>this.handleSelectUser(s)}>{s.phoneNumber}</DropdownItem>
                            )}
                          </DropdownMenu>
                        :null}              
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              {error!==null?
                <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
              :
                ( isLoaded ?
                  <div>
                    {enteredPhone&&selectedUser&&selectedUser.notes?
                      <div>
                        <div>{t("User Notes")}:</div>
                        <div>{selectedUser.notes}</div>
                      </div>
                    :null}
                    {openSms?
                      <div className="form-row">
                        <div className="col-1 form-group d-flex align-items-end">
                          <input type="checkbox" className="form-check-input mis-0 cursor-pointer position-static" onChange={() => this.toggleSmsAll()} checked={toggleAll} /> 
                        </div>
                        <div className="form-group d-flex col-5">
                          <label className="mie-2 align-top">
                          {t("SMS Message")}: 
                            <small className="d-block">({t("160 character limit")})</small>
                          </label>
                          <div className="d-inline-block w-100">
                            <textarea maxLength="160" rows="3" id={'smsMessage'} name={'smsMessage'} className="form-control" value={smsMessage} onChange={(e) => this.handleSMSChange(e)} />
                          </div>
                        </div>
                        <div className="form-group d-flex col-5">
                          <label className=" mis-3 mie-2 align-top">
                          {t("Additional Numbers")}: 
                            <small className="d-block">({t("Comma Separated List")})</small>
                          </label>
                          <div className="d-inline-block w-100">
                            <textarea rows="3" id={'smsPhone'} name={'smsPhone'} className="form-control" value={smsPhone} onChange={(e) => this.handleSMSChange(e)} />
                          </div>
                        </div>
                        <div className="col-1 form-group d-flex align-items-end">
                          <Button className="btn-secondary" disabled={!smsMessage || (!sendToSms.length && !smsPhone)} onClick={() => this.submitSMS()}>{t("Send SMS")}</Button>                      
                        </div>
                      </div>
                    :null}
                    {this.state.tipTotal?
                      <div className="text-center">${this.state.tipTotal} {t("Total Tips")}</div>
                    :null}
                    {groupedTips?
                    <div className="d-flex justify-content-center">
                    {Object.keys(groupedTips).map((tipFor, i)=>
                      <div className="text-center mb-3 font-weight-normal pie-2" key={i}>{tipFor!==''?t(tipFor):t("General")}: ${groupedTips[tipFor]}{Object.keys(groupedTips).length>i+1?<span class="pb-1">,</span>:''}</div>
                    )}</div>
                    :null}
                      {reservations?
                      <ReservationsTable isLoadedAttendants={isLoadedAttendants} attendants={attendants} data={reservations.all} t={t} viewReservation={this.viewReservation} openSms={openSms} sendToSms={sendToSms} kallah={kallah}/>
                      :null}
                  </div>
                : <Loader /> )
              }
              
            </div>
          </div>

          {selectedReservation!==null?
            <ReservationDetails modalToggle={this.modalToggle}  handleReservationUpdated={(s) => this.handleReservationUpdated(s)} reservation={reservations.all[selectedReservation]} />
          :null}
          <Modal isOpen={this.state.smsModal} toggle={this.smsModalToggle} centered size="m" className={"lang-" + i18n.language} modalClassName="modal-cubicle-screen">
            <ModalHeader toggle={this.smsModalToggle}>{t("SMS Details")}</ModalHeader>
            <ModalBody>
              {smsResult.length || smsError?
                smsError?
                  <div>{t(smsError)}</div>
                :
                <>
                  <small className="mb-3 d-block">{t("'Accepted' means the number is valid, not that it was received by the user.")}</small>
                  <table className="smsResultTable table">
                    <thead>
                      <tr>
                        <th>{t("Number")}</th>
                        <th>{t("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {smsResult.map(res => {
                      return  <tr>
                        <td>{res.number}</td>
                        <td>{res.error?t(res.error):t("Accepted")}</td>
                      </tr>
                    })}
                    </tbody>
                  </table>
                </>
              : <Loader />
              }
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReceptionistReservationsView));

function ReservationsTable(props) {
  const { t, isLoadedAttendants, attendants } = props;
  const excludedColumnsKallah = ['attendantId', 'timeArrived', 'timeAssigned', 'timeCompleted'];
  const privateRooms = {"private-bath":iconBathPrivate, "private-shower": iconShowerPrivate}
  const columns = React.useMemo(
    () => [
      {
        Header: t("SMS"),
        accessor: "sms",
        disableSortBy: true,
        Cell: ({row}) => {
          const res = row.original;
          return  <input type="checkbox" className="form-check-input ml-0 cursor-pointer position-static" defaultChecked={props.sendToSms.includes(res.userId)} />
        }
      },
      {
        Header: t("Code"),
        accessor: "reservationCode",
        disableSortBy: true,
        Cell: ({row}) =>  {
          const res = row.original;
          return (
            <>
            {res.reservationCode}
            {res.user.testUser?' '+t('Test'):null}
            {res.firstTime?
            <>
              <sup><FontAwesomeIcon className="ml-2 text-orange small" icon="asterisk" id={`icon-new-user-${row.id}`} /></sup>
              <UncontrolledTooltip target={`icon-new-user-${row.id}`}>{t("New User")}</UncontrolledTooltip>
            </>
            :null}
            </>
          )
        }, 
      },
      {
        Header: t("Date"),
        accessor: "reservationDate",
        sortDescFirst: true,
        sortType: (a, b) => {
          var a1 = moment(a.original.reservationTime).valueOf();
          var b1 = moment(b.original.reservationTime).valueOf();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
        Cell: ({row}) => {
          const res = row.original;
          return (
          <>
            <Moment format={t("MM/DD")}>{res.reservationTime}</Moment>
            <HebrewDate className="mis-1" date={res.reservationTime} day={true} month={true}/>
          </>
          )
        }
      },
      {
        Header: t("Day"),
        accessor: "reservationDayOfWeek",
        disableSortBy: true,
        Cell: ({row}) => {
          const res = row.original;
          return (
            <Moment format={t("dddd")}>{res.reservationTime}</Moment>
          )
        }
      },
      {
        Header: t("Time"),
        accessor: "reservationTime",
        sortDescFirst: true,
        sortType: (a, b) => {
          var a1 = moment(a.original.reservationTime).valueOf();
          var b1 = moment(b.original.reservationTime).valueOf();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
        Cell: ({row}) => {
          const res = row.original;
          return <>{res.advanceReservation?<Moment format={t("h:mm A")}>{res.reservationTime}</Moment>:null}</>
        }
      },
      {
        Header: t("Status"),
        accessor: "reservationStatus",
        Cell: ({row}) => {
          const res = row.original;
          return t(statuses[res.reservationStatus].name)
        },
      },
      {
        Header: t("Type"),
        accessor: "type",
        Cell: ({row}) => {
          const res = row.original;
          return <>
            {res.alternateLocation?
              <FontAwesomeIcon icon="map-marker-alt" />
            :
             res.privateRoom?<ReactSVG className="icon-private" src={privateRooms[res.roomType]} />:<FontAwesomeIcon icon={res.kallah?['far','gem']:res.roomType} />
            }
            {!res.advanceReservation?<ReactSVG className="icon-walkin mis-2" src={iconWalkin} />:null} 
            {res.user.phoneNumber.slice(-10)=='0000000000'?<FontAwesomeIcon className="mis-1" icon={'user-alt-slash'} />:null}
            {res.parking?<FontAwesomeIcon className="icon mis-2" icon="car" />:null}
            {res.doubleSlot?<FontAwesomeIcon className="icon mis-2" icon={["far", "clock"]} />:null}
            {res.salonManicure||res.salonBoth?<FontAwesomeIcon className="icon mis-2" icon="paint-brush" />:null}
          </>
        },
        sortType: (a, b) => {
          var a1 = a.original.kallah?'kallah':a.original.roomType;
          var b1 = b.original.kallah?'kallah':b.original.roomType;
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Balance"),
        accessor: "balance",
        Cell: ({row}) => {
          const res = row.original;
          return res.paid?t('Paid'):<CurrencyFormat value={res.balance} />
        }
      },
      {
        Header: t("AttendantTevilah"),
        accessor: "attendantId",
        Cell: ({row}) => {
          const res = row.original;
          let atttevilah = isLoadedAttendants && attendants.find(att=>att.id===res.attendantId);
          return atttevilah?atttevilah.name:null
        }
      },
      {
        Header: t("Room"),
        accessor: "roomId",
        Cell: ({row}) => {
          const res = row.original;
          return res.room!=null?res.room.id:(['Done', 'Canceled'].includes(res.reservationStatus)?res.roomId:res.roomIdPreassign?t("Pre")+"-"+res.roomIdPreassign:null)
        },
        sortType: (a, b) => {
          var a1 = a.original.room!=null?a.original.room.id:(['Done', 'Canceled'].includes(a.original.reservationStatus)?a.original.roomId:a.original.roomIdPreassign?a.original.roomIdPreassign:null);
          var b1 = b.original.room!=null?b.original.room.id:(['Done', 'Canceled'].includes(b.original.reservationStatus)?b.original.roomId:b.original.roomIdPreassign?b.original.roomIdPreassign:null);
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Arrived"),
        accessor: "timeArrived",
        Cell: ({row}) => {
          const res = row.original;
          return res.timeArrived!=null?<Moment format={t("h:mm A")} className={!res.arrivedOnTime&&res.advanceReservation?'text-danger':''}>{res.timeArrived}</Moment>:null
        },
        sortType: (a, b) => {
          var a1 = moment(a.original.timeArrived).valueOf()||0;
          var b1 = moment(b.original.timeArrived).valueOf()||0;
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Assigned"),
        accessor: "timeAssigned",
        Cell: ({row}) => {
          const res = row.original;
          return res.timeAssigned!=null?<Moment format={t("h:mm A")}>{res.timeAssigned}</Moment>:null
        },
        sortType: (a, b) => {
          var a1 = moment(a.original.timeAssigned).valueOf()||0;
          var b1 = moment(b.original.timeAssigned).valueOf()||0;
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Completed"),
        accessor: "timeCompleted",
        Cell: ({row}) => {
          const res = row.original;
          return res.timeCompleted!=null?<Moment format={t("h:mm A")}>{res.timeCompleted}</Moment>:null
        },
        sortType: (a, b) => {
          var a1 = moment(a.original.timeCompleted).valueOf()||0;
          var b1 = moment(b.original.timeCompleted).valueOf()||0;
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      {
        Header: t("Notes"),
        accessor: "notes",
        Cell: ({row}) => {
          const res = row.original;
          return <>{res.notes!=null||res.user.notes||res.user.internalNote?<FontAwesomeIcon icon={['far', 'sticky-note']} />:null}
          {res.user.excludedAttendants.length>0?<ReactSVG className="icon-excluded mis-2" src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>:null}
          {res.merchandise && res.merchandise.length?<ReactSVG className="icon-merch mis-2" src={iconMerchandise} alt="Merchandise Icon"/>:null}
          </>
        },
        sortType: (a, b) => {
          var a1 = !!(a.original.notes || a.original.user.notes);
          var b1 = !!(b.original.notes || b.original.user.notes);
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        },
      },
      ],
    [props.sendToSms, props.openSms]
  )
  const data = useMemo(()=>{
    return props.data ? props.data : [{}]
}, [props.data]);
const initialState = { hiddenColumns: [props.openSms?null:'sms',...props.kallah?excludedColumnsKallah:['reservationDayOfWeek']] };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState,
  },useSortBy);

  return (
    <table className="res-table table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (<th className={(column.isSorted?"sort":"")+(column.canSort?" canSort":"")} {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {/* {column.canSort?
                    column.isSorted
                      ? column.isSortedDesc
                      ? <ReactSVG src={iconSortDown} className="icon-sort" />
                      : <ReactSVG src={iconSortUp} className="icon-sort" />
                    : <ReactSVG src={iconSort} className="icon-sort" />
                  :' '} */}
                </span>
              </th>
            )})}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {data.length?
        rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="clickable"  onClick={(e) => props.viewReservation(e,row.original.reservationId,row.original.userId)}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()} className={cell.column.Header=="SMS"?"smsCheckbox":""}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })
        :<tr>
            <td colSpan="10" className="text-center">{t("No reservations found.")}</td>
          </tr>
        }
      </tbody>
    </table>
  );
}