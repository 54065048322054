import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import iconArrow from "../../../assets/images/icon-arrow-back.svg";

function BodyHeader(props) {
  const { t } = props;
  return (
    <div className="modal-header dir-ltr">
      {props.goBack
      ? <span><ReactSVG onClick={() => props.goBack()} src={iconArrow} alt="Go Back" /></span>
      : <svg></svg>}
      <div className="header-title">
        <h4>
          {props.isEditingReservation?
            t('Edit Reservation')
          :
            props.isKallahReservation?t('Kallah Reservation'):
            props.isAdvancedReservation?t('New Reservation'):t('Check In')
          }

        </h4>
        <p>{t(props.subtitle)}</p>
      </div>
    </div>
  )
}

export default withTranslation()(BodyHeader);