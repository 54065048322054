import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import LoaderSpinner from './../assets/images/icon-loader-spinner.svg';

class Flipper extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={'flipper ' + (this.props.type ? this.props.type : '')}>
        {this.props.loading?
          <div className="text"><ReactSVG className="loader-spinner dark" src={LoaderSpinner} /></div>
        :
          <div className={'text ' + (this.props.active ? "active" : '') + (this.props.dropdown ? " dropdown-toggle" : '')}>{t(this.props.text)}</div>
        }
      </div>
    );
  }
}

export default withTranslation()(Flipper);