import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getTimes } from '../models/times';
import { Alert } from "reactstrap";

class ZmanTevilah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      time: null
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.refresh(),
      360000
    );
    this.retrieveZmanTevilah();
  }

  componentDidUpdate(prevProps){
    if(prevProps.date !== this.props.date || prevProps.zmanim !== this.props.zmanim){
      this.retrieveZmanTevilah();
    }
  }

  retrieveZmanTevilah() {
    const { zmanim } = this.props;
    if(zmanim){
      this.setState({
        isLoaded: true,
        time: zmanim.zmanTevillah,
        time2: zmanim.zmanTevillah2,
        time72: zmanim.zmanTevillah72,
      })
    } else{
      if(!this.props.date){
        let localStorageZman = localStorage.getItem('zmanTevilah');
        let localStorageZman2 = localStorage.getItem('zman2');
        let localStorageZman72 = localStorage.getItem('zman72');
        let today = moment().startOf('day');
      
        if ( localStorageZman && localStorageZman72 && localStorageZman2 && moment(localStorageZman).startOf('day').isSame(today) && moment(localStorageZman72).startOf('day').isSame(today) && moment(localStorageZman2).startOf('day').isSame(today) ) {
          this.setState({
            isLoaded: true,
            time: localStorageZman,
            time2: localStorageZman2,
            time72: localStorageZman72,
          })
          return false;
        }
      } else{
        this.setState({isLoaded: false})
      }
      getTimes(this.props.date||null)
        .then(
          result => {
            if(!this.props.date){
              localStorage.setItem('zmanTevilah', result.zmanTevillah);
              localStorage.setItem('zman2', result.zmanTevillah2);
              localStorage.setItem('zman72', result.zmanTevillah72);
            }
            this.setState({
              isLoaded: true,
              time: result.zmanTevillah,
              time2: result.zmanTevillah2,
              time72: result.zmanTevillah72
            })
          }, error => {
            this.setState({error});
          }
        );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  refresh() {
    this.retrieveZmanTevilah();
  }

  render() {
    const { t, className } = this.props;
    const { isLoaded, time, time2, time72, error } = this.state;
    return isLoaded ? (
      <>
      <div className={"zman-tevilah " + (className?className:"")}>
        <div className="title">{t('Zman')}:</div>
        <div className="zmanTime">
          {time2||time72?<div className="zmanTitle">{t('standardZman')}</div>:null}
          <Moment format={t("h:mm")} element="span">{time}</Moment>
        </div>
      {time2?
        <div className="zmanTime">
          <div className="zmanTitle">{t('belzZman')}</div>
          <Moment format={t("h:mm")} element="span">{time2}</Moment>
        </div>
      :null}
      {time72?
        <div className="zmanTime">
          <div className="zmanTitle">{t('rtZman')}</div>
          <Moment format={t("h:mm")} element="span">{time72}</Moment>
        </div>
      :null}
      </div>
      </>
    ) : 
    error?
      <Alert color="danger">{t(error)}</Alert>
    :null;
  }
}

export default withTranslation()(ZmanTevilah);