import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import Flipper from './../components/flipper';
import Button from './../components/button';
import { updateRoomStatus, unlockRoom } from './../models/room';
import { getReservation } from './../models/reservation';
import { statuses } from './../global-props';
import i18n from './../i18n';
import { SettingsContext } from './../components/settings';

class RoomAdmin extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      hiddenPressTimeout: 1000,
      adminCorrectCode: context.settings ? context.settings.roomAdminPasscode.value : '',
      adminCode: '',
      isAuthenticated: false,
      isUpdatingStatus: false,
      isOpenAdvanced: false,
      isOpenChangeStatus: false,
      waitingReservation: null,
      roomStatuses: [
        'Offline',
        'Ready',
        'NeedsCleaning',
        'Cleaning',
      ],
      reservationStatuses: [
        'Prep',
        'ReadyForMikvah',
        'ReadyForChecking',
        'Checking',
        'InMikvah',
        'Dressing',
        'Done',
      ],
      reservationChangeStatuses: [
        { status: 'Waiting', title:'Next Available Room'},
        { status: 'Canceled', title:'Cancel Reservation'},
        { status: 'Done', title:'Complete Reservation'}
      ]
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isActivated&&this.state.isActivated) {
      this.setState({
        isAuthenticated: false,
        isOpenAdvanced: false,
        adminCode: '',
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.touchTimeout);
  }

  handleTouchStart = () => {
    this.touchTimeout = setTimeout(()=>this.setState({isActivated: true}), this.state.hiddenPressTimeout);    
  }

  handleTouchEnd = () => {
    clearTimeout(this.touchTimeout);
  }

  toggle = () => {
    this.setState({
      isActivated: !this.state.isActivated,
    })
  }

  toggleAdvanced = () => {
    this.setState({
      isOpenAdvanced: !this.state.isOpenAdvanced,
    })
  }

  handlePress = (key) => {
    let input = this.state.adminCode;
    if ( key === 'del' && input.length!==0 ) { input = input.slice(0, -1); }
    if ( typeof key === 'number' && (input.length<4) ) { input = input + key; }
    this.setState({
      adminCode: input,
    })
    if ( /*key === 'enter' &&*/ input.length>=4 ) {
      if ( input===this.state.adminCorrectCode ) {
        setTimeout(()=>{
          this.setState({isAuthenticated: true});
        },100);
      } else {
        this.setState({adminCode: ''});
      }
    }
  }
  
  handleChangeStatus(status, reservationStatus) {
    let isAllowableReservationStatus = this.state.reservationChangeStatuses.filter(status=>status.status===reservationStatus).length;
    if (reservationStatus!==null && this.state.roomStatuses.includes(status) && !isAllowableReservationStatus) {
      return this.setState({modalStatus: true, tempStatus: status})
    }

    const roomId = this.props.room.id;
    const reservationId = this.props.room.reservation ? this.props.room.reservation.reservationId : null;
    this.setState({
      isUpdatingStatus: true,
      modalStatus: false,
    });
    updateRoomStatus(roomId, status, reservationStatus)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
          if (reservationStatus==='Waiting') {
            getReservation(reservationId)
              .then(result=>this.setState({
                waitingReservation: result,
                modalStatus: true,
              }));
          }
        },
        error => {
          this.setState({
            error
          });
        }
      ).finally(result => this.setState({isUpdatingStatus:false, isActivated: false}));
  }
  
  modalStatusToggle = () => {
    this.setState({
      modalStatus: !this.state.modalStatus,
      waitingReservation: null,
    });
  }

  toggleChangeStatus = () => {
    this.setState({isOpenChangeStatus: !this.state.isOpenChangeStatus});
  }

  handleClearRoom = () => {
    localStorage.removeItem('selectedRoom');
  }

  handleUnlockWetDoor = () => {
    this.setState({isUnlockingDoor: true});
    unlockRoom(this.props.room.id, 'wet')
      .then(result => {
        this.setState({isUnlockingDoor: false, showDoorUnlocked: true});
        setTimeout(() => {
          this.setState({showDoorUnlocked: false});
        }, 3000);
      }, error => {
        this.setState({error: error, isUnlockingDoor: false})
      }).finally(result => this.setState({isActivated: false}));
  }

  render() {
    const { isActivated, isAuthenticated, adminCode, isUpdatingStatus, roomStatuses, reservationStatuses, reservationChangeStatuses, waitingReservation, isUnlockingDoor, showDoorUnlocked, isOpenAdvanced, isOpenChangeStatus } = this.state;
    const { t, room } = this.props;
    return <div className="room-admin" onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}>
      <Modal className={"modal-room-admin lang-" + i18n.language} isOpen={isActivated} centered toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{t("Admin Menu")}</ModalHeader>
        <ModalBody>
          <div>
            {isAuthenticated?(
              <div className="text-center">
                <ul className="list-unstyled pis-0">
                  <li>
                    <Dropdown className="dropdown-room-status d-inline-block" isOpen={isOpenChangeStatus} toggle={this.toggleChangeStatus}>
                      <DropdownToggle tag="span"><Button onClick={this.toggleChangeStatus} type="secondary btn-lg mb-3" loading={isUpdatingStatus}>{t("Change Status")}</Button></DropdownToggle>
                      <DropdownMenu>
                        {room.reservation!==null?
                          <>
                            {reservationStatuses.map((s,i) => {
                              let status = statuses[s];
                              // First, hide current status from list.
                              if (status.name!==room.statusName) {
                                return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s,  room.reservation.reservationStatus)}>
                                  {status.longName?t(status.longName):t(status.name)}
                                </DropdownItem>
                              } else {
                                return null;
                              }
                            })}
                            <DropdownItem divider />
                          </>
                        :null}
                        {roomStatuses.map((s,i) => {
                          let status = statuses[s];
                          let roomReservationStatus = room.reservation?room.reservation.reservationStatus:null;
                          // First, hide current status from list.
                          if (status.name!==room.effectiveStatus) {
                            return <DropdownItem key={i} onClick={() => this.handleChangeStatus(s, roomReservationStatus)}>
                              {status.changeRequirements!=="no-reservation"?
                                <div>{t(status.name)}</div>
                              :t(status.name)}
                            </DropdownItem>
                          } else {
                            return null;
                          }
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                  <li>
                    <Button type="secondary btn-lg mb-3" loading={isUnlockingDoor} disable={showDoorUnlocked} onClick={this.handleUnlockWetDoor}>{showDoorUnlocked?t('Unlocked'):t('Open Wet Door')}</Button>
                  </li>
                  {!isOpenAdvanced?
                    <li>
                      <Button onClick={this.toggleAdvanced}>{t("Show Advanced")}</Button>
                    </li>
                  :null}
                </ul>
                {isOpenAdvanced?
                  <ul className="list-unstyled pis-0">
                    <li>
                      <Button type="secondary btn-lg mb-3" onClick={()=>window.location.reload(true)}>{t("Refresh Screen")}</Button>
                    </li>                  
                    <li>
                      <Link to="/room" onClick={this.handleClearRoom}><Button type="secondary btn-lg mb-3">{t("Clear Room Number")}</Button></Link>
                    </li>                  
                  </ul>
                :null}
              </div>
            ):(
              <>
                <div className="wrapper-number">
                  <div className={"flippers justify-content-center mb-4"}>
                    {[0,0,0,0].map((v,i) => {
                      return <Flipper type="single" key={i} text={adminCode[i]?'•':''} />
                    })}
                  </div>
                </div>
                <NumericKeyboard layout="tel" onPress={this.handlePress} />
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={this.state.modalStatus} toggle={this.modalStatusToggle} centered className={"lang-" + i18n.language} modalClassName="modal-status">
        <ModalHeader toggle={this.modalStatusToggle}>{t("Status Change")}</ModalHeader>
        <ModalBody>
          {waitingReservation?
            <>
              {waitingReservation.reservationStatus==='Assigned'?
                <>
                  <ul>
                    <li>{t("Access Number")}: {waitingReservation.reservationCode}</li> 
                    <li>{t("Assigned Room")}: {waitingReservation.room.id}</li> 
                    <li>{t("Attendant")}: {waitingReservation.room.section.attendant.firstName} {waitingReservation.room.section.attendant.lastName}</li> 
                    <li>{t("Mikvah")}: {waitingReservation.room.section.name}</li> 
                  </ul>
                </>
              :null}
              {waitingReservation.reservationStatus==='Waiting'?
                <>
                  <ul>
                    <li>{t("Access Number")}: {waitingReservation.reservationCode}</li>
                    {/* <li>Waiting Code: {waitingReservation.waitingCode}</li> */}
                  </ul>
                </>
              :null}
            </>
          :
            <>
              <h4>{t("What should we do with the current reservation?")}</h4>
              <div className="text-center">
                {reservationChangeStatuses.map((ss,si) => {
                  return <Button type="secondary btn-lg mb-3" key={si} onClick={() => this.handleChangeStatus(this.state.tempStatus,ss.status)}>{t(ss.title)}</Button>
                })}
              </div>
            </>
          }
        </ModalBody>
      </Modal>
    </div>;
  }
}

export default withTranslation()(RoomAdmin);