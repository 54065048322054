import React from 'react';
import { withTranslation } from 'react-i18next';
import Logo from '../assets/images/MikvahLogoWhite.png';
import { logoImageAltText } from '../global-props';
import ReactSVG from 'react-svg';
import moment from 'moment';

class ScreenSaver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format(),
      show: false,
    };
    
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.setUpTick();
    this.hide();
    document.addEventListener('touchstart', this.hide);
    document.addEventListener('mousemove', this.hide);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearTimeout(this.timeOut);
    document.removeEventListener('touchstart', this.hide);
    document.removeEventListener('mousemove', this.hide);
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  hide() {
    this.setState({
      show: false
    })
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => this.setState({show: true}), 10000);
  }

  render() {
    const { show } = this.state;
    const { t, text } = this.props;
    return <div className={"screen-saver" + (show?" show":"")}>
      <div className="x">
        <div className="y">
          <img src={Logo} className="logo" alt={logoImageAltText}/>
          {text?<div className="text">{t(text)}</div>:null}
        </div>
      </div>
    </div>
  }
}

export default withTranslation()(ScreenSaver);