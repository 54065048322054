import React from "react";
import { withTranslation } from "react-i18next";
import { getLanguage } from "../../models/setting";
import { languages } from "../../global-props";
import { setLanguageText, setLanguageDescription } from "../../models/setting";

class EditTranslations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      l1: languages[0],
      l2: languages[1],
    };
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(column, newLanguage) {
    newLanguage = languages.find((lang) => lang.name === newLanguage);
    this.setState({
      [column]: newLanguage,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="container-page view-admin view-admin-schedule">
        <div className="container-xl">
          <table id="translations-table" className="w-100 table table-striped">
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">
                  <Select
                    value={this.state.l1}
                    column="l1"
                    onChange={this.changeLanguage}
                  />
                </th>
                <th scope="col">
                  <Select
                    value={this.state.l2}
                    column="l2"
                    onChange={this.changeLanguage}
                  />
                </th>
                <th scope="col">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRows l1={this.state.l1.name} l2={this.state.l2.name} />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.selectNewLanguage = this.selectNewLanguage.bind(this);
  }

  selectNewLanguage(e) {
    let column = this.props.column;
    let newLanguage = e.target.value;
    this.props.onChange(column, newLanguage);
  }

  render() {
    const value = this.props.value.name;

    return (
      <select
        className="custom-select"
        value={value}
        onChange={this.selectNewLanguage}
      >
        {languages.map((lang) => (
          <option value={lang.name}>{lang.title}</option>
        ))}
      </select>
    );
  }
}

class TableRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      backgroundColor: "",
      filtered: [],
      filter: false
    };

    this.gatherData = this.gatherData.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  getTranslations() {
    getLanguage()
      .then((result) => {
        let filtered = Object.keys(result).filter(key => !result[key]['values']['he']);
        this.setState({
          data: result,
          filtered
        });
      })
      .catch((e) => {
        console.log(
          "The translations were not retrieved successfully. " + e.errorMessage
        );
      });
  }

  componentDidMount() {
    this.getTranslations();
  }

  gatherData(e, key, lang) {
    const text = e.target.value;
    if(lang!=="desc"){
      const data = {
        key: key,
        lang: this.props[lang],
        text: text,
      };
      this.updateValue(data);
    } else{
      const data = {
        key: key,
        text: text,
      };
      this.updateValue(data, true);
    }

  }

  updateValue(data, desc=false) {
    if (data !== false) {
      let queryString;
      if(desc){
        setLanguageDescription(encodeURIComponent(data.key),encodeURIComponent(data.text))
          .then((result) => {
            let filtered = Object.keys(result).filter(key => !result[key]['values']['he']);
            this.setState({
              data: result,
              filtered
            });
                this.updateColor(data.key);
          })
          .catch((e) => {
            console.log("An error has occured: " + e.errorMessage);
          });
      } else {
        queryString = `key=${encodeURIComponent(data.key)}&lang=${data.lang}&text=${encodeURIComponent(data.text)}`;
        setLanguageText(queryString)
          .then((result) => {
            let filtered = Object.keys(result).filter(key => !result[key]['values']['he']);
            this.setState({
              data: result,
              filtered
            });
                this.updateColor(data.key);
          })
          .catch((e) => {
            console.log("An error has occured: " + e.errorMessage);
          });
      }
    }
  }

  updateColor(key) {
    let row = document.getElementById(key);
    row.style.backgroundColor = "rgba(0, 128, 0, 0.25)";
    setTimeout(() => (row.style.backgroundColor = ""), 3000);
  }

  render() {
    const l1 = this.props.l1;
    const l2 = this.props.l2;
    const data = this.state.data;
    let rows;

    {this.state.filter?
    rows = this.state.filtered.map((key) => (
      <tr key={key} id={key}>
        <th scope="row">{key}</th>
        <td>
          <TranslationInput
            value={data[key]["values"][l1] ? data[key]["values"][l1] : ""}
            onBlur={(e) => this.gatherData(e, key, "l1")}
            language={l1}
          />
        </td>
        <td>
          <TranslationInput
            value={data[key]["values"][l2] ? data[key]["values"][l2] : ""}
            onBlur={(e) => this.gatherData(e, key, "l2")}
            language={l2}
          />
        </td>
        <td>
          <TranslationInput
            value={data[key]["description"] ? data[key]["description"] : ""}
            onBlur={(e) =>this.gatherData(e, key, "desc")}
          />
        </td>
      </tr>
    ))
    :
      rows = Object.keys(data).map((key) => (
        <tr key={key} id={key}>
        <th scope="row">{key}</th>
        <td>
          <TranslationInput
            value={data[key]["values"][l1] ? data[key]["values"][l1] : ""}
            onBlur={(e) => this.gatherData(e, key, "l1")}
            language={l1}
          />
        </td>
        <td>
          <TranslationInput
            value={data[key]["values"][l2] ? data[key]["values"][l2] : ""}
            onBlur={(e) => this.gatherData(e, key, "l2")}
            language={l2}
          />
        </td>
        <td>
          <TranslationInput
            value={data[key]["description"] ? data[key]["description"] : ""}
            onBlur={(e) =>this.gatherData(e, key, "desc")}
          />
        </td>
      </tr>
      )
    );
    }

    return (
      <>
        {this.state.filter?
        <>
          <span className="mb-2 mie-4 btn btn-secondary" onClick={() => this.setState({filter: !this.state.filter})}>No Filter</span>
          <span>{this.state.filtered.length + ' strings need translation'}</span>
        </>
        :
        <>
          <span className="mb-2 mie-4 btn btn-secondary" onClick={() => this.setState({filter: !this.state.filter})}>Needs Translation</span>
          <span>{Object.keys(this.state.data).length + ' total strings'}</span>
        </>
        }

        <AddRow
          l1={this.props.l1}
          l2={this.props.l2}
          addRowAction={this.updateValue}
        />
        {rows}
      </>
    );
  }
}

class AddRow extends React.Component {
  constructor(props) {
    super(props);
    this.provideKeyInput = this.provideKeyInput.bind(this);
    this.gatherData = this.gatherData.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.state = {
      isAdding: false,
      backgroundColor: "",
    };
  }

  provideKeyInput() {
    this.setState({
      isAdding: true,
    });
  }

  gatherData() {
    let keyInput = document.getElementById("addRowKeyName");
    let value1Input = document.getElementById("addRowValue1");
    let value2Input  = document.getElementById("addRowValue2");
    let valueDesc  = document.getElementById("addRowDescription");

    let keyName = keyInput.value || false;
    let value1 = value1Input.value || false;
    let value2 = value2Input.value || false;
    let value3 = valueDesc.value || false;
    if (keyName) {
      if (value1 || value2 || value3) {
        if (value1) {
          this.props.addRowAction({
            key: keyName,
            lang: this.props.l1,
            text: value1,
          });
          value1Input.value = '';
        }
        if (value2) {
          this.props.addRowAction({
            key: keyName,
            lang: this.props.l2,
            text: value2,
          });
        }
        value2Input.value = '';
        if (value3) {
          this.props.addRowAction({
            key: keyName,
            text: value3,
          }, true);
        }
        valueDesc.value = '';
      }
      keyInput.value = '';
      this.updateColor();
    }
  }

  updateColor() {
    let row = document.getElementById("add-row");
    row.style.backgroundColor = "rgba(0, 128, 0, 0.25)";
    setTimeout(() => (row.style.backgroundColor = ""), 3000);
  }

  render() {
    if (this.state.isAdding) {
      return (
        <tr id="add-row">
          <th scope="row">
            <div className="w-100 d-flex justify-content-space-around">
              <input
                type="submit"
                className="btn btn-primary float-start"
                value="Submit"
                onClick={this.gatherData}
              />
              <textarea
                id="addRowKeyName"
                className="form-control"
                type="text"
                placeholder="Type key here"
              />
            </div>
          </th>
          <td>
            <textarea
              id="addRowValue1"
              className="form-control"
              type="text"
            />
          </td>
          <td>
            <textarea
              id="addRowValue2"
              className="form-control"
              type="text"
            />
          </td>
          <td>
            <textarea
              id="addRowDescription"
              className="form-control"
              type="text"
            />
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <th scope="row">
            <input
              type="button"
              className="btn btn-primary"
              id="add-row"
              value="Add Row"
              onClick={this.provideKeyInput}
            />
          </th>
          <td />
          <td />
          <td />
        </tr>
      );
    }
  }
}

class TranslationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      backgroundColor: "",
    };
    this.onChange = this.onChange.bind(this);
    this.respondToTyping = this.respondToTyping.bind(this);
    this.checkValue = this.checkValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChange(e) {
    const value = e.target.value;
    this.setState({
      value: value,
    });
  }

  respondToTyping(e) {
    switch (e.key) {
      case "Tab":
      case "Shift":
        return false;
        break;
      default:
        this.setState({
          backgroundColor: "bg-light",
        });
        setTimeout(() => {
          this.setState({
            backgroundColor: "",
          });
        }, 3000);
    }
  }

  checkValue(e) {
    if (e.target.value !== this.props.value) {
      this.props.onBlur(e);
    }
  }

  render() {
    return (
      <textarea
        id={this.props.id}
        rows={Math.round(this.state.value.length / 20)}
        className={"form-control " + this.state.backgroundColor + " lang-" + this.props.language}
        value={this.state.value}
        // data-column={this.props.column}
        // key={this.props.key}
        onChange={this.onChange}
        onKeyDown={this.respondToTyping}
        onBlur={this.checkValue}
      />
    );
  }
}

export default withTranslation()(EditTranslations);
