import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { languages } from './../global-props';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './../components/button';
import moment from 'moment';

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: 'he',
      isOpen: false,
    }

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    let selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      this.setState({selectedLanguage});
      moment.locale(selectedLanguage);
      i18n.changeLanguage(selectedLanguage);  
    } else {
      this.setState({selectedLanguage: i18n.language});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps!==this.props) {
      let selectedLanguage = localStorage.getItem('selectedLanguage');
      if (selectedLanguage) {
        this.setState({selectedLanguage});
      } else{
        this.setState({selectedLanguage: i18n.language});
      }
    }
  }

  handleChangeLanguage(lang) {
    moment.locale(lang);
    i18n.changeLanguage(lang);
    this.setState({selectedLanguage: lang});
    localStorage.setItem('selectedLanguage', lang);
    if (this.props.handleLanguageUpdated) {
      this.props.handleLanguageUpdated(lang);
    }
  }

  toggle() {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const { t } = this.props;
    const { selectedLanguage, isOpen } = this.state;
    return (
      <Dropdown className="dropdown-language" isOpen={isOpen} toggle={this.toggle}>
        <DropdownToggle tag="span"><Button onClick={this.toggle} className="btn btn-sm btn-outline-primary"><div className="dropdown-toggle"><FontAwesomeIcon icon="globe-americas" className="mie-2" /> {t(languages.find(lang=>lang.name===selectedLanguage).title)}</div></Button></DropdownToggle>
        <DropdownMenu right>
          {languages.map((language,i)=><DropdownItem key={i} active={selectedLanguage===language.name} onClick={()=>this.handleChangeLanguage(language.name)}>{language.title}</DropdownItem>)}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withTranslation()(LanguageSelector);