import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faShower,
  faBath,
  faWheelchair,
  faPlayCircle,
  faPauseCircle,
  faGem,
  faCalendarDay,
  faClock,
  faWalking,
  faParking,
  faWifi,
  faCopy,
  faHandPaper,
  faClipboardList,
  faCheck,
  faQuestion,
  faCog,
  faHourglassEnd,
  faFileInvoiceDollar,
  faDoorOpen,
  faSitemap,
  faList,
  faConciergeBell,
  faCalendarPlus,
  faSolarPanel,
  faCalendarWeek,
  faFemale,
  faUserLock,
  faSlidersH,
  faTools,
  faChevronLeft,
  faChevronRight,
  faHome,
  faSignOutAlt,
  faBars,
  faCar,
  faTrash,
  faMusic,
  faVolumeUp,
  faInfoCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faReceipt,
  faExclamationTriangle,
  faMoneyBillWave,
  faTabletAlt,
  faCalendarAlt,
  faRedoAlt,
  faUser,
  faKey,
  faPlus,
  faMinus,
  faWarehouse,
  faGlobeAmericas,
  faClipboardCheck,
  faPhone,
  faPhoneAlt,
  faEllipsisV,
  faPaintBrush,
  faAsterisk,
  faExchangeAlt,
  faLocationArrow,
  faTable,
  faToolbox,
  faLockOpen,
  faLock,
  faStar,
  faUserAltSlash,
  faDesktop,
  faTablet,
  faWater,
  faPlusSquare,
  faMapPin,
  faEnvelope,
  faIdCardAlt,
  faMapMarkerAlt,
  faAward
} from '@fortawesome/free-solid-svg-icons';
import {
  faStickyNote,
  faEdit,
  faTrashAlt,
  faQuestionCircle,
  faGem as faGemFar,
  faClock as faClockFar,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';


library.add(
  faShower,
  faBath,
  faWheelchair,
  faPlayCircle,
  faPauseCircle,
  faGem,
  faCalendarDay,
  faClock,
  faWalking,
  faParking,
  faWifi,
  faCopy,
  faHandPaper,
  faClipboardList,
  faCheck,
  faQuestion,
  faCog,
  faHourglassEnd,
  faFileInvoiceDollar,
  faDoorOpen,
  faSitemap,
  faList,
  faConciergeBell,
  faCalendarPlus,
  faSolarPanel,
  faCalendarWeek,
  faFemale,
  faUserLock,
  faSlidersH,
  faTools,
  faChevronLeft,
  faChevronRight,
  faHome,
  faSignOutAlt,
  faBars,
  faCar,
  faTrash,
  faMusic,
  faVolumeUp,
  faInfoCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faReceipt,
  faExclamationTriangle,
  faMoneyBillWave,
  faTabletAlt,
  faCalendarAlt,
  faRedoAlt,
  faUser,
  faKey,
  faPlus,
  faMinus,
  faWarehouse,
  faGlobeAmericas,
  faClipboardCheck,
  faPhone,
  faPhoneAlt,
  faEllipsisV,
  faPaintBrush,
  faAsterisk,
  faExchangeAlt,
  faLocationArrow,
  faTable,
  faToolbox,
  faLockOpen,
  faLock,
  faStar,
  faUserAltSlash,
  faDesktop,
  faTablet,
  faWater,
  faPlusSquare,
  faMapPin,
  faEnvelope,
  faIdCardAlt,
  faMapMarkerAlt,
  faAward,

  faStickyNote,
  faEdit,
  faTrashAlt,
  faQuestionCircle,
  faGemFar,
  faClockFar,
  
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
);
