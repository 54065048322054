import React, { Component } from 'react';

import { getReservations } from './../../models/reservation';

import { withTranslation } from 'react-i18next';
import Weather from './../../components/snippets/weather';
import Clock from './../../components/clock';
import ZmanTevilah from '../../components/zman-tevilah';
import Flipper from '../../components/flipper';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import SignalR from './../../components/signalr';
import ScreenSaver from '../../components/screensaver';
import { Alert } from 'reactstrap';

class WaitingRoomView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isLoadedReservations: false,
      reservations: [],
    };
    this.notificationSound = new Audio(NotificationSoundFile);
  }

  componentDidMount() {
    this.retrieveReservations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reservations.length > 0 && prevState.reservations && prevState.reservations.length > 0) {
      let shouldPlaySound = false;
      this.state.reservations.map((res, i) => {
        let previousRes = prevState.reservations.find(prevres=>prevres.reservationCode===res.reservationCode);
        if(res.room != null && (previousRes&&previousRes.room === null)){
          shouldPlaySound = true;
          console.log(`New Room for Code ${res.reservationCode}`);
        }
        return false;
      });
      if (shouldPlaySound) { this.notificationSound.play(); }
    }
  }

  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result.waiting.filter(res => res.waitingCode||res.reservationCode);
          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            error
          });
        }
      );
  }




  render() {
    const { t, i18n } = this.props;
    const { isLoadedReservations, reservations, error } = this.state;
    return (
      <div className={'container-page view-waiting-room lang-' + i18n.language}>
        <SignalR setReception={true} onReservationsUpdated={() => this.retrieveReservations()} />
        <div>
          <header className="header">
            <div>
              <div className="header-left">
              </div>
              <div className="header-center">
                <Clock />
              </div>
              <div className="header-right">
                <Weather />
              </div>
            </div>
            <div>
              <div className="header-left">
              </div>
              <div className="header-center">
                <Clock type="fullDate" />
              </div>
              <div className="header-right">
                <ZmanTevilah />
              </div>
            </div>
          </header>
          <div className="container-body">
            {error?
              <Alert color="danger">{error} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>            
            :null}
            {isLoadedReservations ? 
              reservations.length?
                reservations.map((res,i) => {
                  const digits = (''+res.reservationCode).split('');
                  return <div className="room-reservation dir-ltr" key={i}>
                    <div className="reservation-code">
                      {i<3?<div className="title">{t("Code")}</div>:null}
                      <div className={"flippers flippers-lg" + (res.room!=null ? ' active' : '')}>
                        {digits.map((digit, i) => {
                          return <Flipper type="single" key={i} text={digit} active={res.room!=null ? true : false}/>
                        })}
                      </div>
                    </div>
                    <div className="room-number">
                      {i<3?<div className="title">{t("Room")}</div>:null}
                      <div className={'flippers flippers-lg room-flipper' + (res.room!=null ? ' active' : '')}>
                        <Flipper type="double" key={i} text={res.room!=null ? res.room.id : null} active={res.room!=null ? true : false}/>
                      </div>
                    </div>
                  </div>
                })
              : <ScreenSaver />
            : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(WaitingRoomView);
