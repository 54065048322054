import { login as Login, register as Register, registerVerify as RegisterVerify, setUserPassword as SetUserPassword } from './../api';
import { api_url } from './../global-props';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';
//import { appAuth } from './../router';

let tokensStorage = JSON.parse(localStorage.getItem('token_response'));
window.tokens = {
  refreshToken: tokensStorage ? tokensStorage.refresh_token : null,
  accessToken: tokensStorage ? tokensStorage.access_token : null,
  userId: tokensStorage ? tokensStorage.userId : null,
  roles: tokensStorage ? tokensStorage.roles : null,
  username: tokensStorage ? tokensStorage.userName : null,
}

export const config = {
  onClearAuthorization: null,
  onLogout: null
}

export function getAuthorization() {
  return window.tokens;
}

function handleNewToken(response) {
  let refreshToken = response.refresh_token;
  let accessToken = response.access_token;
  let userId = response.userId;
  let roles = response.roles ? response.roles : null;
  let username = response.userName;
  window.tokens = { ...window.tokens, refreshToken, accessToken, userId, roles, username };
  localStorage.setItem('token_response', JSON.stringify(response));
  return response;
}

function clearAuthorization() {
  let refreshToken = null;
  let accessToken = null;
  let userId = null;
  let roles = null;
  let username = null;
  window.tokens = { ...window.tokens, refreshToken, accessToken, userId, roles, username };
  //appAuth.signout();
  localStorage.clear();
  if ( config.onClearAuthorization ) {
    config.onClearAuthorization();
  }
}

export function renewAccessToken() {
  let refreshToken = window.tokens.refreshToken;
  let userId = window.tokens.userId;
  let url = api_url + '../token';
  let args = {refresh_token: refreshToken, grant_type: 'refresh_token', user_id: userId };
  let body = Object.keys(args).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(args[key])).join('&');
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'}
  const method = 'POST';
  if (refreshToken===null) {
    return clearAuthorization();
  }
  return fetchJSON(url, { method: method, body: body, headers: headers })
    .then(({ response, body }) => { handleNewToken(body); return response; })
    .catch(error => {
      if (error.status===400 && error.body.error === 'invalid_grant' ) {
        return logout();
      } else {
        throw Error('An error occurred.')
      }
    });
}

const fetchJSONWithToken = (url, options = {}) => {
  const token = window.tokens.accessToken;
  let optionsWithToken = options
  if (token != null) {
    optionsWithToken = {...options,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
  return fetchJSON(url, optionsWithToken)
}

const shouldRefreshToken = error => error.response.status === 401 && error.body.Message === 'Authorization has been denied for this request.';

const fetchWrapper = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken: renewAccessToken
})

export function doRequest(url, args) {
  return fetchWrapper(url, args)
    .then(({ response, body }) => {
      if ( response.status >= 200 && response.status < 300 ) {
        if (body === '') {  return Promise.reject('An error occurred.'); }
        return body;
      }
      return Promise.reject('An error occurred. [' + response.status + ']');
    } )
    .catch(error => {
      if (typeof error === 'string') { return Promise.reject(error); }
      return Promise.reject(error.body && error.body.Message ? error.body.Message : 'An error occurred.');
    });
}





function processLogin(result) {
  handleNewToken(result);
}

function processLogout(result) {
  clearAuthorization();
  if ( config.onLogout ) {
    config.onLogout();
  }
}

export function register({username=null, phone=null, email=null, password=null, language=null}) {
  return new Promise((resolve, reject) => {
    Register({ username: username, phone: phone, email: email, password: password, language: language })
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}

export function registerVerify({phone=null, authCode=null}) {
  return new Promise((resolve, reject) => {
    RegisterVerify({ phone: phone, authCode: authCode })
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    Login({ username: username, password: password, grant_type: 'password' })
      .then(
        result => {
          if ( result.error ) {
            return reject(result.error_description);
          }
          processLogin(result);
          return resolve(result);
        },
        error => reject('Error')
    )
  })
}

export function logout() {
  return new Promise((resolve, reject) => {
    return resolve(processLogout());
  })
}

export function setUserPassword(userId, password, confirmPassword) {
  return new Promise((resolve, reject) => {
    SetUserPassword(userId, password, confirmPassword)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}
