import React from 'react';

import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statuses } from './../../../global-props';
import { UncontrolledTooltip } from 'reactstrap';
import i18n from './../../../i18n';


class ReservationTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    console.log({...this.props.reservationDetails})
    this.setupTimeline();
    this.setUpTick();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reservationDetails!==this.props.reservationDetails) {
      this.setupTimeline();
    }
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.setupTimeline(),
      60 * 1000
    );
  }

  setupTimeline = () => {
    let res = this.props.reservation;
    let statusHistory = this.props.reservationDetails.statusHistory;
    if (res.timeArrived===null || !statusHistory.length>0) return;
    let timelineEndTime = moment(res.timeArrived).add(3, 'hour').isAfter(moment()) ? moment(res.timeArrived).add(3, 'hour').format() : moment(res.timeArrived).endOf('day').format();
    let timeArrivedTimestamp = this.getTimestamp(res.timeArrived);
    let timeCompletedTimestamp = this.getTimestamp(res.timeCompleted ? res.timeCompleted : timelineEndTime);
    timeCompletedTimestamp = res.reservationStatus==='Canceled' ? this.getTimestamp(statusHistory[statusHistory.length-1].dateCreated) : timeCompletedTimestamp;
    let timeline = {};
    timeline.start = timeArrivedTimestamp;
    timeline.end = timeCompletedTimestamp;
    timeline.total = timeCompletedTimestamp - timeArrivedTimestamp;
    timeline.startHour = moment(timeArrivedTimestamp, 'x').startOf('hour').hour();
    timeline.endHour = moment(timeCompletedTimestamp, 'x').startOf('hour').hour();
    timeline.hours = [];
    
    for (let i=0; i<(timeline.endHour-timeline.startHour); i++) {
      let hourTime = moment(timeArrivedTimestamp, 'x').startOf('hour').add(i+1,'hours');
      let hour = {};      
      hour.timestamp = this.getTimestamp(hourTime);
      hour.percent = this.getTimestampPercent(hour.timestamp, timeline.start, timeline.end);
      hour.number = moment(hourTime).hour();
      hour.numberParsed = hour.number>12?hour.number-12:hour.number;
      timeline.hours.push(hour);
    }

    timeline.entries = statusHistory.filter(status=>!['Reserved','Pending'].includes(status.previousReservationStatusTitle)).map(status=>{
      status.color = statuses[status.previousReservationStatus]?statuses[status.previousReservationStatus].color:null;
      status.timestampStart = this.getTimestamp(status.previousStatusChange);
      status.timestampEnd = this.getTimestamp(status.dateCreated);
      status.percent = this.getDurationPercent(status.timestampStart, status.timestampEnd, timeline.start, timeline.end);
      return status;
    });

    let lastStatusHistory = {...statusHistory[statusHistory.length-1]};
    if ( lastStatusHistory.newReservationStatusTitle!=='Done' && lastStatusHistory.newReservationStatusTitle!=='Canceled' ) {
      let status = lastStatusHistory;
      status.previousStatusChange = lastStatusHistory.dateCreated;
      status.previousReservationStatus = lastStatusHistory.newReservationStatus;
      status.previousReservationStatusTitle = lastStatusHistory.newReservationStatusTitle;
      status.timestampStart = this.getTimestamp(lastStatusHistory.dateCreated);
      status.timestampEnd = moment(lastStatusHistory.dateCreated).isSame(moment(),'day')?this.getTimestamp(moment()):this.getTimestamp(moment(lastStatusHistory.dateCreated).endOf('day'));
      status.percent = this.getDurationPercent(status.timestampStart, status.timestampEnd, timeline.start, timeline.end);
      timeline.entries.push(status);
    }

    let supplies = this.props.reservationDetails.supplies.map(item=>{
      item.timestampEnd = moment(item.dateRequested).isSame(moment(),'day')?this.getTimestamp(moment()):this.getTimestamp(moment(item.dateRequested).endOf('day'));
      if ( item.status==="complete" ) {
        item.timestampEnd = this.getTimestamp(item.dateCompleted);
      } else if ( item.status==="canceled" ) {
        item.timestampEnd = this.getTimestamp(item.dateCanceled);
      }
      item.timestampStart = this.getTimestamp(item.dateRequested);
      item.type = 'supply';
      item.timestamp = this.getTimestamp(item.dateRequested);
      item.percent = this.getTimestampPercent(item.timestamp, timeline.start, timeline.end);
      item.durationPercent = this.getDurationPercent(item.timestampStart, item.timestampEnd, timeline.start, timeline.end);
      return item;
    })

    let accessLogs = this.props.reservationDetails.accessGrantedLogs.map(item=>{
      item.type = 'access';
      item.timestamp = this.getTimestamp(item.dateCreated);
      item.percent = this.getTimestampPercent(item.timestamp, timeline.start, timeline.end);
      return item;
    })
    timeline.points = [];
    timeline.points = timeline.points.concat(supplies, accessLogs);
    console.log(timeline);
    this.setState({timeline});
  }

  getTimestamp(time) {
    return parseInt(moment(time).format('x'));
  }

  getDurationPercent(timestampStart, timestampEnd, timelineStart, timelineEnd) {
    let total = timelineEnd - timelineStart;
    let current = timestampEnd - timestampStart;
    let percent = (current/total)*100;
    return percent;
  }

  getTimestampPercent(timestamp, timelineStart, timelineEnd) {
    let total = timelineEnd - timelineStart;
    let current = timestamp - timelineStart;
    let percent = (current/total)*100;
    return percent;
  }

  render() {
    const { t } = this.props;
    const { timeline } = this.state;
    return timeline && timeline.start ?
      <>
        <div className="mt-4 progress-reservation" style={{position: 'relative'}}>
          <div className="points">
            {timeline.points.map((point,i)=><div key={i} id={"point-"+point.timestamp} className="point" style={i18n.dir()=="ltr"?{left:point.percent + "%",position: 'absolute'}:{right: point.percent + "%", position: 'absolute'}}>{point.type==='supply'?<FontAwesomeIcon icon="hand-paper" />:''}{point.type==='access'?<FontAwesomeIcon icon="key" />:''}</div>)}
          </div>

          <div className="progress progress-statuses">
            {timeline.entries.map((entry,i)=><div key={i} id={"progress-bar-"+entry.timestampStart} className={"progress-bar progress-bar-" + entry.previousReservationStatus.toLowerCase()} role="progressbar" style={{width: entry.percent + "%"}} aria-valuenow={entry.percent} aria-valuemin="0" aria-valuemax="100"></div>)}
          </div>

          <div className="progress-supplies">
            {timeline.points.filter(entry=>entry.type==='supply').map((entry,i)=><div key={i} id={"progress-supplies-"+entry.timestamp} className={"progress-bar"} role="progressbar" style={i18n.dir()=="ltr"?{width: entry.durationPercent + "%", left: entry.percent + "%"}:{width: entry.durationPercent + "%", right: entry.percent + "%"}} aria-valuenow={entry.durationPercent} aria-valuemin="0" aria-valuemax="100"></div>)}
          </div>

          <div className="hours">
            <div className="hour" style={i18n.dir()=="ltr"?{left:"0"}:{right: "0"}}><Moment format={t("h:mma")}>{timeline.start}</Moment></div>
            {timeline.hours.map((hour,i)=><div key={i} className="hour" style={i18n.dir()=="ltr"?{left: hour.percent + "%"}:{right: hour.percent + "%"}}><Moment format={t("ha")}>{hour.timestamp}</Moment></div>)}
            <div className="hour" style={i18n.dir()=="ltr"?{right: "0"}:{left: "0"}}><Moment format={t("h:mma")}>{timeline.end}</Moment></div>
          </div>

          {timeline.points.map((point,i)=>
            <UncontrolledTooltip key={i} target={"point-"+point.timestamp}>
              {point.type==='access'?
                <>
                  <div className="text-capitalize">{t((point.door||"") + " Door Unlocked")}</div>
                  <div><Moment format={t("h:mma")}>{point.timestamp}</Moment></div>
                  <div>{point.firstName} {point.lastName}</div>
                </>
              :null}
              {point.type==='supply'?
                <>
                  <div>{t("Supply Requested")}</div>
                  <div><Moment format={t("h:mma")}>{point.timestamp}</Moment></div>
                </>
              :null}
            </UncontrolledTooltip>
          )}

          {timeline.points.filter(entry=>entry.type==='supply').map((point,i)=>
            <UncontrolledTooltip key={i} target={"progress-supplies-"+point.timestamp}>
              <div>{t("Supply Requested")}</div>
              <div><Moment format={t("h:mma")}>{point.timestamp}</Moment> - <Moment format={t("h:mma")}>{point.timestampEnd}</Moment></div>
              <div><FontAwesomeIcon icon="hourglass-end" className="small" /> <Moment date={point.timestampEnd} duration={point.dateRequested} /></div>
            </UncontrolledTooltip>
          )}

          {timeline.entries.map((entry,i)=>
            <UncontrolledTooltip key={i} target={"progress-bar-"+entry.timestampStart}>
              <div>{t(entry.previousReservationStatusTitle)}</div>
              <div><Moment format={t("h:mma")}>{entry.timestampStart}</Moment> - <Moment format={t("h:mma")}>{entry.timestampEnd}</Moment></div>
              <div><FontAwesomeIcon icon="hourglass-end" className="small" /> <Moment date={entry.timestampEnd} duration={entry.timestampStart} /></div>
            </UncontrolledTooltip>
          )}
        </div>
      </>
    :null;
  }
}

export default withTranslation()(ReservationTimeline);
