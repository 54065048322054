import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { hubConnection } from '@flipdish/signalr-no-jquery';
import { signalr_url } from './../global-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderSpinner from './../assets/images/icon-loader-spinner.svg';
import ReactSVG from 'react-svg';
import Settings from './settings';

class SignalR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      reconnectCount: 0,
      willAttemptToReconnectTime: null,
      updateNew: false,
    }
    this.intervalID = null;
    this.countdown = this.countdown.bind(this);
    this.handleClickTryAgain = this.handleClickTryAgain.bind(this);
  }

  randomNumber(min,max) { //copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  componentDidMount() {
    console.log('mount');
    this.setUpSignalr();
  }

  componentWillUnmount() {
    console.log('unmount');
    if ( this.state.connection ) {
      this.state.connection.stop();
    }
    clearInterval(this.intervalID);
    clearTimeout(this.isStartedTimeout);
  }

  tick() {
    this.setState({
      time: moment()
    });
  }

  attemptReconnect() {
    this.setUpSignalr();
    this.setState({
      reconnectCount: this.state.reconnectCount + 1,
      mode: "Reconnecting",
    })
  }

  startReconnect() {
    console.log('startReconnect');
    if ( this.state.reconnectCount >= 5 ) {
      this.setState({
        mode: 'Disconnected',
        willAttemptToReconnectTime: null,
      })
      setTimeout(()=>{
        this.handleClickTryAgain();
      }, 5*60*1000); //Run try again every 5 minutes.
    } else {
      var reloadDelay = this.randomNumber(10, 20);
      this.setState({
        willAttemptToReconnectTime: moment().add(reloadDelay, 'seconds')
      }, () => {
        this.countdown();
        this.intervalID = setInterval(
          this.countdown,
          1000
        )
      });
    }
  }

  handleClickTryAgain() {
    this.setState({
      reconnectCount: 0,
    }, () => this.attemptReconnect())
  }

  handlePageReload() {
    window.location.reload(true);
  }

  handleReloadSettings() {
      console.log('Reloading settings');
      //reload the settings here.
      this.setState({
       updateNew: true,
     })
     setTimeout(
       this.setState({
         updateNew: false
       })
     ,1000)
  }

  countdown() {
    this.setState({
      time: moment(),
    })
    if ( moment(this.state.willAttemptToReconnectTime).isBefore(moment()) ) {
      clearInterval(this.intervalID);
      this.attemptReconnect();
    }
  }
  
  reloadWithDelay() {
    var reloadDelay = this.randomNumber(2000,10000);
    console.log('SignalR reconnected, page will reload in ' + reloadDelay + 'ms.');
    setTimeout(() => window.location.reload(), reloadDelay);
  }
  
  setUpSignalr() {
    console.log('Setting up signalr.');

    const options = {qs: 'connectionData=[{"name":"signalhub"}]'};
    const connection = hubConnection(signalr_url, options);

    this.setState({ connection }, () => {
      this.state.connection.start()
        .always(() => {
          this.isStartedTimeout = setTimeout(() => {
            this.setState({
              mode: "Disconnected",
            }, this.startReconnect())
          }, 5000);
        })
        .done(() => {
          console.log('Connected, setting up proxy.');
          console.log('state.mode = ' + this.state.mode);
          if (this.state.mode === 'Reconnecting') { //if mode is 'Reconnecting' that means we lost the connection and started a new connection without a page reload, so reload here. M.N. 7/8/2020 (seems like connection.stateChanged is not being called on the initial setup of a connection)
            this.reloadWithDelay();
          }

          var signalProxy = this.state.connection.createHubProxy('signalHub');
          if ( this.props.setReception ) { signalProxy.invoke('setReception'); }
          if ( this.props.setAttendant ) { signalProxy.invoke('setAttendant', this.props.setAttendant); }
          if ( this.props.setRoom ) { signalProxy.invoke('setRoom', this.props.setRoom); }
          if ( this.props.setExitScreen ) { signalProxy.invoke('setExitScreen'); }

          if ( this.props.onRoomsUpdated ) {
            signalProxy.on('roomsUpdated', (s) => this.props.onRoomsUpdated(s))
          }
          if ( this.props.onRoomUpdated ) {
            signalProxy.on('roomUpdated', (s) => this.props.onRoomUpdated(s))
          }
          if ( this.props.onSectionsUpdated ) {
            signalProxy.on('sectionsUpdated', (s) => this.props.onSectionsUpdated())
          }
          if ( this.props.onReservationsUpdated ) {
            signalProxy.on('reservationsUpdated', (s) => this.props.onReservationsUpdated())
          }
          if ( this.props.onReceptionUpdated ) {
            signalProxy.on('receptionUpdated', (s) => this.props.onReceptionUpdated())
          }
          if ( this.props.onNewSupplyRequest ) {
            signalProxy.on('newSupplyRequest', (s) => this.props.onNewSupplyRequest(s))
          }
          if ( this.props.onNewReadyForMikvah ) {
            signalProxy.on('newReadyForMikvah', (s) => this.props.onNewReadyForMikvah(s))
          }
          if ( this.props.onNewReadyForChecking ) {
            signalProxy.on('newReadyForChecking', (s) => this.props.onNewReadyForChecking(s))
          }
          if ( this.props.onEntryDoorOpen ) {
            signalProxy.on('entryDoorOpened', (s) => this.props.onEntryDoorOpen())
          }
          if ( this.props.onCubiclesUpdated ) {
            signalProxy.on('cubiclesUpdated', (s) => this.props.onCubiclesUpdated(s))
          }
          if ( this.props.onYouAreNext ) {
            signalProxy.on('youAreNext', (s) => this.props.onYouAreNext(s))
          }
          if ( this.props.onPrinterTrouble ) {
            signalProxy.on('printerTrouble', (s) => this.props.onPrinterTrouble(s))
          }
          signalProxy.on('pageReload', (s) => this.handlePageReload());
          signalProxy.on('reloadSettings', (s) => this.handleReloadSettings());

          this.setState({ signalProxy });

          this.state.connection.starting(() => {
            console.log('starting');
          })

          this.state.connection.received(() => {
            console.log('received');
            clearTimeout(this.isStartedTimeout);
            this.setState({
              mode: "Connected",
            })
          })

          /*this.state.connection.reconnecting(() => {
            console.log('reconnecting');
          })*/

          /*this.state.connection.connectionSlow(() => {
            console.log('connectionSlow');
          })*/

          /*this.state.connection.reconnected(() => {
            console.log('reconnected');
          })*/

          /*this.state.connection.disconnected(() => {
            console.log('disconnected');
          })*/

          this.state.connection.stateChanged((response) => {
            let mode = null;
            console.log(response);
            switch ( response.newState ) {
              case 0: //Connecting
                mode = "Connecting";
                break;
              case 1: //Connected
                mode = "Connected";
                this.reloadWithDelay();
                clearInterval(this.intervalID);
                break;
              case 2: //Reconnecting
                mode = "Reconnecting";
                break;
              case 4: //Disconnected
                mode = "Disconnected";
                this.startReconnect();
                break;
              default:
                break;
            }
            this.setState({
              mode: mode,
            })
          });
        });
    })
  }



  render() {
    const { t } = this.props;
    const { mode, willAttemptToReconnectTime, time, updateNew } = this.state;
    return (mode!==null?
      <>
       {updateNew?
       <Settings updateNow={true}></Settings>
       :null}
      <div className={"signalr-status mode-" + mode.toLowerCase()}>
      <FontAwesomeIcon className="icon" icon="wifi" />
      {mode==="Connected"?
        <>{t("Connected")}</>
      :null}
      {mode==="Reconnecting"?
        <><ReactSVG className="loader-spinner" src={LoaderSpinner} />{t("Attempting to Reconnect")} </>
      :null}
      {mode==="Disconnected"&&willAttemptToReconnectTime!=null?
        <>{t("Attempting to reconnect in")} {moment.duration(moment(willAttemptToReconnectTime).diff(moment(time))).format("m:ss")}</>
      :null}
      {mode==="Disconnected"&&willAttemptToReconnectTime==null?
        <>
          {t("Lost connection.")} <span className="btn btn-link" onClick={this.handleClickTryAgain}>Try again</span>
        </>
      :null}
    </div>
    </>
    :null)
  }
}

export default withTranslation()(SignalR);