import React from 'react';
import { withTranslation } from 'react-i18next';

class RoomSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      cleaning: 0,
      available: 0
    };
  }

  componentDidMount() {
    this.processSummary();
  }

  componentDidUpdate(prevProps) {
    if ( this.props.rooms !== prevProps.rooms ) {
      this.processSummary();
    }
  }

  processSummary() {
    let summary = {};
    this.props.rooms.forEach(room => {
      room = {...room};
      if ( room.status.includes('Mikvah')||room.status.includes('Checking') ) { room.statusColor = 'yellow' };
      if ( room.roomType === 'wheelchair' ) { room.roomType = 'bath' };
      let type = { green: 0, orange: 0, purple: 0, grey: 0, yellow: 0 };
      if ( typeof summary[room.roomType] == "undefined" ) {
        summary[room.roomType] = type;
      }
      if ( typeof summary[room.roomType][room.statusColor] == "undefined" ) {
        summary[room.roomType][room.statusColor] = 0;
      }
      summary[room.roomType][room.statusColor] += 1;
    });
    this.setState({
      summary: summary
    });
    this.setState({
      needsCleaning: this.props.rooms.filter((room)=>room.status==="NeedsCleaning").length,
      cleaning: this.props.rooms.filter((room)=>room.status==="Cleaning").length,
      available: this.props.rooms.filter((room)=>room.status==="Ready"&&room.section.attendant!=null).length,
      dressing: this.props.rooms.filter((room)=>room.status==="Dressing").length
    })
  }

  render() {
    const { t, i18n, hideTitle }= this.props;
    const { summary } = this.state;
    return summary!=null ?
      <div className={"room-availability lang-" + i18n.language}>
        {hideTitle?null:<h3>{t("Room Summary")}</h3>}
        <div className="items">
          <div>
            <div className="title">{t("Available")}</div><span className={"status status-color-green bg-green"}>{this.state.available}</span>
          </div>
          {this.state.summary.shower?
          <div>
            <div className="title">{t("Shower")}</div><span className={"status status-color-orange bg-yellow"}>{this.state.summary.shower.yellow}</span>
          </div>
          :null}
          <div>
            <div className="title">{t("Bath")}</div><span className={"status status-color-orange bg-yellow"}>{this.state.summary.bath.yellow}</span>
          </div>
          <div>
            <div className="title">{t("Dressing")}</div><span className={"status status-color-purple bg-maroon"}>{this.state.dressing}</span>
          </div>
          <div>
            <div className="title">{t("To Clean")}</div><span className={"status status-color-purple bg-purple"}>{this.state.needsCleaning}</span>
          </div>
          <div>
            <div className="title">{t("Cleaning")}</div><span className={"status status-color-purple bg-orange"}>{this.state.cleaning}</span>
          </div>
        </div>
      </div>
    : null
  }
}

export default withTranslation()(RoomSummary);