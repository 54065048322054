import React, { Component } from 'react';
import { Alert, UncontrolledTooltip } from 'reactstrap';

import Moment from 'react-moment';
import { getReservations } from './../../models/reservation';

import Clock from './../../components/clock';
import moment from 'moment';
import SignalR from './../../components/signalr';
import Loader from './../../components/snippets/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SalonView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorReservations: null,
      isLoadedReservations: false,
    };
  }

  componentDidMount() {
    this.retrieveReservations();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result;

          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
            errorReservations: null,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            errorReservations: error
          });
        }
      );
  }
  
  render() {
    const { isLoadedReservations, reservations, errorReservations } = this.state;

    return (
      <div>
        <SignalR setReception={true} onReservationsUpdated={() => this.retrieveReservations()} />
        <div className={'container-page view-tablet'}>
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-center">
                  <Clock />
                </div>
              </div>
              <div>
                <div className="header-center">
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <div className="container-body">
              <div className="sidebar-top">
                {errorReservations !== null ?
                  <div>
                    <Alert color="danger">{errorReservations} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>Try again</span></Alert>
                  </div>
                :
                  isLoadedReservations ?
                    <div>
                      <div className="d-flex">
                        <h3>Active</h3>
                        <button id="tooltipGroupActive" className="btn mis-auto"><FontAwesomeIcon icon="question" /></button>
                      </div>
                      <UncontrolledTooltip target="tooltipGroupActive">Guests with salon appointment who have completed their appointment within the last hour.</UncontrolledTooltip>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Reservation</th>
                            <th>Type</th>
                            <th>Ready</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservations.completed.filter(res => (res.salonManicure||res.salonPedicure) && moment(res.timeCompleted).isAfter(moment().subtract(1, "hour")))
                            .map((res, i) => {
                            return <tr key={i}>
                              <td className="room-number">{res.reservationCode}</td>
                              <td></td>
                              <td><Moment format="h:mma" date={res.timeCompleted}></Moment></td>
                            </tr>
                          })}
                          {reservations.completed.filter(res => (res.salonManicure||res.salonPedicure) && moment(res.timeCompleted).isAfter(moment().subtract(1, "hour"))).length === 0 ?
                            <tr><td colSpan="5" className="text-center">No ready guests with salon appointments.</td></tr>
                            : null}
                        </tbody>
                      </table>

                      <div className="d-flex">
                        <h3>Upcoming</h3>
                        <button id="tooltipGroupUpcoming" className="btn mis-auto"><FontAwesomeIcon icon="question" /></button>
                      </div>
                      <UncontrolledTooltip target="tooltipGroupUpcoming">Guests with salon appointment who have checked in.</UncontrolledTooltip>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Reservation</th>
                            <th>Type</th>
                            <th>Arrived</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservations.here.filter(res => (res.salonManicure||res.salonPedicure))
                            .map((res, i) => {
                            return <tr key={i}>
                              <td className="room-number">{res.reservationCode}</td>
                              <td></td>
                              <td><Moment format="h:mma" date={res.timeArrived}></Moment></td>
                            </tr>
                          })}
                          {reservations.here.filter(res => (res.salonManicure||res.salonPedicure)).length === 0 ?
                            <tr><td colSpan="5" className="text-center">There are no checked in guests with salon appointments.</td></tr>
                            : null}
                        </tbody>
                      </table>

                      <div className="d-flex">
                        <h3>Later</h3>
                        <button id="tooltipGroupLater" className="btn mis-auto"><FontAwesomeIcon icon="question" /></button>
                      </div>
                      <UncontrolledTooltip target="tooltipGroupLater">Guests with salon appointment who have not yet checked in.</UncontrolledTooltip>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Reservation</th>
                            <th>Type</th>
                            <th>Arrived</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservations.upcoming.filter(res => (res.salonManicure||res.salonPedicure))
                            .map((res, i) => {
                            return <tr key={i}>
                              <td className="room-number">{res.reservationCode}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          })}
                          {reservations.upcoming.filter(res => (res.salonManicure||res.salonPedicure)).length === 0 ?
                            <tr><td colSpan="5" className="text-center">There are no upcoming reservations with salon appointments.</td></tr>
                            : null}
                        </tbody>
                      </table>

                    </div>
                  : <Loader />
                }
              </div>
            </div>
          </div>
          <div className="container-sidebar">
          </div>
        </div>
      </div>
    );
  }
}

export default SalonView;
