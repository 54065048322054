import React from 'react';
import { withTranslation } from 'react-i18next';

import { getAttendants } from './../../models/attendant';
import { getSections, setSectionAttendant, setSectionHelper, setBorStatus } from './../../models/section';
import { getActiveReception, updateActiveReception, deleteActiveReception } from './../../models/staff';
import { getStaff } from './../../models/staff';
import Loader from './../../components/snippets/loader';
import { Alert } from 'reactstrap';
import Button from './../../components/button';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

class ManagerAttendantView extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isLoadedAttendants: false,
      isLoadedSections: false,
      isLoadedActiveReception: false,
      attendant: null,
      sections: null,
      error: null,
      modalAttendant: false,
      activeReception: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.retrieveAttendants = this.retrieveAttendants.bind(this);
    this.modalAttendantToggle = this.modalAttendantToggle.bind(this);
    this.retrieveActiveReception = this.retrieveActiveReception.bind(this);
    this.handleChangeReceptionist = this.handleChangeReceptionist.bind(this);
    this.handleDeleteReceptionist = this.handleDeleteReceptionist.bind(this);
    this.retrieveStaff = this.retrieveStaff.bind(this);
  }

  componentDidMount() {
    this.retrieveAttendants();
    this.retrieveSections();
    this.retrieveActiveReception();
    this.retrieveStaff();
  }

  retrieveActiveReception() {
    getActiveReception()
      .then(
        result => {
          this.setState({
            isLoadedActiveReception: true,
            activeReception: result
          });
        },
        error => {
          this.setState({
            isLoadedReception: true,
            error
          });
        }
      );
  }

  handleChangeReceptionist(staff) {
    updateActiveReception(staff)
    .then(result => {
      this.setState({
        activeReception: result,
      })
    }, error => {
      this.setState({error});
    });
  }

  handleDeleteReceptionist(staff) {
    deleteActiveReception(staff)
    .then(result => {
      this.setState({
        activeReception: result,
      })
    }, error => {
      this.setState({error});
    });
  }

  retrieveStaff() {
    getStaff(true)
      .then(result => {
        let staffs = result;
        this.setState({
          staffs,
          isLoadedStaff: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let attendants = result;
        this.setState({
          attendants,
          isLoadedAttendants: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  retrieveSections() {
    getSections()
      .then(result => {
        let sections = result;
        this.setState({
          sections,
          isLoadedSections: true,
        })
      }, error => {
        this.setState({error});
      });
  }

  handleChangeSectionAttendant(id, attendantId) {
    let sections = this.state.sections;
    let selectedAttendant = attendantId ? this.state.attendants.find(s=>s.id===attendantId) : null;
    sections.find(s=>s.id===id).attendant = selectedAttendant;
    this.setState({
      sections
    })
    setSectionAttendant(id, attendantId)
      .then(result => {
        this.retrieveSections();
      })
  }

  handleChangeSectionHelper(id, attendantId) {
    let sections = this.state.sections;
    let selectedAttendant = attendantId ? this.state.attendants.find(s=>s.id===attendantId) : null;
    sections.find(s=>s.id===id).helper = selectedAttendant;
    this.setState({
      sections
    })
    setSectionHelper(id, attendantId)
      .then(result => {
        this.retrieveSections();
      })
  }

  handleChangeBorStatus(id, newStatus) {
    let sections = this.state.sections;
    sections.find(s=>s.id===id).borStatus = newStatus;
    this.setState({
      sections
    })
    setBorStatus(id, newStatus)
      .then(result => {
        this.retrieveSections();
      })
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var attendant = {...this.state.attendant};
    attendant[name] = value;
    this.setState({attendant});
  }

  showAttendantForm(attendant) {
    this.setState({
      attendant: attendant,
      modalAttendant: true,
    })
  }

  modalAttendantToggle() {
    this.setState({
      modalAttendant: !this.state.modalAttendant,
    })
  }
  
  render() {
    const { t } = this.props;
    const { attendants, attendant, isLoadedAttendants, sections, isLoadedSections, isLoadedActiveReception, activeReception, isLoadedStaff, staffs, error } = this.state;
    return (
      <div className="reception-view">
          {isLoadedActiveReception && isLoadedStaff?
            <div className="card card-default card-body row flex-row mb-4">
              <div className="d-flex justify-content-between w-100 mb-2 align-items-center">
                <h5 className="mb-0">{t("Active Receptionist")}</h5>
                <UncontrolledDropdown className="">
                  <DropdownToggle caret className="btn-block">
                    {t("Add Receptionist")}
                  </DropdownToggle>
                  <DropdownMenu>
                    {staffs && staffs.map((staff,i) => {
                      return staff.active && staff.role=="Receptionist" && !activeReception.filter(rec=>rec.id===staff.id).length ?
                       <DropdownItem key={i} onClick={() => this.handleChangeReceptionist(staff.id)}>{staff.name}</DropdownItem>
                       : null
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            {activeReception && activeReception.length?
            <table className="table">
              <thead>
                <tr>
                  <th>{t("Name")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {activeReception && activeReception.map((rec, i) => {
                  return <tr key={i}>
                    <td>{t(rec.name)}</td>
                    <td className="text-end"><Button type="secondary" onClick={() => this.handleDeleteReceptionist(rec.id)}>{t("Remove")}</Button></td>
                  </tr>
                })}
              </tbody>
            </table>
            :null}
          </div>
          :<Loader />}
          {isLoadedAttendants&&isLoadedSections?
            <div className="row">
              <div className="col-12">
                {sections.map((section,i)=>{
                  return <div className={"mb-3"} key={i}>
                    <div className="card card-default card-body row flex-row">
                      <h5 className="mb-2 col-12">{t("Section")} {section.name}:</h5>
                      <UncontrolledDropdown className="col-4">
                      {t("Attendant")}
                        <DropdownToggle caret className="btn-block">
                          {section.attendant!==null ? section.attendant.firstName + " " + section.attendant.lastName : t("No Attendant")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className={section.attendant===null?"active":""} onClick={() => this.handleChangeSectionAttendant(section.id,null)}>{t("No Attendant")}</DropdownItem>
                          <DropdownItem divider />
                          {attendants.map((attendant,i) => {
                            return attendant.active ?
                             <DropdownItem key={i} className={section.attendant && attendant.id===section.attendant.id?"active":""} onClick={() => this.handleChangeSectionAttendant(section.id,attendant.id)}>{attendant.firstName} {attendant.lastName}</DropdownItem>
                             : null
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown className="col-4">
                      {t("Helper")}
                        <DropdownToggle caret className="btn-block">
                          {section.helper!==null ? section.helper.firstName + " " + section.helper.lastName : t("No Helper")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className={section.helper===null?"active":""} onClick={() => this.handleChangeSectionHelper(section.id,null)}>{t("No Helper")}</DropdownItem>
                          <DropdownItem divider />
                          {attendants.map((attendant,i) => {
                            return attendant.active && (attendant.id !== (section.attendant && section.attendant.id ))?
                             <DropdownItem key={i} className={section.helper && attendant.id===section.helper.id?"active":""} onClick={() => this.handleChangeSectionHelper(section.id,attendant.id)}>{attendant.firstName} {attendant.lastName}</DropdownItem>
                             : null
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown className="col-4">
                      {t("Bor Status")}
                        <DropdownToggle caret className="btn-block">
                          {t(section.borStatus)}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem />
                          {["Online", "Offline"].map((status,i) => {
                            return <DropdownItem key={i} className={section.borStatus===status?"active":""} onClick={() => this.handleChangeBorStatus(section.id,status)}>{t(status)}</DropdownItem>
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                })}
              </div>
            </div>
          : (error ?
            <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
          : <Loader />)}
        </div>
    )
  }
}
export default withTranslation()(ManagerAttendantView);
