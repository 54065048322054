import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NumericKeyboard } from 'numeric-keyboard/dist/numeric_keyboard.react';
import Flipper from './flipper';
import Button from './button';
import i18n from './../i18n';
import { getCardReaders } from '../models/setting';
import { SettingsContext } from './settings';

class KioskAdmin extends React.Component {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props);
    this.state = {
      hiddenPressTimeout: 1000,
      adminCorrectCode: context.settings ? context.settings.roomAdminPasscode.value : '',
      adminCode: '',
      isAuthenticated: false,
      isOpenAdvanced: false,
    };
  }

  componentDidMount() {
    this.retrieveCardReaders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isActivated&&this.state.isActivated) {
      this.setState({
        isAuthenticated: false,
        isOpenAdvanced: false,
        adminCode: '',
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.touchTimeout);
  }

  retrieveCardReaders = () => {
    getCardReaders()
      .then(
        result => {
          this.setState({
            cardReaders: result,
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }

  handleTouchStart = () => {
    this.touchTimeout = setTimeout(()=>this.setState({isActivated: true}), this.state.hiddenPressTimeout);    
  }

  handleTouchEnd = () => {
    clearTimeout(this.touchTimeout);
  }

  toggle = () => {
    this.setState({
      isActivated: !this.state.isActivated,
    })
  }

  toggleAdvanced = () => {
    this.setState({
      isOpenAdvanced: !this.state.isOpenAdvanced,
    })
  }

  handlePress = (key) => {
    let input = this.state.adminCode;
    if ( key === 'del' && input.length!==0 ) { input = input.slice(0, -1); }
    if ( typeof key === 'number' && (input.length<4) ) { input = input + key; }
    this.setState({
      adminCode: input,
    })
    if ( /*key === 'enter' &&*/ input.length>=4 ) {
      if ( input===this.state.adminCorrectCode ) {
        setTimeout(()=>{
          this.setState({isAuthenticated: true});
        },100);
      } else {
        this.setState({adminCode: ''});
      }
    }
  }
    
  modalStatusToggle = () => {
    this.setState({
      modalStatus: !this.state.modalStatus,
    });
  }

  handleClearKiosk = () => {
    localStorage.removeItem('selectedPrinter');
    this.props.handleKioskPrinterSelected(null);
    this.props.changePrinter(null);
    this.setState({ isActivated: false });
  }

  handleEarlyCheckin = () => {
    this.props.handleEarlyCheckin("enterCode");
    this.setState({ isActivated: false })
  }

  render() {
    const { isActivated, isAuthenticated, adminCode, isUpdatingStatus, isOpenAdvanced, cardReaders } = this.state;
    const { t, beforeCheckinAllowed } = this.props;
    const { changeCardReader, selCardReader } = this.context;
    const currentReader = cardReaders && cardReaders.find(cr=>selCardReader==cr.stripeTerminalId);
    return <div className="kiosk-admin" onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}>
      <Modal className={"modal-kiosk-admin lang-" + i18n.language} isOpen={isActivated} centered toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{t("Admin Menu")}</ModalHeader>
        <ModalBody>
          <div>
            {isAuthenticated?(
              <div className="text-center">
                <ul className="list-unstyled pis-0">
                  {beforeCheckinAllowed?
                    <li>
                      <Button type="secondary btn-lg mb-3" onClick={this.handleEarlyCheckin}>{t("Check In")}</Button>
                    </li>
                  :null}
                  {!isOpenAdvanced && beforeCheckinAllowed?
                    <li>
                      <Button onClick={this.toggleAdvanced}>{t("Show Advanced")}</Button>
                    </li>
                  :null}
                </ul>
                {isOpenAdvanced || !beforeCheckinAllowed?
                  <ul className="list-unstyled pis-0">
                    <li>
                      <Button type="secondary btn-lg mb-3" onClick={()=>window.location.reload(true)}>{t("Refresh Screen")}</Button>
                    </li>
                    {cardReaders && cardReaders.length?
                        <UncontrolledDropdown className="col-8 mx-auto">
                          <DropdownToggle caret className="btn-block">
                           {selCardReader&&currentReader?t(currentReader.readerName):t("Select Card Reader")}
                          </DropdownToggle>
                          <DropdownMenu>
                            {selCardReader?<DropdownItem onClick={() => changeCardReader(null)}>{t("Clear Card Reader")}</DropdownItem>:null}
                            {cardReaders && cardReaders.length?
                            <>
                            <DropdownItem divider />
                            {cardReaders.map((cardReader,i) => {
                              return  cardReader.stripeTerminalId !== selCardReader?
                              <DropdownItem key={i} onClick={() => changeCardReader(cardReader.stripeTerminalId)}>{cardReader.readerName}</DropdownItem>
                              :null
                            })}
                            </>
                            :null}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      :null}
                    {/* <li>
                      <Link to="/kiosk" onClick={this.handleClearKiosk}><Button type="secondary btn-lg mb-3">{t("Clear Kiosk Printer")}</Button></Link>
                    </li> */}
                  </ul>
                :null}
              </div>
            ):(
              <>
                <div className="wrapper-number">
                  <div className={"flippers justify-content-center mb-4"}>
                    {[0,0,0,0].map((v,i) => {
                      return <Flipper type="single" key={i} text={adminCode[i]?'•':''} />
                    })}
                  </div>
                </div>
                <NumericKeyboard layout="tel" onPress={this.handlePress} />
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>;
  }
}

export default withTranslation()(KioskAdmin);