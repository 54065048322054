import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { SettingsContext } from './../components/settings';

class Clock extends React.Component {
  static contextType = SettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toISOString()
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    const { settings } = this.context;
    this.setState({
      time: moment(new Date().toISOString()).subtract(settings&&settings.offset&&settings.offset.value,'seconds')
    });
  }
  render() {
    const { t } = this.props;
    const { settings } = this.context;
    switch ( this.props.type ) {
      case 'fullDate':
        return (
          <div className="full-date">
              <Moment className="mie-3" format={t("dddd, MMMM D")} element="span">{this.state.time}</Moment>
              {settings?
                <span>{settings.hebrewDayHe.value} {settings.hebrewMonthHe.value}, {settings.hebrewYearHe.value}</span>
              :null}
          </div>
        );
      default:
        return (
          <div className="clock">
              <Moment className="time" format={t("h:mm")} element="span">{this.state.time}</Moment>
              <Moment format={t("A")} element="span" className="small">{this.state.time}</Moment>
          </div>
        );
    }
  }
}

export default withTranslation()(Clock);