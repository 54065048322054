import React from "react";

import i18n from '../../../i18n';
import Clock from './../../../components/clock';
import ZmanTevilah from '../../../components/zman-tevilah';
import ClosingTime from '../../../components/closing-time';
import Shkia from '../../../components/shkia';
import Weather from './../../../components/snippets/weather';
import logo from '../../../assets/images/MikvahLogo.png';
import { logoImageAltText } from "../../../global-props";

function ReceptionistHeader(props) {
  return <header className={"header reception-header lang-" + i18n.language}>
    <div>
      <div className="header-left row align-items-center">
        <img src={logo} className="logo" alt={logoImageAltText}/>
      </div>
      <div className="header-center">
        <Clock />
        <Clock type="fullDate" />
      </div>
      <div className="header-right row flex-xl-row-reverse align-items-end justify-content-xl-start justify-content-end">
        <Weather />
        <div className="">
          <Shkia />
          <ZmanTevilah />
          <ClosingTime />
        </div>
      </div>
    </div>
  </header>
}

export default ReceptionistHeader;