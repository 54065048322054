import React from 'react';
import { withTranslation } from 'react-i18next';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Button from './button';

class CardForm extends React.Component {

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleIsLoading(true);
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => this.props.onGetToken(payload));
    } else {
      this.props.handleIsLoading(false);
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  render() {
    const { t, isLoading } = this.props;
    return <form onSubmit={this.handleSubmit} autoComplete="off">
      <div className="form-group">
        <label className="sr-only">
          {t("Add New Card")}
        </label>
        <div className="form-control">
          <CardElement
              hidePostalCode={true}
              {...createOptions(this.props.fontSize)}
            />
        </div>
      </div>
      <div className="d-flex">
        {/*<Button type="outline-primary mx-1" size="lg" onClick={() => this.props.handleUpdateCreditMode(null)} disable={isLoading}>Cancel</Button>*/}

        <Button type="secondary mx-auto btn-lg" disabled={this.props.disabled} loading={isLoading}>{this.props.paymentButtonText?t(this.props.paymentButtonText):t('Charge Card')}</Button>
      </div>
    </form>
  }
}
export default withTranslation()(injectStripe(CardForm));

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: '"Lato", monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};
