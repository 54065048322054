import React from "react";

import { withTranslation } from 'react-i18next';
import BodyHeader from "../partials/BodyHeader";
import Button from "./../../../components/button";
import { createReservation } from './../../../models/reservation';
import { Alert } from 'reactstrap';
import iconShower from "../../../assets/images/icon-shower.svg";
import iconBath from "../../../assets/images/icon-bath.svg";
import iconKallah from "../../../assets/images/icon-kallah.svg";
import iconParking from "../../../assets/images/icon-parking.svg";
import iconClockExtra from "../../../assets/images/icon-clock-extra.svg";
import iconWheelchair from "../../../assets/images/icon-wheelchair.svg";
import ReactSVG from "react-svg";
import { SettingsContext } from "../../../components/settings";
import CurrencyFormat from "../../../components/snippets/currency-format";

class CheckinRoomType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isSubmitting: false,
      bath: props.reservation.bath ? props.reservation.bath : false,
      // parking: props.reservation.parking ? props.reservation.parking : false,
      handicapped: props.reservation.handicapped ? props.reservation.handicapped : false,
      doubleSlot: props.reservation.doubleSlot ? props.reservation.doubleSlot : false,
      kallah: props.reservation.kallah ? props.reservation.kallah : false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(value, type) {
    let option = value.name;
    let prevOptionState = this.state[option];
    let options = {};
    if ( type==='type' ) {
      options = { bath: false, handicapped: false, kallah: false };
      if ( this.state[option] ) return;
      if ( option==='shower' ) {
        if ( !this.state.bath && !this.state.kallah && !this.state.handicapped ) return;
        option = 'bath';
        prevOptionState = !prevOptionState;
      }
    }
    options = {...options, [option]: !prevOptionState};
    this.setState(options);
  }

  handleSubmit() {
    let res = {
      ...this.props.reservation,
      roomType: this.state.handicapped ? "wheelchair" : (this.state.bath?"bath":"shower"),
      bath: this.state.bath,
      handicapped: this.state.handicapped,
      kallah: this.state.kallah,
      doubleSlot: this.state.doubleSlot,
      // parking: this.state.parking,
    }

    this.setState({
      isSubmitting: true,
    })

    //If checking in using an existing reservation, remove time and advanceReservation.
    if ( res.replacingReservationId ) {
      res.reservationTime = null;
      res.advanceReservation = false;
    }

    //Create/modify reservation
    createReservation(res)
      .then(result => {
        this.props.handleUpdateReservation(result, 'checkin-payment');
      }, error => {
        this.setState({
          error,
          isSubmitting: false,
        })
      });
  }

  render() {
    const { t, reservation }  = this.props;
    const { isSubmitting, error } = this.state;

    return (
      <SettingsContext.Consumer>
        {({settings}) => <>
          <BodyHeader isEditingReservation={reservation.reservationId||reservation.replacingReservationId} xsubtitle={reservation.reservationId||reservation.replacingReservationId?"Update Reservation":"No Reservation Found"} goBack={this.props.goBack} />

          {error!==null?<div className="checkin-message"><Alert color="danger">{t(error)}</Alert></div>:null}

          <div className="modal-body">
            <div className="checkin-roomType">
              <div className="title">{t("Select Room Type")}</div>
              <div className="d-flex">
                {[
                  {title:"Shower", name:"shower", icon: iconShower, price: settings.priceShower.value, minutes: settings.reservationMinutesShower.value },
                  {title:"Bath", name:"bath", icon: iconBath, price: settings.priceBath.value, minutes: settings.reservationMinutesBath.value },
                  {title:"Kallah", name:"kallah", icon: iconKallah, price: settings.priceKallah.value, minutes: settings.reservationMinutesBath.value },
                  {title:"Accessible", name:"handicapped", icon: iconWheelchair, price: settings.priceBath.value, minutes: settings.reservationMinutesBath.value }
                ].map((type,i) => {
                  let active = this.state[type.name];
                  if ( type.name==='shower' ) { active = (!this.state.bath&&!this.state.kallah&&!this.state.handicapped) }
                  return <div key={i}><Button type="circle" active={active} icon={<ReactSVG src={type.icon} />} value={type} xtext={type.title} key={i} onClick={() => this.handleChange(type, "type")} /><div className="btn-text">{t(type.title)}</div><div className="price"><CurrencyFormat value={type.price} /></div>{type.minutes?<div className="price">{type.minutes} {t("min")}</div>:null}</div>;
                })}
              </div>
            </div> 
            <div className="checkin-roomType">
              <div className="title">{t("Additional Options")}</div>
              <div className="d-flex">
                {[
                  // {title:"Parking", name:"parking", icon: iconParking, price: settings.priceParking.value },
                  {title:"Extra Time", name:"doubleSlot", icon: iconClockExtra, price: settings.priceDoubleSlot.value, minutes: settings.reservationMinutesDoubleSlot.value },
                ].map((type,i) => {
                  return <div key={i}><Button type="circle" active={this.state[type.name]} icon={<ReactSVG src={type.icon} />} value={type} xtext={type.title} onClick={() => this.handleChange(type, "option")} /><div className="btn-text">{t(type.title)}</div><div className="price"><CurrencyFormat value={type.price} /></div>{type.minutes?<div className="price">+{type.minutes} {t("min")}</div>:null}</div>;
                })}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button type="secondary" size="lg" loading={isSubmitting} onClick={this.handleSubmit}>{reservation.reservationId||reservation.replacingReservationId?t("Update Reservation"):t("Create Reservation")}</Button>
          </div>
        </>}
      </SettingsContext.Consumer>
    );
  }
}

export default withTranslation()(CheckinRoomType);
