import React from "react";
import { withTranslation } from 'react-i18next';
import { updateRoomStatus } from './../models/room';
import Button from './../components/button';
import { Alert } from 'reactstrap';

class ScreenCleaning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUpdatingStatus: false,
    }
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus() {
    let roomId = this.props.room.id;
    let status = "Ready";

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status)
      .then(
        result => {
          this.props.handleUpdateRoom(result);
        },
        error => {
          this.setState({
            isUpdatingStatus: false,
            error
          });
        }
      );
  }

  render() {
    const { t } = this.props;
    const { error } = this.state;
    return (
      <div className="body-right">
        {error!=null
        ? <Alert color="danger">{t("An error occurred.")}</Alert>
        : null}

        <div className="instructions">
          {t("cleaningInstructions")}
        </div>
        <Button type="circle-big" onClick={this.handleChangeStatus} loading={this.state.isUpdatingStatus} text={t("Done Cleaning")} />
      </div>
    );
  }
}

export default withTranslation()(ScreenCleaning);
