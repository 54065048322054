import React from "react";
import { Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { getAuthorization, config, logout } from './models/account';
import { adminPagesMenu } from './global-props';
import { withTranslation } from 'react-i18next';

import RoomView from "./views/room";
import ReceptionistView from "./views/receptionist";
import ReceptionistMapView from "./views/receptionist/map";
import ReceptionistReservationsView from "./views/receptionist/reservations";
import ReceptionistCheckInView from "./views/receptionist/checkin";
import ReceptionistAdvancedReservationView from "./views/receptionist/reservation";
import ReceptionistDevicesView from "./views/receptionist/devices";
import ReceptionistPaymentView from "./views/receptionist/payment";
import ReceptionistHeader from "./views/receptionist/partials/header";
import AttendantMapView from "./views/attendant/map";
import AttendantSectionMapView from "./views/attendant/section-map";
import AttendantAllTasks from "./views/attendant/all-tasks";
import Login from "./views/Login";
import WaitingRoomView from "./views/waitingroom";
import KioskView from "./views/kiosk";
import MikvahView from "./views/mikvah";
import AttendantTabletView from "./views/attendant/tablet";
import CleanerView from "./views/cleaner";
import ManagerView from "./views/manager";
import UsageReport from "./views/manager/usageReport";
import ExitView from "./views/exit";
import LobbyView from "./views/lobby";
import SalonView from "./views/salon";
import Button from "./components/button";
import { SettingsProvider } from './components/settings';
import ReceptionistUserView from "./views/receptionist/user";
import CubicleView from "./views/cubicle";
import EditTranslations from "./views/translations/translations";

const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute path="/" exact component={Index} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/translations/" usernames={['translator@spotlightdesign.com']} component={EditTranslations} />

      <PrivateRoute path="/receptionist/map" usernames={['Reception']} render={() => <ReceptionistWideScreenMapView><ReceptionistMapView/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/reservations/kallah" usernames={['Reception']} render={() => <ReceptionistWideScreenMapView><ReceptionistReservationsView key="1" kallah={true}/></ReceptionistWideScreenMapView> } />
      <PrivateRoute path="/receptionist/reservations" usernames={['Reception']} render={() => <ReceptionistWideScreenMapView><ReceptionistReservationsView key="2"/></ReceptionistWideScreenMapView> } />
      <PrivateRoute path="/receptionist/checkin" usernames={['Reception']} render={({location}) => <ReceptionistWideScreenMapView><ReceptionistCheckInView reservation={location.state&&location.state.reservation?location.state.reservation:null} userId={location.state&&location.state.userId?location.state.userId:null} walkin={location.state&&location.state.walkin?location.state.walkin:false}/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/reservation" usernames={['Reception']} render={({location}) => <ReceptionistWideScreenMapView><ReceptionistAdvancedReservationView reservation={location.state&&location.state.reservation?location.state.reservation:null} kallah={location.state&&location.state.kallah} isEditingReservation={location.state&&location.state.isEditingReservation?location.state.isEditingReservation:null} userId={location.state&&location.state.userId?location.state.userId:null} /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/devices" usernames={['Reception']} render={({location}) => <ReceptionistWideScreenMapView><ReceptionistDevicesView /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/payment" usernames={['Reception']} render={({location}) => <ReceptionistWideScreenMapView><ReceptionistPaymentView userId={location.state&&location.state.userId?location.state.userId:null} /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/user/:id" usernames={['Reception']} render={({match}) => <ReceptionistWideScreenMapView><ReceptionistUserView userId={match.params.id} /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/user" usernames={['Reception']} render={({location}) => <ReceptionistWideScreenMapView><ReceptionistUserView userId={location.state&&location.state.userId?location.state.userId:null} /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/dashboard" usernames={['Reception']} render={() => <ReceptionistWideScreenMapView><ReceptionistView/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/receptionist/" usernames={['Reception']} render={() => <Redirect to="/receptionist/dashboard/" />} />

      <PrivateRoute path="/attendant/tablet/" usernames={['Attendant']} component={() => <AttendantTabletView />} />
      <PrivateRoute path="/attendant/map/" usernames={['Attendant']} component={() => <AttendantMapView/>} />
      <PrivateRoute path="/attendant/alltasks/" usernames={['Attendant']} component={() => <AttendantAllTasks/>} />
      <PrivateRoute path="/attendant/sectionmap/:id" usernames={['Attendant']} component={({match}) => <AttendantSectionMapView id={match.params.id} />} />
      <PrivateRoute path="/attendant/sectionmap/" usernames={['Attendant']} component={() => <AttendantSectionMapView/>} />
      <PrivateRoute path="/attendant/" usernames={['Attendant']} component={Attendant} />
      
      <PrivateRoute path="/room/:id/" usernames={['Room', 'Reception']} component={({match}) => <RoomView id={match.params.id} />} />
      <PrivateRoute path="/room/" usernames={['Room']} component={() => <RoomView/>} />

      <PrivateRoute path="/waiting/" usernames={['Waiting']} component={() => <WaitingRoomView />} />

      <PrivateRoute path="/cubicle/:id" usernames={['Waiting']} component={({match}) => <CubicleView id={match.params.id} />} />
      <PrivateRoute path="/cubicle/" usernames={['Waiting']} component={() => <CubicleView />} />
      
      <PrivateRoute path="/kiosk/:id/" usernames={['Kiosk']} component={({match}) => <KioskView id={match.params.id} />} />
      <PrivateRoute path="/kiosk/" usernames={['Kiosk']} component={() => <KioskView />} />
      
      <PrivateRoute path="/mikvah/:id/" usernames={['Mikvah']} component={({match}) => <MikvahView id={match.params.id} />} />
      <PrivateRoute path="/mikvah/" usernames={['Mikvah']} component={() => <MikvahView />} />
      
      <PrivateRoute path="/cleaner/" usernames={['Cleaner']} component={() => <CleanerView />} />
      
      {/* <PrivateRoute path="/manager/checkin" render={({location}) => <ReceptionistCheckInView reservation={location.state&&location.state.reservation?location.state.reservation:null} userId={location.state&&location.state.userId?location.state.userId:null} managerMenu={true} />} />
      <PrivateRoute path="/manager/map" component={() => <ReceptionistMapView/>} /> */}
      <PrivateRoute path="/manager/reservations/report" component={(props) => <ReceptionistWideScreenMapView><UsageReport {...props} /></ReceptionistWideScreenMapView>} />
      {/* <PrivateRoute path="/manager/reservation" component={({location}) => <ReceptionistAdvancedReservationView reservation={location.state&&location.state.reservation?location.state.reservation:null} managerMenu={true} />} />
      <PrivateRoute path="/manager/reservations" component={() => <ReceptionistReservationsView managerMenu={true}/>} /> */}
      {/* <PrivateRoute path="/manager/devices" component={() => <ReceptionistDevicesView managerMenu={true}/>} /> */}
      <PrivateRoute path="/manager/schedule" component={() => <ReceptionistWideScreenMapView><ManagerView route="Schedule" /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/manager/settings" component={() => <ReceptionistWideScreenMapView><ManagerView route="Settings"/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/manager/advanced" component={() => <ReceptionistWideScreenMapView><ManagerView route="SettingsAdvanced"/></ReceptionistWideScreenMapView>} />
      {/* <PrivateRoute path="/manager/payment" component={() => <ReceptionistPaymentView managerMenu={true} />} /> */}
      <PrivateRoute path="/manager/attendants" usernames={['Reception']} component={() => <ReceptionistWideScreenMapView><ManagerView route="Attendants"/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/manager/users" component={() => <ReceptionistWideScreenMapView><ManagerView route="AdminUsers"/></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/manager/audio" component={() => <ReceptionistWideScreenMapView><ManagerView route="Audio"/></ReceptionistWideScreenMapView>} />
      {/* <PrivateRoute path="/manager/user/:id" component={({match}) => <ReceptionistUserView managerMenu={true} userId={match.params.id} />} />
      <PrivateRoute path="/manager/user" component={({location}) => <ReceptionistUserView managerMenu={true} userId={location.state&&location.state.userId?location.state.userId:null} />} /> */}
      <PrivateRoute path="/manager/dashboard" usernames={['Reception']} component={() => <ReceptionistWideScreenMapView><ManagerView route="ManagerDash" /></ReceptionistWideScreenMapView>} />
      <PrivateRoute path="/manager/" component={() => <Redirect to="/manager/dashboard/" />} />
      
      <PrivateRoute path="/exit/" usernames={['ExitScreen']} component={() => <ExitView />} />
      <PrivateRoute path="/lobby/" usernames={['LobbyScreen']} component={() => <LobbyView />} />
      
      <PrivateRoute path="/salon/" usernames={['Salon']} component={() => <SalonView />} />
      
    </Switch>
  </Router>
);

config.onLogout = () => {
  appAuth.signout();
  history.push('/');
  /*withRouter(
    ({ history }) => console.log(history)
  );*/
  //this.props.history.push("/login");
};


export const appAuth = {
  authorization: null,
  isAuthenticated() {
    return this.authorization != null
  },
  checkAuthorization(cb) {
    let authorization = getAuthorization();
    if ( authorization && authorization.refreshToken !== null ) {
      this.authorization = authorization;
    } else {
      this.authorization = null;
    }
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.authorization = null;
    setTimeout(cb, 100);
  }
};

function PrivateRoute({ component: Component, render: Render, ...rest }) {
  appAuth.checkAuthorization();
  return (
    <SettingsProvider>
      <Route
        {...rest}
        render={props =>
          appAuth.isAuthenticated() ? (
            //If Admin role OR allowed username
            appAuth.authorization.roles.includes('"Admin"') || (typeof(rest.usernames)!=='undefined'?rest.usernames.includes(appAuth.authorization.username):false) || props.location.pathname==='/' ? (
              Render ? (
                Render(props)
              ) : Component ? (
                  <Component {...props} />
              ) : null
            ) : (
              <Redirect to={{ pathname: "/", }} />
            )
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    </SettingsProvider>
  );
}

const Index = withTranslation()((props) => {
  const { t } = props;
  appAuth.checkAuthorization();
  return <div className="container-page vh-100 d-flex align-items-center">
    <div className="container-body text-center">
      <nav>
        <ul className="list-unstyled">
          {adminPagesMenu.map((item,i) => {
            if (typeof(item.usernames)==='undefined' || (appAuth.isAuthenticated() && (appAuth.authorization.roles.includes('"Admin"') || item.usernames.includes(appAuth.authorization.username)))) {
              return <li key={i}>
                <Link to={item.link} className="btn btn-secondary btn-lg mb-3">{t(item.title)}</Link>
              </li>
            } else {
              return null;
            }
          })}
          <hr />
          {appAuth.isAuthenticated()?
            <li>
              <Button type="primary" onClick={logout}>{t("Logout")}</Button>
            </li>
          :
            <li>
              <Link to="/login/" className="btn btn-primary">{t("Login")}</Link>
            </li>
          }
        </ul>
      </nav>
    </div>
  </div>
})

const Attendant = withTranslation()((props) => {
  const { t } = props;
 return <div className="container-page vh-100 d-flex align-items-center">
  <div className="container-body text-center">
    <nav>
      <ul className="list-unstyled">
        <li>
          <Link to="/attendant/map/" className="btn btn-secondary btn-lg mb-3">{t("Map")}</Link>
        </li>
        <li>
          <Link to="/attendant/sectionmap/" className="btn btn-secondary btn-lg mb-3">{t("Section Map")}</Link>
        </li>
        <li>
          <Link to="/attendant/alltasks/" className="btn btn-secondary btn-lg mb-3">{t("All Sections Tasks")}</Link>
        </li>
        <li>
          <Link to="/attendant/tablet/" className="btn btn-secondary btn-lg mb-3">{t("Tablet")}</Link>
        </li>
      </ul>
    </nav>
  </div>
</div>});

const ReceptionistWideScreenMapView = (props) => <div className="container-page">
  <ReceptionistHeader />
  <div className="container-page container-page-widescreen align-items-center">
    {props.children}
    <div className="view-map-extension">
      <ReceptionistMapView />
    </div>
  </div>
</div>;

export default AppRouter;