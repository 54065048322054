import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import Moment from 'react-moment';
import { getCleaningRooms } from './../../models/room';

import Clock from './../../components/clock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import SignalR from './../../components/signalr';
import Loader from './../../components/snippets/loader';
import { SettingsContext } from '../../components/settings';
import LanguageSelector from '../../components/language-selector';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import ScreenSaver from '../../components/screensaver';
import i18n from './../../i18n';

class CleanerView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoadedRooms: false,
      time: moment().format(),
      roomsToClean: [],
      roomsInCleaning: [],
      roomsDressing: [],
      roomsToCleanFull: [],
      roomsNeedToClean: -1
    };
    this.notificationSound = new Audio(NotificationSoundFile);
    this.playNotificationSound = this.playNotificationSound.bind(this);
  }

  componentDidMount() {
    this.retrieveRooms();
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  retrieveRooms() {
    getCleaningRooms()
      .then(
        result => {
          this.processCleaning(result);
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  processCleaning(result) {
    const {settings} = this.context;
    let shouldPlayNotification = false;
    let prevRooms = this.state.roomsToClean;
    let roomsToClean = result.roomsToClean;
    let roomsInCleaning = result.roomsInCleaning;
    let roomsDressing = result.roomsDressing;
    let roomsToCleanFull = result.roomsToCleanFull;
    let roomsNeedToClean = result.roomsNeedToClean;
    let roomsNeedToCleanBath = result.roomsNeedToCleanBath;
    let roomsNeedToCleanShower = result.roomsNeedToCleanShower;
    roomsToClean
      .map(room=>{
        let prevRoom = prevRooms.find(pr=>pr.id===room.id)
        if (prevRooms.length && !prevRoom) { shouldPlayNotification = true; }
        let minutesAllowed = settings.needsCleaningMinutes.value;
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>minutesAllowed;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(minutesAllowed, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
        return room;
      })    
    roomsInCleaning.map((room,i) => {
        let minutesAllowed = settings.cleaningMinutes.value;
        room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>minutesAllowed;
        room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(minutesAllowed, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
      return room;
    })
    roomsToClean = roomsToClean.sort((a,b)=> a.sort < b.sort ? 1 : -1);
    roomsToClean = roomsToClean.sort((a,b)=> parseInt(a.cleaningSection) > parseInt(b.cleaningSection) ? 1 : -1);

    if (shouldPlayNotification) { this.playNotificationSound() }
    this.setState({
      isLoadedRooms: true,
      error: null,
      roomsToClean: roomsToClean,
      roomsInCleaning: roomsInCleaning,
      roomsDressing: roomsDressing,
      roomsToCleanFull: roomsToCleanFull,
      roomsNeedToClean: roomsNeedToClean,
      roomsNeedToCleanBath: roomsNeedToCleanBath,
      roomsNeedToCleanShower: roomsNeedToCleanShower
    });
  }
  
  render() {
    const { t } = this.props;
    const { isLoadedRooms, roomsToClean, roomsInCleaning, roomsDressing, roomsToCleanFull, roomsNeedToClean, roomsNeedToCleanBath, roomsNeedToCleanShower, time, error } = this.state;
    const {settings} = this.context;

    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} />
        <div className={'container-page view-tablet lang-' + i18n.language}>
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-center">
                  <Clock />
                </div>
              </div>
              <div>
                <div className="header-center">
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <div className="container-body">
              <LanguageSelector />
              <React.Fragment>
                <div className="sidebar-top">
                  {error !== null ?
                    <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                  :
                    isLoadedRooms ?
                      <div>
                        {!roomsToClean.length && !roomsInCleaning && !roomsNeedToClean?
                          <ScreenSaver />
                        :null}
                        <div className="d-flex justify-content-between">
                          <h3>{t("Needs Cleaning")}</h3>
                          {/* <h3>{t("Rooms Need Cleaning")}: {t("Bath")} <b>{roomsNeedToCleanBath}</b> {t("Shower")} <b>{roomsNeedToCleanShower}</b></h3> */}
                        </div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Room")}</th>
                              <th>{t("Section")}</th>
                              <th>{t("Type")}</th>
                              <th>{t("Wait Time")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roomsToClean
                              .map((room, i) => {
                              return <tr key={i}>
                                <td className="room-number">{room.id}</td>
                                <td className="room-number">{room.cleaningSection}</td>
                                <td>
                                  <FontAwesomeIcon icon={room.roomType} />
                                </td>
                                <td>
                                  {moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).format("h:*mm:ss")} 
                                  <span className={room.isOverTime?"over-time":""}>
                                    {room.isOverTime?parseInt(room.overTimeMinutes)+':00':null}
                                  </span>
                                </td>
                              </tr>
                            })}
                            {roomsToClean.length === 0 ?
                              <tr><td colSpan="5" className="text-center">{t("No rooms need cleaning.")}</td></tr>
                              : null}
                          </tbody>
                        </table>

                        {roomsDressing.length?
                        <>
                        <h3>{t("Rooms in Dressing")}</h3>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Room")}</th>
                              <th>{t("Section")}</th>
                              <th>{t("Type")}</th>
                              <th>{t("Wait Time")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roomsDressing
                              .map((room, i) => {
                              return <tr key={i}>
                                <td className="room-number">{room.id}</td>
                                <td className="room-number">{room.cleaningSection}</td>
                                <td>
                                  <FontAwesomeIcon icon={room.roomType} />
                                </td>
                                <td>
                                  {moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).format("h:*mm:ss")}
                                </td>
                              </tr>
                            })}
                            {roomsDressing.length === 0 ?
                              <tr><td colSpan="5" className="text-center">{t("No rooms are in dressing.")}</td></tr>
                              : null}
                          </tbody>
                        </table>
                        </>
                        :null}

                      {/* {roomsToCleanFull.length?
                        <>
                        <h3>{t("Quick Cleaned")}</h3>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Room")}</th>
                              <th>{t("Section")}</th>
                              <th>{t("Type")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roomsToCleanFull
                              .map((room, i) => {
                              return <tr key={i}>
                                <td className="room-number">{room.id}</td>
                                <td className="room-number">{room.cleaningSection}</td>
                                <td>
                                  <FontAwesomeIcon icon={room.roomType} />
                                </td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                        </>
                        :null} */}

                        <h3>{t("Cleaning In Progress")}</h3>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Room")}</th>
                              <th>{t("Section")}</th>
                              <th>{t("Type")}</th>
                              <th>{t("Wait Time")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roomsInCleaning
                              .map((room, i) => {
                              return <tr key={i}>
                                <td className="room-number">{room.id}</td>
                                <td className="room-number">{room.cleaningSection}</td>
                                <td>
                                  <FontAwesomeIcon icon={room.roomType} />
                                </td>
                                <td>
                                  {moment.duration(moment(time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).format("h:*mm:ss")}
                                  <span className={room.isOverTime?"over-time":""}>
                                    {room.isOverTime?parseInt(room.overTimeMinutes)+':00':null}
                                  </span>
                                </td>
                              </tr>
                            })}
                            {roomsInCleaning.length === 0 ?
                              <tr><td colSpan="5" className="text-center">{t("No rooms are being cleaned.")}</td></tr>
                              : null}
                          </tbody>
                        </table>

                      </div>
                    : <Loader />
                  }
                </div>
              </React.Fragment>
            </div>
          </div>
          <div className="container-sidebar">
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CleanerView);
