import React from "react";
import { Redirect } from "react-router-dom";
import { appAuth } from "../router";
import { withTranslation } from 'react-i18next';
import { login } from './../models/account';
import { Alert } from 'reactstrap';
import { logoImageAltText } from "../global-props";

import logo from '../assets/images/MikvahLogo.png';
import ReactSVG from 'react-svg';
import Button from './../components/button';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      username: null,
      password: null,
      error: null,
      isLoading: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  login = () => {
    this.setState({error: null, isLoading: true});
    login(this.state.username, this.state.password)
      .then(
        result => {
          if ( result ) {
            appAuth.checkAuthorization(() => {
              this.setState({ redirectToReferrer: true });
            });
          }
        },
        error => {
          this.setState({error, isLoading: false});
        }
      )
  };

  handleSubmit(e) {
    e.preventDefault();
    this.login();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  render() {
    const { t } = this.props;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer, error, isLoading } = this.state;
    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div className="view-login container vh-100 py-5 d-flex align-items-center">
        <div className="container-login">
          <div className="logo mb-5">
            <img src={logo} className="logo" alt={logoImageAltText}/>          
          </div>
          <h2>{t("Log In")}</h2>
          {error?<Alert color="warning">{t(error)}</Alert>:null}
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input type="text" className="form-control" placeholder={t("Username")} name="username" onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input type="password" className="form-control" placeholder={t("Password")} name="password" onChange={this.handleChange} />
            </div>
            <Button type="secondary" loading={isLoading}>{t("Log In")}</Button> 
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
