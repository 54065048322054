import React from "react";

import { withTranslation } from 'react-i18next';
import i18n from './../../../i18n';
import BodyHeader from "./BodyHeader";
import Calendar from 'react-calendar';
import moment from "moment";
import { SettingsContext } from './../../../components/settings';
import HebrewDate from './../../../components/hebrewDate';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

class ReservationCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      allowPastCutoff: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkbox = this.checkbox.bind(this);
  }

  componentDidMount() {
    if (this.props.reservation && (this.props.reservation.reservationId || this.props.reservation.replacingReservationId) ) {
      this.setState({
        selectedDate: moment(this.props.reservation.reservationDate).toDate()
      });
    }
  }

  handleChange(date) {
    let ignoreCutoff = this.state.allowPastCutoff ? true : null;
    this.props.handleUpdateReservation({...this.props.reservation, reservationDate: date, ignoreCutoff});
    this.props.handleScreenChange('reservation-setup');
  }

  checkbox() {
    this.setState({
      checked: !this.state.checked
    });
  }

  render() {
    const { t, reservation } = this.props;
    const { allowPastCutoff, selectedDate } = this.state;
    return (
      <>
        <BodyHeader isEditingReservation={this.props.isEditingReservation||(reservation&&reservation.replacingReservationId)} isAdvancedReservation={true}/>
        <div className="modal-body">
          <SettingsContext.Consumer>
            {({settings}) => {
              let isAfterCutoff = settings && settings.reservationCutOff ? moment().isAfter(moment(settings.reservationCutOff.value)) : false;
              return <>
                <Calendar
                  onChange={(value) => this.setState({selectedDate: value})}
                  value={selectedDate?selectedDate:moment().add((isAfterCutoff?1:0),"days").toDate()}
                  minDate={moment().add((isAfterCutoff&&!allowPastCutoff?1:0),"days").toDate()}
                  maxDate={moment().add(9 + (isAfterCutoff?1:0),"days").toDate()}
                  onClickDay={(value) => this.handleChange(value)}
                  showNeighboringMonth={true}
                  minDetail="month"
                  calendarType="US"
                  next2Label={false}
                  locale={i18n.language}
                  tileContent={({date, view}) => <div><HebrewDate className="dir-rtl" date={date} day={true}/></div>}
                  formatMonthYear={(locale, date) => {return (<>{moment(date).format('MMMM')} <HebrewDate date={date} month={true} monthNext={true}/></>)}}
                />
                {isAfterCutoff&&!allowPastCutoff?
                  <UncontrolledDropdown className="mt-4">
                    <DropdownToggle caret>{t("Advanced Options")}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={()=>this.setState({allowPastCutoff: true})}>
                        {t("Allow Reservations Past Cutoff")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                :null}
              </>
            }}
          </SettingsContext.Consumer>

        </div>
      </>
    );
  }
}

export default withTranslation()(ReservationCalendar);
