import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getTimes } from '../models/times';

class closingTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      time: null
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.refresh(),
      360000
    );
    this.retrieveClosingTime();
  }

  retrieveClosingTime() {
    let localStorageTime = localStorage.getItem('closingTime');
    let today = moment().startOf('day');

    if ( localStorageTime && moment(localStorageTime).startOf('day').isSame(today) ) {
      this.setState({
        isLoaded: true,
        time: localStorageTime
      })
      return false;
    }
    getTimes()
      .then(
        result => {
          localStorage.setItem('closingTime', result.closingTime);
          this.setState({
            isLoaded: true,
            time: result.closingTime
          })
        }
      );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  refresh() {
    this.retrieveClosingTime();
  }

  render() {
    const { t } = this.props;
    const { isLoaded, time } = this.state;
    return isLoaded ? (
      <div className="closing-time">
        <div className="title">{t("Closing")}</div>
        <Moment format={t("h:mm A")} element="span">{time}</Moment>
      </div>
    ) : null;
  }
}

export default withTranslation()(closingTime);