import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class ErrorBoundary extends Component {
  constructor(props) {
  super(props);
  this.state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };
}
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    this.setState({ error: error.message, errorInfo });
    if(error.message.includes("React error #185")){
      setTimeout(() => window.location.reload(), 10000);
    }
  }
  render() {
  const { hasError, error, errorInfo } = this.state;
  if (hasError) {
    return(
      <div className="card my-5">
      <div className="card-header">
        <p>
          There was an error in loading this page.{' '}
          <span
            style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload this page
          </span>{' '}
        </p>
      </div>
      <div className="card-body">
        <div>{moment().format()}</div>
        <details style={{whiteSpace:'break-spaces'}} className="error-details">
          <summary>Click for error details</summary>
          {error}
          <br/><b>Error Info:</b>
          {errorInfo&&errorInfo.componentStack}
        </details>
      </div>
    </div>
    )
  }
  return this.props.children;
  }
}
export default withTranslation()(ErrorBoundary);