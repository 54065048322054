import React, { Component } from 'react';
import i18n from './../../i18n';

import ManagerSettings from './settings';
import ManagerAttendantView from './attendants';
import ManagerDashboardView from './dashboard';
import ManagerUserView from './users';
import ManagerAudioView from './audio';

import ReceptionistTabs from '../receptionist/partials/tabs';
import ManagerScheduleView from './schedule';

class ManagerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };

  }

  currentView() {
    this.currentViewName = this.props.route;
    switch (this.props.route) {
      case 'ManagerDash':
        return <ManagerDashboardView />;
      case 'Settings':
        return <ManagerSettings />;
      case 'SettingsAdvanced':
        this.currentViewName = 'Advanced Settings';
        return <ManagerSettings showAdvanced={true} />;
      case 'Attendants':
        return <ManagerAttendantView />;
      case 'Schedule':
        return <ManagerScheduleView />;
      case 'AdminUsers':
        this.currentViewName = 'Admin Users';
        return <ManagerUserView />;
      case 'Audio':
        return <ManagerAudioView />;
      default:
        return true;
    }
  }

  render() {
    return (
      <div className={'container-page view-manager lang-' + i18n.language}>
        <div className="container-main">
          <div className="container container-body manager-background">
            <ReceptionistTabs page="admin"/>
            {this.currentView()}
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerView;
