import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';

import Moment from 'react-moment';
import { getRooms } from './../../models/room';
import { getSections, setSectionAttendant } from './../../models/section';
import { getAttendants } from './../../models/attendant';

import ReactSVG from 'react-svg';
import iconExcludedAttendant from "../../assets/images/icon-excluded-attendant.svg";

import { getReservations } from './../../models/reservation';
import Clock from './../../components/clock';
import ZmanTevilah from './../../components/zman-tevilah';
import ProgressBar from './../../components/snippets/progress-bar';
import RoomSummary from './../receptionist/room-summary';
import SignalR from './../../components/signalr';
import moment from 'moment';
import NotificationSoundFile from './../../assets/sounds/notification.mp3';
import Loader from './../../components/snippets/loader';
import { SettingsContext } from '../../components/settings';
import ScreenSaver from '../../components/screensaver';
import { statuses } from './../../global-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/button';
import { updateRoomStatus } from './../../models/room';
import LanguageSelector from './../../components/language-selector';

import AttendantListView from './partials/attendantList';
import Emergency from '../../components/emergency';

class AttendantTabletView extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoadedRooms: false,
      isLoadedReservations: false,
      errorReservations: null,
      modal: false,
      selectedRoom: null,
      supplyRequests: null,
      dropdownAttendantOpen: false,
      selectedAttendant: null,
      attendants: null,
      attendantRooms: null,
      time: moment().format(),
      showManageAttendants: false,
      allAttendants: null,
    };

    this.toggleDropdownAttendant = this.toggleDropdownAttendant.bind(this);
    this.handleAttendantChange = this.handleAttendantChange.bind(this);
    this.notificationSound = new Audio(NotificationSoundFile);
    this.playNotificationSound = this.playNotificationSound.bind(this);
    this.handleShowUpcomingReservations = this.handleShowUpcomingReservations.bind(this);
    this.handleShowAllReservations = this.handleShowAllReservations.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidMount() {
    this.retrieveReservations();
    //this.retrieveRooms();
    this.retrieveSections();
   //this.retrieveAttendants();
    this.setUpTick();
  }

  setUpTick() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  tick() {
    this.setState({
      time: moment().format(),
    });
  }

  toggleDropdownAttendant() {
    this.setState(prevState => ({
      dropdownAttendantOpen: !prevState.dropdownAttendantOpen
    }))
  }

  handleAttendantChange(attendant) {
    this.setState({
      selectedAttendant: attendant,
      showManageAttendants: false,
    }, () => {
      if (attendant) {
        localStorage.setItem('selectedAttendant', attendant.id)
      } else {
        localStorage.removeItem('selectedAttendant');
      }
      if (this.state.rooms) {
        this.processRooms(this.state.rooms);
      }
    })
  }

  handleChangeSectionAttendant(id, attendantId) {
    let sections = this.state.sections;
    let selectedAttendant = attendantId ? this.state.allAttendants.find(s=>s.id===attendantId) : null;
    sections.find(s=>s.id===id).attendant = selectedAttendant;
    this.setState({
      sections
    })
    setSectionAttendant(id, attendantId)
      .then(result => {
        this.retrieveSections();
      })
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearTimeout(this.resreservationListTimer);
  }

  retrieveAttendants() {
    getAttendants(true)
      .then(result => {
        let selectedAttendantId = localStorage.getItem('selectedAttendant');
        let selectedAttendant = selectedAttendantId?result.find(s=>s.id===parseInt(selectedAttendantId)):null;
        if ( this.state.sections.filter(section=>section.attendant&&selectedAttendant&&section.attendant.id===selectedAttendant.id).length===0 ) {
          selectedAttendant = null;
          localStorage.removeItem('selectedAttendant');
        }
        this.setState({
          allAttendants: result,
          selectedAttendant,
        }, () => this.retrieveRooms())
      }, error => {
        this.setState({error});
      });
  }

  retrieveRooms() {
    getRooms()
      .then(
        result => {
          let rooms = result;
          this.processRooms(rooms);
        },
        error => {
          this.setState({
            isLoadedRooms: false,
            error
          });
        }
      );
  }

  processRooms(rooms) {
    const {settings} = this.context;
    let attendantRooms = rooms;
    let selectedAttendant = this.state.selectedAttendant;
    if ( selectedAttendant!==null && !this.state.showAllReservations ) {
      attendantRooms = rooms.filter(room => room.section.attendant!==null && room.section.attendant.id===selectedAttendant.id);
    }

    let supplies = attendantRooms.map((room, i) => {
      let hasActiveRequest = false;
      if ( room.reservation!=null ) {
        room.reservation.supplies.forEach((supply) => {
          if (supply.status === 'open') { hasActiveRequest = true; }
          supply.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes()>settings.waitingForSuppliesMinutes.value;
          supply.overTimeMinutes = supply.isOverTime?moment.duration(moment(this.state.time).subtract(settings.waitingForSuppliesMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(supply.dateRequested)).asMinutes():null;
        });
        if (room.effectiveStatus==='ReadyForMikvah'||room.effectiveStatus==='ReadyForMikvahNext') {
          room.isOverTime = moment.duration(moment(this.state.time).subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes()>settings.readyForMikvahMinutes.value;
          room.overTimeMinutes = room.isOverTime?moment.duration(moment(this.state.time).subtract(settings.readyForMikvahMinutes.value, 'minute').subtract(settings&&settings.offset&&settings.offset.value,'seconds').diff(room.effectiveStatusLastChange)).asMinutes():null;
        }
      }
      return hasActiveRequest ? room.reservation : null;
    }).filter(room => room!=null);
    this.setState({
      isLoadedRooms: true,
      supplyRequests: supplies,
      error: null,
      rooms: rooms,
      attendantRooms: attendantRooms
    });
  }

  retrieveSections() {
    getSections()
      .then(
        result => {
          let attendants = result.map((section) => {
            return section.attendant;
          }).filter((attendant, index, self) => {
           return index === self.findIndex((t) => (t !== null && attendant !== null) && t.id === attendant.id)
          }
          );

          this.setState({
            isLoadedSections: true,
            sections: result,
            attendants: attendants,
            errorSections: null,
          }, () => {this.retrieveAttendants()});
        },
        error => {
          this.setState({
            isLoadedSections: false,
            errorSections: true,
          });
        }
      );
  }
  
  retrieveReservations() {
    getReservations()
      .then(
        result => {
          let reservations = result;

          this.setState({
            reservations: reservations,
            isLoadedReservations: true,
            errorReservations: null,
          });
        },
        error => {
          this.setState({
            isLoadedReservations: false,
            errorReservations: error
          });
        }
      );
  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  playNotificationSoundForAttendant(roomId) {
    let selectedAttendant = this.state.selectedAttendant;
    if ( selectedAttendant ) {
      let room = this.state.rooms.filter(room=>room.id===roomId)[0];
      if ( room.section.attendant.id === selectedAttendant.id ) {
        this.playNotificationSound();
      }
    } else {
      this.playNotificationSound();
    }
  }

  handleNewSupplyRequest(id) {
    this.playNotificationSoundForAttendant(id);
  }

  handleNewReadyForMikvah(id) {
    this.playNotificationSoundForAttendant(id);
  }

  handleShowUpcomingReservations() {
    this.setState({showUpcomingReservations: true}, () => this.resreservationListTimer = setTimeout(()=>this.setState({showUpcomingReservations: false}),10000))
  }

  handleShowAllReservations() {
    const {rooms} = this.state;
    this.setState({showAllReservations: true}, () => {
      return this.processRooms(rooms),
      this.resreservationListTimer = setTimeout(()=>this.setState({showAllReservations: false}, () => this.processRooms(rooms)),10000)
    })
  }

  handleSectionsUpdated() {
    this.retrieveSections();
    console.log('sections updated');
  }

  handleChangeStatus(roomId, status, reservationStatus) {

    this.setState({
      isUpdatingStatus: true,
    })

    updateRoomStatus(roomId, status, reservationStatus)
    .then(
      result => {
        this.retrieveRooms();
      },
      error => {
        this.setState({
          error
        });
      }
    ).finally(() => 
      this.setState({
        isUpdatingStatus: false,
      })
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { isLoadedRooms, isLoadedReservations, isLoadedSections, rooms, sections, reservations, time, error, supplyRequests, attendants, attendantRooms, selectedAttendant, showManageAttendants, allAttendants, showUpcomingReservations, isUpdatingStatus } = this.state;

    return (
      <div>
        <SignalR setReception={true} onRoomsUpdated={() => this.retrieveRooms()} setReservations={true} onReservationsUpdated={() => this.retrieveReservations()} onNewSupplyRequest={(id) => this.handleNewSupplyRequest(id)} onNewReadyForMikvah={(id) => this.handleNewReadyForMikvah(id)} onSectionsUpdated = { () => this.handleSectionsUpdated() } />
        <div className={'container-page view-attendant-tablet view-tablet lang-' + i18n.language}>
          <div className="container-main">
            <header className="header">
              <div>
                <div className="header-left">
                  {attendants!=null ?
                    <Dropdown isOpen={this.state.dropdownAttendantOpen} toggle={this.toggleDropdownAttendant}>
                      {t("Attendant")}:
                      <DropdownToggle caret className="d-block">
                        {showManageAttendants?
                          t("Manage")
                        :
                          (selectedAttendant!==null?
                            selectedAttendant.firstName + " " + selectedAttendant.lastName
                          :
                          t("All")
                          )
                        }
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this.handleAttendantChange(null)}>{t("All Attendants")}</DropdownItem>
                        {attendants.length?
                          <>
                            <DropdownItem divider />
                            {attendants.map((attendant,i) => {
                              let attendantSections = sections.filter(s=>s.attendant&&s.attendant.id===attendant.id).map(s=>s.id);
                              return <DropdownItem key={i} onClick={() => this.handleAttendantChange(attendant)}>{attendant.firstName} {attendant.lastName} - {t("Section")} {attendantSections.join(', ')}</DropdownItem>
                            })}
                          </>
                        :null}
                        <DropdownItem divider />
                        <DropdownItem onClick={() => this.setState({showManageAttendants: true})}>{t("Manage")}</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  :null}
                  {selectedAttendant!==null?
                   <div onClick={() => this.handleShowAllReservations()}>{t("All Attendants")}</div>
                  :null}
                </div>
                <div className="header-right">
                  <Clock />
                </div>
              </div>
              <div>
                <div className="header-left">
                  <ZmanTevilah />
                </div>
                <div className="header-right">
                  <Clock type="fullDate" />
                </div>
              </div>
            </header>
            <div className="container-body">
              <React.Fragment>
                <div className="sidebar-top">
                  {showManageAttendants?(
                    <div className="row justify-content-center mb-5">
                      <div className="col-sm-6">
                        <div className="xrow">
                          {isLoadedSections ? sections.map((section,i) => {
                            return <div className={"xcol-3 mb-3"} key={i}>
                              <div className="card card-default card-body">
                                <UncontrolledDropdown>
                                {t("Section")} {section.name} {t("Attendant")}:
                                  <DropdownToggle caret className="btn-block">
                                    {section.attendant!==null ? section.attendant.firstName + " " + section.attendant.lastName : t("No Attendant")}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem className={section.attendant===null?"active":""} onClick={() => this.handleChangeSectionAttendant(section.id,null)}>{t("No Attendant")}</DropdownItem>
                                    <DropdownItem divider />
                                    {allAttendants?allAttendants.map((attendant,i) => {
                                      return attendant.active ?
                                      <DropdownItem key={i} className={section.attendant && attendant.id===section.attendant.id?"active":""} onClick={() => this.handleChangeSectionAttendant(section.id,attendant.id)}>{attendant.firstName} {attendant.lastName}</DropdownItem>
                                      :null
                                    }):null}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          }) : <Loader /> }
                        </div>

                      </div>
                    </div>
                  ):(
                    error !== null ?
                      <Alert color="danger">{t(error)} <span className="alert-link mis-2" onClick={()=>window.location.reload()}>{t("Try again")}</span></Alert>
                    :
                      isLoadedRooms ?
                        <div>
                          {showUpcomingReservations?
                            <div>
                              <h3>{t("Upcoming")}</h3>
                              <table className="table text-start">
                                <thead>
                                  <tr>
                                    <th>{t("Code")}</th>
                                    <th>{t("Time")}</th>
                                    <th>{t("Status")}</th>
                                    <th>{t("Room Type")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reservations?
                                    reservations.upcoming.length?
                                      reservations.upcoming.map((res,i) => {
                                        return <tr key={i}>
                                          <td>
                                            {res.reservationCode}{res.user.testUser?<small className="text-muted"> {t("Test")}</small>:null}
                                            {res.firstTime?
                                              <>
                                                <sup><FontAwesomeIcon className="mis-2 text-orange small" icon="asterisk" id={`icon-new-user-${res.reservationCode}`} /></sup>
                                                <UncontrolledTooltip target={`icon-new-user-${res.reservationCode}`}>{t("New User")}</UncontrolledTooltip>
                                              </>
                                            :null}

                                            </td>
                                          <td><Moment format={t("h:mma")}>{res.reservationTime}</Moment></td>
                                          <td>{t(statuses[res.reservationStatus].name)}</td>
                                          <td>
                                            <FontAwesomeIcon icon={res.kallah?['far','gem']:res.roomType} id={"room-type-icon-"+i} />
                                            {res.parking?<FontAwesomeIcon className="icon mis-2" icon="car" />:null}
                                            {res.doubleSlot?<FontAwesomeIcon className="icon mis-2" icon={["far", "clock"]} />:null}
                                            {res.salonManicure||res.salonBoth?<FontAwesomeIcon className="icon mis-2" icon="paint-brush" />:null}
                                          </td>
                                          <td>
                                            {res.notes!=null||res.user.notes||res.user.internalNote?<FontAwesomeIcon icon={['far', 'sticky-note']} />:null}
                                            {res.user.excludedAttendants && res.user.excludedAttendants.length>0?<ReactSVG className="icon-excluded" src={iconExcludedAttendant} alt="Excluded Attendant Icon"/>:null}
                                            </td>
                                        </tr>
                                      })
                                    :<tr>
                                      <td colSpan="4" className="text-center">{t("No upcoming reservations.")}</td>
                                    </tr>
                                  :null}                          
                                </tbody>
                              </table>
                            </div>
                          :null}
                          <AttendantListView isLoadedRooms={rooms} attendantTablet={true} rooms={rooms} attendant={selectedAttendant} handleChangeStatus={this.handleChangeStatus} isUpdatingPrepStatus={isUpdatingStatus} isLoadedSections={isLoadedSections} sections={sections} isLoadedAttendants={allAttendants} attendants={allAttendants} supplyRequests={supplyRequests} id={this.props.id} />
                          <Emergency rooms={rooms} />
                        </div>
                      : <Loader />
                    )
                  }
                </div>
                <div className="sidebar-bottom">
                  {/* <div>Summary</div> */}
                  {isLoadedReservations ?
                    <React.Fragment>
                      <div>
                        { isLoadedRooms && attendantRooms && attendantRooms.length ?
                          <>
                            <Button className="float-right btn-link btn-sm" onClick={this.handleShowUpcomingReservations}>{t("Upcoming")}</Button>
                            <RoomSummary rooms={attendantRooms} />
                          </>
                        : <Loader />}
                      </div>
                      <div className="reservation-count">
                        <ProgressBar reservations={reservations} />
                        <div className="row text-center">
                          <div className="col">{reservations.completed.length} {t("CompletedRooms")}</div>
                          <div className="col">{reservations.here.length} {t("Here")}</div>
                          <div className="col">{reservations.upcoming.length} {t("Upcoming")}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  : ""}
                </div>
                <LanguageSelector />
              </React.Fragment>
            </div>
          </div>
          <div className="container-sidebar">
          </div>
        </div>



      </div>
    );
  }
}

export default withTranslation()(AttendantTabletView);
